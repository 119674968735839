import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


function SkelUpgradeCard({ key, }) {
  return (
  <div key = {key} className='z-0'>
    <span>
      <div className = "my-1 flex items-left justify-left border-t border-gray-100">
        <Skeleton count = {0.3} containerClassName='flex-[0.3]'/>
      </div>
      <div className = "my-1 flex items-left justify-left overflow-hidden border-t border-gray-100">
        <Skeleton containerClassName='flex-[0.7]'/>
      </div>
    </span>
    <div className = "my-1 flex items-left justify-left overflow-hidden border-t border-gray-100">
        <Skeleton height = {60} containerClassName='flex-1 z-0'/>
    </div>

  </div>
  )
}

export default SkelUpgradeCard;