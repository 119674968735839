import React, { useState, useEffect } from "react";
import axios from "axios";

export default function SignInPage() {
  const [result, setResult] = useState("");

  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  }
  
  const unSub = async () => {
    try {
      const website = getQueryVariable("website");

      if (!website) {
        throw new Error("Website parameter is missing");
      }

			const response = await axios.post("https://murmuring-plateau-21252-b7994639367f.herokuapp.com/unsubscribe", { website });
			console.log("response", response.data);
      setResult("good");
		} catch (error) {
			console.error(error);
      setResult("bad");
		}
  }

  useEffect(() => {
    unSub();
  }, [])

  return (
    <div className="mx-auto max-w-max">
      {result === "good" 
      ? <p>You have successfully unsubscribed from the email list</p>
      : result === "bad"
      ? <p>Something went wrong, please try again later</p>
      : <p>Loading, please wait</p>}
    </div>
  )
}
