import { Fragment, useState, useEffect } from "react";
import useBase from "./useBase";
import { checkEmail, checkPhoneNumber, extractDomain } from "./helpers";
import { Dialog, Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import Cookies from "js-cookie";
import Airtable from "airtable";
import { useEmailLink } from "@clerk/clerk-react";
import setupLogRocketReact from "logrocket-react";
import { phi } from "mathjs";

export default function JITModal({ website, setRequestEmail, setJITModalSubmit, countDown, userEmail }) {
  const [seconds, setSeconds] = useState(0);

  const [curEmail, setCurEmail] = useState(userEmail);
  const [curPhone, setCurPhone] = useState('');

  const [open, setOpen] = useState(false);

  const [validEmail, setValidEmail] = useState("empty");
  const [validPhone, setValidPhone] = useState("empty");
  const [phoneMessage, setPhoneMessage] = useState("");

  const apiKey = process.env.REACT_APP_AIR_KEY;
  const baseId = process.env.REACT_APP_AIR_BASEID;
  const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;
  const usersTable = process.env.REACT_APP_AIR_USERS;
  const { loading: usersLoading, data: users, error: usersError } = useBase("users");
  
  const base = new Airtable({ apiKey }).base(baseId);

  useEffect(() => {
    setValidEmail(checkEmail(curEmail) ? "correct" : "incorrect");
  }, [])

  async function handleSubmit(event) {
    event.preventDefault();

    setValidEmail(checkEmail(curEmail) ? "correct" : "incorrect");

    if (checkEmail(curEmail)) {
      if (extractDomain(curEmail) !== process.env.REACT_APP_COOL_EMAIL) {
        const request = "A lead submitted the JIT Form while they were waiting for the report to load!" + curPhone
        const website = curEmail
        fetch(`https://hooks.zapier.com/hooks/catch/13965335/${process.env.REACT_APP_ZAPIER_SAVINGS}/`, {
          method: "POST",
          body: JSON.stringify({ request, website }),
        })
      }

      const curUser = users.filter((user) => user?.fields?.Email === curEmail)[0];

      if (curUser) {
        fetch("https://api.airtable.com/v0/" + baseId + "/" + usersTable + "/" + curUser.id, {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fields: {
              Phone: validPhone === "true" ? curPhone : "",
            },
          }),
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
      }

      console.log('about to get record id for', website);

      let recordId = null;

      const records = await base("companies")
					.select({ 
						view: "Grid view",
						filterByFormula: `REGEX_MATCH({Domain}, '^${website}$')`, })
					.all();

      recordId = records[0]?.id;

      console.log('recordId', recordId);

      // Updating the 'empty' company record in Airtable that was created when the JIT Modal first shows up.
      // By default the RequestEmail field is the email the user is signed in with. Here, we update that field to whichever email the user enters
      // in the form.
      if (recordId) {
        fetch('https://api.airtable.com/v0/' + baseId + '/' + companiesTableId + '/' + recordId, {
          method: 'PATCH',
          headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            fields: {
              RequestEmail: curEmail,
              SendJITEmail: "true",
            }
          })
        })
        .then(response => response.json())
        .then(data => console.log('email changed', data))
        .catch(error => console.error('Error:', error));
      }

      setOpen(false);
      setRequestEmail(curEmail);
      setTimeout(() => setJITModalSubmit(true), 10000);
    }
  }

  function handleEmailChange(event) {
    const email = event.target.value;
    setCurEmail(email);
    setValidEmail(checkEmail(email) ? "correct" : email === "" ? "empty" : "incorrect");
  }

  function handlePhoneChange(event) {
    const phInput = event.target.value;
    setCurPhone(phInput);
    const phOutput = checkPhoneNumber(phInput);
    if (phInput === "") {
      setValidPhone('empty');
      setPhoneMessage("");
    } else if (phOutput.result == "Failed") {
      setValidPhone('false');
      setPhoneMessage(phOutput.message);
    } else {
      setValidPhone('true');
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds < countDown) {
        setSeconds(seconds + 1);
      } else {
        setOpen(true);
        clearInterval(interval);
      }
    }, 1000);

    // Cleanup function
    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all my-auto sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  {/* <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div> */}
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      <h1 className="text-lg">We're currently upgrading your report for {website}.</h1>
                    </Dialog.Title>
                    <p className="text-sm mt-4 text-gray-700">Please fill out the form below to receive a copy of the report.</p>
                    <div>
                      <div className="mx-auto my-4 w-36 lg:w-72 text-center justify-end">
                        {/* Orcas gif : https://i.gifer.com/7Ox.gif */}
                        <img className = "h-auto object-cover object-center rounded-md" src = "https://cdn.dribbble.com/users/122051/screenshots/5749053/dribbble_1.gif"/>
                      </div>
                      <form className="mt-5 mx-auto space-y-6 max-w-md" method="POST" onSubmit={handleSubmit}>
                        <div>
                          <label htmlFor="email" className="text-left block text-sm font-medium leading-6 text-gray-900">
                            Email address<span className="text-red-700">*</span>
                            {validEmail === "correct" ? (
                              <CheckCircleIcon
                                className="inline h-5 w-5 mx-2 text-indigo-500 group-hover:text-indigo-900"
                                aria-hidden="true"
                              />
                            ) : (
                              ""
                            )}
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={handleEmailChange}
                              value={curEmail}
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              required
                              placeholder="name@company.com"
                              className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-${
                                validEmail === "incorrect" ? "red" : "indigo"
                              }-600 sm:text-sm sm:leading-6`}
                            />
                            <p className="text-red-800 ml-1 mb-2 text-sm">
                              {validEmail === "incorrect" ? "Invalid email, please try again" : ""}
                            </p>
                          </div>
                        </div>

              
                        <div>
                          <label htmlFor="phone" className="text-left block text-sm font-medium leading-6 text-gray-900">
                            Phone Number (optional)
                            {validPhone === "true" ? (
                              <CheckCircleIcon
                                className="inline h-5 w-5 mx-2 text-indigo-500 group-hover:text-indigo-900"
                                aria-hidden="true"
                              />
                            ) : (
                              ""
                            )}
                          </label>
                          <div className="mt-2">
                            <input
                              value={curPhone}
                              id="phone"
                              name="phone"
                              type="tel"
                              onChange={handlePhoneChange}
                              autoComplete="phone"
                              placeholder="289-349-3249"
                              className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-${
                                validPhone === "false" ? "red" : "indigo"
                              }-600 sm:text-sm sm:leading-6`}
                            />
                            {validPhone === "false" ? <p className="text-red-800 ml-1 mb-2 text-sm">{phoneMessage}</p> : ""}
                          </div>
                        </div>

                        <div>
                          <button
                            type="submit"
                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            // disabled={!recordId}
                          >
                            Send me the report when it's ready
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="mt-2">
                      {/* <p className="text-sm text-gray-500">
                        Your report will be ready within 24 hours.
                      </p> */}
                    </div>
                  </div>
                </div>
                {/* <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => setOpen(false)}
                  >
                    Go back to dashboard
                  </button>
                </div> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
