import { React, useState, useEffect } from "react";
import Airtable from "airtable";
import GrantQuestionsCard from "./GrantQuestionsCard.js";
import NotFoundPage from "./NotFoundPage.jsx";
import Company from "./Company.js";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { isAnswer, isNumbered, numberingCompare, setShowNotif, setContents, classNames, extractDomain, removeQueryParameters } from "./helpers.js";
import Notification from "./Notification.js";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const answersTableId = process.env.REACT_APP_AIR_ANSWERS;
const base = new Airtable({ apiKey: apiKey }).base(baseId);
const urlSearchValue = window.location?.pathname
	?.split("/")
	?.filter((item) => item)
	.slice(1)[0];

const CompanyInfoPage = () => {
	const navigate = useNavigate();

	const [answers, setAnswers] = useState([]);
	const [questions, setQuestions] = useState([]);
	const [grants, setGrants] = useState([]);
	const [company, setCompany] = useState({});
	const [notFound, setNotFound] = useState(!Boolean(urlSearchValue));
	const [loading, setLoading] = useState(true);
	const [questionAnswerSearchValue, setQuestionAnswerSearchValue] = useState("");
	const [showSubmitNotif, setShowSubmitNotif] = useState({
		showNotif: false,
		contents: { type: "", heading: "", message: "" },
	});
	const [dataStates, setDataStates] = useState({
		Questions: { message: "Questions Loaded In!", id: "Questions", show: false },
		Answers: { message: "Answers Loaded In!", id: "Answers", show: false },
		Company: { message: "Company Loaded In!", id: "Company", show: false },
		Grants: { message: "Grants Loaded In!", id: "Grants", show: false },
	});


	// Auth -- Restricting users to their own company-info pages
	const {isSignedIn, isLoaded : isUserLoaded, user } = useUser();
	const [admin, setAdmin] = useState(false);
	useEffect(() => {
		if (isUserLoaded === false || isSignedIn === false) return;
		const userDomain = extractDomain(user.primaryEmailAddress.emailAddress);

		const isAdmin = extractDomain(user.primaryEmailAddress.emailAddress) === process.env.REACT_APP_COOL_EMAIL;
		setAdmin(isAdmin);

		if (!isAdmin && (urlSearchValue !== userDomain)) {
			navigate(`/company-info/${userDomain}`);
		}

	}, [isSignedIn, isUserLoaded, user]);

	// Fetching Answers
	useEffect(() => {
		if (notFound) return;
		if (answers.length !== 0) return;

		let ignore = false;

		(async () => {
			if (ignore === true) return;

			base("answers")
				.select({ view: "Grid view" })
				.all()
				.then((records) => {
					console.log("answers loaded in");
					setAnswers(records);
					setDataStates((prev) => ({ ...prev, Answers: { ...prev.Answers, show: true } }));
				})
				.catch((e) => console.log("Error occurred in fetching answers: ", e));
		})();

		return () => {
			ignore = true;
		};
	}, []);

	// Fetching Questions
	useEffect(() => {
		if (notFound) return;
		if (questions.length !== 0) return;

		let ignore = false;

		(async () => {
			if (ignore === true) return;

			base("questions")
				.select({ view: "Grid view" })
				.all()
				.then((questionRecords) => {
					console.log("questions loaded in");
					setQuestions(questionRecords);
					setDataStates((prev) => ({ ...prev, Questions: { ...prev.Questions, show: true } }));
				})
				.catch((e) => console.log("Error occurred in fetching questions: ", e));
		})();

		return () => {
			ignore = true;
		};
	}, []);

	// Fetching Grants
	useEffect(() => {
		if (notFound) return;
		if (Object.keys(company).length === 0) return;
		if (grants.length !== 0) return;

		let ignore = false;

		(async () => {
			if (ignore === true) return;

			base("deduped_grants")
				.select({ view: "Grid view" })
				.all()
				.then((records) => {
					setGrants(records.filter((record) => record.fields.Name && company.fields.InterestedIn.split(",").includes(record.fields.Name)));
					setDataStates((prev) => ({ ...prev, Grants: { ...prev.Grants, show: true } }));
				})
				.catch((e) => console.log("Error in Fetching Grants: ", e));
		})();

		return () => {
			console.log("useEffect clean-up");
			ignore = true;
		};
	}, [company]);

	// Fetching Companies
	useEffect(() => {
		if (notFound) return;
		if (company?.fields?.Domain) return;

		let ignore = false;

		(async () => {
			if (ignore === true) return;

			base("companies")
				.select({ view: "Grid view" })
				.all()
				.then((companies) => {
					let currentCompany = companies.find((record) => record.fields.Domain && record.fields.Domain === urlSearchValue);
					setDataStates((prev) => ({ ...prev, Company: { ...prev.Company, show: true } }));

					if (!currentCompany) {
						setNotFound(true);
						return;
					} else {
						setCompany(currentCompany);
					}
				})
				.catch((e) => console.log("Error in Fetching Companies: ", e));
		})();

		return () => {
			ignore = true;
		};
	}, []);

	// useEffect to turn Loading off
	useEffect(() => {
		if (loading === false) return;

		let turnOffLoading = false;
		if (notFound) turnOffLoading = true;
		else if (grants.length > 0 && Object.keys(company).length > 0 && questions.length > 0 && answers.length > 0) turnOffLoading = true;

		if (turnOffLoading) {
			setTimeout(() => {
				setLoading(false);
			}, 1000);
		}
	}, [grants, company, questions, answers, notFound]);

	const sortQuestions = (questionsArray) => {
		return questionsArray.sort((a, b) => {
			if (a.fields.Link && b.fields.Link) return 0;
			else if (a.fields.Link) return -1;
			else if (b.fields.Link) return 1;

			// Answers to Questions that are not numbered should always appear at the bottom
			if (!isNumbered(a?.fields?.QuestionText) && isAnswer(a.answer)) return 1;
			if (!isNumbered(b?.fields?.QuestionText) && isAnswer(b.answer)) return -1;

			if (isNumbered(a?.fields?.QuestionText) && isNumbered(b?.fields?.QuestionText))
				return numberingCompare(a.fields.QuestionText, b.fields.QuestionText);
			else if (isNumbered(a?.fields?.QuestionText)) return 1;
			else if (isNumbered(b?.fields?.QuestionText)) return -1;

			return 0;
		});
	};

	const filterQuestionsBySearch = (questionsArray, searchValue) => {
		return questionsArray.filter(
			(question) =>
				question?.fields?.QuestionText?.toLowerCase().includes(searchValue.toLowerCase()) ||
				(isAnswer(question.answer) &&
					question.answer.fields.AnswerText.toLowerCase().includes(searchValue.toLowerCase())));
	};

	const getQuestionsForGrant = (questionsArray, answersArray, grantIdentifier, companyId) => {
		console.log("getQuestionsForGrant running");
		let newQuestions = questionsArray.filter(
			(question) => question.fields.grantIdentifier && question.fields.grantIdentifier.split(",").includes(grantIdentifier)
		);

		for (let i = 0; i < newQuestions.length; ++i) {
			let potentialAnswers = answersArray.filter(
				(ans) =>
					Boolean(ans.fields.AnswerText) &&
					ans.fields.QuestionID === newQuestions[i].fields.QuestionID &&
					ans.fields.grantIdentifier === grantIdentifier &&
					ans.fields.CompanyID === companyId
			);

			if (potentialAnswers.length === 0) {
				continue;
			}

			if (potentialAnswers.length > 1) {
				console.error(`More than one potential answer for question ${newQuestions[i].fields.QuestionText}, grantId : ${grantIdentifier}`);
			}

			newQuestions[i].answer = potentialAnswers[0];
		}

		newQuestions = sortQuestions(filterQuestionsBySearch(newQuestions, questionAnswerSearchValue));

		return newQuestions;
	};

	const submitAnswer = async (companyId, questionId, grantIdentifier, answer, newAnswerText) => {
		await (async () => {
			let contents = { type: "", heading: "", message: "" };

			if (isAnswer(answer) && Boolean(answer.id)) {
				console.log('running patch')
				// Send PATCH request to edit answer
				// patch record answer.id to have new info newAnswerText
				fetch("https://api.airtable.com/v0/" + baseId + "/" + answersTableId + "/" + answer.id, {
					method: "PATCH",
					headers: {
						Authorization: `Bearer ${apiKey}`,
						"Content-Type": "application/json",
					},

					body: JSON.stringify({
						fields: {
							AnswerText: newAnswerText,
						},
					}),
				})
					.then((response) => {
						console.log(response);
						if (!response || !response.ok) {
							throw Error(JSON.stringify(response));
						}

						return response.json();
					})
					.then((data) => {
						console.log(data);
						return new Promise((resolve, reject) => {
							if (!data || JSON.stringify(data).toLowerCase().includes("error")) {
								reject(data);
							} else {
								resolve(data);
								contents.type = "success";
								contents.message = `Answer updated to ${newAnswerText}`;
								contents.heading = "Answer Successfully Edited";

								setContents(contents, setShowSubmitNotif);
								setShowNotif(true, setShowSubmitNotif);
							}
						});
					})
					.then(() => {
						base("answers")
							.select({ view: "Grid view" })
							.all()
							.then((records) => {
								setAnswers(records);
								contents.type = "success";
								contents.message = `Answer updated to ${newAnswerText}`;
								contents.heading = "Answer Successfully Edited";

								setContents(contents, setShowSubmitNotif);
								setShowNotif(true, setShowSubmitNotif);
							});
					})
					.catch((error) => {
						console.error("Error in editing answer:", error);
						contents.type = "error";
						contents.message = JSON.stringify(error);
						contents.heading = "Failed to submit";

						setContents(contents, setShowSubmitNotif);
						setShowNotif(true, setShowSubmitNotif);
					});
			} else {
				console.log('running post')
				// Send POST request to create answer
				fetch("https://api.airtable.com/v0/" + baseId + "/" + answersTableId, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${apiKey}`,
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						fields: {
							AnswerText: newAnswerText,
							CompanyID: companyId,
							grantIdentifier: grantIdentifier,
							QuestionID: questionId,
							AnswerID: `${Date.now()}`,
						},
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						console.log(data);
						return new Promise((resolve, reject) => {
							if (!data || JSON.stringify(data).toLowerCase().includes("error")) {
								reject(data);
							} else {
								resolve(data);
								contents.type = "success";
								contents.message = `Answered '${newAnswerText}'`;
								contents.heading = "Answer Successfully Submitted!";

								setContents(contents, setShowSubmitNotif);
								setShowNotif(true, setShowSubmitNotif);
							}
						});
					})
					.then(() => {
						base("answers")
							.select({ view: "Grid view" })
							.all()
							.then((records) => {
								setAnswers(records);
							});
					})
					.catch((error) => {
						console.log('failed to create airtable record')
						console.error("Error:", error);
						contents.type = "error";
						contents.message = JSON.stringify(error);
						contents.heading = "Failed to submit";

						setContents(contents, setShowSubmitNotif);
						setShowNotif(true, setShowSubmitNotif);
					});
			}
		})();
	};

	return (
		<>
			{loading ? (
				<div>
					<div className="flex justify-center items-center align-middle">
						<p>Loading in {urlSearchValue}</p>
					</div>
					<div className="flex justify-center items-center align-middle">
						<ul className="mt-6 space-y-6">
							{Object.keys(dataStates).map((item, index) => {
								return (
									<div>
										{dataStates[item].show && (
											<li key={item} className="relative flex gap-x-4">
												<div
													className={classNames(
														index === dataStates.length - 1 ? "h-6" : "-bottom-6",
														"absolute left-0 top-0 flex w-6 justify-center"
													)}
												>
													<div className="w-px bg-gray-200" />
												</div>

												<>
													<div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
														<CheckCircleIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
													</div>
													<p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
														<span className="font-medium text-gray-900">{dataStates[item].message}</span>
													</p>
												</>
											</li>
										)}
									</div>
								);
							})}
						</ul>
					</div>
				</div>
			) : notFound ? (
				<NotFoundPage />
			) : (
				<main>
					<header className="relative isolate pt-8">
						<div className="absolute inset-0 -z-10 overflow-visible" aria-hidden="true">
							<div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
						</div>

						<div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8">
							<div className="mx-auto flex max-w-2xl items-center justify-center gap-x-8 lg:mx-0 lg:max-w-none">
								<div className="flex jusitfy-center items-center gap-x-6">
									{company?.fields?.LogoUrl && (
										<img
											src={company.fields.LogoUrl}
											alt={`${company?.fields?.Name}'s company logo`}
											className="h-16 w-16 flex-none rounded-full ring-1 ring-gray-900/10"
										/>
									)}
									<h1>
										<div className="mt-1 text-base font-semibold text-xl leading-6 text-gray-900">{company?.fields?.Name}</div>
									</h1>
								</div>
							</div>
						</div>
					</header>

					<div>
						<div>
							<div className="mx-auto max-w-7xl">
								<Company company={company} loading={loading} />
							</div>
						</div>
						<div className="my-10">
							<div className="mx-auto max-w-7xl">
								<div className="grid grid-cols-3">
									<h1 className="col-span-2 text-xl text-left font-semibold leading-6 text-gray-900 mb-4">Grant Questions and Answers</h1>
									<span className="mx-2 px-2 inline-flex">
										<input
											className="py-2 px-3 rounded-lg max-w-lg w-full"
											onChange={(e) => setQuestionAnswerSearchValue(e.target.value)}
											value={questionAnswerSearchValue}
											placeholder="Search for Information"
										/>
									</span>
								</div>
								<p>
									There are {grants.length} grants that {company?.fields?.Name} is Interested In
								</p>
							</div>
							{/* Grants Questions and Answers */}
							{grants.map((grant) => {
								return (
									<div key={grant.id} className="overflow-visible">
										<GrantQuestionsCard
											company={company}
											grant={grant}
											searchValue={questionAnswerSearchValue}
											grantQuestions={getQuestionsForGrant(questions, answers, `${grant?.fields?.Name}-${removeQueryParameters(grant?.fields?.GrantUrl)}`, company.id)}
											submitAnswer={submitAnswer}
										/>
									</div>
								);
							})}
						</div>
					</div>
				</main>
			)}
			<Notification
				showNotif={showSubmitNotif.showNotif}
				setShowNotif={(val) => setShowNotif(val, setShowSubmitNotif)}
				contents={showSubmitNotif.contents}
			/>
		</>
	);
};

export default CompanyInfoPage;
