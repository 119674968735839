import React, { useState } from 'react';
import Airtable from 'airtable';
import Papa from 'papaparse';
import { setShowNotif, setContents } from "./helpers";
import Notification from "./Notification.js";

// const airtableBase = new Airtable({ apiKey: 'YOUR_AIRTABLE_API_KEY' }).base('YOUR_BASE_ID');

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const airtableBase = new Airtable({ apiKey }).base(baseId);

// const requiredHeaders = ['First Name', 'Last Name', 'URL','Company','Email Address', 'Position'];

const requiredHeaders = ['FirstName', 'LastName', 'PersonalLinkedIn', 'Email', 'Position', 'Company'];

const DataUploader = ({ user, copyClipboardNotif,setCopyClipboardNotif, setNotifications, onUploadComplete }) => {
  const [csvFile, setCsvFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const uploadHeaders = ['First Name', 'Last Name', 'URL', 'Company', 'Email Address', 'Position'];
  const databaseHeaders = ['FirstName', 'LastName', 'PersonalLinkedIn', 'Company', 'Email', 'Position'];

  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  const validateHeaders = (headers) => {
    return requiredHeaders.every((header) => headers.includes(header));
  };

  const checkDuplicate = async (PersonalLinkedIn) => {
    const records = await airtableBase('Referrals')
      .select({
        filterByFormula: `{PersonalLinkedIn} = '${PersonalLinkedIn}'`,
      })
      .firstPage();

    return records.length > 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!csvFile) {
      setMessage('Please upload a CSV file.');
      return;
    }

    setLoading(true);

    // complete: async (results) => {
    //   const headers = results.meta.fields;
    //   if (!validateHeaders(headers)) {
    //     setMessage(`CSV file must contain the following headers: ${requiredHeaders.join(', ')}`);
    //     setLoading(false);
    //     return;
    //   }

    Papa.parse(csvFile, {
      header: true,
      complete: async (results) => {
        const data = results.data.map(row => {
          const transformedRow = {};
          uploadHeaders.forEach((header, index) => {
            transformedRow[databaseHeaders[index]] = row[header];
          });
          return transformedRow;
        });

        // const data = results.data;
        // console.log(user)


        try {
          for (let i = 0; i < data.length; i++) {
            console.log(data[i], "data")
            const isDuplicate = await checkDuplicate(data[i].PersonalLinkedIn);
            if (!isDuplicate) {
              await airtableBase('Referrals').create([
                {
                  fields: {
                    ...data[i],
                    UploadedBy: user.primaryEmailAddress.emailAddress,
                    Status: "Completed"
                  },
                },
              ]);

              console.log("Connection Uploaded!")
              setShowNotif(true, setCopyClipboardNotif);
              const contents = { type: "success", heading: "Connection Uploaded!", message: `${data[i].FirstName + " " + data[i].LastName + " " + data[i].PersonalLinkedIn + "was created."}` }
              console.log(contents)
              setContents(contents, setCopyClipboardNotif);
            } else {
              setMessage('Duplicate found!' + data[i].FirstName + " " + data[i].LastName + " " + data[i].PersonalLinkedIn);

              console.log("Duplicate connection") 
              setShowNotif(true, setCopyClipboardNotif);
              const contents = { type: "error", heading: "Duplicate found!", message: `${data[i].FirstName + " " + data[i].LastName + " " + data[i].PersonalLinkedIn + "already exits"}` }
              console.log(contents)
              setContents(contents, setCopyClipboardNotif);
            }
          }
          setMessage('Data uploaded successfully!');
          onUploadComplete(); // Trigger the refresh callback
        } catch (error) {
          setMessage(`Error: ${error.message}`);
        } finally {
          setLoading(false);
          setCsvFile(null);
        }
      },
      error: (error) => {
        setMessage(`Error parsing CSV file: ${error.message}`);
        setLoading(false);
      },
    });
  };

  const testNotification = (val, setNotif) => {
    console.log("Duplicate connection") 
    setShowNotif(true, setCopyClipboardNotif);
    const contents = { type: "success", heading: "Duplicate found!", message: `already exits` }
    console.log(contents)
    setContents(contents, setCopyClipboardNotif);
    addNotification(contents);
  };

  const testNotification2 = (val, setNotif) => {
    console.log("Duplicate connection") 
    setShowNotif(true, setCopyClipboardNotif);
    const contents = { type: "success", heading: "Duplicate found!", message: `already exits or does it?` }
    console.log(contents)
    setContents(contents, setCopyClipboardNotif);
    addNotification(contents);
  };

  const addNotification = (contents) => {
    const newNotification = {
      id: Date.now(),
      showNotif: true,
      contents,
    };
    setNotifications((prev) => [...prev, newNotification]);
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white rounded shadow-md">
       {/* <button
        onClick={() => testNotification()}
        className="bg-blue-500 text-white p-2 rounded cursor-pointer"
      >
        Test Notifications
      </button>
      <button
        onClick={() => testNotification2()}
        className="bg-blue-500 text-white p-2 rounded cursor-pointer ml-2"
      >
        Test Notifications 2
      </button> */}
      <h2 className="text-2xl font-bold mb-4">Upload File</h2>
      <p className="text-gray-700 mb-2 text-sm">Default LinkedIn headers 'First Name', 'Last Name', 'URL', 'Company', 'Email Address', 'Position'</p>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">CSV File</label>
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            className="mt-1 p-2 w-full border rounded"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
          disabled={loading}
        >
          {loading ? 'Uploading...' : 'Upload'}
        </button>
      </form>
      {message && <p className="mt-4 text-green-500">{message}</p>}
      {/* <Notification
        showNotif={copyClipboardNotif.showNotif}
        setShowNotif={(val) => setCopyClipboardNotif(val, setCopyClipboardNotif)}
        contents={copyClipboardNotif.contents}
      /> */}
    </div>
  );
};

export default DataUploader;