import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon, ArrowUpCircleIcon } from "@heroicons/react/24/outline";
import { containsCommonElement } from "./helpers.js";

export default function IndustriesMappingModal({ open, setOpen, company, industries, setIndustries, industryRecords, setIndustryRecords }) {
  const [editable, setEditable] = useState({});
  const [industrySearch, setIndustrySearch] = useState("");

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    const industryArray = Object.keys(industries);
    let temp = {};

    for (const key of industryArray) {
      temp[key] = false;
    }

    setEditable(temp);
  }, []);

  const addSubIndustry = (primary, subs) => {
    subs = subs.toLowerCase().split(",");
    let currentSubs = industries[primary];
    let newSubs = [...currentSubs, ...subs];

    setIndustries({
      ...industries,
      [primary]: newSubs,
    });
  };

  const removeSubIndustry = (primary, sub) => {
    let newSubs = industries[primary].filter((item) => item !== sub);
    console.log("subs stuff", sub, newSubs);

    setIndustries({
      ...industries,
      [primary]: newSubs,
    });
  };

  const saveChanges = () => {
    const baseId = "appVulceDQ4SKlQoP";
    const industriesTableId = "tblUNrXboXnFYBHh1";

    for (const key in industries) {
      const recordId = industryRecords.filter((rec) => rec.fields.Industry === key)[0].id;
      console.log(industries[key]);

      fetch("https://api.airtable.com/v0/" + baseId + "/" + industriesTableId + "/" + recordId, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer keyd3DeTqTwCCeKlF`,
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          fields: {
            SubIndustries: industries[key]?.map((item) => item.toLowerCase().trim()).join(","),
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((err) => console.error(err));
    }
  };

  const resetChanges = () => {
    let industriesObj = {};
    industryRecords.map((rec) => (industriesObj[rec.fields.Industry] = rec.fields.SubIndustries.split(",")));

    setIndustries(industriesObj);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 max-w-screen w-full overflow-y-auto">
          <div className="flex min-h-full max-w-full w-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white  text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-7xl sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <span>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {`Check out the Industries ` + (company ? ` Mapping for ${company?.fields?.Name}` : "")}
                        {company ? (
                          <img src={company?.fields?.LogoUrl} className="mx-3 mb-1 inline-flex h-8 w-auto aria-hidden" />
                        ) : (
                          "    👀"
                        )}
                      </Dialog.Title>
                    </span>

                    <div className="mt-2">
                      {/* Modal for if we're looking at a specific company (i.e., company !== false */}
                      {company && company?.fields?.Industry ? (
                        <ul>
                          {Object.keys(industries).map((industry) => {
                            return containsCommonElement(
                              industries[industry],
                              company?.fields?.Industry.split(",").map((item) => item.toLowerCase().trim())
                            ) ? (
                              <li className="my-3" key={industry}>
                                <p className="text-md strong text-left text-gray-900">
                                  {industry}:
                                  <button
                                    className="mx-3 px-4 mb-2 w-auto max-w-lg text-sm rounded-md bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                    onClick={() => {
                                      setEditable({
                                        ...editable,
                                        [industry]: !editable[industry],
                                      });
                                    }}
                                  >
                                    {editable[industry] ? "View Only" : "Edit"}
                                  </button>
                                </p>

                                {editable[industry] && (
                                  <div className="w-auto">
                                    <form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        addSubIndustry(industry, e.target["subIndustries"].value);
                                      }}
                                    >
                                      <input
                                        className="mx-auto px-3 mb-2 w-auto max-w-xl border-2 border-black"
                                        type="text"
                                        id="subIndustries"
                                        placeholder="Separate by commas"
                                      />
                                      <button className="mt-2" type="submit">
                                        <ArrowUpCircleIcon className="h-8 w-8" />
                                      </button>
                                    </form>
                                  </div>
                                )}

                                {industries[industry].map((subIndustry) => {
                                  return (
                                    <p
                                      key={subIndustry}
                                      className={`inline text-sm text-left ${
                                        company?.fields?.Industry.split(",")
                                          .map((item) => item.toLowerCase())
                                          .includes(subIndustry)
                                          ? "font-bold text-red-500"
                                          : "text-gray-900"
                                      }`}
                                    >
                                      {!editable[industry] ? (
                                        ""
                                      ) : (
                                        <button onClick={() => removeSubIndustry(industry, subIndustry)}>
                                          <XMarkIcon className="bg-gray-100 text-gray-400 h-4 w-auto hover:bg-red-200" />
                                        </button>
                                      )}
                                      {subIndustry + ", "}
                                    </p>
                                  );
                                })}
                              </li>
                            ) : (
                              ""
                            );
                          })}
                        </ul>
                      ) : (
                        <ul>
                          {/* Modal for generic industries object -- no specific company attached (i.e., company === false)*/}
                          <input
                            className="border-2 border-gray-700"
                            onChange={(e) => setIndustrySearch(e?.target?.value?.trim().toLowerCase())}
                            placeholder="Search for an Industry"
                          ></input>
                          {Object.keys(industries)
                            ?.filter((key) => key?.toLowerCase().trim().includes(industrySearch?.trim().toLowerCase()))
                            .map((industry) => {
                              return (
                                <li className="my-3" key={industry}>
                                  <p className="text-md strong text-left text-gray-900">
                                    {industry}:
                                    <button
                                      className="mx-3 px-4 mb-2 w-auto max-w-lg text-sm rounded-md bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                      onClick={() => {
                                        setEditable({
                                          ...editable,
                                          [industry]: !editable[industry],
                                        });
                                      }}
                                    >
                                      {editable[industry] ? "View Only" : "Edit"}
                                    </button>
                                  </p>

                                  {editable[industry] && (
                                    <div className="w-auto">
                                      <form
                                        onSubmit={(e) => {
                                          e.preventDefault();
                                          addSubIndustry(industry, e.target["subIndustries"].value);
                                        }}
                                      >
                                        <input
                                          className="mx-auto px-3 mb-2 w-auto max-w-xl border-2 border-black"
                                          type="text"
                                          id="subIndustries"
                                          placeholder="Separate by commas"
                                        />
                                        <button className="mt-2" type="submit">
                                          <ArrowUpCircleIcon className="h-8 w-8" />
                                        </button>
                                      </form>
                                    </div>
                                  )}

                                  {industries[industry].map((subIndustry) => {
                                    return (
                                      <div key={subIndustry} className="text-left">
                                        <p className="inline text-sm text-left text-gray-900">
                                          {!editable[industry] ? (
                                            ""
                                          ) : (
                                            <button onClick={() => removeSubIndustry(industry, subIndustry)}>
                                              <XMarkIcon className="bg-gray-100 text-gray-400 h-4 w-auto hover:bg-red-200" />
                                            </button>
                                          )}
                                          {"•   " + subIndustry}
                                        </p>
                                      </div>
                                    );
                                  })}
                                </li>
                              );
                            })}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
                <div className="inline-flex mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-md justify-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-indigo-300 hover:bg-indigo-300 sm:col-start-2 sm:mt-0"
                    onClick={() => {
                      saveChanges();
                      setOpen(false);
                      setIndustrySearch("");
                    }}
                  >
                    Save Changes to Airtable
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-md justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => {
                      resetChanges();
                      setOpen(false);
                      setIndustrySearch("");
                    }}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

IndustriesMappingModal.defaultProps = {
  companies: false,
};
