import {
  React,
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { FunnelIcon, TrashIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { currencyFormat, classNames, extractDomain } from "./helpers.js";
import { Tooltip } from "@material-tailwind/react";
import Airtable from "airtable";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Menu } from "@headlessui/react";
import { send } from "emailjs-com";
import { AppContext } from "./AppContext.js";
import { getQuestionsToDisplay, handleAutodraftGrant } from "./Autodraft.js";
import { Switch } from "@headlessui/react";
import { setShowNotif, setContents } from "./helpers.js";
import Notification from "./Notification";
import {
  processedQuestions,
  pastAnswersContext,
} from "./utils/autoDraftHelper.js";
import AutomationControls from './components/AutomationControls';

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;

const base = new Airtable({ apiKey }).base(baseId);

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

const pageSize = 36;

export default function Leaderboard({}) {
  const [admin, setAdmin] = useState(false);
  const {
    companies,
    setCompanies,
    offset,
    setOffset,
    showAmount,
    setShowAmount,
    clerkUser,
    setClerkUser,
  } = useContext(AppContext);
  const [companySearch, setCompanySearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [currentCompany, setCurrentCompany] = useState({});
  const [industries, setIndustries] = useState({});
  const [shouldSort, setShouldSort] = useState(false);
  const [shouldLoad, setShouldLoad] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    Industries: [],
  });
  const [isOpen, setIsOpen] = useState(false);

  // Notification states
  const [autoDraftNotification, setAutoDraftNotification] = useState({
    showNotif: false,
    contents: {
      type: "info",
      heading: "",
      message: "",
    },
  });

  // Add these state variables at the beginning of the Leaderboard component
  const [isAutoDraftEnabled, setIsAutoDraftEnabled] = useState(false);
  const [lastRunTime, setLastRunTime] = useState(null);
  const [nextRunTime, setNextRunTime] = useState(null);

  const [notifications, setNotifications] = useState([]);

  const displayResults =
    searchResults.length > 0 ? searchResults : companies.slice(0, showAmount);

  useEffect(() => {
    if (companies.length > 0 || !clerkUser) return;
    if (
      extractDomain(clerkUser.primaryEmailAddress.emailAddress) ===
      process.env.REACT_APP_COOL_EMAIL
    )
      setAdmin(true);
    loadCompanies();
  }, [clerkUser]);

  const loadCompanies = async () => {
    try {
      let params = {
        pageSize,
        view: "Grid view",
        offset,
        // Adding filterByFormula to load only companies where ManagedClient is true
        filterByFormula: `{ManagedClient} = 'true'`,
      };

      if (shouldSort) {
        params.sort = [{ field: "createdTime", direction: "desc" }];
      }

      const response = await axios.get(
        `https://api.airtable.com/v0/${baseId}/companies`,
        {
          headers: { Authorization: `Bearer ${apiKey}` },
          params,
        }
      );
      console.log("Response:", response);
      setCompanies((prevCompanies) => [
        ...prevCompanies,
        ...response.data.records,
      ]);
      setShowAmount((prevAmount) => prevAmount + response.data.records.length);
      setOffset(response.data.offset);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const handleShowMore = () => {
    loadCompanies();
  };

  const handleSort = () => {
    setShouldSort(!shouldSort);
    setOffset(null);
    setShowAmount(36);
    setCompanies([]);
    setShouldLoad(true);
  };

  useEffect(() => {
    if (shouldLoad) {
      loadCompanies();
      setShouldLoad(false);
    }
  }, [offset, showAmount, companies, shouldLoad]);

  const cancelButtonRef = useRef(null);

  const sendingFrequencyOptions = [
    { label: "Never", value: "-1" },
    { label: "Monthly", value: "28" },
    { label: "Bi-Weekly", value: "14" },
    { label: "Weekly", value: "7" },
    { label: "Twice a Week", value: "4" },
    { label: "Every Three Days", value: "3" },
    { label: "Three Times a Week", value: "2" },
    { label: "Daily", value: "1" },
  ];

  const fetchCompanies = async (companySearch) => {
    try {
      const response = await axios.get(
        `https://api.airtable.com/v0/${baseId}/companies`,
        {
          headers: { Authorization: `Bearer ${apiKey}` },
          params: {
            view: "Grid view",
            filterByFormula: `AND(REGEX_MATCH(LOWER({Name}), '.*${companySearch.toLowerCase()}.*'), {ManagedClient} = 'true')`,
          },
        }
      );
      if (companySearch.length < 2) {
        setSearchResults([]);
        return;
      }
      setSearchResults(response.data.records);
      // console.log('returned', response.data.records);
    } catch (error) {
      console.error("Error fetching companies:", error);
      setSearchResults([]);
    }
  };

  useEffect(() => {
    fetchCompanies(companySearch);
  }, [companySearch]);

  function toggle() {
    setIsOpen((isOpen) => !isOpen);
  }

  // useEffect(() => {
  //   if (companies.length > 1) return;

  //   base("companies")
  //     .select({ view: "Grid view" })
  //     .all()
  //     .then((records) => {
  //       setCompanies(records);
  //     });
  // }, []);

  useEffect(() => {
    base("industries")
      .select({ view: "Grid view" })
      .all()
      .then((data) => {
        let industriesObj = {};
        data.map(
          (rec) =>
            (industriesObj[rec.fields.Industry] =
              rec.fields.SubIndustries.split(",").map((item) =>
                item.trim().toLowerCase()
              ))
        );

        setIndustries(industriesObj);
      })
      .catch((err) => console.log(err));
  }, []);

  // const axios = require('axios'); // Make sure to install axios with `npm install axios`

  // Function to find a record in the "grant" table by Name
  const findGrantByName = async (grantName) => {
    // const baseId = 'YOUR_BASE_ID'; // Replace with your Airtable Base ID
    // const apiKey = 'YOUR_API_KEY'; // Replace with your Airtable API Key
    const grantTable = "deduped_grants"; // Ensure this matches your grant table name

    try {
      const response = await axios.get(
        `https://api.airtable.com/v0/${baseId}/${encodeURIComponent(
          grantTable
        )}`,
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
          params: {
            filterByFormula: `{Name} = "${grantName}"`,
            maxRecords: 1, // Since we're only interested in a single record
          },
        }
      );

      const records = response.data.records;

      if (records.length > 0) {
        const record = records[0];
        console.log("Found record:", record);
        return record;
      } else {
        console.log(`No record found with the name: ${grantName}`);
        return null;
      }
    } catch (error) {
      console.error("Error fetching grant record:", error);
      return null;
    }
  };

  // Usage example
  // findGrantByName('CanExport SME');

  const handleSubmit = (event, company) => {
    event.preventDefault();
    console.log(event.target[0].value);
    console.log("event", event);
    console.log("event target", event.target);

    // setShowNotif(true, setCompanyFormNotif);
    // setContents(
    // 	{ type: "success", heading: "Form Submitted", message: "Thank you for updating your information. Please refresh the page." },
    // 	setCompanyFormNotif
    // );

    // Company Id
    console.log("company", company);
    console.log("company id", company.id);
    var companyId = company.id;

    // // Company Name
    // console.log("company name", event.target["companyName"].value);
    // var companyName = event.target["companyName"].value;

    // // Employees
    // console.log("employees", event.target["employees"].value);
    // var employees = event.target["employees"].value;

    // // Location
    // console.log("location", event.target["location"].value);
    // var location = event.target["location"].value;

    // // Country
    // const countryString = event.target["country"].value;
    // const countriesString = Array.from(event.target["country"])
    //   .map((country) => country.value)
    //   .join(",");
    // console.log("event country:", countryString);
    // console.log("event countries:", countriesString);

    // var country = countryString ? countryString : countriesString;
    // console.log("final country", country);

    // // Industry
    // const industryString = event.target["industry"].value;
    // const industriesString = Array.from(event.target["industry"])
    //   .map((industry) => industry.value)
    //   .join(",");

    // var industry = industryString ? industryString : industriesString;

    // console.log("industry", industry);

    // // Eligible Grants

    // if (secret_1) {
    //   // const eligibleGrant = event.target["eligibleGrants"].value
    //   // console.log('DEBUG 1', eligibleGrant);
    //   const inputGrants = Array.from(event.target["eligibleGrants"]).map((grant) => JSON.parse(grant.value));
    //   // console.log('DEBUG 2', eligibleGrants);
    //   // console.log("event grant:", eligibleGrant);
    //   // console.log("event grants:", eligibleGrants);
    //   // console.log('event target grants', Array.from(event.target["eligibleGrants"])[1]);

    //   console.log("input grants", inputGrants);
    //   console.log("not updated state", eligibleGrants);

    //   eligibleGrants.forEach((grant) => {
    //     console.log("current grant", grant.fields.Name);
    //     console.log("total", inputGrants);
    //     if (!inputGrants?.map((grant) => grant.fields.Name)?.includes(grant.fields.Name)) {
    //       // excludeGrants.push(grant.fields.Name)
    //       setFlaggedGrants((prevArray) => [...prevArray, grant]);
    //       console.log("now flagged", grant.fields.Name);
    //     }
    //   });

    //   flaggedGrants.forEach((grant) => {
    //     if (inputGrants.map((grant) => grant.fields.Name).includes(grant.fields.Name)) {
    //       setFlaggedGrants(flaggedGrants.filter((cur) => cur.fields.Name !== grant.fields.Name));
    //     }
    //   });

    //   // console.log("WHAT ARE THE EXCLUDED", flaggedGrants);

    //   setEligibleGrants(inputGrants);
    // }

    // // Founded
    // var founded = event.target["founded"].value;
    // console.log("founded", event.target["founded"].value);

    // // Annual Revenue
    // var annualRevenue = event.target["annualRevenue"].value.replace(",", "");
    // console.log("annualRevenue", annualRevenue);

    // // Conversion Rate
    // var conversionRate = event.target["conversionRate"].value;
    // console.log("conversionRate", event.target["conversionRate"].value);

    // // Avg Deal Size
    // var avgDealSize = event.target["avgDealSize"].value.replace(",", "");
    // console.log("avgDealSize", event.target["avgDealSize"].value);

    // // Debt
    // var debt = event.target["Debt"].value.replace(",", "");
    // console.log("Debt", event.target["Debt"].value);

    // // Annual Traffic
    // var annualTraffic = event.target["AnnualTraffic"].value.replace(",", "");
    // console.log("AnnualTraffic", event.target["AnnualTraffic"].value);

    // // Operational Costs
    // var operationalCosts = event.target["OperationalCosts"].value.replace(",", "");
    // console.log("OperationalCosts", event.target["OperationalCosts"].value);

    // // List Size
    // var listSize = event.target["ListSize"].value.replace(",", "");
    // console.log("ListSize", event.target["ListSize"].value);

    // // Likely Customers
    // console.log("likelyCustomers", event.target["likelyCustomers"].value);
    // var likelyCustomers = event.target["likelyCustomers"].value;

    // // Description
    // console.log("description", event.target["description"].value);
    // var description = event.target["description"].value;

    // // Technologies
    // const technologyString = event.target["technology"].value;
    // const technologiesString = Array.from(event.target["technology"])
    //   .map((technology) => technology.value.trim())
    //   .join(", ");
    // console.log("event technology:", technologyString);
    // console.log("event technologies:", technologiesString);

    // var technology = technologyString ? technologyString : technologiesString;

    // console.log("technology", technology);

    // var profitable = event.target["profitable-checkbox"].checked === true ? "Yes" : "No";
    // console.log("profitable-checkbox", profitable);

    // let provincesOrStates = Array.from(event.target["province"])
    //   .map((option) => option.value)
    //   .join(",");

    // if (!provincesOrStates) {
    //   provincesOrStates = event.target["province"].value;
    // }
    // console.log("province", provincesOrStates);

    let sendingFrequencyNumValue = parseFloat(
      event.target["sendingFrequencyOption"].value
    );
    let sendingFrequency =
      sendingFrequencyNumValue === -1 ? null : sendingFrequencyNumValue;
    let reportingEmailsValue =
      sendingFrequencyNumValue === -1 ? "false" : "true";
    console.log(
      "sendingFrequency and reportingEmail",
      sendingFrequency,
      reportingEmailsValue
    );

    let requestEmails = event.target["requestEmail"];
    if (requestEmails)
      requestEmails = requestEmails.value
        .split(",")
        .map((item) => item.trim())
        .join(",");

    // if (requestEmails && requestEmails.split(",").some((email) => !checkEmail(email))) {
    //   highlightErrorOnElement(requestEmailRef.current, true, 5);
    //   console.log("One or more of the Emails are invlaid. Form not Submitted.");
    //   return;
    // }
    // console.log("Request Email", requestEmails);

    let person1Name = event.target["People1Name"].value;
    console.log("People1Name", person1Name);

    let ccEmails = event.target["CC"];
    if (ccEmails)
      ccEmails = ccEmails.value
        .split(",")
        .map((item) => item.trim())
        .join(",");

    // if (ccEmails && ccEmails.split(",").some((email) => !checkEmail(email))) {
    //   highlightErrorOnElement(ccEmailRef.current, true, 5);
    //   console.log("One or more of the emails are invalid. Form not submitted.");
    //   return;
    // }

    // console.log("CCEmails", ccEmails);

    let bccEmails = event.target["BCC"];
    if (bccEmails)
      bccEmails = bccEmails.value
        .split(",")
        .map((item) => item.trim())
        .join(",");

    // if (bccEmails && bccEmails.split(",").some((email) => !checkEmail(email))) {
    //   highlightErrorOnElement(bccEmailRef.current, true, 5);
    //   console.log("One or more of the emails are invalid. Form not submitted.");
    //   return;
    // }

    // console.log("bcc emails", bccEmails);

    let fromName = event.target["fromName"];
    if (fromName) fromName = fromName.value;
    console.log("from name", fromName);

    let blurEmailString = company?.fields?.blurMe;
    let blurEmailCheckbox = event.target["blurEmail"];
    if (blurEmailCheckbox)
      blurEmailString = blurEmailCheckbox.checked === false ? "false" : "true";
    console.log("blurMe", blurEmailString);

    let lastSentValue = company?.fields?.lastSent;
    if (!lastSentValue) {
      lastSentValue = new Date(2024, 0, 1).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    }

    // // Debt
    // var debt = event.target["Debt"].value.replace(",", "");
    // console.log("Debt", event.target["Debt"].value);

    // // Annual Traffic
    // var annualTraffic = event.target["AnnualTraffic"].value.replace(",", "");
    // console.log("AnnualTraffic", event.target["AnnualTraffic"].value);

    // // Operational Costs
    // var operationalCosts = event.target["OperationalCosts"].value.replace(",", "");
    // console.log("OperationalCosts", event.target["OperationalCosts"].value);

    // // List Size
    // var listSize = event.target["ListSize"].value.replace(",", "");
    // console.log("ListSize", event.target["ListSize"].value);

    fetch(
      "https://api.airtable.com/v0/" +
        baseId +
        "/" +
        companiesTableId +
        "/" +
        companyId,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fields: {
            // Name: companyName,
            // Employees: parseFloat(employees),
            // Address: location,
            // Country: country,
            // Industry: industry,
            // Founded: parseFloat(founded),
            // AnnualRevenue: parseFloat(annualRevenue),
            // ConversionRate: parseFloat(conversionRate),
            // AvgDealSize: parseFloat(avgDealSize),
            // Debt: parseFloat(debt),
            // AnnualTraffic: parseFloat(annualTraffic),
            // OperationalCosts: parseFloat(operationalCosts),
            // ListSize: parseFloat(listSize),
            // LikelyCustomers: likelyCustomers,
            // Description: description,
            // Technologies: technology,
            // "Profitable?": profitable,
            // ExcludeGrants: flaggedGrants?.map((grant) => grant.fields.Name).join(","),
            // ProvinceOrState: provincesOrStates,
            sendFrequency: sendingFrequency,
            reportingEmails: reportingEmailsValue,
            RequestEmail: requestEmails,
            People1Name: person1Name,
            ccEmails: ccEmails,
            bccEmails: bccEmails,
            fromName: fromName,
            blurMe: blurEmailString,
            lastSent: lastSentValue,
          },
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error("Error:", error));

    // setOpen((prev) => !prev);
    // setTimeout(() => setFormSubmitted(true), 1000);

    // setCompany((prev) => {
    //   let newCompany = prev;
    //   newCompany = {
    //     ...newCompany,
    //     fields: {
    //       ...newCompany.fields,
    //       Name: companyName,
    //       Employees: parseFloat(employees),
    //       Address: location,
    //       Country: country,
    //       Industry: industry,
    //       Founded: parseFloat(founded),
    //       AnnualRevenue: parseFloat(annualRevenue),
    //       ConversionRate: parseFloat(conversionRate),
    //       AvgDealSize: parseFloat(avgDealSize),
    //       Debt: parseFloat(debt),
    //       AnnualTraffic: parseFloat(annualTraffic),
    //       OperationalCosts: parseFloat(operationalCosts),
    //       ListSize: parseFloat(listSize),
    //       LikelyCustomers: likelyCustomers,
    //       Description: description,
    //       Technologies: technology,
    //       "Profitable?": profitable,
    //       ExcludeGrants: flaggedGrants?.map((grant) => grant.fields.Name).join(","),
    //       ProvinceOrState: provincesOrStates,
    //       sendFrequency: sendingFrequency,
    //       reportingEmails: reportingEmailsValue,
    //       RequestEmail: requestEmails,
    //       People1Name: person1Name,
    //       ccEmails: ccEmails,
    //       bccEmails: bccEmails,
    //       fromName: fromName,
    //       blurMe: blurEmailString,
    //       lastSent: lastSentValue,
    //     },
    //   };
    //   return newCompany;
    // });

    // setIndustryOptions(getNewIndustrySelectOptions());
  };

  const deleteRecord = (recordId) => {
    fetch(
      "https://api.airtable.com/v0/" +
        baseId +
        "/" +
        companiesTableId +
        "/" +
        recordId,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer keyd3DeTqTwCCeKlF`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((confirmation) => {
        console.log("Record Deleted Confirmation", confirmation);
        setCompanies((prev) =>
          prev.filter((company) => company.id !== recordId)
        );
      })
      .catch((e) => console.log(e));
  };

  // First, filter the companies where ManagedClient exists
  const filteredCompanies = companies.filter(
    (company) => company.fields.ManagedClient
  );

  // Create a grant map to count companies interested in each grant
  const grantMap = {};

  filteredCompanies.forEach((company) => {
    const interestedGrants = company.fields.InterestedIn.split(",") || [];
    interestedGrants.forEach((grant) => {
      if (!grantMap[grant]) {
        grantMap[grant] = {
          count: 0,
          companies: [],
        };
      }
      grantMap[grant].count += 1;
      grantMap[grant].companies.push({
        name: company.fields.Name,
        domain: company.fields.Domain,
        email: company.fields.RequestEmail,
      });
    });
  });

  // Convert the grantMap to an array and sort it by count descending
  const grantArray = Object.keys(grantMap)
    .map((grant) => ({
      grant,
      count: grantMap[grant].count,
      companies: grantMap[grant].companies,
    }))
    .sort((a, b) => b.count - a.count);

  // First, filter the companies where ManagedClient exists
  const notClientCompanies = companies.filter(
    (company) =>
      !company.fields.ManagedClient && company.fields.Country === "CA"
  );

  const grantMapTwo = {};

  notClientCompanies.forEach((company) => {
    const interestedGrantsTwo = company?.fields?.InterestedIn?.split(",") || [];
    interestedGrantsTwo.forEach((grant) => {
      if (!grantMapTwo[grant]) {
        grantMapTwo[grant] = {
          count: 0,
          companies: [],
        };
      }
      grantMapTwo[grant].count += 1;
      grantMapTwo[grant].companies.push({
        name: company.fields.Name,
        domain: company.fields.Domain,
        email: company.fields.RequestEmail,
      });
    });
  });

  // Convert the grantMapTwo to an array and sort it by count descending
  const grantArrayTwo = Object.keys(grantMapTwo)
    .map((grant) => ({
      grant,
      count: grantMapTwo[grant].count,
      companies: grantMapTwo[grant].companies,
    }))
    .sort((a, b) => b.count - a.count);

  // TECHNOLOGIES LEADERBOARD
  // Define the keywords to filter technologies
  const technologyKeywords = [
    "Amazon",
    "AWS",
    "Azure",
    "Microsoft",
    "Google",
    "Hubspot",
  ];
  // Add other large technologies here where they apply to existing customers

  // Step 1: Filter companies where ManagedClient exists
  const filteredCompaniesTechnology = companies.filter(
    (company) => company.fields.ManagedClient
  );

  const filteredCompaniesTechnologyTwo = companies.filter(
    (company) => !company.fields.ManagedClient
  );

  // Step 2: Create a technology map to count companies interested in each technology
  const technologyMap = {};

  const technologyMapTwo = {};

  filteredCompaniesTechnology.forEach((company) => {
    const technologies = company.fields.Technologies || [];

    // Ensure technologies is an array
    const technologiesArray = Array.isArray(technologies)
      ? technologies
      : [technologies];

    technologiesArray.forEach((tech) => {
      // Check if the technology contains any of the keywords (case-insensitive)
      const matchesKeyword = technologyKeywords.some((keyword) =>
        tech.toLowerCase().includes(keyword.toLowerCase())
      );

      if (matchesKeyword) {
        // Find which keyword(s) the technology matches
        technologyKeywords.forEach((keyword) => {
          if (tech.toLowerCase().includes(keyword.toLowerCase())) {
            // Use the keyword as the key to standardize group names
            const key = keyword;

            if (!technologyMap[key]) {
              technologyMap[key] = {
                count: 0,
                companies: [],
              };
            }
            technologyMap[key].count += 1;
            technologyMap[key].companies.push({
              name: company.fields.Name,
              domain: company.fields.Domain,
            });
          }
        });
      }
    });
  });

  filteredCompaniesTechnologyTwo.forEach((company) => {
    const technologies = company.fields.Technologies || [];

    // Ensure technologies is an array
    const technologiesArray = Array.isArray(technologies)
      ? technologies
      : [technologies];

    technologiesArray.forEach((tech) => {
      // Check if the technology contains any of the keywords (case-insensitive)
      const matchesKeyword = technologyKeywords.some((keyword) =>
        tech.toLowerCase().includes(keyword.toLowerCase())
      );

      if (matchesKeyword) {
        // Find which keyword(s) the technology matches
        technologyKeywords.forEach((keyword) => {
          if (tech.toLowerCase().includes(keyword.toLowerCase())) {
            // Use the keyword as the key to standardize group names
            const key = keyword;

            if (!technologyMapTwo[key]) {
              technologyMapTwo[key] = {
                count: 0,
                companies: [],
              };
            }
            technologyMapTwo[key].count += 1;
            technologyMapTwo[key].companies.push({
              name: company.fields.Name,
              domain: company.fields.Domain,
            });
          }
        });
      }
    });
  });

  // Step 3: Convert the technologyMap to an array and sort it by count descendingi
  const technologyArray = Object.keys(technologyMap)
    .map((tech) => ({
      technology: tech,
      count: technologyMap[tech].count,
      companies: technologyMap[tech].companies,
    }))
    .sort((a, b) => b.count - a.count);

  const technologyArrayTwo = Object.keys(technologyMapTwo)
    .map((tech) => ({
      technology: tech,
      count: technologyMapTwo[tech].count,
      companies: technologyMapTwo[tech].companies,
    }))
    .sort((a, b) => b.count - a.count);

  const handleSubmitAutoDraft = async (company, email, grant) => {
    try {
      // Get the grant record
      const grantRecord = await findGrantByName(grant);
      if (!grantRecord) {
        console.error("Grant record not found");
        return;
      }

      // Get the questions for this grant
      const grantQuestions = await base("questions")
        .select({
          filterByFormula: `FIND("${grant}", grantIdentifier) > 0`,
          view: "Grid view",
        })
        .firstPage();

      // Get past answers for context
      const answersRecords = await base("answers")
        .select({
          filterByFormula: `AND(CompanyId = "${company.id}", FIND("${grant}", GrantIdentifier) > 0)`,
          view: "Grid view",
        })
        .firstPage();

      // Format past answers for context
      const formattedPastAnswers = answersRecords.map((answer) => ({
        question: answer.fields.QuestionText,
        answer: answer.fields.AnswerText,
      }));

      // Process questions with existing answers
      const processedQuestions = grantQuestions.map((question) => ({
        ...question,
        answer: answersRecords.find(
          (a) => a.fields.QuestionID === question.fields.QuestionID
        ),
      }));

      // Send the autodraft
      console.log("=== Autodraft Variables ===");
      console.log("Grant Record:", grantRecord);
      console.log("Company:", company);
      console.log("Processed Questions:", processedQuestions);
      console.log("Email:", email);
      console.log("Formatted Past Answers:", formattedPastAnswers);
      console.log("=== End Autodraft Variables ===");

      // await handleAutodraftGrant(
      //   grantRecord,
      //   company,
      //   processedQuestions,
      //   email,
      //   formattedPastAnswers
      // );

      // Show success notification
      setContents(
        {
          type: "success",
          heading: "Draft Sent Successfully!",
          message: (
            <div className="flex items-center space-x-2">
              <CheckCircleIcon
                className="h-6 w-6 text-green-400 animate-bounce"
                aria-hidden="true"
              />
              <span>Your draft has been sent! Please check your inbox.</span>
            </div>
          ),
          className: "bg-green-50 border-green-200 text-green-800",
        },
        setAutoDraftNotification
      );
      setShowNotif(true, setAutoDraftNotification);

      // After handleAutodraftGrant call
      await fetch(
        `https://api.airtable.com/v0/${baseId}/ALLGRANTS&SAVINGS(Automated-AccountsReceivable)`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fields: {
              CompanyName: company?.fields?.Name,
              ClientEmail: email,
              RequesterEmail: email,
              PartnerEmail: "",
              UpgradeName: grantRecord.fields.Name,
              Amount: grantRecord.fields.AnnualMargin,
              ClientName: company?.fields?.People1Name || "",
              Date: new Date().toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }),
              InvoiceReminderDate: new Date(
                Date.now() + 11 * 24 * 60 * 60 * 1000
              ).toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }),
              UpgradeType: "Grant",
              Status: "Sent",
              ManagedClient: company?.fields?.ManagedClient || false,
            },
          }),
        }
      );
    } catch (error) {
      console.error("Error in handleSubmitAutoDraft:", error);
      // Show error notification
      setContents(
        {
          type: "error",
          heading: "Error Sending Draft",
          message: `Failed to send draft: ${error.message}`,
          className: "bg-red-50 border-red-200 text-red-800",
        },
        setAutoDraftNotification
      );
      setShowNotif(true, setAutoDraftNotification);
    }
  };

  // Main function that handles automated sending of grant drafts
  // Runs every 14.4 minutes when automation is enabled
  // TODO: MAIN ACTION
  const handleAutomatedDrafts = useCallback(async () => {
    if (!isAutoDraftEnabled) return;
    console.log("Sending automated drafts");

    try {
      // Add draft counter
      let draftsSent = 0;
      const MINIMUM_DRAFTS = 100;

      // Keep fetching and processing records until we hit our minimum
      while (draftsSent < MINIMUM_DRAFTS) {
        const grantsTable = base(
          "ALLGRANTS&SAVINGS(Automated-AccountsReceivable)"
        );

        // Modified filter to get more potential records
        const filterFormula =
          "AND(" +
          "{UpgradeType} = 'Grant'," +
          "OR(" +
          "BLANK({Status})," +
          "{Status} != 'Sent'" +
          ")" +
          ")";

        const records = await grantsTable
          .select({
            maxRecords: 1000,
            view: "Grid view",
            filterByFormula: filterFormula,
          })
          .firstPage();

        if (records.length === 0) {
          console.log("No more records to process");
          break;
        }

        const processedCompanies = new Map();

        for (const record of records) {
          if (draftsSent >= MINIMUM_DRAFTS) break;

          const companyName = record.fields.CompanyName;
          const grantName = record.fields.UpgradeName;

          // Skip if we've already processed this company/grant combination
          if (processedCompanies.has(companyName)) {
            const company = processedCompanies.get(companyName);
            if (company.sentGrants.has(grantName)) {
              continue;
            }
          }

          try {
            // Lookup detailed company information
            const companyRecords = await base("companies")
              .select({
                maxRecords: 1,
                filterByFormula: `{Name} = '${companyName}'`,
              })
              .firstPage();

            if (!companyRecords || companyRecords.length === 0) {
              console.error(`Company not found: ${companyName}`);
              continue;
            }

            const company = companyRecords[0];
            const email = record.fields.ClientEmail;

            // Lookup detailed grant information
            const grantRecord = await findGrantByName(grantName);
            if (!grantRecord) {
              console.error(`Grant not found: ${grantName}`);
              continue;
            }

            // Get all questions associated with this grant
            const grantQuestions = await base("questions")
              .select({
                filterByFormula: `FIND("${grantName}", grantIdentifier) > 0`,
                view: "Grid view",
              })
              .firstPage();

            // Get previous answers for context
            const answersRecords = await base("answers")
              .select({
                filterByFormula: `AND(CompanyId = "${company.id}", FIND("${grantName}", GrantIdentifier) > 0)`,
                view: "Grid view",
              })
              .firstPage();

            // Format past answers for context
            const formattedPastAnswers = answersRecords.map((answer) => ({
              question: answer.fields.QuestionText,
              answer: answer.fields.AnswerText,
            }));

            // Process questions with existing answers
            const processedQuestions = grantQuestions.map((question) => ({
              ...question,
              answer: answersRecords.find(
                (a) => a.fields.QuestionID === question.fields.QuestionID
              ),
            }));

            // Check if company is a managed client
            const managedClientRecords = await base(companiesTableId)
              .select({
                filterByFormula: `{Name} = '${record.fields.CompanyName}'`,
                maxRecords: 1,
              })
              .firstPage();

            const isManagedClient =
              managedClientRecords[0]?.fields?.ManagedClient === true;

            if (isManagedClient) {
              // For managed clients, just update status
              const today = new Date();
              const formattedDate = `${
                today.getMonth() + 1
              }/${today.getDate()}/${today.getFullYear()}`;
              await grantsTable.update(record.id, {
                ManagedClient: "true",
                Date: formattedDate,
              });
            } else {
              // For non-managed clients, generate and send the draft
              await handleAutodraftGrant(
                grantRecord,
                company,
                processedQuestions,
                email,
                formattedPastAnswers
              );

              draftsSent++; // Increment counter after successful send
              console.log(`Drafts sent: ${draftsSent}/${MINIMUM_DRAFTS}`);

              // Track this send to prevent duplicates
              if (!processedCompanies.has(companyName)) {
                processedCompanies.set(companyName, {
                  sentGrants: new Set([grantName]),
                });
              } else {
                processedCompanies.get(companyName).sentGrants.add(grantName);
              }

              // Update record status
              const today = new Date();
              const formattedDate = `${
                today.getMonth() + 1
              }/${today.getDate()}/${today.getFullYear()}`;

              await grantsTable.update(record.id, {
                Status: "Sent",
                Date: formattedDate,
              });

              // Show success notification
              setContents(
                {
                  type: "success",
                  heading: "Draft Sent Successfully!",
                  message: `Sent draft to ${companyName} for ${grantName} ${record.fields.Amount}!`,
                  className: "bg-green-50 border-green-200 text-green-800",
                },
                setAutoDraftNotification
              );
              setShowNotif(true, setAutoDraftNotification);
            }

            // Add delay between sends
            await new Promise((resolve) => setTimeout(resolve, 2500));
          } catch (error) {
            console.error(`Error processing draft for ${companyName}:`, error);
            setContents(
              {
                type: "error",
                heading: "Error Sending Draft",
                message: `Failed to send draft to ${companyName}: ${error.message}`,
                className: "bg-red-50 border-red-200 text-red-800",
              },
              setAutoDraftNotification
            );
            setShowNotif(true, setAutoDraftNotification);
          }
        }

        // If we've processed all records but haven't hit minimum,
        // wait briefly before trying another batch
        if (draftsSent < MINIMUM_DRAFTS) {
          console.log(
            `Processed batch but only sent ${draftsSent} drafts. Fetching more...`
          );
          await new Promise((resolve) => setTimeout(resolve, 5000));
        }
      }

      // Update timing information for next run
      setLastRunTime(new Date());
      const next = new Date();
      next.setMinutes(next.getMinutes() + 14.4);
      setNextRunTime(next);

      console.log(`Completed sending ${draftsSent} drafts`);
    } catch (error) {
      console.error("Error in automated drafts:", error);
      setContents(
        {
          type: "error",
          heading: "Automation Error",
          message: `Error in automated drafts system: ${error.message}`,
          className: "bg-red-50 border-red-200 text-red-800",
        },
        setAutoDraftNotification
      );
      setShowNotif(true, setAutoDraftNotification);
    }
  }, [isAutoDraftEnabled]);

  // Add useEffect to handle the cron job
  useEffect(() => {
    let intervalId;

    if (isAutoDraftEnabled) {
      // Initial run
      handleAutomatedDrafts();

      // Set up interval (14.4 minutes = 24 hours / 100 runs)
      intervalId = setInterval(handleAutomatedDrafts, 14.4 * 60 * 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isAutoDraftEnabled, handleAutomatedDrafts]);

  // Add these state variables at the top of the LeaderboardPage component
  const [isMonthlyDraftEnabled, setIsMonthlyDraftEnabled] = useState(false);
  const [lastMonthlyRunTime, setLastMonthlyRunTime] = useState(null);
  const [nextMonthlyRunTime, setNextMonthlyRunTime] = useState(null);

  // Add this new function to handle monthly drafts
  const handleMonthlyDrafts = useCallback(async () => {
    if (!isMonthlyDraftEnabled) return;
    console.log("Checking for monthly drafts to send");

    try {
      // Get companies with upvoted grants
      const companiesTable = base("companies");
      const records = await companiesTable
        .select({
          maxRecords: 100,
          view: "Grid view",
          filterByFormula: "NOT({InterestedIn} = '')", // Only get companies with interested grants
        })
        .firstPage();

      for (const record of records) {
        // Check if it's been 30 days since last send
        const lastSentDate = new Date(record.fields.lastSent || "1970-01-01");
        const daysSinceLastSent =
          (new Date() - lastSentDate) / (1000 * 60 * 60 * 24);

        if (daysSinceLastSent >= 30) {
          // Get interested grants
          const interestedGrants = record.fields.InterestedIn.split(",");

          if (interestedGrants.length > 0) {
            // Get the next grant to send
            const nextGrant = interestedGrants[0];

            // Send the draft
            // await handleSubmitAutoDraft(
            //   record,
            //   record.fields.RequestEmail,
            //   nextGrant
            // );

            // // Update last sent date in Airtable
            // await companiesTable.update(record.id, {
            //   lastSent: new Date().toISOString(),
            // });

            console.log(
              `Sent monthly draft to ${record.fields.Name} for grant ${nextGrant}`
            );

            // Show notification
            setContents(
              {
                type: "success",
                heading: "Monthly Draft Sent!",
                message: `Sent draft for ${nextGrant} to ${record.fields.Name}`,
                className: "bg-green-50 border-green-200 text-green-800",
              },
              setAutoDraftNotification
            );
            setShowNotif(true, setAutoDraftNotification);

            // Add delay between sends
            await new Promise((resolve) => setTimeout(resolve, 1500));
          }
        }
      }

      // Update run times
      setLastMonthlyRunTime(new Date());
      const next = new Date();
      next.setDate(next.getDate() + 1); // Check daily for any companies that need monthly drafts
      setNextMonthlyRunTime(next);
    } catch (error) {
      console.error("Error in monthly drafts:", error);
    }
  }, [isMonthlyDraftEnabled]);

  // Add useEffect for the monthly check
  useEffect(() => {
    let intervalId;

    if (isMonthlyDraftEnabled) {
      // Initial run
      handleMonthlyDrafts();

      // Check daily
      intervalId = setInterval(handleMonthlyDrafts, 24 * 60 * 60 * 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isMonthlyDraftEnabled, handleMonthlyDrafts]);

  // Add this JSX component for the monthly toggle
  const MonthlyDraftToggle = () => (
    <div className="flex items-center justify-between p-4 bg-white rounded-lg shadow mb-4">
      <div>
        <h3 className="text-lg font-medium">Monthly Grant Draft Sender</h3>
        <p className="text-sm text-gray-500">
          Send 1 upvoted grant draft per company per month
        </p>
        {lastMonthlyRunTime && (
          <p className="text-xs text-gray-400">
            Last run: {lastMonthlyRunTime.toLocaleString()}
          </p>
        )}
        {nextMonthlyRunTime && (
          <p className="text-xs text-gray-400">
            Next run: {nextMonthlyRunTime.toLocaleString()}
          </p>
        )}
      </div>
      <Switch
        checked={isMonthlyDraftEnabled}
        onChange={setIsMonthlyDraftEnabled}
        className={`${
          isMonthlyDraftEnabled ? "bg-indigo-600" : "bg-gray-200"
        } relative inline-flex h-6 w-11 items-center rounded-full`}
      >
        <span className="sr-only">Enable monthly drafts</span>
        <span
          className={`${
            isMonthlyDraftEnabled ? "translate-x-6" : "translate-x-1"
          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </Switch>
    </div>
  );

  // Add new state for accordion
  const [showPreview, setShowPreview] = useState(false);
  const [previewData, setPreviewData] = useState([]);

  // Add new state variables at the top of the component
  const [selectedGrantFilter, setSelectedGrantFilter] = useState("all");
  const [availableGrants, setAvailableGrants] = useState([]);

  // Add this state near your other state declarations
  const [showManagedClientsOnly, setShowManagedClientsOnly] = useState("true");

  // Modify the fetchPreviewData function to be more responsive
  const fetchPreviewData = useCallback(async () => {
    try {
      const grantsTable = base(
        "ALLGRANTS&SAVINGS(Automated-AccountsReceivable)"
      );

      // Build the filter formula based on selected grant and client type
      let filterFormula = `AND(
        {UpgradeType} = 'Grant',
        OR(BLANK({Status}), {Status} != 'Sent'),
        {ManagedClient} = '${showManagedClientsOnly ? "true" : "false"}'
      )`;

      if (selectedGrantFilter !== "all") {
        filterFormula = `AND(${filterFormula}, {UpgradeName} = '${selectedGrantFilter}')`;
      }

      const records = await grantsTable
        .select({
          maxRecords: 1000,
          view: "Grid view",
          filterByFormula: filterFormula,
          sort: [{ field: "Date", direction: "asc" }],
        })
        .firstPage();

      // Create a Map to track unique companies and their grants
      const companyMap = new Map();

      // Process records and only keep first instance of each company
      records.forEach((record) => {
        const companyName = record.fields.CompanyName;
        const grantName = record.fields.UpgradeName;

        // Only add if company hasn't been seen yet
        if (!companyMap.has(companyName)) {
          companyMap.set(companyName, {
            companyName,
            grantName,
            email: record.fields.ClientEmail,
            amount: record.fields.Amount || "$50,000",
            scheduledDate: new Date(
              Date.now() + Math.random() * 24 * 60 * 60 * 1000
            ).toLocaleString(),
            sentGrants: new Set([grantName]), // Track which grants have been sent
          });
        }
      });

      // Convert map to array for display
      const formattedRecords = Array.from(companyMap.values());

      setPreviewData(formattedRecords);
    } catch (error) {
      console.error("Error fetching preview data:", error);
    }
  }, [selectedGrantFilter, showManagedClientsOnly]);

  // Add useEffect to refresh preview data when filters change
  useEffect(() => {
    if (showPreview) {
      fetchPreviewData();
    }
  }, [
    showPreview,
    selectedGrantFilter,
    showManagedClientsOnly,
    fetchPreviewData,
  ]);

  // Modify the preview toggle button click handler
  const handlePreviewToggle = async () => {
    if (!showPreview) {
      await fetchPreviewData(); // Fetch fresh data before showing
    }
    setShowPreview(!showPreview);
  };

  // Add new function to fetch available grants
  const fetchAvailableGrants = async () => {
    try {
      const grantsTable = base(
        "ALLGRANTS&SAVINGS(Automated-AccountsReceivable)"
      );
      const records = await grantsTable
        .select({
          maxRecords: 100,
          view: "Grid view",
          filterByFormula: `{UpgradeType} = 'Grant'`,
        })
        .firstPage();

      // Get unique grant names
      const uniqueGrants = [
        ...new Set(records.map((record) => record.fields.UpgradeName)),
      ];
      setAvailableGrants(uniqueGrants);
    } catch (error) {
      console.error("Error fetching available grants:", error);
    }
  };

  // Add useEffect to fetch available grants when component mounts
  useEffect(() => {
    fetchAvailableGrants();
  }, []);

  // Modify the AutoDraftToggle component
  const AutoDraftToggle = () => (
    <div>
      {/* Add AutomationControls here */}
      <div className="flex flex-col bg-white rounded-lg shadow mb-4">
        {/* Header section */}
        <div className="flex items-center justify-between p-4">
          <div>
            <h3 className="text-lg font-medium">
              Automated Grant Drafts & Sending Upgrades
            </h3>
            <p className="text-sm text-gray-500">
              Send 100 grant drafts per day automatically
            </p>
            {lastRunTime && (
              <p className="text-xs text-gray-400">
                Last run: {lastRunTime.toLocaleString()}
              </p>
            )}
            {nextRunTime && (
              <p className="text-xs text-gray-400">
                Next run: {nextRunTime.toLocaleString()}
              </p>
            )}
          </div>

          {/* Controls section - now horizontally aligned */}
          <div className="flex items-center gap-4">
            {/* Grant Selector Dropdown */}
            <select
              value={selectedGrantFilter}
              onChange={(e) => {
                setSelectedGrantFilter(e.target.value);
                if (showPreview) {
                  fetchPreviewData();
                }
              }}
              className="block w-48 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            >
              <option value="all">All Grants</option>
              {availableGrants.map((grant) => (
                <option key={grant} value={grant}>
                  {grant}
                </option>
              ))}
            </select>

            {/* Client Type Toggle */}
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-500">
                {showManagedClientsOnly ? "Managed" : "Non-Managed"}
              </span>
              <Switch
                checked={showManagedClientsOnly}
                onChange={(checked) => {
                  setShowManagedClientsOnly(checked);
                  fetchPreviewData();
                }}
                className={`${
                  showManagedClientsOnly ? "bg-indigo-600" : "bg-gray-200"
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Toggle client type</span>
                <span
                  className={`${
                    showManagedClientsOnly ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>

            {/* Auto Draft Toggle */}
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-500">
                {isAutoDraftEnabled ? "Turn Off" : "Full Send"}
              </span>
              <Switch
                checked={isAutoDraftEnabled}
                onChange={setIsAutoDraftEnabled}
                className={`${
                  isAutoDraftEnabled ? "bg-indigo-600" : "bg-gray-200"
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Enable automated drafts</span>
                <span
                  className={`${
                    isAutoDraftEnabled ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>

            {/* Preview Toggle Button */}
            <button
              onClick={handlePreviewToggle}
              className="text-sm text-indigo-600 hover:text-indigo-800"
            >
              {showPreview ? "Hide Preview" : "Show Next 100 Drafts"}
            </button>
          </div>
        </div>

        {/* Preview Accordion */}
        <Transition
          show={showPreview}
          enter="transition-all ease-in-out duration-300"
          enterFrom="opacity-0 max-h-0"
          enterTo="opacity-100 max-h-[80vh]"
          leave="transition-all ease-in-out duration-300"
          leaveFrom="opacity-100 max-h-[80vh]"
          leaveTo="opacity-0 max-h-0"
          className="overflow-hidden"
        >
          <div className="border-t border-gray-200 p-4">
            <div className="overflow-auto max-h-[60vh]">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50 sticky top-0">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Scheduled Time
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Company
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Grant
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {previewData.map((item, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {item.scheduledDate}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {item.companyName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {item.grantName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {item.email}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {item.amount}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="mt-4">
                <h3 className="text-lg font-medium mb-4">
                  Automated Draft Sending Rules
                </h3>
              </div>
              <div className="grid grid-cols-2 gap-8">
                <div>
                  <h4 className="font-medium mb-3">Sending Logic:</h4>
                  <ol className="space-y-3 text-sm leading-relaxed list-decimal list-inside">
                    <li>
                      <s>Maximum 1 grant per company per week or month</s>
                    </li>
                    <li>
                      <s>
                        Don't send drafts for grants that have been sent to the
                        client before
                      </s>
                    </li>
                    <li>
                      <s>Label upvoted grants as clients or not</s>
                    </li>
                    <li><s>Convert this to a daily cron job</s></li>
                    <li>
                      Pull from not only the upvoted grants, but the entire list
                      of grants
                    </li>
                  </ol>
                </div>
                <div>
                  <h4 className="font-medium mb-3">Improvement TODOs:</h4>
                  <ol className="space-y-3 text-sm leading-relaxed list-decimal list-inside">
                    <li>Flag all the upvote upgrades as clients or not</li>
                    <li>
                      Modularize the leaderboard page so its not 2,000 lines
                    </li>
                    <li>
                      Send drafts if the grant has all the required questions
                    </li>
                    <li>Add configurable time intervals between sends</li>
                    <li>Implement priority queue for urgent grants</li>
                    <li>Add email validation and bounce handling</li>
                    <li>Create detailed logging system for debugging</li>
                    <li>Add retry mechanism for failed sends</li>
                    <li>Implement rate limiting per company</li>
                    <li>Add blackout periods for holidays</li>
                    <li>Create analytics dashboard for send metrics</li>
                    <li>Add company-specific send preferences</li>
                    <li>Implement smart scheduling based on open rates</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );

  // 1. First declare state variables at the top of the component
  const [isDeadlineDraftEnabled, setIsDeadlineDraftEnabled] = useState(false);
  const [lastDeadlineRunTime, setLastDeadlineRunTime] = useState(null);
  const [nextDeadlineRunTime, setNextDeadlineRunTime] = useState(null);

  // 2. Define the handler function
  const handleDeadlineBasedDrafts = useCallback(async () => {
    if (!isDeadlineDraftEnabled) return;

    try {
      // Get companies with interested grants
      const companiesTable = base("companies");
      const records = await companiesTable
        .select({
          maxRecords: 500,
          view: "Grid view",
          filterByFormula: "NOT({InterestedIn} = '')", // Only get companies with interested grants
        })
        .firstPage();

      const today = new Date();
      const DAYS_BEFORE_DEADLINE = 360;
      const MS_PER_DAY = 24 * 60 * 60 * 1000;

      console.log("Checking for deadline-based drafts to send");

      for (const company of records) {
        const interestedGrants = company.fields.InterestedIn.split(",").map(
          (g) => g.trim()
        );

        console.log(
          `Checking ${interestedGrants.length} grants for ${company.fields.Name}`
        );

        // Get activity history to check if we've already sent drafts
        const activityHistory = JSON.parse(
          company.fields.Activity || '{"Grants": []}'
        );

        // Fetch each interested grant individually from deduped_grants table
        for (const grantName of interestedGrants) {
          console.log(`Looking for grant: ${grantName}`);

          // Query the specific grant by name
          const grantRecords = await base("deduped_grants")
            .select({
              maxRecords: 1,
              filterByFormula: `{Name} = '${grantName}'`,
            })
            .firstPage();

          const grantRecord = grantRecords[0];

          if (!grantRecord || !grantRecord.fields.Deadline) {
            console.log(`No deadline found for grant: ${grantName}`);
            continue;
          }

          // Skip if deadline is the placeholder date
          if (grantRecord.fields.Deadline === "2023-08-23") {
            console.log(
              `Skipping grant with placeholder deadline: ${grantName}`
            );
            continue;
          }

          const deadline = new Date(grantRecord.fields.Deadline);
          const daysUntilDeadline = Math.ceil((deadline - today) / MS_PER_DAY);

          console.log(`Days until deadline: ${daysUntilDeadline}`);

          // Check if we're within the deadline window
          if (
            daysUntilDeadline <= DAYS_BEFORE_DEADLINE &&
            daysUntilDeadline > 0
          ) {
            // Update activity history
            activityHistory.Grants.push({
              [grantRecord.id]: {
                Date: today.toDateString(),
                Text: `${grantRecord.fields.Name} worth ${grantRecord.fields.Amount}`,
              },
            });
            activityHistory.LatestActivity = today.toDateString();

            // TODO: Uncomment when ready to enable actual sending
            // await handleSubmitAutoDraft(
            //   company,
            //   company.fields.RequestEmail,
            //   grantName
            // );

            // Update company activity in Airtable
            // await companiesTable.update(company.id, {
            //   fields: {
            //     Activity: JSON.stringify(activityHistory),
            //   },
            // });

            // Show notification
            setContents(
              {
                type: "success",
                heading: "Deadline Draft Sent!",
                message: `Sent draft to ${company.fields.Name} for ${grantName} (${daysUntilDeadline} days until deadline)`,
                className: "bg-green-50 border-green-200 text-green-800",
              },
              setAutoDraftNotification
            );
            setShowNotif(true, setAutoDraftNotification);

            // Add delay between sends to avoid rate limiting
            await new Promise((resolve) => setTimeout(resolve, 2000));
          }
        }
      }

      // Update run times
      setLastDeadlineRunTime(new Date());
      const next = new Date();
      next.setDate(next.getDate() + 1);
      setNextDeadlineRunTime(next);
    } catch (error) {
      console.error("Error in deadline-based drafts:", error);
    }
  }, [isDeadlineDraftEnabled]);

  // 3. Define the useEffect that uses the handler
  useEffect(() => {
    let intervalId;

    if (isDeadlineDraftEnabled) {
      // Initial run
      handleDeadlineBasedDrafts();

      // Check daily
      intervalId = setInterval(handleDeadlineBasedDrafts, 24 * 60 * 60 * 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isDeadlineDraftEnabled, handleDeadlineBasedDrafts]);

  // 4. Define the toggle component
  const DeadlineDraftToggle = () => (
    <div className="flex items-center justify-between p-4 bg-white rounded-lg shadow mb-4">
      <div>
        <h3 className="text-lg font-medium">Deadline-Based Grant Drafts</h3>
        <p className="text-sm text-gray-500">
          Send grant drafts 60 days before deadlines
        </p>
        {lastDeadlineRunTime && (
          <p className="text-xs text-gray-400">
            Last run: {lastDeadlineRunTime.toLocaleString()}
          </p>
        )}
        {nextDeadlineRunTime && (
          <p className="text-xs text-gray-400">
            Next run: {nextDeadlineRunTime.toLocaleString()}
          </p>
        )}
      </div>
      <Switch
        checked={isDeadlineDraftEnabled}
        onChange={setIsDeadlineDraftEnabled}
        className={`${
          isDeadlineDraftEnabled ? "bg-indigo-600" : "bg-gray-200"
        } relative inline-flex h-6 w-11 items-center rounded-full`}
      >
        <span className="sr-only">Enable deadline-based drafts</span>
        <span
          className={`${
            isDeadlineDraftEnabled ? "translate-x-6" : "translate-x-1"
          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </Switch>
    </div>
  );

  // 1. Add state variables for tab control
  const [activeTab, setActiveTab] = useState("summary");
  const [activeGrantTab, setActiveGrantTab] = useState("summary");
  const [selectedTechnology, setSelectedTechnology] = useState(null);
  const [selectedGrant, setSelectedGrant] = useState(null);

  // Add this function before the return statement
  const handleSubmitTechSavings = async (company, email, technology) => {
    try {
      // Send POST request to Zapier
      const response = await fetch(
        "https://hooks.zapier.com/hooks/catch/13965335/2ota6y3/",
        {
          method: "POST",
          body: JSON.stringify({
            CompanyName: company.name,
            ClientEmail: email,
            RequesterEmail: "userEmail",
            PartnerEmail: "",
            Link: `${window.location.origin}/tech-savings/${company.domain}`,
            UpgradeName: `${technology} Cost Optimization`,
            Amount: "$100,000",
            ClientName: company.name,
            Date: new Date().toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            UpgradeType: "Tech Savings",
            Deadline: "2024-12-31",
            Password: "",
            SlackNotification: "true",
          }),
        }
      );

      if (response.ok) {
        console.log("Tech savings email sent successfully to Zapier");

        // Show success notification
        setContents(
          {
            type: "success",
            heading: "Tech Savings Draft Sent!",
            message: (
              <div className="flex items-center space-x-2">
                <CheckCircleIcon
                  className="h-6 w-6 text-green-400 animate-bounce"
                  aria-hidden="true"
                />
                <span>
                  Tech savings draft has been sent! Please check your inbox.
                </span>
              </div>
            ),
            className: "bg-green-50 border-green-200 text-green-800",
          },
          setAutoDraftNotification
        );
        setShowNotif(true, setAutoDraftNotification);
      } else {
        throw new Error("Failed to send tech savings email");
      }
    } catch (error) {
      console.error("An error occurred:", error);

      // Show error notification
      setContents(
        {
          type: "error",
          heading: "Error Sending Draft",
          message: "Failed to send tech savings draft. Please try again.",
          className: "bg-red-50 border-red-200 text-red-800",
        },
        setAutoDraftNotification
      );
      setShowNotif(true, setAutoDraftNotification);
    }
  };

  // Add these helper functions outside the return statement
  const filterBySearch = (company) => {
    const searchTerm = companySearch?.trim()?.toLowerCase() || "";
    const companyName = company?.fields?.Name?.toLowerCase() || "";
    return companyName.includes(searchTerm);
  };

  const filterByIndustry = (company) => {
    if (filterOptions.Industries.length === 0) return true;

    const companyIndustries =
      company?.fields?.Industry?.toLowerCase()
        ?.split(",")
        .map((i) => i.trim()) || [];

    return filterOptions.Industries.some((industryOption) =>
      industries[industryOption]?.some((subindustry) =>
        companyIndustries.includes(subindustry)
      )
    );
  };

  const renderCompanyCard = (company) => (
    <li
      key={company.id}
      className="overflow-hidden rounded-xl border border-gray-200"
    >
      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
        <a
          href={`/?website=${company.fields.Domain}&company=${company.fields.Name}`}
          className="pointer"
        >
          <img
            src={company?.fields?.LogoUrl}
            alt={company?.fields?.Name}
            className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
          />
        </a>
        <div className="text-sm font-medium leading-6 text-gray-900">
          {company?.fields?.Name}
        </div>
      </div>
      {isOpen && (
        <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Revenue</dt>
            <dd className="text-gray-700">
              <time>
                {currencyFormat(
                  company?.fields?.AnnualRevenue
                    ? company.fields.AnnualRevenue
                    : company.fields.Employees * 100000
                )}
              </time>
            </dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Industry</dt>
            <dd className="flex items-start gap-x-2">
              <div className="font-medium text-gray-900">
                {company?.fields?.Industry || ""}
              </div>
            </dd>
          </div>

          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Country</dt>
            <dd className="flex items-start gap-x-2">
              <div className="font-medium text-gray-900">
                {company?.fields?.Country || ""}
              </div>
            </dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">To Email</dt>
            <dd className="flex items-start gap-x-2">
              <div className="font-medium text-gray-900">
                {company?.fields?.RequestEmail || ""}
              </div>
            </dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Reporting On / Off</dt>
            <dd className="flex items-start gap-x-2">
              <div className="font-medium text-gray-900">
                {company?.fields?.reportingEmails || ""}
              </div>
            </dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">To Name</dt>
            <dd className="flex items-start gap-x-2">
              <div className="font-medium text-gray-900">
                {company?.fields?.People1Name || ""}
              </div>
            </dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">From Name</dt>
            <dd className="flex items-start gap-x-2">
              <div className="font-medium text-gray-900">
                {company?.fields?.fromName || ""}
              </div>
            </dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Send Frequency</dt>
            <dd className="flex items-start gap-x-2">
              <div className="font-medium text-gray-900">
                {company?.fields?.sendFrequency || ""}
              </div>
            </dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Last Sent</dt>
            <dd className="flex items-start gap-x-2">
              <div className="font-medium text-gray-900">
                {company?.fields?.lastSent || ""}
              </div>
            </dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Blurred</dt>
            <dd className="flex items-start gap-x-2">
              <div className="font-medium text-gray-900">
                {company?.fields?.blurMe || ""}
              </div>
            </dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">cc Emails</dt>
            <dd className="flex items-start gap-x-2">
              <div className="font-medium text-gray-900">
                {company?.fields?.ccEmails || ""}
              </div>
            </dd>
          </div>
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">bcc Emails</dt>
            <dd className="flex items-start gap-x-2">
              <div className="font-medium text-gray-900">
                {company?.fields?.bccEmails || ""}
              </div>
            </dd>
          </div>
          <form onSubmit={(e) => handleSubmit(e, company)}>
            <div className="sm:col-span-full">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Manage Company Contact(s)
              </label>
              <p className="block text-xs font-small text-gray-700 mb-2">
                Enter lists of emails (separated by commas!)
              </p>
              <span className="items-center justify-center my-4">
                <label className="block text-sm font-medium leading-6 text-gray-700">
                  To Email(s):
                </label>
                <textarea
                  defaultValue={company?.fields?.RequestEmail || ""}
                  id="requestEmail"
                  name="requestEmail"
                  className="block text-sm rounded-md p-3 ml-2 max-w-screen w-full h-100 resize-y"
                />
              </span>
              <span className="items-center justify-center my-4">
                <label className="block text-sm font-medium leading-6 text-gray-700 mt-4">
                  Who's CC'd on this email?
                </label>

                <textarea
                  id="CC"
                  name="CC"
                  defaultValue={company?.fields?.ccEmails || ""}
                  className="block text-sm max-w-screen max-w-screen w-full p-3 rounded-md resize-y h-100 ml-2"
                />
              </span>
              <span className="inline-flex items-center justify-center grid grid-cols-1 my-4">
                <label className="block text-sm font-medium leading-6 text-gray-700">
                  Name of First Contact
                </label>
                <input
                  id="People1Name"
                  name="People1Name"
                  defaultValue={company?.fields?.People1Name || ""}
                  className="block text-sm max-w-screen w-sm p-3 rounded-md h-8 ml-2"
                />
              </span>
              <>
                {/* <label className="block text-sm font-medium leading-6 text-gray-900">Admin:</label> */}
                <span className="items-center justify-center my-4">
                  <label className="block text-sm font-medium leading-6 text-gray-900 mt-3">
                    Who's BCC'd on this email?
                  </label>
                  <textarea
                    id="BCC"
                    name="BCC"
                    defaultValue={company?.fields?.bccEmails || ""}
                    className="block text-sm max-w-screen w-full p-3 resize-y rounded-md h-100 ml-2"
                  />
                </span>
                <span className="items-center justify-center my-4">
                  <label className="block text-sm font-medium leading-6 text-gray-900 mt-3">
                    Who's this email from? (Enter a name)
                  </label>
                  <input
                    id="fromName"
                    name="fromName"
                    defaultValue={company?.fields?.fromName || ""}
                    className="block text-xs max-w-screen w-full p-3 rounded-md h-8 ml-2"
                  />
                </span>
                <span className="inline-flex items-center justify-center my-4">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Should this email be blurred?
                  </label>
                  <input
                    type="checkbox"
                    id="blurEmail"
                    name="blurEmail"
                    defaultChecked={company.fields.blurMe === "true"}
                    className="block text-sm ml-3"
                  />
                </span>
              </>
            </div>
            <div className="sm:col-span-full">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Sending Frequency
              </label>
              <select
                id="sendingFrequencyOption"
                defaultValue={
                  !company?.fields?.reportingEmails ||
                  company.fields.reportingEmails === "false" ||
                  !company?.fields?.sendFrequency
                    ? "-1"
                    : company.fields.sendFrequency
                }
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block max-w-sm w-full"
              >
                {sendingFrequencyOptions.map((option) => (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <button
              type="submit"
              className="mt-4 inline-flex rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Save
            </button>
          </form>
        </dl>
      )}
      {admin && (
        <div className="ml-auto inline-flex text-gray-300 hover:text-red-400">
          <button
            className="block"
            onClick={() => {
              setCurrentCompany(company);
              setShowWarningModal(true);
            }}
          >
            <TrashIcon className="h-6 w-6 aria-hidden" />
          </button>
        </div>
      )}
    </li>
  );

  const [automationStatus, setAutomationStatus] = useState({
    isEnabled: false,
    lastRun: null,
    nextRun: null,
  });

  // Fetch status periodically
  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await fetch("/api/automation/status");
        const data = await response.json();
        setAutomationStatus(data);
      } catch (error) {
        console.error("Error fetching automation status:", error);
      }
    };

    fetchStatus();
    const intervalId = setInterval(fetchStatus, 60000); // Check every minute

    return () => clearInterval(intervalId);
  }, []);

  // Toggle automation
  const toggleAutomation = async (enabled) => {
    try {
      const response = await fetch("/api/automation/toggle", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ enabled }),
      });
      const data = await response.json();
      if (data.success) {
        setAutomationStatus((prev) => ({ ...prev, isEnabled: enabled }));
      }
    } catch (error) {
      console.error("Error toggling automation:", error);
    }
  };

  return (
    <>
      <div className="bg-white px-6 py-12 sm:py-12 lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl drop-shadow-[0_4px_3px_rgba(0,0,0,0.3)]">
            No Risk. All Reward.
          </h2>
          {/* Send and get paid for tech savings, grants, automations, growth upgrades */}
          <p className="mt-6 text-lg leading-8 text-gray-600 font-sf-pro-display tracking-tight">
            Transform your career with powerful AI & automation.
            <br />
            Send 100 perfectly crafted drafts daily, unlock new opportunities
            with ease.
            <br />
            Experience a little slice of freedom — where earning $1,000 daily
            feels effortless.{" "}
            <button
              type="button"
              className="ml-2 rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={toggle}
            >
              i
            </button>
          </p>
        </div>
        <br />
        {/* Add AutomationControls here */}
        <AutomationControls />
        {/* Keep existing toggles */}
        <AutoDraftToggle />
        <MonthlyDraftToggle />
        <DeadlineDraftToggle />
        <br />

        <div className="mx-auto max-w-7xl text-center">
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <Fragment>
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                  <div className="flex items-center justify-between">
                    <h2
                      className="hidden sm:block text-base font-semibold leading-7 text-gray-900"
                      id="companies"
                    >
                      Recommended Partners
                    </h2>
                    <span className="inline-flex ml-auto mr- items-center justify-center">
                      <button
                        onClick={handleSort}
                        className="text-sm text-gray-600 mr-4 font-semibold leading-6"
                      >
                        <Tooltip
                          content="Sort by Created Date"
                          placement="bottom"
                          className="z-50 inline"
                        >
                          {shouldSort ? (
                            <BarsArrowUpIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          ) : (
                            <BarsArrowDownIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          )}
                        </Tooltip>
                      </button>
                      {filterOptions.Industries.map((industryOption, index) => {
                        return (
                          <div
                            key={industryOption}
                            className={`mx-2 p-2 ${
                              index % 3 === 0
                                ? "bg-green-200"
                                : index % 3 === 1
                                ? "bg-indigo-200"
                                : "bg-amber-200"
                            } ${
                              index % 3 === 0
                                ? "hover:bg-green-100"
                                : index % 3 === 1
                                ? "hover:bg-indigo-100"
                                : "hover:bg-amber-100"
                            } rounded-s-xl rounded-e-xl text-sm max-w-xl w-auto`}
                          >
                            <span className="inline-flex">
                              <p className="text-sm text-gray-900">
                                {industryOption}
                              </p>
                              <button
                                onClick={() => {
                                  setFilterOptions((prev) => ({
                                    ...prev,
                                    Industries: prev.Industries.filter(
                                      (item) => item != industryOption
                                    ),
                                  }));
                                }}
                                aria-hidden="true"
                                className="rounded-md text-white"
                              >
                                <XMarkIcon className="w-4 h-auto ml-auto text-white hover:text-black" />
                              </button>
                            </span>
                          </div>
                        );
                      })}
                      <Menu as="div" className="relative ml-auto mr-1">
                        <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Open options</span>
                          <FunnelIcon className="h-5 w-5" aria-hidden="true" />
                        </Menu.Button>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            <p className="text-sm">Filter By:</p>
                            <Menu.Item>
                              {({ active }) => (
                                <Menu as="div">
                                  <Menu.Button
                                    className={classNames(
                                      active ? "bg-gray-50" : "",
                                      "w-full block px-3 py-1 text-sm leading-6 text-gray-900"
                                    )}
                                  >
                                    <span>Industry</span>
                                  </Menu.Button>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute right-0 z-10 mt-0.5 w-36 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                      <ul className="flex-1 flex-wrap flex-col h-40px">
                                        {Object.keys(industries).length !== 0 &&
                                          Object.keys(industries).map(
                                            (industry) => {
                                              return (
                                                <Menu.Item key={industry}>
                                                  {({ active }) => (
                                                    <button
                                                      className="w-full"
                                                      onClick={() => {
                                                        setFilterOptions(
                                                          (prev) => ({
                                                            ...prev,
                                                            Industries:
                                                              prev.Industries.map(
                                                                (item) =>
                                                                  item
                                                                    .toLowerCase()
                                                                    .trim()
                                                              ).includes(
                                                                industry
                                                                  .trim()
                                                                  .toLowerCase()
                                                              )
                                                                ? prev.Industries.filter(
                                                                    (item) =>
                                                                      item !==
                                                                      industry
                                                                  )
                                                                : [
                                                                    ...prev.Industries,
                                                                    industry,
                                                                  ],
                                                          })
                                                        );
                                                      }}
                                                    >
                                                      <p
                                                        className={classNames(
                                                          active
                                                            ? "bg-gray-50"
                                                            : "",
                                                          "block px-3 py-1 text-sm leading-6 text-gray-900 w-full"
                                                        )}
                                                      >
                                                        {industry}
                                                      </p>
                                                    </button>
                                                  )}
                                                </Menu.Item>
                                              );
                                            }
                                          )}
                                      </ul>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              )}
                            </Menu.Item>
                            {/* <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={classNames(active ? "bg-gray-50" : "", "block px-3 py-1 text-sm leading-6 text-gray-900")}
                                >
                                  Edit
                                </button>
                              )}
                            </Menu.Item> */}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </span>

                    <div className="">
                      <input
                        type="text"
                        placeholder="Search for a company"
                        className="rounded-md px-3 py-1"
                        value={companySearch}
                        onChange={(e) => setCompanySearch(e.target.value)}
                        // onKeyDown={(e) => e.key === "Enter" && fetchCompanies(e.target.value)}
                      />
                    </div>
                  </div>
                  <ul className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                    {companies.length === 0
                      ? // Loading state
                        [1, 2, 3].map((id) => (
                          <div key={id}>
                            <Skeleton height={200} />
                          </div>
                        ))
                      : // Filtered and mapped companies
                        displayResults
                          .filter(filterBySearch)
                          .filter(filterByIndustry)
                          .map(renderCompanyCard)}
                  </ul>
                  <div className="items-center text-center mx-auto">
                    <button
                      onClick={() => {
                        if (showAmount - 36 > 0)
                          setShowAmount((prev) => prev - 36);
                      }}
                      className="mt-4 mr-1 relative w-lg text-center max-w-sm inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Show Less
                    </button>
                    {
                      <button
                        onClick={handleShowMore}
                        className="mt-4 ml-1 relative w-lg text-center max-w-sm inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        Show More
                      </button>
                    }
                  </div>
                </div>
              </Fragment>
            </div>
            {/* Clients on Left, Non Clients on Right */}
            <div className="grid grid-cols-1 md:grid-cols-1 gap-4 p-4">
              {/* Left Column */}
              <div className="p-6 rounded-lg">
                {/* TECH LEADERBOARD */}
                <h2 className="text-2xl font-semibold my-4">
                  Client Vendor Savings Leaderboard
                </h2>

                {/* Tech Tabs Navigation */}
                <div className="border-b border-gray-200 mb-6">
                  <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    <button
                      onClick={() => setActiveTab("summary")}
                      className={classNames(
                        activeTab === "summary"
                          ? "border-indigo-500 text-indigo-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                      )}
                    >
                      Summary View
                    </button>
                    <button
                      onClick={() => setActiveTab("detailed")}
                      className={classNames(
                        activeTab === "detailed"
                          ? "border-indigo-500 text-indigo-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                      )}
                    >
                      Detailed View
                    </button>
                  </nav>
                </div>

                {/* Tech Summary View */}
                {activeTab === "summary" && (
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {technologyArray.map((item) => (
                      <div
                        key={item.technology}
                        className="p-4 rounded-lg border border-gray-200 hover:shadow-lg transition-shadow"
                      >
                        <div className="flex justify-between items-center mb-2">
                          <h3
                            className="font-bold text-lg truncate"
                            title={item.technology}
                          >
                            {item.technology}
                          </h3>
                          <span className="text-sm text-gray-500">
                            {item.count} companies
                          </span>
                        </div>
                        <div className="text-lg font-semibold text-gray-900">
                          {currencyFormat(item.count * 100000)}
                        </div>
                        <button
                          onClick={() => {
                            setSelectedTechnology(item.technology);
                            setActiveTab("detailed");
                          }}
                          className="mt-2 text-sm text-indigo-600 hover:text-indigo-800"
                        >
                          View {item.companies.length} companies →
                        </button>
                      </div>
                    ))}
                  </div>
                )}

                {/* Tech Detailed View */}
                {activeTab === "detailed" && (
                  <div className="space-y-6">
                    <ul role="list" className="divide-y divide-gray-100">
                      {technologyArray
                        .filter(
                          (item) =>
                            !selectedTechnology ||
                            item.technology === selectedTechnology
                        )
                        .map((item) => (
                          <li key={item.technology} className="py-5">
                            <div className="flex justify-between items-start">
                              <div>
                                <h3 className="text-lg font-semibold">
                                  {item.technology}
                                </h3>
                                <p className="text-sm text-gray-500">
                                  {currencyFormat(item.count * 100000)}{" "}
                                  potential
                                </p>
                              </div>
                            </div>
                            <div className="mt-2 flex flex-wrap gap-2">
                              {item.companies.map((company, index) => (
                                <div
                                  key={index}
                                  className="flex items-center bg-gray-50 rounded-full px-3 py-1"
                                >
                                  <a
                                    href={
                                      company.domain.startsWith("http")
                                        ? company.domain
                                        : `https://${company.domain}`
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-sm text-blue-500 hover:underline mr-2"
                                  >
                                    {company.name}
                                  </a>
                                  <button
                                    onClick={() =>
                                      handleSubmitTechSavings(
                                        company,
                                        company.email,
                                        item.technology
                                      )
                                    }
                                    className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                  >
                                    Send Money
                                  </button>
                                </div>
                              ))}
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}

                {/* Grants Section */}
                <div className="p-6 rounded-lg">
                  <h2 className="text-2xl font-semibold mb-4">
                    Client Grants Leaderboard
                  </h2>

                  {/* Grants Tabs Navigation */}
                  <div className="border-b border-gray-200 mb-6">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                      <button
                        onClick={() => setActiveGrantTab("summary")}
                        className={classNames(
                          activeGrantTab === "summary"
                            ? "border-indigo-500 text-indigo-600"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                        )}
                      >
                        Summary View
                      </button>
                      <button
                        onClick={() => setActiveGrantTab("detailed")}
                        className={classNames(
                          activeGrantTab === "detailed"
                            ? "border-indigo-500 text-indigo-600"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                        )}
                      >
                        Detailed View
                      </button>
                    </nav>
                  </div>

                  {/* Grants Summary View */}
                  {activeGrantTab === "summary" && (
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      {grantArray.map((item) => (
                        <div
                          key={item.grant}
                          className="p-4 rounded-lg border border-gray-200 hover:shadow-lg transition-shadow"
                        >
                          <div className="flex justify-between items-center mb-2">
                            <h3
                              className="font-bold text-lg truncate"
                              title={item.grant}
                            >
                              {item.grant}
                            </h3>
                            <span className="text-sm text-gray-500">
                              {item.count} companies
                            </span>
                          </div>
                          <div className="text-lg font-semibold text-gray-900">
                            {currencyFormat(item.count * 50000 * 0.2)}
                          </div>
                          <button
                            onClick={() => {
                              setSelectedGrant(item.grant);
                              setActiveGrantTab("detailed");
                            }}
                            className="mt-2 text-sm text-indigo-600 hover:text-indigo-800"
                          >
                            View {item.companies.length} companies →
                          </button>
                        </div>
                      ))}
                    </div>
                  )}

                  {/* Grants Detailed View */}
                  {activeGrantTab === "detailed" && (
                    <div className="space-y-6">
                      {/* Grant Filter Dropdown */}
                      <div className="mb-6">
                        <select
                          value={selectedGrant || ""}
                          onChange={(e) => setSelectedGrant(e.target.value)}
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                          <option value="">All Grants</option>
                          {grantArray.map((item) => (
                            <option key={item.grant} value={item.grant}>
                              {item.grant} ({item.count} companies)
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* Company List */}
                      <div className="bg-white shadow overflow-hidden sm:rounded-md">
                        <ul className="divide-y divide-gray-200">
                          {grantArray
                            .filter(
                              (item) =>
                                !selectedGrant || item.grant === selectedGrant
                            )
                            .map((item) => (
                              <li key={item.grant}>
                                <div className="px-4 py-4 sm:px-6">
                                  <div className="flex items-center justify-between">
                                    <h4 className="text-lg font-medium text-indigo-600">
                                      {item.grant}
                                    </h4>
                                    <div className="text-sm text-gray-500">
                                      {item.count} companies |{" "}
                                      {currencyFormat(item.count * 50000 * 0.2)}
                                    </div>
                                  </div>
                                  <div className="mt-2 flex flex-wrap gap-2">
                                    {item.companies.map((company, index) => (
                                      <div
                                        key={index}
                                        className="flex items-center bg-gray-50 rounded-full px-3 py-1"
                                      >
                                        <a
                                          href={
                                            company.domain.startsWith("http")
                                              ? company.domain
                                              : `https://app.getupgraded.ca/?website=${company.domain}`
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="text-sm text-blue-500 hover:underline mr-2"
                                        >
                                          {company.name}
                                        </a>
                                        <button
                                          onClick={() =>
                                            handleSubmitTechSavings(
                                              company,
                                              company.email,
                                              item.grant
                                            )
                                          }
                                          className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        >
                                          Send Money
                                        </button>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div></div>

            <Transition.Root show={showWarningModal} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                initialFocus={cancelButtonRef}
                onClose={setShowWarningModal}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                        <div className="sm:flex sm:items-start">
                          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            <ExclamationTriangleIcon
                              className="h-6 w-6 text-red-600"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <Dialog.Title
                              as="h3"
                              className="text-base font-semibold leading-6 text-gray-900"
                            >
                              Delete Company
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Are you sure you want to delete this company?
                                All of the data will be removed from our
                                database.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                            onClick={() => {
                              deleteRecord(currentCompany.id);
                              setCurrentCompany({});
                              setShowWarningModal(false);
                            }}
                          >
                            Delete
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={() => {
                              setCurrentCompany({});
                              setShowWarningModal(false);
                            }}
                            ref={cancelButtonRef}
                          >
                            Cancel
                          </button>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          </>
        </div>
      </div>

      {/* Add notification component */}
      <Notification
        showNotif={autoDraftNotification.showNotif}
        setShowNotif={(val) => setShowNotif(val, setAutoDraftNotification)}
        contents={autoDraftNotification.contents}
      />
    </>
  );
}

Leaderboard.defaultProps = {
  admin: false,
};
