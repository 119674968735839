import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { classNames, } from "./helpers.js";

export default function RemoveQuestionDialog({
	open,
	setOpen,
	handleDeleteQuestion,
	handleRemoveQuestion,
	DeleteOrRemove, // either 'Delete' or 'Remove'
	question,
	setQuestion,
}) {
	const cancelButtonRef = useRef(null);

	const numRelatedGrants = question.fields.grantIdentifier.split(",").length;
	const numRelatedGrantsMessage =
		numRelatedGrants === 1
			? "This question is only related to the currently selected grant."
			: `This question is associated with ${numRelatedGrants} in total.`;
	const deleteMessage = "Are you sure you want to delete this grant? " + numRelatedGrantsMessage;
	const removeMessage = "Are you sure you want to remove this question from the currently selected grant?";

	const [modalColour, setModalColour] = useState(DeleteOrRemove === "Delete" ? "red" : DeleteOrRemove === "Remove" ? "yellow" : "");



	const handleClick = () => {
		if (DeleteOrRemove === "Delete") {
			handleDeleteQuestion(question);
			
		} else if (DeleteOrRemove === "Remove") {
			handleRemoveQuestion(question);
		}
		setQuestion({});
	};

	return (
		<>
			<Transition.Root show={open} as={Fragment}>
				<Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
									<div className="sm:flex sm:items-start">
										<div
											className={classNames(
												`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-${modalColour}-100 sm:mx-0 sm:h-10 sm:w-10`
											)}
										>
											<ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
										</div>
										<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
											<Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
												{DeleteOrRemove === "Delete" && `Delete Question: '${question.fields.QuestionText}'`}
												{DeleteOrRemove === "Remove" && `Remove Question: '${question.fields.QuestionText}'`}
											</Dialog.Title>
											<div className="mt-2">
												<p className="text-sm text-gray-500">
													{DeleteOrRemove === "Delete" && deleteMessage}
													{DeleteOrRemove === "Remove" && removeMessage}
												</p>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
										<button
											type="button"
											className={"inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto " + (modalColour === "red" ? "bg-red-500 hover:bg-red-600" : modalColour === "yellow" ? "bg-yellow-500 hover:bg-yellow-600" : "")}
											onClick={() => {
												handleClick();
												setOpen(false);
											}}
										>
											{DeleteOrRemove}
										</button>
										<button
											type="button"
											className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
											onClick={() => setOpen(false)}
											ref={cancelButtonRef}
										>
											Cancel
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}
