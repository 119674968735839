import React, { useState } from 'react';
import { useSignIn, useUser } from "@clerk/clerk-react";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { CheckCircleIcon, XCircleIcon, XMarkIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import {
	// checkValidDomainName,
	// checkEmail,
	// extractDomain,
	// setContents,
	// setShowNotif,
	includesSpecialCharacters,
	containsNumber,
} from "./helpers";

const specialCharacters = `\!"#$%&'()*+,-./:;<=>?@[\]^_\{|}~`;

function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState("");
  const [code, setCode] = useState('');
  const [successfulCreation, setSuccessfulCreation] = useState(false);
  const [error, setError] = useState('');
  const [isReset, setIsReset] = useState(false);

  const navigate = useNavigate();
  const { isLoaded, signIn, setActive } = useSignIn();
  const { user, isSignedIn, isLoaded : isUserLoaded } = useUser()
  const [isFocused, setIsFocused] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
		minimumLength: {
			message: "Minimum password length of 8",
			fulfilled: false,
		},
		specialChar: {
			message: `At least 1 special character from: ${specialCharacters}`,
			fulfilled: false,
		},
		number: {
			message: "At least 1 number",
			fulfilled: false,
		},
		confirmPasswordMatches: {
			message: "Passwords match",
			fulfilled: false,
		},
	});

  useEffect(() => {
    if (!isLoaded || !isUserLoaded) {
      return;
    }
  
    if (isSignedIn) {
      navigate('/');
    }
  }, [isLoaded, isUserLoaded, isSignedIn])

  // useEffect(() => {

  // }, [isReset])

  const create = async (e) => {
    e.preventDefault();

    if (!isLoaded) return;
    console.log("running")

    try {
			const result = await signIn.create({
				strategy: 'reset_password_email_code',
        identifier: email,
			});
      setSuccessfulCreation(true);
      setError('');
		} catch (error) {
			console.error('error', error.errors[0].longMessage);
      setError(error.errors[0].longMessage);
		}
  }

  async function reset(e) {
    e.preventDefault();

    if (!isLoaded) return;

    try {
			const result = await signIn.attemptFirstFactor({
				strategy: 'reset_password_email_code',
        code,
        password,
			});

			if (result.status === 'complete') {
        setIsReset(true)
        setTimeout(() => setActive({ session: result.createdSessionId }), 5000);
        setError('');
      } else {
        console.log(result);
      }
		} catch (error) {
			console.error('error', error.errors[0].longMessage);
      setError(error.errors[0].longMessage);
		}
  }

  const changePasswordFulfilledValue = (field, value) => {
		setPasswordRequirements((prev) => ({ ...prev, [field]: { ...prev[field], fulfilled: value } }));
	};

  const onPasswordChange = (e) => {
		const password = e.target.value;

		setPassword(password);

		// Minimum Length Checks
		if (passwordRequirements.minimumLength.fulfilled === false && password.length >= 8) {
			changePasswordFulfilledValue("minimumLength", true);
		} else if (passwordRequirements.minimumLength.fulfilled === true && password.length < 8) {
			changePasswordFulfilledValue("minimumLength", false);
		}

		// Special Char Checks
		if (passwordRequirements.specialChar.fulfilled === false && includesSpecialCharacters(specialCharacters, password)) {
			changePasswordFulfilledValue("specialChar", true);
		} else if (passwordRequirements.specialChar.fulfilled === true && !includesSpecialCharacters(specialCharacters, password)) {
			changePasswordFulfilledValue("specialChar", false);
		}

		// Number Checks
		if (passwordRequirements.number.fulfilled === false && containsNumber(password)) {
			changePasswordFulfilledValue("number", true);
		} else if (passwordRequirements.number.fulfilled === true && !containsNumber(password)) {
			changePasswordFulfilledValue("number", false);
		}

		// 'Confirm Password' matches 'Password' Checks
		if (passwordRequirements.confirmPasswordMatches.fulfilled === false && password === confirmPassword) {
			changePasswordFulfilledValue("confirmPasswordMatches", true);
		} else if (passwordRequirements.confirmPasswordMatches.fulfilled === true && password !== confirmPassword) {
			changePasswordFulfilledValue("confirmPasswordMatches", false);
		}
	};

	const onConfirmPasswordChange = (event) => {
		const confirmPassword = event.target.value;

		setConfirmPassword(confirmPassword);

		if (passwordRequirements.confirmPasswordMatches.fulfilled === false && confirmPassword === password) {
			changePasswordFulfilledValue("confirmPasswordMatches", true);
		} else if (passwordRequirements.confirmPasswordMatches.fulfilled === true && confirmPassword !== password) {
			changePasswordFulfilledValue("confirmPasswordMatches", false);
		}
	};

  return (
      <section class="relative bg-white dark:bg-gray-900">
        {isReset && !error && (
          <div class="absolute inset-x-0 top-4 flex justify-center">
            <div className="w-1/4 rounded-md bg-green-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-green-800">Successfully reset password</p>
                </div>
                <div className="ml-auto pl-3">
                  <div className="-mx-1.5 -my-1.5">
                    <button
                      type="button"
                      onClick={() => setIsReset(false)}
                      className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                    >
                      <span className="sr-only">Dismiss</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isReset && error && (
          <div class="absolute inset-x-0 top-4 flex justify-center">
            <div className="w-1/4 border-l-4 border-yellow-400 bg-yellow-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-yellow-700">
                    Password reset failed.{' '}
                    <Link to="/forgot-password" className="font-medium text-yellow-700 underline hover:text-yellow-600">
                      Try again.
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                <img
                  className="h-10 w-auto"
                  src="https://i.imgur.com/PbiW6TE.png"
                  alt="Upgraded Logo"
                />    
            </a>
            <div class="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
                <h2 class="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                    Change Password
                </h2>
                <form
                  className="mt-4 space-y-4 lg:mt-5 md:space-y-5" 
                  action="#"
                  onSubmit={!successfulCreation ? create : reset}
                >
                  {!successfulCreation && (
                    <>
                      <label htmlFor='email' className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Please provide your email address</label>
                      <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        placeholder="name@company.com" 
                        required=""
                        type='email'
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />

                      <button type="submit" class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Send Password Reset Code</button>
                      {error && <p className='text-center text-red-800 text-sm font-medium'>Please try again. {error}</p>}
                    </>
                  )}

                  {successfulCreation && (
                    <>
                      <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Enter your new Password
                      </label>

                      <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        id="password"
                        name="password"
                        type="password"
                        required
                        value={password}
                        onChange={onPasswordChange}
                        onFocus={() => setIsFocused(true)}
                      />
                      
                      <label htmlFor="confirmPassword" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Confirm Password
                      </label>
                      <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                        required
                        autoComplete="new-password"
                        value={confirmPassword}
                        onChange={onConfirmPasswordChange}
                        onFocus={() => setIsFocused(true)}
                      />

                      {isFocused && <div className="grid grid-rows-3 mt-1 px-1">
                        {Object.keys(passwordRequirements).map((key) => (
                          <div key={key} className="inline-flex mt-1 space-x-1">
                            <div className="flex text-center items-center mb-2 justify-center">
                              {passwordRequirements[key].fulfilled ? (
                                <CheckCircleIcon className="text-green-400 h-4 w-4" aria-hidden />
                              ) : (
                                <XCircleIcon className="text-gray-400 h-4 w-4" aria-hidden />
                              )}
                            </div>
                            <p className="mt-1 text-xs text-left">{passwordRequirements[key].message}</p>
                          </div>
                        ))}
                      </div>}

                      <label htmlFor='password' className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Enter the reset code that was sent to your email</label>
                      <input
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type='text'
                        value={code}
                        onChange={e => setCode(e.target.value)}
                      />

                      <button type="submit" class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Reset</button>
                      {error && <p className='text-center text-red-800 text-sm font-medium'>Please try again. {error}</p>}
                    </>
                  )}
              </form>
            </div>
        </div>
      </section>
  );
};

export default ForgotPasswordPage;
