import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckCircleIcon, PencilSquareIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { setContents, setShowNotif, checkDateFormat, getDateColor, currencyFormat, classNames } from "./helpers";
import { countryCodes } from "./helperData";
import Notification from "./Notification";
import EditGrantFeatureCard from "./EditGrantFeatureCard";
import { Checkbox, Select } from "@mui/material";
import Cookies from "js-cookie";

const EditGrantModal = ({ open, setOpen, grant, setGrant, company, industries }) => {
	const baseId = process.env.REACT_APP_AIR_BASEID;
	const grantTableId = process.env.REACT_APP_AIR_GRANTS;
	const apiKey = process.env.REACT_APP_AIR_KEY;

	const [grantVerified, setGrantVerified] = useState(Boolean(grant?.fields?.Verified));
	const [QuestionsComplete, setQuestionsComplete] = useState(Boolean(grant?.fields?.QuestionsComplete));
	const [SampleAnswersComplete, setSampleAnswersComplete] = useState(Boolean(grant?.fields?.SampleAnswersComplete));
	const [editingName, setEditingName] = useState(false);

	const cancelButtonRef = useRef(null);

	/* ----------------------------------------------

				  Notification State

  ------------------------------------------------- */

	const [updatedNotif, setUpdatedNotif] = useState({
		showNotif: false,
		contents: { type: "", heading: "", message: "" },
	});

	/* ----------------------------------------------

		 updateGrantFeature - Airtable Function

  ------------------------------------------------- */
	// updateGrantFeature is a sort of generic function which will update whichever field of a certain grant with a new value.
	//   feature : string --> feature should be exactly one of the fields of upgrade objects ("Technologies", "Amount", "Deadline", etc.)
	//   recordId : string --> The recordId record in our database
	//   newValue : string | number | boolean -> the new value for the field
	const updateGrantFeature = (feature, recordId, newValue) => {
		if (!grant) {
			return <div>Loading...</div>;
		}


		if (
			[
				"Eligible_Industry",
				"Eligible_Region",
				"Amount",
				"Deadline",
				"Eligible_Employees",
				"Eligible_Founded",
				"Eligible_Profitable",
				"GrantUrl",
				"Description_2",
				"Eligibility_Criteria",
				"Eligible_Activities",
				"Eligible_Applicants",
				"Eligible_Revenue",
				"Description",
				"Verified",
				"Verified By",
				"Date Verified",
				"Grant_Goal",
				"GrantOrLoan",
				"OpenOrClosed",
				"Name",
				"TimeEstimateToComplete",
				"AdjustedAmount",
				"pSuccess",
				"Successful_Grant_Application",
				"Empty_Grant_Application",
				"Internal_Process",
				"Contact_Info",
				"QuestionsComplete",
				"SampleAnswersComplete"
			].includes(feature) === false
		) {
			console.log(feature + " was not an acceptable and/or updateable feature of this grant.");
			return;
		}

		fetch("https://api.airtable.com/v0/" + baseId + "/" + grantTableId + "/" + recordId, {
			method: "PATCH",
			headers: {
				Authorization: `Bearer ${apiKey}`,
				"Content-Type": "application/json",
			},

			body: JSON.stringify({
				fields: {
					[feature]: newValue,
				},
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				console.log(data);

				if (Object.keys(data).includes("error")) {
					setContents(
						{
							type: "error",
							heading: typeof data.error === "object" && Object.keys(data.error).includes("type") ? data.error.type : "Error",
							message: typeof data.error === "object" && Object.keys(data.error).includes("message") ? data.error.message : data.error,
						},
						setUpdatedNotif
					);
				} else {
					setContents(
						{
							type: "success",
							heading: `Saved to Airtable`,
							message: `${grant?.fields?.Name.trim()}'s ${feature} field has been changed from "${grant?.fields[feature]
								}" to "${newValue}"`,
						},
						setUpdatedNotif
					);
				}

				setShowNotif(true, setUpdatedNotif);
				setGrant((prev) => ({ id: prev.id, recordId: prev.recordId, fields: { ...prev.fields, [feature]: newValue } }));
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	return (
		<>
			<Transition.Root show={open} as={Fragment}>
				<Dialog as="div" className="relative z-40" initialFocus={cancelButtonRef} onClose={setOpen}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
									<div>
										<div className="mt-3 text-center sm:mt-5">
											{!editingName ? (
												<span className="inline-flex">
													<Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
														Edit {grant.fields.Name}
													</Dialog.Title>
													<button onClick={() => setEditingName((prev) => !prev)}>
														<PencilSquareIcon className="h-6 w-6 rounded-md mx-2 hover:text-indigo-700" />
													</button>
												</span>
											) : (
												<span className="inline-flex items-center text-center">
													<h3>Edit</h3>
													<input
														className="rounded-md h-auto p-2 mx-1 mt-1"
														defaultValue={grant.fields.Name}
														onChange={(e) => {
															if (!e?.target?.value || e.target.value === grant?.fields?.Name) return;

															setGrant((prev) => ({ ...prev, fields: { ...prev.fields, Name: e.target.value } }));
															return;
														}}
													/>
													<button
														onClick={() => {
															setEditingName((prev) => !prev);
															updateGrantFeature("Name", grant.recordId, grant.fields.Name);
															return;
														}}
													>
														<CheckCircleIcon className="h-6 w-6 rounded-md mx-2 hover:text-indigo-700" aria-hidden="true" />
													</button>
												</span>
											)}
										</div>
									</div>

									{/* Updating Verified */}
									<div className="my-3">
										<form
											onSubmit={(e) => {
												e.preventDefault();
												updateGrantFeature("Verified", grant?.recordId, grantVerified);

												if (grantVerified === true) {
													updateGrantFeature("Verified By", grant?.recordId, Cookies.get("email"));
													updateGrantFeature(
														"Date Verified",
														grant?.recordId,
														new Date().toLocaleString("en-US", { timeZone: "EST", hour12: true })
													);
												} else {
													updateGrantFeature("Verified By", grant?.recordId, "");
													updateGrantFeature("Date Verified", grant?.recordId, "");
												}
											}}
										>
											<span className="inline-flex text-sm">
												<strong className="inline-flex mt-3">Verified? Does this Grant Look Good?</strong> &nbsp;
												<Checkbox
													id={"Verified" + grant?.recordId}
													onChange={(e) => setGrantVerified(e.target.checked)}
													checked={grantVerified}
													className="inline-flex"
												/>
												<button
													type="submit"
													className="mx-2 p-2 max-w-sm w-auto h-auto text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
												>
													Update Verified
												</button>
											</span>
										</form>
									</div>

									{/* TODO: Updating Questions */}
									<div className="my-3">
										<form
											onSubmit={(e) => {
												e.preventDefault();
												console.log("e.target.checked", e.target)
												console.log("questions complete", QuestionsComplete)

												updateGrantFeature("QuestionsComplete", grant?.recordId, QuestionsComplete);

												if (QuestionsComplete === true) {
													// Do nothing
												} else {
													updateGrantFeature("QuestionsComplete", grant?.recordId, "");
												}
											}}
										>
											<span className="inline-flex text-sm">
												<strong className="inline-flex mt-3">Questions Complete?</strong> &nbsp;
												<Checkbox
													id={"QuestionsComplete" + grant?.recordId}
													checked={QuestionsComplete}
													onChange={(e) => setQuestionsComplete(e.target.checked)}
													className="inline-flex"
												/>
												<button
													type="submit"
													className="mx-2 p-2 max-w-sm w-auto h-auto text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
												>
													Update Questions Complete 
												</button>
											</span>
										</form>
									</div>

									{/* TODO: Updating Sample Answers */}
									<div className="my-3">
										<form
											onSubmit={(e) => {
												e.preventDefault();
												updateGrantFeature("SampleAnswersComplete", grant?.recordId, SampleAnswersComplete);

												// if (grantVerified === true) {
												// 	updateGrantFeature("Verified By", grant?.recordId, Cookies.get("email"));
												// 	updateGrantFeature(
												// 		"Date Verified",
												// 		grant?.recordId,
												// 		new Date().toLocaleString("en-US", { timeZone: "EST", hour12: true })
												// 	);
												// } else {
												// 	updateGrantFeature("Verified By", grant?.recordId, "");
												// 	updateGrantFeature("Date Verified", grant?.recordId, "");
												// }
											}}
										>
											<span className="inline-flex text-sm">
												<strong className="inline-flex mt-3">Sample Answers Complete?</strong> &nbsp;
												<Checkbox
													id={"SampleAnswersComplete" + grant?.recordId}
													checked={SampleAnswersComplete}
													onChange={(e) => setSampleAnswersComplete(e.target.checked)}
													className="inline-flex"
												/>
												<button
													type="submit"
													className="mx-2 p-2 max-w-sm w-auto h-auto text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
												>
													Update Answers Complete
												</button>
											</span>
										</form>
									</div>

									{/* Updating Industries */}

									<div className="my-3">
										<div className="text-sm mt-3">
											<span className="inline-flex">
												<strong>Industries : &nbsp;</strong> <p className="text-xs mt-0.5">(Cmd/Ctrl to Select Multiple)</p>
											</span>
											<ul>
												{grant?.fields?.Eligible_Industry?.split(",").map((grantIndustry) => (
													<li
														key={grant?.id + grantIndustry}
														className={
															company &&
															(grantIndustry === "All" ||
																company?.fields?.Industry?.toLowerCase()
																	.split(",")
																	.filter((companyIndustry) => industries[grantIndustry]?.includes(companyIndustry)).length >= 1
																? "text-red-700"
																: "")
														}
													>
														• {grantIndustry} &nbsp;
													</li>
												))}
											</ul>
											<form
												className="text-sm"
												onSubmit={(e) => {
													e.preventDefault();
													let newIndustries = Array.from(e.target["grantIndustries" + grant?.id].options)
														.filter((option) => option.selected)
														.map((option) => option.value.trim())
														.join(",");

													console.log(newIndustries);
													updateGrantFeature("Eligible_Industry", grant?.recordId, newIndustries);
												}}
											>
												{industries && (
													<div className="sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
														<div className="sm:col-start-1">
															<select
																id={"grantIndustries" + grant?.id}
																multiple
																className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
															>
																{Object.keys(industries).map((industry) => (
																	<option key={industry + grant?.id} value={industry}>
																		{industry}
																	</option>
																))}
															</select>
														</div>
														<div className="sm:col-start-2">
															<button
																type="submit"
																className="mx-2 p-2 max-w-sm w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
															>
																Update Grant Industries
															</button>
														</div>
													</div>
												)}
											</form>
										</div>
									</div>

									{/* Updating Region */}
									<div className="my-3">
										<div className="text-sm">
											<strong>Region</strong> : &nbsp;{" "}
											{
												<p
													className={
														company?.fields?.Address?.toLowerCase()
															?.split(",")
															.map((item) => item.trim().toLowerCase())
															.includes(grant?.fields?.Eligible_Region?.toLowerCase()) ||
															company?.fields?.Country?.split(",")
																.map((code) => countryCodes[code])
																.some((item) => grant?.fields?.Eligible_Region?.toLowerCase().trim()?.split(",").includes(item))
															? "text-red-700"
															: ""
													}
												>
													{grant?.fields?.Eligible_Region}
												</p>
											}
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												let newRegion = e.target["grantRegion" + grant?.id].value
													?.split(",")
													.map((item) => item.trim())
													.join(",");

												updateGrantFeature("Eligible_Region", grant?.recordId, newRegion);
											}}
										>
											<div className="sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
												<div className="sm:col-start-1">
													<input
														defaultValue={grant?.fields?.Eligible_Region?.split(",")
															.map((item) => item.trim())
															.join(", ")}
														id={"grantRegion" + grant?.id}
														className="p-1 rounded-xl border-2 border-gray-700 text-black"
													/>
													{/* Clear (X) Button beside input tag */}
													<button
														className="mt-auto hover:bg-gray-100"
														onClick={(e) => {
															e.preventDefault();
															document.getElementById("grantRegion" + grant?.id).value = "";
														}}
													>
														<XMarkIcon className="h-6 w-auto m-auto" />
													</button>
												</div>
												<div className="sm:col-start-2">
													<button
														type="submit"
														className="mx-2 p-2 max-w-sm w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
													>
														Update Grant Region
													</button>
												</div>
											</div>
										</form>
									</div>

									<div className="my-3">
										<div className="text-sm">
											<strong>Amount</strong> : {currencyFormat(grant?.fields?.AnnualMargin)}
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												let newAmount = parseFloat(e.target["grantAmount" + grant?.id].value.replaceAll("$", "").replaceAll(",", ""));
												console.log(grant.recordId, newAmount);
												if (isNaN(newAmount) || newAmount === undefined) {
													console.log("new value is not a number");
													return;
												}

												updateGrantFeature("Amount", grant?.recordId, newAmount);
											}}
										>
											<div className="sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
												<div className="sm:col-start-1">
													<input
														placeholder="1,000"
														defaultValue={grant?.fields?.AnnualMargin}
														id={"grantAmount" + grant?.id}
														className="p-1 border-2 rounded-xl border-gray-700 text-black"
													/>
													{/* Clear (X) Button beside input tag */}
													<button
														className="mt-auto hover:bg-gray-100"
														onClick={(e) => {
															e.preventDefault();
															document.getElementById("grantAmount" + grant?.id).value = "";
														}}
													>
														<XMarkIcon className="h-6 w-auto m-auto" />
													</button>
												</div>
												<div className="col-start-2">
													<button
														type="submit"
														className="mx-2 p-2 max-w-sm w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
													>
														Update Grant Amount
													</button>
												</div>
											</div>
										</form>
									</div>

									{/* Adjusted Amount */}
									<div className="my-3">
										<div className="text-sm">
											<strong>Adjusted Amount (Internal)</strong> : {currencyFormat(grant?.fields?.AdjustedAmount)}
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												let newAdjustedAmount = parseFloat(e.target["AdjustedAmount" + grant?.id].value.replaceAll("$", "").replaceAll(",", ""));
												console.log(grant.recordId, newAdjustedAmount);
												if (isNaN(newAdjustedAmount) || newAdjustedAmount === undefined) {
													console.log("new value is not a number");
													return;
												}

												updateGrantFeature("AdjustedAmount", grant?.recordId, newAdjustedAmount);
											}}
										>
											<div className="sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
												<div className="sm:col-start-1">
													<input
														placeholder="1,000"
														defaultValue={grant?.fields?.AdjustedAmount}
														id={"AdjustedAmount" + grant?.id}
														className="p-1 border-2 rounded-xl border-gray-700 text-black"
													/>
													{/* Clear (X) Button beside input tag */}
													<button
														className="mt-auto hover:bg-gray-100"
														onClick={(e) => {
															e.preventDefault();
															document.getElementById("AdjustedAmount" + grant?.id).value = "";
														}}
													>
														<XMarkIcon className="h-6 w-auto m-auto" />
													</button>
												</div>
												<div className="col-start-2">
													<button
														type="submit"
														className="mx-2 p-2 max-w-sm w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
													>
														Update Adjusted Amount
													</button>
												</div>
											</div>
										</form>
									</div>

									{/* Probability of Success */}
									<div className="my-3">
										<div className="text-sm">
											<strong>Probability of Success (Internal)</strong> : {grant?.fields?.pSuccess}
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												let newpSuccess = parseFloat(e.target["pSuccess" + grant?.id].value.replaceAll("$", "").replaceAll(",", ""));
												console.log(grant.recordId, newpSuccess);
												if (isNaN(newpSuccess) || newpSuccess === undefined) {
													console.log("new value is not a number");
													return;
												}
												updateGrantFeature("pSuccess", grant?.recordId, newpSuccess);
											}}
										>
											<div className="sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
												<div className="sm:col-start-1">
													<input
														placeholder="0.67"
														defaultValue={grant?.fields?.pSuccess}
														id={"pSuccess" + grant?.id}
														className="p-1 border-2 rounded-xl border-gray-700 text-black"
													/>
													{/* Clear (X) Button beside input tag */}
													<button
														className="mt-auto hover:bg-gray-100"
														onClick={(e) => {
															e.preventDefault();
															document.getElementById("pSuccess" + grant?.id).value = "";
														}}
													>
														<XMarkIcon className="h-6 w-auto m-auto" />
													</button>
												</div>
												<div className="col-start-2">
													<button
														type="submit"
														className="mx-2 p-2 max-w-sm w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
													>
														Update Probability of Success
													</button>
												</div>
											</div>
										</form>
									</div>

									{/* Time Required */}
									<div className="my-3">
										<div className="text-sm">
											<strong>Time Required (Internal)</strong> : {grant?.fields?.TimeEstimateToComplete}
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												let newTimeEstimateToComplete = parseFloat(e.target["TimeEstimateToComplete" + grant?.id].value.replaceAll("$", "").replaceAll(",", ""));
												console.log(grant.recordId, newTimeEstimateToComplete);
												if (isNaN(newTimeEstimateToComplete) || newTimeEstimateToComplete === undefined) {
													console.log("new value is not a number");
													return;
												}

												updateGrantFeature("TimeEstimateToComplete", grant?.recordId, newTimeEstimateToComplete);
											}}
										>
											<div className="sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
												<div className="sm:col-start-1">
													<input
														placeholder="12"
														defaultValue={grant?.fields?.TimeEstimateToComplete}
														id={"TimeEstimateToComplete" + grant?.id}
														className="p-1 border-2 rounded-xl border-gray-700 text-black"
													/>
													{/* Clear (X) Button beside input tag */}
													<button
														className="mt-auto hover:bg-gray-100"
														onClick={(e) => {
															e.preventDefault();
															document.getElementById("TimeEstimateToComplete" + grant?.id).value = "";
														}}
													>
														<XMarkIcon className="h-6 w-auto m-auto" />
													</button>
												</div>
												<div className="col-start-2">
													<button
														type="submit"
														className="mx-2 p-2 max-w-sm w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
													>
														Update Time Required
													</button>
												</div>
											</div>
										</form>
									</div>

									<div className="my-3">
										<div className="text-sm">
											<strong className="text-black">Deadline : </strong>{" "}
											<p className={checkDateFormat(grant?.fields?.Deadline) ? getDateColor(grant?.fields?.Deadline) : "text-black"}>
												{grant?.fields?.Deadline}
											</p>
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												let newDeadline = e.target["grantDeadline" + grant?.id].value;
												if (!checkDateFormat(newDeadline) && newDeadline.trim().toLowerCase() !== "n/a") {
													e.target["grantDeadline" + grant?.id].value = "Incorrect date format";
													return;
												}

												updateGrantFeature("Deadline", grant?.recordId, newDeadline);
											}}
										>
											<div className="sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
												<div className="sm:col-start-1">
													<input
														placeholder=""
														defaultValue={grant?.fields?.Deadline}
														id={"grantDeadline" + grant?.id}
														className="p-1 border-2 rounded-xl border-gray-700 text-black"
													/>
													{/* Clear (X) Button beside input tag */}
													<button
														className="mt-auto hover:bg-gray-100"
														onClick={(e) => {
															e.preventDefault();
															document.getElementById("grantDeadline" + grant?.id).value = "";
														}}
													>
														<XMarkIcon className="h-6 w-auto m-auto" />
													</button>
												</div>
												<div className="col-start-2">
													<button
														type="submit"
														className="mx-2 p-2 max-w-sm w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
													>
														Update Grant Deadline
													</button>
												</div>
											</div>
										</form>
									</div>

									<div className="my-3">
										<div className="text-sm">
											<strong>Employees</strong> : &nbsp; <br />
											<span className="text-sm inline-flex">
												{company && <p>Requires that Company has at least &nbsp;</p>}
												<p
													className={
														company?.fields?.Employees &&
															grant?.fields?.Eligible_Employees &&
															company.fields.Employees >= grant.fields.Eligible_Employees
															? "text-red-700"
															: "text-black"
													}
												>
													{grant?.fields?.Eligible_Employees} &nbsp;
												</p>
												<p> Employees </p>
											</span>
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												const newEmployees = e.target["grantEmployees" + grant?.id].value;

												updateGrantFeature("Eligible_Employees", grant?.recordId, newEmployees);
											}}
										>
											<div className="sm:grid sm:grid-flow-row-dense sm:grid-col-2 sm:gap-3">
												<div className="sm:col-start-1">
													<input
														defaultValue={grant?.fields?.Eligible_Employees}
														placeholder=""
														type="number"
														id={"grantEmployees" + grant?.id}
														className="p-1 rounded-xl border-2 border-gray-700 text-black"
													/>
													{/* Clear (X) Button beside input tag */}
													<button
														className="mt-auto hover:bg-gray-100"
														onClick={(e) => {
															e.preventDefault();
															document.getElementById("grantEmployees" + grant?.id).value = "";
														}}
													>
														<XMarkIcon className="h-6 w-auto m-auto" />
													</button>
												</div>

												<div className="sm:col-start-2">
													<button
														type="submit"
														className="mx-2 p-2 max-w-sm w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
													>
														Update Grant Employees
													</button>
												</div>
											</div>
										</form>
									</div>

									{/* Grant Year Founded */}
									<div className="my-3">
										<div className="text-sm">
											<strong>Year Founded</strong> : &nbsp; <br />
											<div className="inline-flex">
												{company && <p>Requires that Company founded in at least &nbsp;</p>}
												<p
													className={
														company?.fields?.Founded &&
															grant?.fields?.Eligible_Founded &&
															company.fields.Founded >= grant.fields.Eligible_Founded
															? "text-red-700"
															: "text-black"
													}
												>
													{grant?.fields?.Eligible_Founded} &nbsp;
												</p>
											</div>
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												const newYear = e.target["grantFoundedYear" + grant?.id].value;

												updateGrantFeature("Eligible_Founded", grant?.recordId, newYear);
											}}
										>
											<div className="sm:grid sm:grid-flow-row-dense sm:grid-col-2 sm:gap-3">
												<div className="sm:col-start-1">
													<input
														defaultValue={grant?.fields?.Eligible_Founded}
														placeholder=""
														type="number"
														id={"grantFoundedYear" + grant?.id}
														className="p-1  rounded-xl border-2 border-gray-700 text-black"
													/>
													{/* Clear (X) Button beside input tag */}
													<button
														className="mt-auto hover:bg-gray-100"
														onClick={(e) => {
															e.preventDefault();
															document.getElementById("grantFoundedYear" + grant?.id).value = "";
														}}
													>
														<XMarkIcon className="h-6 w-auto m-auto" />
													</button>
												</div>

												<div className="sm:col-start-2">
													<button
														type="submit"
														className="mx-2 p-2 max-w-sm w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
													>
														Update Grant Year Founded
													</button>
												</div>
											</div>
										</form>
									</div>

									{/* Grant Eligible Revenue */}
									<div className="my-3">
										<div className="text-sm">
											<strong>Eligible Revenue</strong> : &nbsp; <br />
											<div className="inline-flex">
												{Boolean(company) && Boolean(company?.fields) && <p>Requires that Company's Revenue is at least: &nbsp;</p>}
												<p
													className={
														company?.fields?.AnnualRevenue &&
															grant?.fields?.Eligible_Revenue &&
															parseFloat(company.fields.AnnualRevenue) >= parseFloat(grant.fields.Eligible_Revenue)
															? "text-red-700"
															: "text-black"
													}
												>
													{grant?.fields?.Eligible_Revenue} &nbsp;
												</p>
											</div>
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												const newEligibleRevenue = parseFloat(e.target["grantEligibleRevenue" + grant?.id].value);

												updateGrantFeature("Eligible_Revenue", grant?.recordId, newEligibleRevenue);
											}}
										>
											<div className="sm:grid sm:grid-flow-row-dense sm:grid-col-2 sm:gap-3">
												<div className="sm:col-start-1">
													<input
														defaultValue={grant?.fields?.Eligible_Revenue ? grant?.fields?.Eligible_Revenue : ""}
														placeholder=""
														type="number"
														id={"grantEligibleRevenue" + grant?.id}
														className="p-1 rounded-xl border-2 border-gray-700 text-black"
													/>
													{/* Clear (X) Button beside input tag */}
													<button
														className="mt-auto hover:bg-gray-100"
														onClick={(e) => {
															e.preventDefault();
															document.getElementById("grantEligibleRevenue" + grant?.id).value = "";
														}}
													>
														<XMarkIcon className="h-6 w-auto m-auto" />
													</button>
												</div>

												<div className="sm:col-start-2">
													<button
														type="submit"
														className="mx-2 p-2 max-w-sm w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
													>
														Update Grant Eligible Revenue
													</button>
												</div>
											</div>
										</form>
									</div>

									{/* Grant Eligible Profitable */}
									<div className="my-3">
										<div className="text-sm">
											<strong>Requires a Profitable Company?</strong> : &nbsp; <br />
											<div className="inline-flex">
												<p>{grant?.fields?.Eligible_Profitable}</p>
											</div>
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												let newValue = e.target["grantProfitable" + grant?.id].value;
												if (["yes", "no"].includes(newValue.toLowerCase().trim()) === false) {
													e.target["grantProfitable" + grant?.id].value = "either 'Yes' or 'No'";
													return;
												} else if (newValue.toLowerCase().trim() === "yes") {
													newValue = "Yes";
												} else if (newValue.toLowerCase().trim() === "no") {
													newValue = "No";
												}

												updateGrantFeature("Eligible_Profitable", grant?.recordId, newValue);
											}}
										>
											<div className="sm:grid sm:grid-flow-row-dense sm:grid-col-2 sm:gap-3">
												<div className="sm:col-start-1">
													<input
														defaultValue={grant?.fields?.Eligible_Profitable}
														placeholder=""
														type="text"
														id={"grantProfitable" + grant?.id}
														className="p-1 rounded-xl border-2 border-gray-700 text-black"
													/>
													{/* Clear (X) Button beside input tag */}
													<button
														className="mt-auto hover:bg-gray-100"
														onClick={(e) => {
															e.preventDefault();
															document.getElementById("grantProfitable" + grant?.id).value = "";
														}}
													>
														<XMarkIcon className="h-6 w-auto m-auto" />
													</button>
												</div>

												<div className="sm:col-start-2">
													<button
														type="submit"
														className="mx-2 p-2 max-w-md w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
													>
														Update Grant Profitable
													</button>
												</div>
											</div>
										</form>
									</div>

									{/* GrantOrLoan */}
									<div className="my-3">
										<div className="text-sm">
											<strong>Grant or Loan?</strong> : &nbsp; <br />
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												let newValue = e.target["GrantOrLoan" + grant?.id].value;
												if (["grant", "loan", "other", "both"].includes(newValue.toLowerCase().trim()) === false) {
													e.target["GrantOrLoan" + grant?.id].value = "either 'Grant', 'Loan', 'Other', or 'Both'";
													return;
												} else if (newValue.toLowerCase().trim() === "grant") {
													newValue = "Grant";
												} else if (newValue.toLowerCase().trim() === "loan") {
													newValue = "Loan";
												} else if (newValue.toLowerCase().trim() === "other") {
													newValue = "Other";
												} else if (newValue.toLowerCase().trim() === "both") {
													newValue = "Both";
												}

												updateGrantFeature("GrantOrLoan", grant?.recordId, newValue);
											}}
										>
											<div className="sm:grid sm:grid-flow-row-dense sm:grid-col-2 sm:gap-3">
												<div className="sm:col-start-1">
													<input
														defaultValue={grant?.fields?.GrantOrLoan}
														placeholder=""
														type="text"
														id={"GrantOrLoan" + grant?.id}
														className="p-1 rounded-xl border-2 border-gray-700 text-black"
													/>
													{/* Clear (X) Button beside input tag */}
													<button
														className="mt-auto hover:bg-gray-100"
														onClick={(e) => {
															e.preventDefault();
															document.getElementById("GrantOrLoan" + grant?.id).value = "";
														}}
													>
														<XMarkIcon className="h-6 w-auto m-auto" />
													</button>
												</div>

												<div className="sm:col-start-2">
													<button
														type="submit"
														className="mx-2 p-2 max-w-md w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
													>
														Update GrantOrLoan
													</button>
												</div>
											</div>
										</form>
									</div>

									{/* Update OpenOrClosed */}
									<div className="my-3">
										<div className="text-sm">
											<strong>Open or Closed?</strong> : &nbsp; <br />
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												let newValue = e.target["OpenOrClosed" + grant?.id].value;
												if (["open", "closed"].includes(newValue.toLowerCase().trim()) === false) {
													e.target["OpenOrClosed" + grant?.id].value = "either 'Open' or 'Closed'";
													return;
												} else if (newValue.toLowerCase().trim() === "open") {
													newValue = "Open";
												} else if (newValue.toLowerCase().trim() === "closed") {
													newValue = "Closed";
												}

												updateGrantFeature("OpenOrClosed", grant?.recordId, newValue);
											}}
										>
											<div className="sm:grid sm:grid-flow-row-dense sm:grid-col-2 sm:gap-3">
												<div className="sm:col-start-1">
													<input
														defaultValue={grant?.fields?.OpenOrClosed}
														placeholder=""
														type="text"
														id={"OpenOrClosed" + grant?.id}
														className="p-1 rounded-xl border-2 border-gray-700 text-black"
													/>
													{/* Clear (X) Button beside input tag */}
													<button
														className="mt-auto hover:bg-gray-100"
														onClick={(e) => {
															e.preventDefault();
															document.getElementById("OpenOrClosed" + grant?.id).value = "";
														}}
													>
														<XMarkIcon className="h-6 w-auto m-auto" />
													</button>
												</div>

												<div className="sm:col-start-2">
													<button
														type="submit"
														className="mx-2 p-2 max-w-md w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
													>
														Update OpenOrClosed
													</button>
												</div>
											</div>
										</form>
									</div>

									{/* Grant URL */}
									<div className="my-3">
										<div className="text-sm">
											<strong>Grant Link</strong> : &nbsp; <br />
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												let newValue = e.target["grantURL" + grant?.id].value;

												updateGrantFeature("GrantUrl", grant?.recordId, newValue);
											}}
										>
											<div className="sm:grid sm:grid-flow-row-dense sm:grid-col-2 sm:gap-3">
												<div className="sm:col-start-1">
													<input
														defaultValue={grant?.fields?.GrantUrl}
														placeholder=""
														type="text"
														id={"grantURL" + grant?.id}
														className="p-1 rounded-xl border-2 border-gray-700 text-black"
													/>
													{/* Clear (X) Button beside input tag */}
													<button
														className="mt-auto hover:bg-gray-100"
														onClick={(e) => {
															e.preventDefault();
															document.getElementById("grantURL" + grant?.id).value = "";
														}}
													>
														<XMarkIcon className="h-6 w-auto m-auto" />
													</button>
												</div>

												<div className="sm:col-start-2">
													<button
														type="submit"
														className="mx-2 p-2 max-w-md w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
													>
														Update Grant Link
													</button>
												</div>
											</div>
										</form>
									</div>

									{/* Grant Description */}
									<EditGrantFeatureCard
										grant={grant}
										feature={"Description"}
										updateGrantFeature={updateGrantFeature}
										defaultText={grant?.fields?.Description}
									/>

									{/* Grant Summary / Description_2 */}
									<div className="my-3">
										<div className="text-sm">
											<strong>Description_2 (Summary)</strong> : &nbsp; <br />
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												let newValue = e.target["Description_2" + grant?.id].value;

												updateGrantFeature("Description_2", grant?.recordId, newValue);
											}}
										>
											<div className="sm:grid sm:grid-flow-row-dense sm:grid-row-2 sm:gap-3">
												<div className="sm:row-start-1">
													<textarea
														rows="6"
														cols="50"
														defaultValue={grant?.fields?.Description_2}
														type="text"
														id={"Description_2" + grant?.id}
														className="p-1 rounded-xl border-2 border-gray-700 text-black"
													/>
												</div>

												<div className="inline-flex sm:row-start-2">
													{/* Clear (X) Button beside input tag */}
													<button
														className="mt-auto hover:bg-gray-100"
														onClick={(e) => {
															e.preventDefault();
															document.getElementById("Description_2" + grant?.id).value = "";
														}}
													>
														<XMarkIcon className="h-6 w-auto m-auto" />
													</button>
													<button
														type="submit"
														className="mx-2 p-2 max-w-md w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
													>
														Update Grant Summary
													</button>
												</div>
											</div>
										</form>
									</div>

									{/* Grant Goal */}
									<EditGrantFeatureCard
										grant={grant}
										feature={"Grant_Goal"}
										updateGrantFeature={updateGrantFeature}
										defaultText={grant.fields.Grant_Goal}
									/>

									{/* Update Grant Eligibility Critera */}
									<div className="my-3">
										<div className="text-sm">
											<strong>Eligibility Criteria (Required Information)</strong> : &nbsp; <br />
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												let newValue = e.target["grantCriteria" + grant?.id].value;

												updateGrantFeature("Eligibility_Criteria", grant?.recordId, newValue);
											}}
										>
											<div className="sm:grid sm:grid-flow-row-dense sm:grid-row-2 sm:gap-3">
												<div className="sm:row-start-1">
													<textarea
														rows="6"
														cols="50"
														defaultValue={grant?.fields?.Eligibility_Criteria}
														type="text"
														id={"grantCriteria" + grant?.id}
														className="p-1 rounded-xl border-2 border-gray-700 text-black"
													/>
												</div>

												<div className="inline-flex sm:row-start-2">
													{/* Clear (X) Button beside input tag */}
													<button
														className="mt-auto hover:bg-gray-100"
														onClick={(e) => {
															e.preventDefault();
															document.getElementById("grantCriteria" + grant?.id).value = "";
														}}
													>
														<XMarkIcon className="h-6 w-auto m-auto" />
													</button>
													<button
														type="submit"
														className="mx-2 p-2 max-w-md w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
													>
														Update Grant Criteria
													</button>
												</div>
											</div>
										</form>
									</div>

									{/* Update Grant Activities */}
									<div className="my-3">
										<div className="text-sm">
											<strong>Eligible Activities</strong> : &nbsp; <br />
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												let newValue = e.target["grantActivities" + grant?.id].value;

												updateGrantFeature("Eligible_Activities", grant?.recordId, newValue);
											}}
										>
											<div className="sm:grid sm:grid-flow-row-dense sm:grid-row-2 sm:gap-3">
												<div className="sm:row-start-1">
													<textarea
														rows="6"
														cols="50"
														defaultValue={grant?.fields?.Eligible_Activities}
														type="text"
														id={"grantActivities" + grant?.id}
														className="p-1 rounded-xl border-2 border-gray-700 text-black"
													/>
												</div>

												<div className="inline-flex sm:row-start-2">
													{/* Clear (X) Button beside input tag */}
													<button
														className="mt-auto hover:bg-gray-100"
														onClick={(e) => {
															e.preventDefault();
															document.getElementById("grantActivities" + grant?.id).value = "";
														}}
													>
														<XMarkIcon className="h-6 w-auto m-auto" />
													</button>
													<button
														type="submit"
														className="mx-2 p-2 max-w-md w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
													>
														Update Grant Activities
													</button>
												</div>
											</div>
										</form>
									</div>

									{/* Update Grant Applicants */}
									<div className="my-3">
										<div className="text-sm">
											<strong>Eligible Applicants</strong> : &nbsp; <br />
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												let newValue = e.target["grantApplicants" + grant?.id].value;

												updateGrantFeature("Eligible_Applicants", grant?.recordId, newValue);
											}}
										>
											<div className="sm:grid sm:grid-flow-row-dense sm:grid-row-2 sm:gap-3">
												<div className="sm:row-start-1">
													<textarea
														rows="6"
														cols="50"
														defaultValue={grant?.fields?.Eligible_Applicants}
														type="text"
														id={"grantApplicants" + grant?.id}
														className="p-1 rounded-xl border-2 border-gray-700 text-black"
													/>
												</div>

												<div className="inline-flex sm:row-start-2">
													{/* Clear (X) Button beside input tag */}
													<button
														className="mt-auto hover:bg-gray-100"
														onClick={(e) => {
															e.preventDefault();
															document.getElementById("grantApplicants" + grant?.id).value = "";
														}}
													>
														<XMarkIcon className="h-6 w-auto m-auto" />
													</button>
													<button
														type="submit"
														className="mx-2 p-2 max-w-md w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
													>
														Update Grant Applicants
													</button>
												</div>
											</div>
										</form>
									</div>

									{/* TODO: WIP - THURSDAY MODS */}
									{/* Empty Grant Link */}
									<div className="my-3">
										<div className="text-sm">
											<strong>Empty Grant Link</strong> : &nbsp; <br />
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												let newValue = e.target["emptyGrantApplication" + grant?.id].value;
												updateGrantFeature("Empty_Grant_Application", grant?.recordId, newValue);
											}}
										>
											<div className="sm:grid sm:grid-flow-row-dense sm:grid-row-2 sm:gap-3">
												<div className="sm:row-start-1">
													<textarea
														rows="1"
														cols="50"
														defaultValue={grant?.fields?.Empty_Grant_Application}
														// defaultValue={grant?.fields?.Eligible_Applicants}
														type="text"
														id={"emptyGrantApplication" + grant?.id}
														className="p-1 rounded-xl border-2 border-gray-700 text-black"
													/>
												</div>
												<div className="inline-flex sm:row-start-2">
													{/* Clear (X) Button beside input tag */}
													<button
														className="mt-auto hover:bg-gray-100"
														onClick={(e) => {
															e.preventDefault();
															document.getElementById("emptyGrantApplication" + grant?.id).value = "";
														}}
													>
														<XMarkIcon className="h-6 w-auto m-auto" />
													</button>
													<button
														type="submit"
														className="mx-2 p-2 max-w-md w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
													>
														Update Empty Grant Application
													</button>
												</div>
											</div>
										</form>
									</div>

									{/* Successful Grants Links */}
									<div className="my-3">
										<div className="text-sm">
											<strong>Successful Grant Applications</strong> : &nbsp; <br />
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												let newValue = e.target["successfulGrantApplication" + grant?.id].value;

												updateGrantFeature("Successful_Grant_Application", grant?.recordId, newValue);
											}}
										>
											<div className="sm:grid sm:grid-flow-row-dense sm:grid-row-2 sm:gap-3">
												<div className="sm:row-start-1">
													<textarea
														rows="6"
														cols="50"
														defaultValue={grant?.fields?.Successful_Grant_Application}
														type="text"
														id={"successfulGrantApplication" + grant?.id}
														className="p-1 rounded-xl border-2 border-gray-700 text-black"
													/>
												</div>

												<div className="inline-flex sm:row-start-2">
													{/* Clear (X) Button beside input tag */}
													<button
														className="mt-auto hover:bg-gray-100"
														onClick={(e) => {
															e.preventDefault();
															document.getElementById("successfulGrantApplication" + grant?.id).value = "";
														}}
													>
														<XMarkIcon className="h-6 w-auto m-auto" />
													</button>
													<button
														type="submit"
														className="mx-2 p-2 max-w-md w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
													>
														Update Successful Grant Applications
													</button>
												</div>
											</div>
										</form>
									</div>

									{/* Internal Process (ChatGPT Prompt link, Special Instructions, Learnings, Gotchas) */}
									<div className="my-3">
										<div className="text-sm">
											<strong>Internal Process (ChatGPT Prompt link, Special Instructions, Learnings, Gotchas) </strong> : &nbsp; <br />
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												let newValue = e.target["internalProcess" + grant?.id].value;

												updateGrantFeature("Internal_Process", grant?.recordId, newValue);
											}}
										>
											<div className="sm:grid sm:grid-flow-row-dense sm:grid-row-2 sm:gap-3">
												<div className="sm:row-start-1">
													<textarea
														rows="6"
														cols="50"
														defaultValue={grant?.fields?.Internal_Process}
														type="text"
														id={"internalProcess" + grant?.id}
														className="p-1 rounded-xl border-2 border-gray-700 text-black"
													/>
												</div>

												<div className="inline-flex sm:row-start-2">
													{/* Clear (X) Button beside input tag */}
													<button
														className="mt-auto hover:bg-gray-100"
														onClick={(e) => {
															e.preventDefault();
															document.getElementById("internalProcess" + grant?.id).value = "";
														}}
													>
														<XMarkIcon className="h-6 w-auto m-auto" />
													</button>
													<button
														type="submit"
														className="mx-2 p-2 max-w-md w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
													>
														Update Grant Criteria
													</button>
												</div>
											</div>
										</form>
									</div>

									{/* Contact Info */}
									<div className="my-3">
										<div className="text-sm">
											<strong>Contact Info</strong> : &nbsp; <br />
										</div>
										<form
											onSubmit={(e) => {
												e.preventDefault();
												let newValue = e.target["contactInfo" + grant?.id].value;

												updateGrantFeature("Contact_Info", grant?.recordId, newValue);
											}}
										>
											<div className="sm:grid sm:grid-flow-row-dense sm:grid-row-2 sm:gap-3">
												<div className="sm:row-start-1">
													<textarea
														rows="6"
														cols="50"
														defaultValue={grant?.fields?.Contact_Info}
														type="text"
														id={"contactInfo" + grant?.id}
														className="p-1 rounded-xl border-2 border-gray-700 text-black"
													/>
												</div>

												<div className="inline-flex sm:row-start-2">
													{/* Clear (X) Button beside input tag */}
													<button
														className="mt-auto hover:bg-gray-100"
														onClick={(e) => {
															e.preventDefault();
															document.getElementById("contactInfo" + grant?.id).value = "";
														}}
													>
														<XMarkIcon className="h-6 w-auto m-auto" />
													</button>
													<button
														type="submit"
														className="mx-2 p-2 max-w-md w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
													>
														Update Grant Contact Info
													</button>
												</div>
											</div>
										</form>
									</div>

									<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 sm:gap-3">
										<button
											type="button"
											className="mt-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2 sm:mt-0"
											onClick={() => setOpen(false)}
											ref={cancelButtonRef}
										>
											Done
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
			<Notification
				showNotif={updatedNotif.showNotif}
				setShowNotif={(val) => setShowNotif(val, setUpdatedNotif)}
				contents={updatedNotif.contents}
			/>
		</>
	);
};

export default EditGrantModal;
