import React, { createContext, useState, useEffect } from 'react';
import Airtable from 'airtable';

const AppContext = createContext();

const baseId = process.env.REACT_APP_AIR_BASEID;
const apiKey = process.env.REACT_APP_AIR_KEY;
const base = new Airtable({ apiKey: apiKey }).base(baseId);

function hasLessThanNWords(text, wordLimit) {
  if (!text) {
    return false;
  }
  // Split the text by spaces to count the words
  const words = text.trim().split(/\s+/);

  // Check if the number of words is less than the specified limit
  return words.length < wordLimit;
}

const AppProvider = ({ children }) => {
  const [company, setCompany] = useState();
  const [companies, setCompanies] = useState([]);
  const [offset, setOffset] = useState();
  const [showAmount, setShowAmount] = useState(0);

  // clerk
  const [clerkUser, setClerkUser] = useState(null);

  // airtable record
  const [userRecord, setUserRecord] = useState();

  const [domainStatus, setDomainStatus] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [grants, setGrants] = useState([]);
  const [grantLoading, setGrantLoading] = useState(true);
  const [JITWorking, setJITWorking] = useState(true);
  const [unknownDomain, setUnknownDomain] = useState(false);
  const [matchedDiscounts, setMatchedDiscounts] = useState([]);
  const [totalTechSavings, setTotalTechSavings] = useState(0);
  const [eligibleGrants, setEligibleGrants] = useState([]);
  const [grantsSum, setGrantsSum] = useState(0);

  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [pastAnswersContext, setPastAnswersContext] = useState([]);

  const [showTechPipelineInfo, setShowTechPipelineInfo] = useState(false);
  let [techScraperStatus, setTechScraperStatus] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch questions
        const questionsRecords = await base("questions")
          .select({
            view: "Grid view",
          })
          .all();
  
        // Fetch answers
        const answersRecords = await base("answers")
          .select({
            view: "Grid view",
          })
          .all();

        // fetch companies
        const companiesRecords = await base("companies")
          .select({
            view: "Grid view",
          })
          .all();

        // // fetch grants
        // const grantsRecords = await base("grants")
        //   .select({
        //     view: "Grid view",
        //   })
        //   .all();
  
        // Set state with fetched data
        setQuestions(questionsRecords);
        setAnswers(answersRecords);
        setCompanies(companiesRecords);
        // setGrants(grantsRecords);
        // console.log('setting states', companiesRecords, grantsRecords);
      } catch (err) {
        console.error("Error occurred in fetching data: ", err);
      }
    };
  
    fetchData();
  }, []); // Empty dependency array ensures this runs once on mount

  useEffect(() => {
    if (answers.length === 0 || questions.length === 0 || !company) return;
    let questionAndAnswers = [];

    const answersByCompany = answers
      .filter((answer) => answer.fields.CompanyID === company.id)
      .filter((answer) => hasLessThanNWords(answer.fields.AnswerText, 50));

    answersByCompany.forEach(answer => {
        // Find the corresponding question based on QuestionID
        const matchingQuestion = questions.find(question => question.fields.QuestionID === answer.fields.QuestionID);
        
        if (matchingQuestion) {
            questionAndAnswers.push({
                question: matchingQuestion.fields.QuestionText,
                answer: answer.fields.AnswerText
            });
        }
    });

    // console.log("CONTEXT:", JSON.stringify(questionAndAnswers));
    setPastAnswersContext(questionAndAnswers);

}, [answers, company, questions]);

  return (
    <AppContext.Provider
      value={{
        company,
        setCompany,
        companies,
        setCompanies,
        offset,
        setOffset,
        showAmount,
        setShowAmount,
        clerkUser,
        setClerkUser,
        userRecord,
        setUserRecord,
        domainStatus,
        setDomainStatus,
        open,
        setOpen,
        loading,
        setLoading,
        grants,
        setGrants,
        grantLoading,
        setGrantLoading,
        JITWorking,
        setJITWorking,
        unknownDomain,
        setUnknownDomain,
        matchedDiscounts,
        setMatchedDiscounts,
        totalTechSavings,
        setTotalTechSavings,
        eligibleGrants,
        setEligibleGrants,
        grantsSum,
        setGrantsSum,
        questions,
        setQuestions,
        answers,
        setAnswers,
        pastAnswersContext,
        setPastAnswersContext,
        showTechPipelineInfo,
        setShowTechPipelineInfo,
        techScraperStatus,
        setTechScraperStatus,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
