import React, { useState } from 'react';

const Tooltip = ({ text, children }) => {
  const [show, setShow] = useState(false);

  const toggleTooltip = () => {
    setShow(!show);
  };

  return (
    <div className="relative flex items-center" onClick={toggleTooltip}>
      {children}
      {show && (
        <div className="absolute bottom-full mb-2 w-max p-2 text-sm text-white bg-gray-800 rounded-lg shadow-lg">
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
