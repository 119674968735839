export const nextButtonStyle =
  "relative bottom-0 left-[150px] md:left-[460px] text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 disabled:bg-gray-200 disabled:text-gray-500";
export const prevButtonStyle =
  "relative bottom-0 left-[0px] text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800";
export const questionStyle =
  "mt-2 mb-5 text-xl font-bold text-blue-900 flex justify-center";
export const textAnswerStyle =
  "w-half outline-none border border-gray-400 px-2 py-1 rounded-lg focus:border-blue-600";
export const textDivStyle = "my-2 flex justify-center";
// export const selectUlStyle = "grid w-full px-20 gap-6 md:grid-cols-4";
export const selectUlStyle = "flex flex-wrap justify-center list-none p-0";
export const selecliStyle = "flex-[0_0_25%] max-w-[50%] text-center m-2.5";
export const selectLabelStyle =
  "block items-center justify-between p-3 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700";
export const selectDiv1Style = "block ";
export const selectDiv2Style = "w-full text-lg font-semibold";
export const textareaStyle =
  "block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500";
export const commentStyle =
  "mt-10 mb-5 text-lg font-normal text-gray-900 dark:text-white flex justify-center";

/// class={`${questionStyle}`}    for the actual question h1 and label
/// class={`${commentStyle}`}     for comments below the question in a h3
/// class={`${textDivStyle}`}     for the div around text answers
/// class={`${textAnswerStyle}`}  for the input field for text answers
/// class={`${textareaStyle}`}    for textarea styles
/// class={`${selectUlStyle}`}    for the ul around the selectable answers
/// class={`${selectLabelStyle}`} for the lable on the checkbox for selectable answers
/// class={`${selectDiv1Style}`}   for the div immediately in the label for selectable answers
/// class={`${selectDiv2Style}`}    for the div nested within the div in the label for selectable answers

/// class={`${prevButtonStyle}`}    for the prev button
/// class={`${nextButtonStyle}`}    for the next and submit button
