import React, { useEffect, useState } from 'react';
import { CheckIcon, PencilIcon } from "@heroicons/react/24/outline";
import { TrashIcon, BoltIcon, CheckBadgeIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import Skeleton from 'react-loading-skeleton';
import Select from 'react-select'
import Airtable from 'airtable';
import AutomationModal from './AutomationModal'
import { BeatLoader } from 'react-spinners';
import Cookies from 'js-cookie';

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const respTableId = process.env.REACT_APP_AIR_RESP;
const base2 = new Airtable({ apiKey }).base(baseId);

export default function AutomationUpgrade({
  automationOptions,
  responsibility,
  automationUpgrades,
  name,
  biggerAutomationMap,
  setBiggerAutomationMap,
  newResponsibilities,
  sendSoftwareSelectDataToParent,
  sendAutomationSelectDataToParent,
  sendLoomDataToParent
}) {
  const [editMode, setEditMode] = useState(true);
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedAutomation, setSelectedAutomation] = useState('');
  const [respId, setRespId] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [createCustomRec, setCreateCustomRec] = useState(false);

  useEffect(() => {
    if (biggerAutomationMap[name]) {
      // console.log(biggerAutomationMap)
      if (biggerAutomationMap[name][responsibility.value + 'id']) {
        setRespId(biggerAutomationMap[name][responsibility.value + 'id'])
      } else if (!createCustomRec) {
        setCreateCustomRec(true);
        fetch('https://api.airtable.com/v0/' + baseId + '/' + respTableId, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            fields: {
              Name: responsibility.value,
            }
          })
        }).then(response => response.json())
          .then(data => {
            const recordId = data.id;
            setBiggerAutomationMap(prev => ({
              ...prev,
              [name]: {
                ...prev[name],
                [responsibility.value + 'id']: recordId,
              }
            }));
            setRespId(recordId);
          });

        setBiggerAutomationMap(prev => ({
          ...prev,
          [name]: {
            ...prev[name],
            [responsibility.value]: []
          }
        }));
      }
    }
  }, [biggerAutomationMap, newResponsibilities])

  useEffect(() => {
    if (waiting) {
      const fetchData = async () => {
        try {
          const response = await fetch('https://api.airtable.com/v0/' + baseId + '/' + respTableId + '/' + respId, {
            headers: {
              'Authorization': `Bearer ${apiKey}`,
              'Content-Type': 'application/json'
            },
          });

          const data = await response.json();

          console.log('polling progress 🤓', data);

          // TODO - FIX THIS
          // console.log(data, "data")
          // console.log(biggerAutomationMap, "biggerAutomationMap")
          // console.log(biggerAutomationMap[name], "test test test")
          // Where does biggerAutomationMap[name] come from?

          if (JSON.parse(data?.fields?.AutomationUpgrades).length === biggerAutomationMap[name][responsibility.value].length + 1) {
            setBiggerAutomationMap(prev => ({
              ...prev,
              [name]: {
                ...prev[name],
                [responsibility.value]: data?.fields?.AutomationUpgrades ? JSON.parse(data?.fields?.AutomationUpgrades) : [],
              }
            }));
            setWaiting(false);
          }

        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      fetchData();
      const interval = setInterval(fetchData, 1500); // Poll every 1.5 seconds
      return () => clearInterval(interval);

    }
  }, [waiting])

  // Function to return a random color class
  function getRandomNumber() {
    return Math.floor(Math.random() * 4);
  }

  // won't find stuff that already exist in list
  function findMatch() {
    fetch(`https://hooks.zapier.com/hooks/catch/16093847/${process.env.REACT_APP_ZAPIER_GPT}/`, {
      method: "POST",
      body: JSON.stringify({
        responsibilityId: biggerAutomationMap[name][responsibility.value + 'id'],
        currentUpgrades: encodeURIComponent(JSON.stringify(biggerAutomationMap[name][responsibility.value])),
        responsibility: responsibility.value,
        automations: Object.keys(automationUpgrades).filter(automation => !biggerAutomationMap[name][responsibility.value].map(upgrade => upgrade.automation).includes(automation)).join(','),
      }),
    })
      .then((response) => response.json().then(data => console.log('this is results', data)))
      .catch((error) => console.error(error));
  }

  function handleSave(e) {
    e.preventDefault();
    console.log('hello world');
    console.log('record id', respId);
    fetch('https://api.airtable.com/v0/' + baseId + '/' + respTableId + '/' + respId, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${apiKey}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        fields: {
          AutomationUpgrades: JSON.stringify(
            [
              ...biggerAutomationMap[name][responsibility.value],
              {
                automation: selectedOption.value,
                verified: true,
                verifier: Cookies.get('email')
              }
            ]
          )
        }
      })
    });

    setBiggerAutomationMap(prev => ({
      ...prev,
      [name]: {
        ...prev[name],
        [responsibility.value]: Array.isArray(prev[name][responsibility.value])
          ? [
            ...prev[name][responsibility.value],
            {
              automation: selectedOption.value,
              verified: true,
              verifier: Cookies.get('email')
            }
          ]
          : [
            {
              automation: selectedOption.value,
              verified: true,
              verifier: Cookies.get('email')
            }
          ],
      }
    }));
  }

  function handleVerify(index) {
    setBiggerAutomationMap(prev => ({
      ...prev,
      [name]: {
        ...prev[name],
        [responsibility.value]: prev[name][responsibility.value].map((upgrade, i) => {
          if (i === index) {
            return {
              ...upgrade,
              verified: true,
              verifier: Cookies.get('email')
            }
          } else {
            return upgrade
          }
        })
      }
    }));

    fetch('https://api.airtable.com/v0/' + baseId + '/' + respTableId + '/' + respId, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${apiKey}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        fields: {
          AutomationUpgrades: JSON.stringify(biggerAutomationMap[name][responsibility.value].map((upgrade, i) => {
            if (i === index) {
              return {
                ...upgrade,
                verified: true,
                verifier: Cookies.get('email')
              }
            } else {
              return upgrade
            }
          }))
        }
      })
    });
  }

  function handleDelete(index) {
    const afterDelete = biggerAutomationMap[name][responsibility.value].filter((automation, i) => i !== index)

    fetch('https://api.airtable.com/v0/' + baseId + '/' + respTableId + '/' + respId, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${apiKey}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        fields: {
          AutomationUpgrades: JSON.stringify(afterDelete)
        }
      })
    });

    setBiggerAutomationMap({
      ...biggerAutomationMap,
      [name]: {
        ...biggerAutomationMap[name],
        [responsibility.value]: afterDelete
      }
    });
  }

  const handleSelectChange = selectedOption => {
    setSelectedOption(selectedOption);
    console.log("selected Automation", selectedOption)
    sendAutomationSelectDataToParent(selectedOption)
  };

  const [videoUrl, setVideoUrl] = useState('');

  const handleChange = (e) => {
    setVideoUrl(e.target.value);
    console.log("videoUrl test 3", e.target.value)
    sendLoomDataToParent(e.target.value)
  };

  const [selectedSoftware, setSelectedSoftware] = useState([]);

  const handleSoftwareSelectChange = (selectedOptions) => {
    setSelectedSoftware(selectedOptions);
    console.log("technologies selected", selectedOptions)
    sendSoftwareSelectDataToParent(selectedOptions)
  };

  const softwareOptions = [
    { value: "QuickBooks", label: "QuickBooks" },
    { value: "Xero", label: "Xero" },
    { value: "FreshBooks", label: "FreshBooks" },
    { value: "Salesforce", label: "Salesforce" },
    { value: "Zoho CRM", label: "Zoho CRM" },
    { value: "HubSpot", label: "HubSpot" },
    { value: "Trello", label: "Trello" },
    { value: "Asana", label: "Asana" },
    { value: "Slack", label: "Slack" },
    { value: "Microsoft 365", label: "Microsoft 365" },
    { value: "Google Workspace", label: "Google Workspace" },
    { value: "Shopify", label: "Shopify" },
    { value: "Square", label: "Square" },
    { value: "Stripe", label: "Stripe" },
    { value: "Mailchimp", label: "Mailchimp" },
    { value: "Constant Contact", label: "Constant Contact" },
    { value: "WordPress", label: "WordPress" },
    { value: "Wix", label: "Wix" },
    { value: "Zoom", label: "Zoom" },
    { value: "Dropbox", label: "Dropbox" },
    { value: "QuickBase", label: "QuickBase" },
    { value: "Monday.com", label: "Monday.com" },
    { value: "ClickUp", label: "ClickUp" },
    { value: "SquareSpace", label: "SquareSpace" },
    { value: "Pipedrive", label: "Pipedrive" },
    { value: "Airtable", label: "Airtable" },
    { value: "Intercom", label: "Intercom" },
    { value: "Zendesk", label: "Zendesk" },
    { value: "Gusto", label: "Gusto" },
    { value: "ADP", label: "ADP" },
    { value: "Paychex", label: "Paychex" },
    { value: "DocuSign", label: "DocuSign" },
    { value: "HelloSign", label: "HelloSign" },
    { value: "Hootsuite", label: "Hootsuite" },
    { value: "Buffer", label: "Buffer" },
    { value: "Canva", label: "Canva" },
    { value: "Adobe Creative Cloud", label: "Adobe Creative Cloud" },
    { value: "Dropbox Paper", label: "Dropbox Paper" },
    { value: "Evernote", label: "Evernote" },
    { value: "OneDrive", label: "OneDrive" },
    { value: "Basecamp", label: "Basecamp" },
    { value: "Notion", label: "Notion" },
    { value: "SurveyMonkey", label: "SurveyMonkey" },
    { value: "Eventbrite", label: "Eventbrite" },
    { value: "Calendly", label: "Calendly" },
    { value: "ZoomInfo", label: "ZoomInfo" },
    { value: "Yelp", label: "Yelp" },
    { value: "GoDaddy", label: "GoDaddy" },
    { value: "Weebly", label: "Weebly" },
    { value: "Jira", label: "Jira" },
    { value: "Gmail", label: "Gmail" },
    { value: "Google Docs", label: "Google Docs" },
  ];

  return (
    <>
      <tr>
        <td colspan="3">
          <div className='my-5'>
              <div className=''>
                <h1 className="max-w-lg text-sm font-semibold tracking-tight text-gray-900">Details For Your Responsibility Automation</h1>

                {/* Technologies Select */}
                <div className="flex flex-col space-y-2 mt-2">
                  <label htmlFor="software-select" className="text-sm font-semibold">
                    Software associated with your automation (Required):
                  </label>
                  <Select
                    id="software-select"
                    isMulti
                    options={softwareOptions}
                    value={selectedSoftware}
                    onChange={handleSoftwareSelectChange}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select software..."
                  />
                  <div className="mt-2 text-sm">
                    {/* Selected Software:{" "}
                  {selectedSoftware.length > 0
                    ? selectedSoftware.map((software) => software.label).join(", ")
                    : "None selected"} */}
                  </div>
                </div>

                {/* Loom Video Link Input */}
                <div className="flex flex-col space-y-2">
                  <label htmlFor="loom" className="text-sm font-semibold">
                    Loom recording of you doing your work, 1 - 5 min (Highly recommended):
                  </label>
                  <input
                    type="url"
                    id="loom"
                    name="loom"
                    value={videoUrl}
                    onChange={handleChange}
                    placeholder="https://loom.com/share/your-video"
                    className="border border-gray-300 rounded-md p-2 focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>

                <div className=''>
                  <label
                    className="mt-2 block text-md text-sm font-semibold leading-6"
                  >
                    Choose automation from list for better match chance (Optional)
                  </label>
                  <div
                    className='flex'
                  >
                    <Select
                      className='w-96'
                      value={selectedOption}
                      onChange={handleSelectChange}
                      options={automationOptions}
                    // styles={{ container: (provided) => ({ ...provided, width: 500 }) }}
                    />
                    <button
                      class="ml-4 box-border h-10 relative z-10 inline-flex items-center justify-center w-auto px-4 py-2 overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 ease focus:outline-none"
                      onClick={handleSave}
                    >
                      <span class="absolute bottom-0 right-0 w-8 h-20 -mb-8 -mr-5 transition-all duration-300 ease-out transform rotate-45 translate-x-1 bg-white opacity-10 group-hover:translate-x-0" />
                      <span class="absolute top-0 left-0 w-20 h-8 -mt-1 -ml-12 transition-all duration-300 ease-out transform -rotate-45 -translate-x-1 bg-white opacity-10 group-hover:translate-x-0" />
                      <span class="relative flex items-center text-sm">
                        Add
                      </span>
                    </button>
                  </div>
                  {/* PRINT INPUTS HERE */}
                  {/* Print Inputs here {selectedOption?.inputs?.value}
                  {selectedOption?.Inputs?.map((input, i) => (
                    <p key={i}>{input}</p>
                  ))} */}

                  {/* RUN AUTOMATION BUTTON */}
                  {/* Trigger Automation Button {
                    selectedOption?.value && (
                      <button
                        type="button"
                        className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Run {selectedOption?.value}
                      </button>
                    )
                  } */}

                  {/* <label
                    className="mt-4 block text-md font-medium leading-6 text-gray-900"
                  >
                    Alfred AI Match (Matching Automations to Responsibility)
                  </label>
                  <button
                    className={`mb-4 flex rounded-md ${waiting ? 'bg-gray-600' : 'bg-gray-900'} px-3 py-2 text-sm font-semibold text-white shadow-md ${!waiting && 'hover:bg-gray-500'} focus-visible:outline focus-visible:outline-2 
                    focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                    onClick={() => {
                      findMatch();
                      setWaiting(true);
                    }}
                    disabled={waiting}
                  >
                    {waiting ? (
                      <>
                        <span className='mr-4'><BeatLoader /></span>
                        <span className=''>Processing</span>
                      </>
                    ) : (
                      <>
                        <span className=''>Try Our New AI Matching</span>
                      </>
                    )}
                  </button> */}

                </div>
              </div>
            <div className='py-2'>
              <div className='-ml-2 flex-wrap justify-between'>
                {biggerAutomationMap[name] && Object.keys(biggerAutomationMap[name]).length !== 0 ?
                  biggerAutomationMap[name][responsibility.value]?.map((automation, i) => (
                    <div className='ml-2 mt-2 text-md bg-gray-100 font-bold inline-flex justify-between rounded-md px-2 py-2'>
                      {automation.verified && (<CheckBadgeIcon className="h-5 text-blue-500" aria-hidden="true" />)}

                      <button
                        className='ml-4'
                        onClick={() => {
                          setSelectedAutomation(automation.automation);
                          setModalOpen(true);
                        }}
                      >
                        {automation.automation}
                      </button>
                      {editMode &&
                        <button className='ml-4' onClick={() => handleDelete(i)}>
                          <TrashIcon className="h-5 w-5 text-xl hover:text-gray-600 text-black" />
                        </button>
                      }
                      {!automation.verified &&
                        <button className='ml-4' onClick={() => handleVerify(i)}>
                          <CheckCircleIcon className="h-5 w-5 text-xl hover:text-gray-600 text-black" />
                        </button>
                      }
                    </div>
                  )) : (
                    <>
                      <Skeleton height={20} />
                      <Skeleton height={40} />
                      <Skeleton className='mt-8' height={20} />
                      <Skeleton height={40} />
                      <Skeleton className='mt-8' height={20} />
                      <Skeleton height={40} />
                    </>
                  )
                }
              </div>

            </div>
          </div>

        </td>
      </tr >
      <AutomationModal open={modalOpen} setOpen={setModalOpen} automation={automationUpgrades[selectedAutomation]} />
    </>

  )
}