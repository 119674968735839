/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import ProgressBar from "./ProgressBar";
import { RequestAmount } from "./Steps/RequestAmount";
import { PersonalInfo } from "./Steps/PersonalInfo";
import { BusinessInfo } from "./Steps/BusinessInfo";
import { BusinessIndustry } from "./Steps/BusinessIndustry";
import { DescribeExistingLoan } from "./Steps/DescribeExistingLoan";
import { DescribeExistingLender } from "./Steps/DescribeExistingLender";
import { SuccessSubmit } from "./Steps/SuccessSubmit";
import {
  averageMonthlyRevenueLastThreeMonthsAnswers,
  businessJourneyStageAnswers,
  businessRepresentationAnswers,
  cashRunwayAnswers,
  currentReceivablesBalanceAnswers,
  expectedGrantsAnswers,
  fixedAssetsValueAnswers,
  fundingUseCaseAnswers,
  incorporationLocationAnswers,
  lastYearAproxProfitAnswers,
  lastYearAproxRevenueAnswers,
  loanImportanceFactorsAnswers,
  revenueModelAnswers,
  timeInBusinessAnswers,
  yearOverYearGrowthAnswers,
} from "./answers";
// import { financingPartners } from "./helperData";
import { CheckboxSteps } from "./Steps/CheckboxSteps";
import Airtable from "airtable";

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const peopleTable = process.env.REACT_APP_AIR_PEOPLE;
const companyTable = process.env.REACT_APP_AIR_COMPANIES;
const base2 = new Airtable({ apiKey }).base(baseId);

export default function FinanceForm({ userEmail, company }) {
  const initialFormData = {
    ///I used exact wording here with the data variable being passed to each step,
    ///if any is modified here, the data variable passed to the corresponding step also needs to be updated below.

    // How much money are you looking for?
    "Funding Amount Requested": "",

    // How could these funds help grow your business?
    "Funding Use Case" : {
      'Improve Cash Flow': false,
      'Business Expansion': false,
      'Payroll': false,
      'Inventory': false,
      'Start A Business': false,
      'Purchase A Business': false,
      'Buy Out A Partner': false,
      'Equipment Leasing': false,
      'Equipment Purchase': false,
      'Real Estate Property Finance': false,
      'Leasehold Improvement': false,
      'Finance Accounts Receivable': false,
      'Debt Finance': false,
      'Other': false,
    },
    

    // Which of the following are most important to you about your loan?
    "Loan Importance Factors": {
      'Low Interest Rate': false,
      'Fast Funding': false,
      'Amount Of Loan': false,
      'Easy Process': false,
      'Type Of Security Needed': false,
    },

    // Where are you in your journey so far?
    "Business Journey Stage": {
      'Just Getting Started': false,
      'Looking For Funding ASAP': false,
      'Looking To See What My Options Are': false,
      "Talked To A Few Lenders But Haven't Seen What I Want Yet": false,
      'Other': false,
    },

    /// personalDetails, these will be asked on a single pagge
    "First Name": "",
    "Last Name": "",
    "Email": userEmail,
    "Phone Number": "",

    /// basic business details, these will be asked on a single page
    "Operating Business Name": company?.fields?.Name,
    "Legal Business Name": company?.fields?.Name,
    "Company Website": company?.fields?.Domain,
    "Job Title": "",

    // What industries does your business operate in?
    Industry: company?.fields?.Industry,

    // Where is your company incorporated?
    "Incorporation Location": {
      "Canada": false,
      "United States": false,
      "Other": false,
    },
  
    "Time In Business": {
      "Less Than Six Months": false,
      "Six Months To One Year": false,
      "One To Three Years": false,
      "More Than Three Years": false,
    },
  
    "Last Year Aprox Revenue": {
      "0": false,
      "1 to 10k": false,
      "10k to 100k": false,
      "100k to 250k": false,
      "250k to 500k": false,
      "500k to 1m": false,
      "1m to 2m": false,
      "2m to 5m": false,
      "Above 5m": false,
    },
  
    "Last Year Aprox Profit": {
      "Not Profitable": false,
      "Broke Even": false,
      "Below 10k": false,
      "10k to 100k": false,
      "100k to 250k": false,
      "250k to 500k": false,
      "500k to 1m": false,
      "1m to 2m": false,
      "Above 2m": false,
    },
  
    "Business Representation": {
      "SME": false,
      "Software Innovation Online Retail": false,
      "New Early Phase Business": false,
    },
  
    "Revenue Model": {
      "B2B": false,
      "B2C": false,
      "Subscription": false,
      "Other": false,
      "E-Commerce": false,
    },
  
    "Year Over Year Growth": {
      "Below 0": false,
      "0 to 5": false,
      "5 to 10": false,
      "10 to 20": false,
      "20 to 30": false,
      "30 to 50": false,
      "Above 50": false,
    },
  
    "Cash Runway": {
      "1 to 6 Months": false,
      "6 to 12 Months": false,
      "12 to 18 Months": false,
      "Above 18 Months": false,
      "Profitable": false,
    },
  
    "Average Monthly Revenue Last Three Months": {
      "0 Pre-Revenue": false,
      "Below 1k": false,
      "1k to 8k": false,
      "8k to 15k": false,
      "15k to 25k": false,
      "25k to 50k": false,
      "50k to 100k": false,
      "100k to 250k": false,
      "Above 250k": false,
    },
  
    "Current Receivables Balance": {
      "None": false,
      "Below 50k": false,
      "50k to 100k": false,
      "100k to 250k": false,
      "250k to 1m": false,
      "1m to 2m": false,
      "Above 2m": false,
    },
  
    "Fixed Assets Value": {
      "None": false,
      "Below 50k": false,
      "50k to 125k": false,
      "125k to 250k": false,
      "250k to 500k": false,
      "500k to 1m": false,
      "1m to 5m": false,
      "Above 5m": false,
    },
  
    "Expected Grants": {
      "None": false,
      "Below 50k": false,
      "50k to 100k": false,
      "100k to 1m": false,
      "1m to 2m": false,
      "Above 2m": false,
    },
  
    "Existing Loans": "",
  
    "Lender Relationships": "",  
  };
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState(initialFormData);
  const [financingPartners, setFinancingPartners] = useState([]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log('DEVELOPMENT MODE');
      setFinancingPartners([
        {name: 'Raymond Pan', email: 'raymond@getupgraded.ca'},
        {name: 'Will Richman', email: 'will@getupgraded.ca'},
      ])
    } else {
      base2("financingpartners")
		  .select({
			view: "Grid view",
		  })
		  .all()
		  .then((data) => {
			const formattedData = [];
      data.forEach((record) => {
        if (record?.fields?.Name && record?.fields?.Email) {
          return formattedData.push({
            name: record?.fields?.Name, 
            email: record?.fields?.Email
          });
        }
      })
	  
			setFinancingPartners(formattedData);
		  })
		  .catch((error) => {
			console.log(error);
		  });
    }
    console.log(financingPartners);
	  }, []);

  const checkHowManySelected = (name) => {
    const count = Object.values(formData[name]).reduce(
      (acc, value) => acc + (value === true ? 1 : 0),
      0
    );
    return count;
  };

  const nextStep = () => {
    if (step !== 23) setStep(step + 1);
    console.log(formData);
  };

  const prevStep = () => {
    if (step !== 1 && step !== 22) setStep(step - 1); ///cant go to prev if on first page or finish submition
  };

  const handleChangeInput = (event) => {
    let fieldName = event.target.name;
    let fieldValue;
    fieldValue = event.target.value;
    if (
      fieldName === "Incorporation Location" ||
      fieldName === "Funding Use Case" ||
      fieldName === "Loan Importance Factors" ||
      fieldName === "Business Journey Stage" ||
      fieldName === "Time In Business" ||
      fieldName === "Last Year Aprox Revenue" ||
      fieldName === "Last Year Aprox Profit" ||
      fieldName === "Business Representation" ||
      fieldName === "Revenue Model" ||
      fieldName === "Year Over Year Growth" ||
      fieldName === "Cash Runway" ||
      fieldName === "Average Monthly Revenue Last Three Months" ||
      fieldName === "Current Receivables Balance" ||
      fieldName === "Fixed Assets Value" ||
      fieldName === "Expected Grants"
    ) {
      setFormData({
        ...formData,
        [fieldName]: {
          ...formData[fieldName],
          [event.target.id]: event.target.checked,
        },
      });
      return;
    } else if (fieldName === "Funding Amount Requested") {
      fieldValue = parseInt(fieldValue, 10) || "";
    }
    setFormData({
      ...formData,
      [fieldName]: fieldValue,
    });
  };

  const handleSubmitFormData = async () => {
    // check if financingpartners is empty
    if (financingPartners.length === 0) return;
    
    try {
      ///await push data to db
      setStep(22); ///goes to confirmation page
      console.log("formData", formData);

      function getTrueKeys(obj) {
        return Object.keys(obj)
          .filter(key => obj[key])
          .join(', ');
      }

      const fundingUseCase = getTrueKeys(formData["Funding Use Case"]);
      const loanImportanceFactors = getTrueKeys(formData["Loan Importance Factors"]);
      const businessJourneyStage = getTrueKeys(formData["Business Journey Stage"]);
      const incorporationLocation = getTrueKeys(formData["Incorporation Location"]);
      const timeInBusiness = getTrueKeys(formData["Time In Business"]);
      const lastYearAproxRevenue = getTrueKeys(formData["Last Year Aprox Revenue"]);
      const lastYearAproxProfit = getTrueKeys(formData["Last Year Aprox Profit"]);
      const businessRepresentation = getTrueKeys(formData["Business Representation"]);
      const revenueModel = getTrueKeys(formData["Revenue Model"]);
      const yearOverYearGrowth = getTrueKeys(formData["Year Over Year Growth"]);
      const cashRunway = getTrueKeys(formData["Cash Runway"]);
      const averageMonthlyRevenueLastThreeMonths = getTrueKeys(formData["Average Monthly Revenue Last Three Months"]);
      const currentReceivablesBalance = getTrueKeys(formData["Current Receivables Balance"]);
      const fixedAssetsValue = getTrueKeys(formData["Fixed Assets Value"]);
      const expectedGrants = getTrueKeys(formData["Expected Grants"]);

      let newFormData = {
        fundingAmountRequested: formData["Funding Amount Requested"],
        fundingUseCase: fundingUseCase,
        loanImportanceFactors: loanImportanceFactors,
        businessJourneyStage: businessJourneyStage,
        incorporationLocation: incorporationLocation,
        timeInBusiness: timeInBusiness,
        lastYearAproxRevenue: lastYearAproxRevenue,
        lastYearAproxProfit: lastYearAproxProfit,
        businessRepresentation: businessRepresentation,
        revenueModel: revenueModel,
        yearOverYearGrowth: yearOverYearGrowth,
        cashRunway: cashRunway,
        averageMonthlyRevenueLastThreeMonths: averageMonthlyRevenueLastThreeMonths,
        currentReceivablesBalance: currentReceivablesBalance,
        fixedAssetsValue: fixedAssetsValue,
        expectedGrants: expectedGrants,
        operatingBusinessName: formData["Operating Business Name"],
        legalBusinessName: formData["Legal Business Name"],
        companyWebsite: formData["Company Website"],
        firstName: formData["First Name"],
        lastName: formData["Last Name"],
        email: formData["Email"],
        phoneNumber: formData["Phone Number"],
        jobTitle: formData["Job Title"],
        industry: formData["Industry"],
        existingLoans: formData["Existing Loans"],
        lenderRelationships: formData["Lender Relationships"],
      };

      // const additionalProperties = {
      //   financingPartners.map(partner => partner.email).join(', ')
      // };

      console.log(financingPartners, "financingPartners");

      var Airtable = require('airtable');
      var base = new Airtable({ apiKey: apiKey }).base('appVulceDQ4SKlQoP');
      base('financing').create([
        {
          "fields": newFormData
        }
      ], function (err, records) {
        if (err) {
          console.error(err);
          return;
        }
        records.forEach(function (record) {
          console.log(record.getId());
        });
      });

      // call our Zapier webhook
      // https://hooks.zapier.com/hooks/catch/13965335/2y1cpu1/
      fetch(`https://hooks.zapier.com/hooks/catch/13965335/2y1cpu1/`, {
        method: "POST",
        body: JSON.stringify({
          ...newFormData,
          financingPartners: financingPartners
        })
      });

    } catch (error) {
      setStep(23); ///if somethingt goes wrong, will change step to 24 which will trigger default in the switch, showing something went wrong to the user.
    }
  };

  const getStepComponent = () => {
    switch (step) {
      case 1:
        return (
          <RequestAmount
            formData={formData}
            handleChangeInput={handleChangeInput}
            nextStep={nextStep}
          />
        );
      case 2:
        return (
          <CheckboxSteps
            formData={formData}
            handleChangeInput={handleChangeInput}
            nextStep={nextStep}
            prevStep={prevStep}
            checkHowManySelected={checkHowManySelected}
            data={"Funding Use Case"}
            question={"How could these funds help grow your business?"}
            answers={fundingUseCaseAnswers}
          />
        );
      case 3:
        return (
          <CheckboxSteps
            formData={formData}
            handleChangeInput={handleChangeInput}
            nextStep={nextStep}
            prevStep={prevStep}
            checkHowManySelected={checkHowManySelected}
            data={"Loan Importance Factors"}
            question={
              "Which of the following are most important to you about your loan?"
            }
            answers={loanImportanceFactorsAnswers}
          />
        );
      case 4:
        return (
          <CheckboxSteps
            formData={formData}
            handleChangeInput={handleChangeInput}
            nextStep={nextStep}
            prevStep={prevStep}
            checkHowManySelected={checkHowManySelected}
            data={"Business Journey Stage"}
            question={"Where are you in your journey so far?"}
            answers={businessJourneyStageAnswers}
            onlySelectOne={true}
          />
        );
      case 5:
        return (
          ///asks for personal info first and last name, email, phone
          <PersonalInfo
            formData={formData}
            handleChangeInput={handleChangeInput}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 6:
        return (
          ///asks for Business operation and legal name, website, and job title
          <BusinessInfo
            formData={formData}
            handleChangeInput={handleChangeInput}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 7:
        return (
          ///asks for industries
          <BusinessIndustry
            formData={formData}
            handleChangeInput={handleChangeInput}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 8:
        return (
          <CheckboxSteps
            formData={formData}
            handleChangeInput={handleChangeInput}
            nextStep={nextStep}
            prevStep={prevStep}
            checkHowManySelected={checkHowManySelected}
            data={"Incorporation Location"}
            question={`Thanks ${formData['First Name']}, where is ${formData['Operating Business Name']} incorporated?`}
            answers={incorporationLocationAnswers}
            onlySelectOne={true}
          />
        );
      case 9:
        return (
          <CheckboxSteps
            formData={formData}
            handleChangeInput={handleChangeInput}
            nextStep={nextStep}
            prevStep={prevStep}
            checkHowManySelected={checkHowManySelected}
            data={"Time In Business"}
            question={"How long have you been in business?"}
            answers={timeInBusinessAnswers}
            onlySelectOne={true}
          />
        );
      case 10:
        return (
          <CheckboxSteps
            formData={formData}
            handleChangeInput={handleChangeInput}
            nextStep={nextStep}
            prevStep={prevStep}
            checkHowManySelected={checkHowManySelected}
            data={"Last Year Aprox Revenue"}
            question={"What was your approximate revenue last year?"}
            answers={lastYearAproxRevenueAnswers}
            onlySelectOne={true}
          />
        );
      case 11:
        return (
          <CheckboxSteps
            formData={formData}
            handleChangeInput={handleChangeInput}
            nextStep={nextStep}
            prevStep={prevStep}
            checkHowManySelected={checkHowManySelected}
            data={"Last Year Aprox Profit"}
            question={"How much profit did you make last year?"}
            answers={lastYearAproxProfitAnswers}
            onlySelectOne={true}
          />
        );
      case 12:
        return (
          <CheckboxSteps
            formData={formData}
            handleChangeInput={handleChangeInput}
            nextStep={nextStep}
            prevStep={prevStep}
            checkHowManySelected={checkHowManySelected}
            data={"Business Representation"}
            question={`Which of the following best represents ${formData["Operating Business Name"]
                ? formData["Operating Business Name"]
                : "Your Company"
              }?`}
            answers={businessRepresentationAnswers}
            onlySelectOne={true}
          />
        );
      case 13:
        return (
          <CheckboxSteps
            formData={formData}
            handleChangeInput={handleChangeInput}
            nextStep={nextStep}
            prevStep={prevStep}
            checkHowManySelected={checkHowManySelected}
            data={"Revenue Model"}
            question={`What is the revenue model for ${formData["Operating Business Name"]
                ? formData["Operating Business Name"]
                : "Your Company"
              }?`}
            answers={revenueModelAnswers}
          />
        );
      case 14:
        return (
          <CheckboxSteps
            formData={formData}
            handleChangeInput={handleChangeInput}
            nextStep={nextStep}
            prevStep={prevStep}
            checkHowManySelected={checkHowManySelected}
            data={"Year Over Year Growth"}
            question={"What is your year-over-year revenue growth?"}
            answers={yearOverYearGrowthAnswers}
            onlySelectOne={true}
          />
        );
      case 15:
        return (
          <CheckboxSteps
            formData={formData}
            handleChangeInput={handleChangeInput}
            nextStep={nextStep}
            prevStep={prevStep}
            checkHowManySelected={checkHowManySelected}
            data={"Cash Runway"}
            question={"What is your cash runway?"}
            answers={cashRunwayAnswers}
            onlySelectOne={true}
          />
        );
      case 16:
        return (
          <CheckboxSteps
            formData={formData}
            handleChangeInput={handleChangeInput}
            nextStep={nextStep}
            prevStep={prevStep}
            checkHowManySelected={checkHowManySelected}
            data={"Average Monthly Revenue Last Three Months"}
            question={
              "What was your average monthly revenue over the last 3 months?"
            }
            answers={averageMonthlyRevenueLastThreeMonthsAnswers}
            onlySelectOne={true}
          />
        );
      case 17:
        return (
          <CheckboxSteps
            formData={formData}
            handleChangeInput={handleChangeInput}
            nextStep={nextStep}
            prevStep={prevStep}
            checkHowManySelected={checkHowManySelected}
            data={"Current Receivables Balance"}
            question={"What is your current accounts receivable balance?"}
            answers={currentReceivablesBalanceAnswers}
            onlySelectOne={true}
          />
        );
      case 18:
        return (
          <CheckboxSteps
            formData={formData}
            handleChangeInput={handleChangeInput}
            nextStep={nextStep}
            prevStep={prevStep}
            checkHowManySelected={checkHowManySelected}
            data={"Fixed Assets Value"}
            question={
              "If you or your business own any fixed assets that could be used to secure a loan, what is its total value?"
            }
            answers={fixedAssetsValueAnswers}
            onlySelectOne={true}
          />
        );
      case 19:
        return (
          <CheckboxSteps
            formData={formData}
            handleChangeInput={handleChangeInput}
            nextStep={nextStep}
            prevStep={prevStep}
            checkHowManySelected={checkHowManySelected}
            data={"Expected Grants"}
            question={
              "If you claim SR&ED or any other large grants, how much do you expect to claim this year?"
            }
            answers={expectedGrantsAnswers}
            onlySelectOne={true}
          />
        );
      case 20:
        return (
          <DescribeExistingLoan
            formData={formData}
            handleChangeInput={handleChangeInput}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 21:
        return (
          <DescribeExistingLender
            formData={formData}
            handleChangeInput={handleChangeInput}
            handleSubmitFormData={handleSubmitFormData}
            prevStep={prevStep}
          />
        );
      case 22:
        return <SuccessSubmit />;
      default:
        return <div>Something Went Wrong</div>;
    }
  };

  return (
    <span>
      <ProgressBar currentStep={step} totalSteps={22} />
      {getStepComponent()}
    </span>
  );
}
