import { React, Fragment, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PaperClipIcon } from '@heroicons/react/20/solid';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react';

import { CheckCircleIcon, ArrowRightIcon } from '@heroicons/react/24/solid';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function currencyFormat(num) {
  return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? 'rotate-180' : ''
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

function Onboarding({ company, isLoading, setSideBarOpen }) {
  const [open, setOpen] = useState(1);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <>
      {loading ? (
        <Skeleton height={200} />
      ) : (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <Fragment>
            <div className="border-b border-gray-200 bg-white py-5">
              <h3 className="text-2xl font-semibold leading-6 text-gray-900">
                Do It Yourself
              </h3>
            </div>
            <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
              <AccordionHeader
                onClick={() => handleOpen(1)}
                className="text-left"
              >
                1. Complete Business Profile
              </AccordionHeader>
              <AccordionBody>
                Ensure your information is accurate and complete in order for us
                to recommend the right savings opportunities.
                <br />
                <a
                  type="button"
                  className="inline-flex items-center gap-x-2 rounded-md bg-stone-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-900 mt-2"
                  onClick={() => {
                    setSideBarOpen(true);
                  }}
                >
                  Ensure Company Info Is Correct In Sidebar
                  <ArrowRightIcon
                    className="-mr-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                </a>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
              <AccordionHeader
                onClick={() => handleOpen(2)}
                className="text-left"
              >
                2. Upgrade to a Paid Plan
              </AccordionHeader>
              <AccordionBody>
                To see all your savings upgrade to a paid plan.
                <br />
                <a
                  type="button"
                  className="inline-flex items-center gap-x-2 rounded-md bg-stone-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-900 mt-2"
                  onClick={() => {
                    const currentSearch = location.search;
                    window.scrollTo({ top: 0 });
                    navigate(`/pricing${currentSearch}`);
                  }}
                  target="_blank"
                >
                  Upgrade to Pro
                  <ArrowRightIcon
                    className="-mr-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                </a>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
              <AccordionHeader
                onClick={() => handleOpen(3)}
                className="text-left"
              >
                3. Start Saving
              </AccordionHeader>
              <AccordionBody>
                Enjoy the sunset and icecream. Sit back, relax and start saving.
                Each savings match has been generated based on your business
                profile and funding needs.
                <br />
                <img
                  className="h-96 rounded-md"
                  src="https://th-thumbnailer.cdn-si-edu.com/8OOv86ONF4sjR_AQooUkhzkYE1c=/fit-in/1072x0/https://tf-cmsv2-photocontest-smithsonianmag-prod-approved.s3.amazonaws.com/12735d73-cade-4fca-ba14-e7e7e8730721.jpg"
                ></img>
              </AccordionBody>
            </Accordion>
            <div className="border-b border-gray-200 bg-white py-5">
              <h3 className="text-2xl font-semibold leading-6 text-gray-900">
                Done For You
              </h3>
            </div>
            <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
              <AccordionHeader
                onClick={() => handleOpen(4)}
                className="text-left"
              >
                1. Grant & Savings Roadmapping Survey
              </AccordionHeader>
              <AccordionBody>
                Like eating ice cream on a hot day, we'd love nothing more than
                to bring you buckets of savings on activities you're already
                pursuing.
                <br />
                <a
                  type="button"
                  className="inline-flex items-center gap-x-2 rounded-md bg-stone-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-900 mt-2"
                  href="https://tally.so/r/me5pGE"
                  target="_blank"
                >
                  See Savings Opportunities
                  <ArrowRightIcon
                    className="-mr-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                </a>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 5} icon={<Icon id={5} open={open} />}>
              <AccordionHeader
                onClick={() => handleOpen(5)}
                className="text-left"
              >
                2. Sign Agreement
              </AccordionHeader>
              <AccordionBody>
                Terms & conditions for our work together.
                <br />
                <a
                  type="button"
                  className="inline-flex items-center gap-x-2 rounded-md bg-stone-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-900 mt-2"
                  href="https://portal.helloworks.com/link/UU9UjiqdqIUa9rvM"
                  target="_blank"
                >
                  Quick signature to get started!
                  <ArrowRightIcon
                    className="-mr-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                </a>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 6} icon={<Icon id={6} open={open} />}>
              <AccordionHeader onClick={() => handleOpen(6)}>
                3. Guaranteed, Deductible Deposit
              </AccordionHeader>
              <AccordionBody>
                Because we need to ensure you're serious, we require first
                months guaranteed, deductible deposit.
                <br />
                <a
                  type="button"
                  className="inline-flex items-center gap-x-2 rounded-md bg-stone-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-900 mt-2"
                  href="https://buy.stripe.com/4gw2bS2xk4Gw7PW4gl"
                  target="_blank"
                >
                  Getting Warmer
                  <ArrowRightIcon
                    className="-mr-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                </a>
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 7} icon={<Icon id={7} open={open} />}>
              <AccordionHeader onClick={() => handleOpen(7)}>
                4. Grant Roadmapping Onboarding Call
              </AccordionHeader>
              <AccordionBody>
                Sit back, relax and start saving. Each savings match is
                generated based on your business profile and funding needs.
                <br />
                <a
                  type="button"
                  className="inline-flex items-center gap-x-2 rounded-md bg-stone-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-900 mt-2"
                  href="https://calendly.com/d/ckft-btn-444/upgraded-onboarding"
                  target="_blank"
                >
                  Speak with our team of grant experts
                  <ArrowRightIcon
                    className="-mr-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                </a>
              </AccordionBody>
            </Accordion>
          </Fragment>
        </div>
      )}
    </>
  );
}

export default Onboarding;
