import React, { useState, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import {
  CurrencyDollarIcon,
  EyeIcon,
  EyeSlashIcon,
} from '@heroicons/react/20/solid';
import Confetti from 'react-confetti';
import FlowbiteSpin from './FlowbiteSpin';
import { Tooltip } from '@material-tailwind/react';

import ErrorList from './ErrorList';
import { currencyFormat } from './helpers';
import { additionalInfoMap } from './helperData';

// initializing secrets
const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;

// helper functions
function getMatchingValue(map, name) {
  for (const key in map) {
    if (name.toLowerCase().includes(key.toLowerCase())) {
      return map[key];
    }
  }
  return '';
}

function getSlug(url) {
  const urlObj = new URL(url);
  const path = urlObj.pathname;
  const segments = path.split('/');
  return segments[segments.length - 1] || segments[segments.length - 2];
}

// functional component for redemption pop up
const RedeemDialog = ({
  open,
  setOpen,
  upgrade,
  company,
  setCompany,
  userEmail,
  redeemed,
  setRedeemed,
}) => {
  // initializing variables
  const navigate = useNavigate();
  const location = useLocation();
  const cancelButtonRef = useRef(null);
  const [email, setEmail] = useState(userEmail);
  const [migrationState, setMigrationState] = useState(false);
  const [redemptionEmail, setRedemptionEmail] = useState(userEmail);
  const [ready, setReady] = useState(upgrade?.fields?.DiscountId);
  const [redeemedAgain, setRedeemedAgain] = useState(false);
  const [password, setPassword] = useState('');
  const [monthlySpending, setMonthlySpending] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [showAdditional, setShowAdditional] = useState(
    getMatchingValue(additionalInfoMap, upgrade?.fields?.Name)
  );
  const [instructions, setInstructions] = useState('');
  const [newTab, setNewTab] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [fullName, setFullName] = useState('');
  const [landingURL, setLandingURL] = useState('');
  const [organizationName, setOrganizationName] = useState(
    company?.fields?.Name
  );
  const [errorList, setErrorList] = useState([]);

  const annualMargin = upgrade?.fields?.AnnualMargin;
  const monthlyMarginInDollars = (annualMargin / 12) * 0.2;
  const monthlyMarginInCents = Math.round(monthlyMarginInDollars * 100);

  // patch request to airtable for redeemed tech
  const addToRedeemed = () => {
    const companyId = company?.id;
    if (companyId) {
      const curCompanyTech = company?.fields?.RedeemedTech || '';

      const updatedCompanyTech =
        curCompanyTech.length > 0
          ? `${curCompanyTech},${upgrade?.fields?.Name}`
          : upgrade?.fields?.Name;

      fetch(
        'https://api.airtable.com/v0/' +
        baseId +
        '/' +
        companiesTableId +
        '/' +
        companyId,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${apiKey}`,
            'Content-Type': 'application/json',
          },

          body: JSON.stringify({
            fields: {
              RedeemedTech: updatedCompanyTech,
            },
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log('success', data);
          setCompany((prevState) => ({
            ...prevState,
            fields: {
              ...prevState.fields,
              RedeemedTech: updatedCompanyTech,
            },
          }));
        })
        .catch((error) => console.error('Error:', error));
    }
  };

  // Log to Google Sheets /13965335/2ota6y3/
  const logToSheets = (msg, status, builtfirst) => {
    if (redeemed) setRedeemedAgain(true);
    setRedeemed(true);
    addToRedeemed();
    fetch('https://hooks.zapier.com/hooks/catch/13965335/2ota6y3/', {
      method: 'POST',
      body: JSON.stringify({
        CompanyName: company?.fields?.Name,
        ClientEmail: email,
        RequesterEmail: userEmail,
        PartnerEmail: '',
        UpgradeName: upgrade?.fields?.Name,
        Amount: currencyFormat(upgrade?.fields?.AnnualMargin),
        ClientName: fullName,
        Date: new Date().toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
        InvoiceReminderDate: new Date(
          Date.now() + 11 * 24 * 60 * 60 * 1000
        ).toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
        UpgradeType: `Tech (${msg})`,
        Deadline: '',
        Password: password,
        MonthlySpending: monthlySpending,
        Status: status,
        Link: !builtfirst ? upgrade?.fields?.href : '',
        AdditionalInfo:
          showAdditional && !builtfirst
            ? `${showAdditional}: ${additionalInfo}`
            : 'n/a',
        HumanInstructions: !builtfirst
          ? 'https://docs.google.com/document/d/1PsxDaAGUH47zf0UtKwajp-OL7gqObllDo-Fpn4jb7S8/edit?pli=1#heading=h.fd89fbawk5ii'
          : '',
      }),
    });

    // Airtable Post to All Grants & Savings Table
    fetch(
      `https://api.airtable.com/v0/${baseId}/ALLGRANTS&SAVINGS(Automated-AccountsReceivable)`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fields: {
            CompanyName: company?.fields?.Name,
            ClientEmail: email,
            RequesterEmail: userEmail,
            PartnerEmail: "",
            UpgradeName: upgrade?.fields?.Name,
            Amount: currencyFormat(upgrade?.fields?.AnnualMargin),
            ClientName: fullName,
            Date: new Date().toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            InvoiceReminderDate: new Date(
              Date.now() + 11 * 24 * 60 * 60 * 1000
            ).toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            UpgradeType: `Tech (${msg})`,
            Deadline: "",
            Password: password,
            MonthlySpending: monthlySpending,
            Status: status,
            Link: !builtfirst ? upgrade?.fields?.href : "",
            AdditionalInfo:
              showAdditional && !builtfirst
                ? `${showAdditional}: ${additionalInfo}`
                : "n/a",
            HumanInstructions: !builtfirst
              ? "https://docs.google.com/document/d/1PsxDaAGUH47zf0UtKwajp-OL7gqObllDo-Fpn4jb7S8/edit?pli=1#heading=h.fd89fbawk5ii"
              : "",
          },
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => console.log("Record added:", data))
      .catch((error) => console.error("Error:", error));
  };

  const sendSlackMSG = (msg, userEmail) => {
    fetch(
      `https://hooks.zapier.com/hooks/catch/13965335/2ueyqal/`,
      {
        method: 'POST',
        body: JSON.stringify({
          Technology: upgrade?.fields?.Name,
          DiscountAmount: currencyFormat(upgrade?.fields?.AnnualMargin),
          Company: company?.fields?.Name,
          SentTo: email,
          MonthlySpending: monthlySpending,
          Password: password,
          Messsage: msg,
          RedeemedBy: userEmail
        }),
      }
    );
  };

  // redeems a tech discount
  // - sends an email to the user
  // - create invoice + subscription
  // - set the correct message for the modal to show
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      setShowForm(false);

      // logic below is for sending emails
      let instructionsInEmail;
      let migration = false;

      // if the upgrade is built first or join secret, then set the url as the href from airtable
      if (
        upgrade?.fields?.Source === 'Built First' &&
        upgrade?.fields?.NewOrExisting !== 'New'
      ) {
        const slug = getSlug(upgrade?.fields?.href);
        const currentSearch = location.search;
        instructionsInEmail = `https://app.getupgraded.ca/perks${currentSearch}&dealPath=${slug}&type=1`;
      } else if (
        upgrade?.fields?.Source === 'JoinSecret' &&
        upgrade?.fields?.NewOrExisting !== 'New'
      ) {
        instructionsInEmail = upgrade?.fields?.href;
      }
      // if migration discount, then set migration to true
      else if (upgrade?.fields?.NewOrExisting === 'New') {
        migration = true;
        setMigrationState(true);
      }

      // sending the request to the backend to send email and also process the redemption using builtfirst api if possible
      const response = await axios.post(
        'https://murmuring-plateau-21252-b7994639367f.herokuapp.com/api/redemption',
        {
          email: email,
          fullName: fullName,
          companyName: organizationName,
          discountId: upgrade?.fields?.DiscountId?.split('#')[1],
          upgradeName: upgrade?.fields?.Name,
          upgradeAmount: currencyFormat(upgrade?.fields?.AnnualMargin),
          instructionsInEmail: instructionsInEmail,
          migration: migration,
          managedClient: company?.fields?.ManagedClient,
        }
      );

      // EVERYTHING BEYOND THIS POINT MEANS THE BUILTFIRST API WAS ABLE TO EITHER
      // - redeem the discount with no additonal steps and an email is sent to the client
      //   OR
      // - a landing page url is returned

      // OTHERWISE the error is caught by the catch block

      // redemption url that we receive from builtfirst api
      setLandingURL(response.data.data.attributes.landing_page_url);

      // configuration if builtfirst api works
      setReady(true);

      // log to google sheets
      logToSheets('redeemed by builtfirst api', 'awarded', true);

      // sending slack message
      sendSlackMSG('', userEmail);
    } catch (error) {
      if (error.response) {
        // the builtfirst api errored out the discount has been redeemed already
        if (error.response.data.type !== 'id') {
          // console.error('Error response data:', error.response.data);
          // setErrorList([`Request responded with a status of ${error.response.status}: ${error.response.data.message}`]);
          logToSheets('redeemed by builtfirst api before', 'awarded', true);

          sendSlackMSG('Builtfirst API has already redeemed this once!', userEmail);
        } else {
          logToSheets('require manual redemption', 'pending', false);

          sendSlackMSG('MANUAL REDEMPTION REQUIRED SINCE DISCOUNTID NOT FOUND', userEmail);
        }
      }
    } finally {
      if (
        upgrade?.fields?.Source === 'Built First' &&
        upgrade?.fields?.NewOrExisting !== 'New'
      ) {
        const slug = getSlug(upgrade?.fields?.href);
        const currentSearch = location.search;
        setInstructions(`/perks${currentSearch}&dealPath=${slug}&type=1`);
      } else if (
        upgrade?.fields?.Source === 'JoinSecret' &&
        upgrade?.fields?.NewOrExisting !== 'New'
      ) {
        setInstructions(upgrade?.fields?.href);
        setNewTab(true);
      }

      // create the invoice and subscribe customer to the plan
      if (upgrade?.fields?.NewOrExisting !== 'New') {
        try {
          const res = await axios.post(
            'https://murmuring-plateau-21252-b7994639367f.herokuapp.com/api/stripe/subscription/new',
            {
              savingName: upgrade?.fields?.Name,
              userDomain: company?.fields?.Domain,
              amountInCents: monthlyMarginInCents,
              amountInDollars: monthlyMarginInDollars,
              annualMargin: upgrade?.fields?.AnnualMargin,
              companyStripeEmail: company?.fields?.StripeBillingEmail,
              userEmail: userEmail,
              companyId: company?.id,
              managedClient: company?.fields?.ManagedClient
            }
          );

          console.log('res', res);
        } catch (err) { }
      }

      setLoading(false);
    }
  };

  const handleTryAgain = () => {
    setErrorList([]);
    setShowForm(true);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                    <CurrencyDollarIcon
                      className="h-6 w-6 fill-blue-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Redeem {currencyFormat(upgrade?.fields?.AnnualMargin)} in
                      Savings for {upgrade?.fields?.Name}
                    </Dialog.Title>
                    <div className="mt-6">
                      {loading ? (
                        <div className="flex flex-col items-center">
                          <div className="my-6 p-8">
                            <FlowbiteSpin />
                          </div>
                        </div>
                      ) : showForm ? (
                        <form onSubmit={handleSubmit}>
                          <div className="mt-4">
                            <label>
                              Redemption Instructions Will Be Sent To:
                            </label>
                            <input
                              type="email"
                              value={redemptionEmail}
                              onChange={(e) =>
                                setRedemptionEmail(e.target.value)
                              }
                              className="w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                              required
                            />
                          </div>
                          <div className="mt-4">
                            <label>Full Name:</label>
                            <input
                              type="text"
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                              className="w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                              required
                            />
                          </div>
                          <div className="mt-4">
                            <label>Organization Name:</label>
                            <input
                              type="text"
                              value={organizationName}
                              onChange={(e) =>
                                setOrganizationName(e.target.value)
                              }
                              className="w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                              required
                            />
                          </div>
                          <div className="mt-4">
                            <label>
                              Monthly Spending on {upgrade?.fields?.Name}:
                            </label>
                            <input
                              type="text"
                              value={monthlySpending}
                              onChange={(e) =>
                                setMonthlySpending(e.target.value)
                              }
                              className="w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                              required
                            />
                          </div>
                          <div className="mt-4">
                            <label>
                              Email used for {upgrade?.fields?.Name}:
                            </label>
                            <input
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                              required
                            />
                          </div>
                          <div className="mt-4 relative">
                            <label className="flex justify-center items-center">
                              {upgrade?.fields?.Name} Password:
                              {!ready && (
                                <Tooltip
                                  content="This product may require credentials to redeem. Providing your password will speed up the redemption process."
                                  placement="top"
                                  className="z-50 w-80 inline"
                                >
                                  <svg
                                    className="ml-2 inline h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    width="100"
                                    height="100"
                                    viewBox="0 0 48 48"
                                  >
                                    <path
                                      fill="#2196f3"
                                      d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                                    ></path>
                                    <path
                                      fill="#fff"
                                      d="M22 22h4v11h-4V22zM26.5 16.5c0 1.379-1.121 2.5-2.5 2.5s-2.5-1.121-2.5-2.5S22.621 14 24 14 26.5 15.121 26.5 16.5z"
                                    ></path>
                                  </svg>
                                </Tooltip>
                              )}
                            </label>
                            <input
                              type={showPassword ? 'text' : 'password'}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              className="w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                              required
                            />
                            <button
                              type="button"
                              onClick={togglePasswordVisibility}
                              className="absolute top-10 right-0 pr-3 flex items-center text-gray-500"
                            >
                              {showPassword ? (
                                <EyeIcon className="h-5 w-5" />
                              ) : (
                                <EyeSlashIcon className="h-5 w-5" />
                              )}
                            </button>
                          </div>
                          {showAdditional && (
                            <div className="mt-4">
                              <label>{showAdditional}:</label>
                              <input
                                type="text"
                                value={additionalInfo}
                                onChange={(e) =>
                                  setAdditionalInfo(e.target.value)
                                }
                                className="w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                required
                              />
                            </div>
                          )}
                          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                            <button
                              type="submit"
                              className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-700 sm:col-start-2 bg-blue-500 ${!email || !fullName || !organizationName
                                ? 'cursor-not-allowed'
                                : 'hover:bg-blue-400 cursor-pointer'
                                }`}
                              disabled={
                                !email || !fullName || !organizationName
                              }
                            >
                              Create Redemption
                            </button>
                            <button
                              type="button"
                              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                              onClick={() => setOpen(false)}
                              ref={cancelButtonRef}
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                      ) : errorList.length !== 0 ? (
                        <div>
                          <ErrorList errors={errorList} />
                          <button
                            onClick={() => handleTryAgain()}
                            className={`inline-flex w-1/2 justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-700 sm:col-start-2 bg-orange-500`}
                          >
                            Try Again
                          </button>
                        </div>
                      ) : landingURL ? (
                        <div className="mx-auto mt-6 flex max-w-md flex-col items-center justify-center rounded-lg bg-blue-100 p-6 text-center">
                          <div className="mb-4 flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                            <svg
                              className="h-6 w-6 text-green-500"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          </div>
                          <h3 className="text-base font-semibold leading-6 text-gray-900">
                            Redemption Successful!
                          </h3>
                          <p className="mt-2 text-sm text-gray-700">
                            Please click on the link below to complete the
                            redemption:
                          </p>
                          <a
                            href={landingURL}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="mt-4 inline-flex w-full justify-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                          >
                            Complete Redemption
                          </a>
                        </div>
                      ) : (
                        <div className="">
                          <Confetti />
                          <div className="bg-green-400 text-white p-4 my-10">
                            <ul className="list-disc list-inside">
                              <li>
                                {redeemedAgain
                                  ? "It appears you've already redeemed this discount once. Thank you for your interest in this opportunity"
                                  : instructions
                                    ? `Click on the "Instructions" button below to complete your redemption`
                                    : migrationState
                                      ? `Thank you for your interest! This discount requires a migration. Please follow the instructions given to you via email to proceed`
                                      : `Thank you! We will keep you updated on the progress of your redemption.`}
                              </li>
                            </ul>
                          </div>
                          {instructions && (
                            <div>
                              {newTab ? (
                                <a
                                  href={instructions}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  class="text-gray-900 bg-gradient-to-r block from-teal-200 to-lime-200 hover:bg-gradient-to-l hover:from-teal-200 hover:to-lime-200 focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-teal-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-4 w-36 mx-auto"
                                >
                                  Instructions
                                </a>
                              ) : (
                                <button
                                  onClick={() => {
                                    navigate(instructions);
                                    window.scrollTo({ top: 0 });
                                  }}
                                  class="text-gray-900 bg-gradient-to-r block from-teal-200 to-lime-200 hover:bg-gradient-to-l hover:from-teal-200 hover:to-lime-200 focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-teal-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-4 w-36 mx-auto"
                                >
                                  Instructions
                                </button>
                              )}
                            </div>
                          )}
                          <button
                            onClick={() => setOpen(false)}
                            class="text-gray-900 bg-gradient-to-r block mx-auto w-36 from-teal-200 to-lime-200 hover:bg-gradient-to-l hover:from-teal-200 hover:to-lime-200 focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-teal-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2"
                          >
                            Finish
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default RedeemDialog;
