import { Fragment, useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";

export default function Notification({ showNotif, setShowNotif, contents, zindex }) {
	/*
    Usage:
    Add tag at end of document.

    <Notification showNotif = { ...stateVariable to show notificaiton... }
                  setShowNotif = {...reference to corresponding setState function... }
                  contents = { ...object with configuration options as described below... }
    />
    
  */

	// contents prop object format
	/* contents = {
    type : string = "success" || "error",
    heading : string = Title message of the notification,
    message : string = message content of the body of the notification,
  } */

	const notificationTime = 7;
	const [type, setType] = useState("");

	useEffect(() => {
		if (contents?.type) {
			setType(contents.type);
		}
	}, [contents]);

	useEffect(() => {
		if (!showNotif) return;

		setTimeout(() => {
			setShowNotif(false);
		}, notificationTime * 1000);

		return () => {
			setShowNotif(false);
		};
	}, [showNotif]);

	return (
		<>
			{/* Global notification live region, render this permanently at the end of the document */}
			<div
				aria-live="assertive"
				className={`pointer-events-none fixed inset-0 items-end px-4 py-1000 sm:items-start sm:p-6 mt-[50px] z-50`}
			>
				<div className="flex w-full flex-col items-center space-y-4 sm:items-end">
					{/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
					{
						<Transition
							show={Boolean(showNotif)}
							as={Fragment}
							enter="transform ease-out duration-300 transition"
							enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
							enterTo="translate-y-0 opacity-100 sm:translate-x-0"
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
								<div className="p-4">
									<div className="flex items-start">
										<div className="flex-shrink-0">
											{type === "error" ? <XCircleIcon className={`h-6 w-6 text-red-400`} aria-hidden="true" /> : ""}
											{type === "success" ? <CheckCircleIcon className={`h-6 w-6 text-green-400`} aria-hidden="true" /> : ""}
										</div>
										<div className="ml-3 w-0 flex-1 pt-0.5">
											<p className="text-sm font-medium text-gray-900">{contents.heading}</p>
											<p className="mt-1 text-sm text-gray-500">{contents.message}</p>
										</div>
										<div className="ml-4 flex flex-shrink-0">
											<button
												type="button"
												className={`inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
												onClick={() => {
													setShowNotif(false);
												}}
											>
												<span className="sr-only">Close</span>
												<XMarkIcon className="h-5 w-5" aria-hidden="true" />
											</button>
										</div>
									</div>
								</div>
							</div>
						</Transition>
					}
				</div>
			</div>
		</>
	);
}

Notification.defaultProps = {
	contents: { type: "succcess", heading: "", message: "" },
	zindex: 50,
};
