export const iconMap = {
    gmail: "https://www.gstatic.com/images/branding/product/1x/gmail_48dp.png",
    gdrive: "https://ssl.gstatic.com/docs/doclist/images/drive_2022q3_32dp.png",
    sheets: "https://www.gstatic.com/images/branding/product/1x/sheets_48dp.png",
    meet: "https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v1/web-32dp/logo_meet_2020q4_color_1x_web_32dp.png",
    forms: "https://www.gstatic.com/images/branding/product/1x/forms_48dp.png",
    ai: "https://www.gstatic.com/lamda/images/favicon_v1_150160cddff7f294ce30.svg",
    buffer: "https://buffer.com/favicon.ico",
    slack: "https://a.slack-edge.com/80588/marketing/img/meta/slack_hash_256.png",
    asana: "https://logo.clearbit.com/asana.com",
    mailchimp: "https://mailchimp.com/favicon.ico",
    hubspot: "https://www.hubspot.com/favicon.ico",
    github: "https://github.githubassets.com/favicons/favicon.svg",
    jira: "https://wac-cdn.atlassian.com/assets/img/favicons/atlassian/favicon.png",
    woocommerce: "https://woocommerce.com/wp-content/themes/woo/images/logo-woocommerce.svg",
    shopify: "https://cdn.shopify.com/static/shopify-favicon.png",
    square: "https://square.com/favicon.ico",
    instagram: "https://www.instagram.com/static/images/ico/favicon-192.png/68d99ba29cc8.png",
    twitter: "https://abs.twimg.com/responsive-web/client-web/icon-ios.b1fc727a.png",
    rss: "https://upload.wikimedia.org/wikipedia/en/thumb/4/43/Feed-icon.svg/128px-Feed-icon.svg.png",
    stripe: "https://stripe.com/favicon.ico",
    quickbooks: "https://seeklogo.com/images/Q/quickbooks-logo-A536B7891E-seeklogo.com.png",
    expensify: "https://www.expensify.com/favicon.ico",
    xero: "https://www.xero.com/favicon.ico",
    greenhouse: "https://www.greenhouse.com/favicon.ico",
    bamboohr: "https://www.bamboohr.com/favicon.ico",
    workday: "https://www.workday.com/favicon.ico",
    calendar: "https://www.gstatic.com/images/branding/product/1x/calendar_48dp.png",
    philipshue: "https://logo.clearbit.com/philips.com",
    nest: "https://logo.clearbit.com/nest.com",
    zendesk: "https://logo.clearbit.com/zendesk.com",
    intercom: "https://logo.clearbit.com/intercom.com",
    salesforce: "https://logo.clearbit.com/salesforce.com",
    docusign: "https://logo.clearbit.com/docusign.com",
    notion: "https://logo.clearbit.com/notion.so",
    linear: "https://logo.clearbit.com/linear.app",
    airtable: "https://logo.clearbit.com/airtable.com",
    zapier: "https://logo.clearbit.com/zapier.com",
    gong: "https://logo.clearbit.com/gong.io",
    outreach: "https://logo.clearbit.com/outreach.io",
    apollo: "https://logo.clearbit.com/apollo.io",
    marketo: "https://logo.clearbit.com/marketo.com",
    gsheet: "https://www.gstatic.com/images/branding/product/1x/sheets_48dp.png",
    gdocs: "https://www.gstatic.com/images/branding/product/1x/docs_48dp.png",
    linkedin: "https://logo.clearbit.com/linkedin.com",
    crm: "https://logo.clearbit.com/salesforce.com",
    zoom: "https://logo.clearbit.com/zoom.us",
    mixpanel: "https://logo.clearbit.com/mixpanel.com",
    typeform: "https://logo.clearbit.com/typeform.com",
    taxjar: "https://logo.clearbit.com/taxjar.com",
  };