import {
    nextButtonStyle,
    prevButtonStyle,
    questionStyle,
    textDivStyle,
    textareaStyle,
  } from "../styles";
  
  export function BusinessIndustry({ formData, handleChangeInput, nextStep, prevStep }) {
    return (
      <>
        <div>
          <label for="Industry" class={`${questionStyle}`}>
            What industries does your business operate in?
          </label>
          <div class={`${textDivStyle}`}>
            <textarea
              name="Industry"
              rows="4"
              class={`${textareaStyle}`}
              placeholder=""
              value={formData["Industry"]}
              onChange={(event) => handleChangeInput(event)}
            ></textarea>
          </div>
          <button type="button" class={`${prevButtonStyle}`} onClick={prevStep}>
            Prev
          </button>
          <button
            type="button"
            class={`${nextButtonStyle}`}
            onClick={nextStep}
            disabled={!formData["Industry"]}
          >
            Next
          </button>
        </div>
      </>
    );
  }
  