import { React, Fragment, useState, useEffect, useRef, useContext, Listbox, Label, ListboxButton, ListboxOption, ListboxOptions } from "react";
import { Menu } from "@headlessui/react";
import { Switch } from '@headlessui/react'
import { FunnelIcon, TrashIcon, XMarkIcon, ChevronUpDownIcon, CheckIcon, ChevronDownIcon, EnvelopeIcon, ChatBubbleOvalLeftIcon, PhoneArrowUpRightIcon, LinkIcon, CloudArrowUpIcon } from "@heroicons/react/20/solid";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon, ClipboardDocumentListIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { currencyFormat, classNames } from "./helpers.js";
import Airtable from "airtable";
import Cookies from "js-cookie";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Select from 'react-select'
import DataUploader from "./DataUploader.js";
import LinkedInExportModal from './LinkedInExportModal';
import DataImportModal from './DataImportModal';
import ClipboardModal from './ClipboardModal';
import { setShowNotif, setContents, shortenText } from "./helpers";
import { CopyClipboard } from "flowbite";
import Notification from "./Notification.js";
import { AppContext } from "./AppContext.js";
import ReferredModal from "./ReferredModal.js";
import { ClipLoader } from 'react-spinners';
import { set } from "lodash";
import heroImage from './assets/upgrade-your-network-newsroom-hero-1120x630.jpg';

const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;

const people1 = [
    {
        id: 13,
        value: 'Daniel Schloss',
        label: 'Daniel Schloss',
        avatar:
            'https://media.licdn.com/dms/image/D4E03AQEPy6SB_StC4Q/profile-displayphoto-shrink_800_800/0/1674154777990?e=1723075200&v=beta&t=s1W7kWKqZMIwPvutn8moZ_hDEEv-tEKzbxKQ-_8xDsA',
    },
    {
        id: 14,
        value: 'Haris Raheel',
        label: 'Haris Raheel',
        avatar:
            'https://i.imgur.com/AvV2FSX.jpeg',
    },
    {
        id: 15,
        value: 'Alex Feldman',
        label: 'Alex Feldman',
        avatar:
            'https://media.licdn.com/dms/image/D4E03AQGLaxwC2j2UQQ/profile-displayphoto-shrink_800_800/0/1715391185719?e=1723075200&v=beta&t=2wNH5DSV99_xx8KMLgKvTUNVyQODx7n-uCESBPJUyjg',
    },
    {
        id: 2,
        value: 'Shaq Rahman',
        label: 'Shaq Rahman',
        avatar:
            'https://media.licdn.com/dms/image/D5603AQHsdwMw9zuotw/profile-displayphoto-shrink_200_200/0/1682904544984?e=1723680000&v=beta&t=06TiRqY6tB0w4ZOq2AAeB1OUcPzdESEkcftl30tbPTc',
    },
    {
        id: 2,
        value: 'Yu Hei Chan',
        label: 'Yu Hei Chan',
        avatar:
            'https://media.licdn.com/dms/image/D4D03AQFSJktyOvK6ow/profile-displayphoto-shrink_200_200/0/1683179647984?e=1723680000&v=beta&t=ewI__-s4tUMIQMVsPxcHqLKXFjD2WRjAigl99zBmY0g',
    },
    {
        id: 1,
        value: 'Sarah Schumacher',
        label: 'Sarah Schumacher',
        avatar:
            'https://media.licdn.com/dms/image/D5603AQG02L5jUZc7mg/profile-displayphoto-shrink_800_800/0/1689863043738?e=1723075200&v=beta&t=4dzUqxI93pOYbmhjc02KfN7bpomj4EOwRsTqOTuz6JU',
    },
    {
        id: 2,
        value: 'Kerem Tirmandi',
        label: 'Kerem Tirmandi',
        avatar:
            'https://media.licdn.com/dms/image/D5603AQGWcnTn0TvsJg/profile-displayphoto-shrink_400_400/0/1692826073695?e=1723075200&v=beta&t=4NDGLb-bieo6O1Xvyz5INzkpQYuOQz0-RaqVxIhfLvU',
    },
    {
        id: 3,
        value: 'Romain Pecard',
        label: 'Romain Pecard',
        avatar:
            'https://media.licdn.com/dms/image/C5603AQE7GYURJ6YFCA/profile-displayphoto-shrink_800_800/0/1654653657454?e=1723075200&v=beta&t=Ur6-JRG848ctUmT4gEMYrocjiGZDxHfh-wbc-4Yc_5Y',
    },
    {
        id: 4,
        value: 'Michael Williams',
        label: 'Michael Williams',
        avatar:
            'https://media.licdn.com/dms/image/C5603AQGI0z2ea28WiQ/profile-displayphoto-shrink_400_400/0/1516506635589?e=1723075200&v=beta&t=rafO3KSlP8VlnP2k6cMFewhxq1FgTEeY3QEdvELYGCc',
    },
    {
        id: 5,
        value: 'Kyle Williamson-Fox',
        label: 'Kyle Williamson-Fox',
        avatar:
            'https://i.imgur.com/UaXCcqm.jpeg',
    },
    {
        id: 6,
        value: 'Vedran Rasidajic',
        label: 'Vedran Rasidajic',
        avatar:
            'https://media.licdn.com/dms/image/C5603AQH0L-pP0m5O2Q/profile-displayphoto-shrink_800_800/0/1630359744660?e=1723075200&v=beta&t=tOdz9QQBprt8A-IFV5PE80SdhQcK92LxtzRS2j88eHg',
    },
    {
        id: 7,
        value: 'Derek Campbell',
        label: 'Derek Campbell',
        avatar:
            'https://i.imgur.com/9KZxKAc.jpeg',
    },
    {
        id: 8,
        value: 'Shimona Slomowitz',
        label: 'Shimona Slomowitz',
        avatar:
            'https://media.licdn.com/dms/image/C5603AQFT-5EeSk_qOw/profile-displayphoto-shrink_800_800/0/1631632351260?e=1723075200&v=beta&t=pazF0JiQZ4J6t-huDijyNpcZrFacTQZfC3lZrfoJ3Sg',
    },
    {
        id: 9,
        value: 'Colby Richardson',
        label: 'Colby Richardson',
        avatar:
            'https://media.licdn.com/dms/image/D4D03AQH0rhR8iSyfLg/profile-displayphoto-shrink_800_800/0/1683094782091?e=1723075200&v=beta&t=cCzZyodn4QZosHeXYkZ--FUOsZTyqIxwV57I8-Zv93Q',
    },
    {
        id: 10,
        value: 'Houtan Sanandaji',
        label: 'Houtan Sanandaji',
        avatar:
            'https://media.licdn.com/dms/image/D5603AQF7mGytCxjdQQ/profile-displayphoto-shrink_800_800/0/1696808259613?e=1723075200&v=beta&t=nn9JLvM1WJFVPBOqjCUn_Z7K8BMK85Jl6GGwRHs45xg',
    },
    {
        id: 11,
        value: 'Jameson Zimmer',
        label: 'Jameson Zimmer',
        avatar:
            'https://media.licdn.com/dms/image/C5603AQHcYGmOefJ-wg/profile-displayphoto-shrink_800_800/0/1582682922429?e=1723075200&v=beta&t=yFIMFb4l8UOkQ_Gt_x8nntYEmS8y46uVJfLhJcd-eiY',
    },
    {
        id: 12,
        value: 'Matthew Parson',
        label: 'Matthew Parson',
        avatar:
            'https://i.imgur.com/B0d16ts.jpeg',
    }
]

function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
        </svg>
    );
}

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const clayApiKey = "80bf797298733a979683";
const base = new Airtable({ apiKey }).base(baseId);
const peopleTable = "tbl3evf4TogmErSt6"

export default function MyNetwork({ }) {
    const [notifications, setNotifications] = useState([]);
    const [copyClipboardNotif, setCopyClipboardNotif] = useState({
        showNotif: false,
        contents: { type: "", heading: "", message: "" },
    });
    const { clerkUser: user, companies, grantLoading, setGrantLoading } = useContext(AppContext);

    const [selected, setSelected] = useState(people1[3])
    const [people, setPeople] = useState([]);
    const cancelButtonRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [numberValue, setNumberValue] = useState(231);
    const [enabled, setEnabled] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isClipModalOpen, setIsClipModalOpen] = useState(false);
    const [isReferredModalOpen, setIsReferredModalOpen] = useState(false);
    const [isDataModalOpen, setIsDataModalOpen] = useState(false);
    const [copySuccess, setCopySuccess] = useState('');
    const [newPerson, setnewPerson] = useState('');
    const [referralInfo, setReferralInfo] = useState({
        referred: 0,
        referrable: 0,
        unreferrable: 0
    });
    const [referralCount, setReferralCount] = useState(0);
    const loginEmail = user?.primaryEmailAddress?.emailAddress || 'will@getupgraded.ca'
    const [userData, setUserData] = useState({});

    // New state to hold an array of referred domains and emails
    const [referralList, setReferralList] = useState([]);

    useEffect(() => {
        if (companies.length > 0) {
            setGrantLoading(false);

            if (people.length > 0) {
                // Initialize an array to hold the referrable domains and emails
                const referrableArray = [];

                // Update referralInfo based on the people array
                const updatedReferralInfo = people.reduce((acc, person) => {
                    const hasInfo = !!person.fields.Website && !!person.fields.Email && !!person.fields.Company; // Check if the person has a website

                    // Check if the person is referred, only if company has reportingEmails
                    const isReferred = companies.some(company => company.fields.Domain === person.fields.Website && company.fields.reportingEmails);

                    if (isReferred) {
                        acc.referred += 1; // Count as referred if there's a matching domain and reportingEmails
                    } else if (hasInfo) {
                        acc.referrable += 1; // Count as referrable if they have a website but aren't referred

                        referrableArray.push({
                            domain: person.fields.Website,
                            email: person.fields.Email,
                            company: person.fields.Company
                        });
                    } else {
                        acc.unreferrable += 1; // Count as unreferrable if they don't have a website
                    }

                    return acc;
                }, {
                    referred: 0,
                    referrable: 0,
                    unreferrable: 0
                });

                // Update the state with referral info and the referrable array
                setReferralInfo(updatedReferralInfo);
                setReferralList(referrableArray); // Set the referralList state with referrable data
            }
        } else {
            setGrantLoading(true);
        }
    }, [companies, people])

    // temporary code for testing, will remove later
    // ----------------------------------------------
    let userEmail;
    // let testEmail = 'will@getupgraded.ca'
    // if (process.env.NODE_ENV === 'production') {
    //     userEmail = user.primaryEmailAddress.emailAddress;
    //     console.log("userEmail production", userEmail)
    // } else {
    //     userEmail = user.primaryEmailAddress.emailAddress;
    //     console.log("userEmail not in production", userEmail)
    // }

    if (user && user.primaryEmailAddress) {
        userEmail = user.primaryEmailAddress.emailAddress;
        console.log("userEmail extra", userEmail);
    }
    // ----------------------------------------------

    const handleCopy = (person) => {
        console.log(person, "person")

        const textToCopy = `Hi ${person.fields.FirstName}, I'd like to share a way to find money you didn't know about before, it's called Upgraded. They work entirely on a contingency basis. They've done over $9.4m in grants, vendor savings and automations at an ~84% success rate and your report shows you have over $105,231/yr in savings or $8,769/mo. Kindly see this link to your report below, would love https://app.getupgraded.ca/?website=${person.fields.Website}`;
        navigator.clipboard.writeText(textToCopy).then(
            () => {
                setCopySuccess('Copied to clipboard!');
            },
            (err) => {
                setCopySuccess('Failed to copy text.');
            }
        );

        // console.log(setCopyClipboardNotif)
        setShowNotif(true, setCopyClipboardNotif);
        const contents = { type: "success", heading: "Copied to clipboard!", message: "You're armed with the right thing to say, spread the love." }
        console.log(contents)
        setContents(contents, setCopyClipboardNotif);
        // console.log(copyClipboardNotif, "2")
    };

    const handleOpenModal = () => {
        console.log("true")
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenDataModal = () => {
        console.log("true")
        setIsDataModalOpen(true);
    };

    const handleCloseDataModal = () => {
        setIsDataModalOpen(false);
    };

    const handleOpenClipModal = () => {
        console.log("true")
        setIsClipModalOpen(true);
    };

    const handleCloseClipModal = () => {
        setIsClipModalOpen(false);
    };

    const handleOpenReferredModal = () => {
        console.log("true")
        setIsReferredModalOpen(true);
    };

    const handleCloseReferredModal = () => {
        setIsReferredModalOpen(false);
    };

    const statuses = { Completed: 'text-green-400 bg-green-400/10', Error: 'text-rose-400 bg-rose-400/10' }

    useEffect(() => {
        // Only run the effect if userEmail is loaded
        if (userEmail) {
            base("Referrals")
                .select({
                    filterByFormula: `{UploadedBy} = '${userEmail}'`,
                    maxRecords: 500,
                    sort: [
                        { field: "Last Modified", direction: "desc" }
                    ]
                })
                .all()
                .then((records) => {
                    console.log("records", records);
                    setPeople(records);
                    setNumberValue(records.length);
                })
                .catch(error => {
                    console.error("Error loading records: ", error);
                });
        }
    }, [userEmail]); // Dependency array includes userEmail

    const extractDomainFromEmail = (emailText) => {
        if (!emailText) {
            return "no email";
        }
        const emailRegex = /@([^\s@]+)/;
        const match = emailText.match(emailRegex);
        return match ? match[1] : "no email";
    };

    const handleSelectChange = (selected) => {
        console.log("selected", selected)
        setSelectedOption(selected);
        if (selected) {
            setNumberValue(Math.random() * 300 + 100);
        } else {
            setNumberValue(231); // Default value if no option is selected
        }
    };


    // TODO: Enrich NEXT 20 - see EnrichConnection method, takes a person
    const handleReferralProcess = () => {
        // Take the people object (all referrals) and filter out 2 that haven't been enriched yet
        let unEnrichedPeople = [];
        // Enriched does not equal "Yes"
        unEnrichedPeople = people.filter((person) => person.fields.Enriched != "Yes").slice(0, 20);

        unEnrichedPeople.forEach((person) => {
            console.log(person)
            enrichConnection(person);
        })

        // TODO: ADD THE REFERAL PARAM TO EACH COMPANY FROM THE USER OBJECT
        // 1. Get the user object
        // 2. Get the referral param
        // 3. Add the referral param to each company below
    
        // For each, run enrich connection for each person

        referralList.forEach((referral) => {
            // Creating a new empty record for JIT companies.
            // This record contains the Domain of the Company, as well as some default values for different fields.
            fetch('https://api.airtable.com/v0/' + baseId + '/' + companiesTableId, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${apiKey}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    fields: {
                        Name: referral.company,
                        Domain: referral.domain,
                        Industry: 'Technology',
                        Technologies: 'N/A',
                        RequestEmail: referral.email,
                        ProvinceOrState: 'ON',
                        Address: 'Toronto, Ontario',
                        Employees: 10,
                        Country: 'CA',
                        Founded: 2000,
                        AnnualRevenue: 1000000,
                        AvgDealSize: 10000,
                        ConversionRate: 2,
                        Debt: 100000,
                        AnnualTraffic: 10000,
                        OperationalCosts: 500000,
                        ListSize: 10000,
                        InterestedIn:
                            'CanExport SME,DS4Y,Student Work Placement Program,MITACS Business Strategy Internship,Canada Job Grant (CJG),Canada Small Business Financing Program (CSBFP)',
                        Activity: JSON.stringify({
                            Grants: [],
                            'Tech Discounts': [],
                            Automations: {
                                excludePeople: [],
                                personMap: {},
                            },
                            LatestActivity: 'n/a',
                        }),
                        'subscribed?': 'false',
                        blurMe: 'true',
                        reportingEmails: 'true',
                        sendFrequency: 7,
                        // bccEmails: 'will@getupgraded.ca',
                        fromName: 'Will Richman',
                        People1Name: 'there',
                        lastSent: '04/25/2024',
                        // ReferralParam: "?grsf=shane-yk88ra"
                        ReferralParam: userData.ReferralParam
                    },
                }),
            })
                .then((response) => response.json())
                .then((data) => console.log("Created 'Empty' Record in database", data))
                .catch((error) => console.error('Error:', error));


            try {
                fetch(
                    `https://mycorsproxy-tuto-35940f676099.herokuapp.com/https://api.clay.com/v3/sources/webhook/${process.env.REACT_APP_CLAY_ENRICH}`,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${clayApiKey}`,
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Headers':
                                'Origin, X-Requested-With, Content-Type, Accept',
                        },
                        body: JSON.stringify({
                            email: 'williamrichman@gmail.com',
                            domain: referral.domain,
                        }),
                    }
                )
                    .then((response) => {
                        console.log('api call successful');
                    })
                    .catch((err) => {
                        console.log('this was the problem', err);
                    });
                // console.log(`❗Finished fetching for ${website} ❗`);
            } catch (error) {
                console.error('Error in Fetching JIT: ', error);
            }

            // Update referral count using functional setState
            setReferralCount((prevCount) => prevCount + 1);
        })

    };

    // function that adds linkedin and other info to person object
    function enrichConnection(person) {
        console.log("enrichConnection", person)
        // alert("You've enriched " + person.fields.FirstName + " " + person.fields.LastName);

        setShowNotif(true, setCopyClipboardNotif);
        const contents = { type: "success", heading: "Started Enrichment!", message: `You've enriched ${person.fields.FirstName + " " + person.fields.LastName}` }
        console.log(contents)
        setContents(contents, setCopyClipboardNotif);

        try {
            fetch(
                `https://mycorsproxy-tuto-35940f676099.herokuapp.com/https://api.clay.com/v3/sources/webhook/pull-in-data-from-a-webhook-7e45f723-9ec9-4222-94e1-d3d4689e5a1b`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${clayApiKey}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    },
                    body: JSON.stringify({
                        PersonalLinkedIn: person.fields.PersonalLinkedIn,
                        Id: person.id,
                        Enriched: "yes"
                    }),
                }
            )
                // next step -> go to useEffect with domainAdded as a dependency
                .then((response) => {
                    console.log("response", response);
                    // alert("It worked! It's just a matter of time, you'll need to refresh the page.");
                    setShowNotif(true, setCopyClipboardNotif);
                    const contents = { type: "success", heading: "Request Received!", message: `It'll take 3 - 5 minutes and you'll be set to see more information for ${person.fields.FirstName + " " + person.fields.LastName}` }
                    console.log(contents)
                    setContents(contents, setCopyClipboardNotif);
                    // setPersonAdded(e.target["personLinkedInUrl"].value);
                });
        } catch (error) {
            console.error("Error in Fetching: ", error);
        }
    }

    const handleDelete = removedPerson => {
        setPeople(prevPeople => prevPeople.filter(person => person.id !== removedPerson.id));
    };

    // add people through input field
    function addNewPeople(e) {
        e.preventDefault();

        fetch("https://api.airtable.com/v0/" + baseId + "/" + peopleTable, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${apiKey}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                fields: {
                    PersonalLinkedIn: e.target["personLinkedInUrl"].value,
                    UploadedBy: userEmail,
                },
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("this is what happened", data);
                // IF ERRORS, ITS BECAUSE WE SET THIS TO THE WRONG VALUE, COULD ADD ENRICH HERE
                setnewPerson(data)
                setShowNotif(true, setCopyClipboardNotif);
                const contents = { type: "success", heading: "Started Enrichment!", message: `You've enriched ${e.target["personLinkedInUrl"].value}, your record will be enriched and added in < 2 mins` }
                console.log(contents)
                setContents(contents, setCopyClipboardNotif);
            })
            .catch((error) => console.error("Error the person was not added to airtable:", error));

        try {
            fetch(
                `https://mycorsproxy-tuto-35940f676099.herokuapp.com/https://api.clay.com/v3/sources/webhook/pull-in-data-from-a-webhook-7e45f723-9ec9-4222-94e1-d3d4689e5a1b`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${clayApiKey}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    },
                    body: JSON.stringify({
                        PersonalLinkedIn: e.target["personLinkedInUrl"].value,
                        Id: newPerson.id
                    }),
                }
            )
                // next step -> go to useEffect with domainAdded as a dependency
                .then((response) => {
                    console.log("response - it worked!", response);
                    // setPersonAdded(e.target["personLinkedInUrl"].value);
                });
        } catch (error) {
            console.error("Error in Fetching: ", error);
        }
    }

    // dummy data
    const deals = [
        {
            "name": "AWS",
            "title": "Amazon's cloud services platform",
            "imageUrl": "https://cdn.prod.website-files.com/619c5a34100fe677d0c61e04/61a9a2f67ce25b0154a2cd81_AWS-Logo.png"
        },
        {
            "name": "Notion",
            "title": "Organize teamwork and increase productivity",
            "imageUrl": "https://res.cloudinary.com/secretsaas/image/upload/c_fit,f_auto,h_80,q_auto,w_80/v1677062676/logo/Notion.png"
        },
        {
            "name": "Airtable",
            "title": "Easily store and organize your data",
            "imageUrl": "https://res.cloudinary.com/secretsaas/image/upload/c_fit,f_auto,h_80,q_auto,w_80/v1681200667/logo/Airtable.png"
        },
        {
            "name": "Typeform",
            "title": "Create beautiful forms and surveys that actually get responses",
            "imageUrl": "https://res.cloudinary.com/secretsaas/image/upload/c_fit,f_auto,h_80,q_auto,w_80/v1651502917/logo/Typeform.jpg"
        },
        {
            "name": "Google Cloud",
            "title": "Cloud services by Google",
            "imageUrl": "https://res.cloudinary.com/secretsaas/image/upload/c_fit,f_auto,h_80,q_auto,w_80/v1638533805/logo/Google%20Cloud.png"
        },
        {
            "name": "Segment",
            "title": "Collect, clean, and control customer data",
            "imageUrl": "https://img.logo.dev/segment.com?token=live_6a1a28fd-6420-4492-aeb0-b297461d9de2&size=200&format=jpg&greyscale=false"
        },
        {
            "name": "Stripe",
            "title": "Online payment processing for internet businesses",
            "imageUrl": "https://img.logo.dev/stripe.com?token=live_6a1a28fd-6420-4492-aeb0-b297461d9de2&size=200&format=jpg&greyscale=false"
        },
        {
            "name": "Intercom",
            "title": "Customer messaging platform for sales, marketing, and support",
            "imageUrl": "https://img.logo.dev/intercom.com?token=live_6a1a28fd-6420-4492-aeb0-b297461d9de2&size=200&format=jpg&greyscale=false"
        },
        {
            "name": "HubSpot",
            "title": "Marketing, sales, and service software that helps your business grow",
            "imageUrl": "https://img.logo.dev/hubspot.com?token=live_6a1a28fd-6420-4492-aeb0-b297461d9de2&size=200&format=jpg&greyscale=false"
        },
    ]

    // assign value to state after import
    const handleUploadComplete = async (uploadedRecords) => {
        const newRecords = await base('Referrals').select({
            pageSize: uploadedRecords.length,
            sort: [{ field: 'Created', direction: 'desc' }],
        }).firstPage();
        setPeople((prevRecords) => [...newRecords, ...prevRecords]);
    };

    // dummy data
    const steps = [
        { id: '01', name: 'Export Network', description: 'Vitae sed mi luctus laoreet.', href: '#', status: 'complete' },
        { id: '02', name: 'Import Network', description: 'Vitae sed mi luctus laoreet.', href: '#', status: 'current' },
        { id: '03', name: 'Refer & Earn', description: 'Vitae sed mi luctus laoreet.', href: '#', status: 'upcoming' },
    ]

    const getInitials = (firstName, lastName) => {
        return `${firstName[0] ?? ''}${lastName[0] ?? ''}`;
    };

    console.log("user", user)

    useEffect(() => {
        const fetchUser = async () => {

            const tableName = 'users'
            const url = `https://api.airtable.com/v0/${baseId}/${tableName}?filterByFormula={Email}='${encodeURIComponent(loginEmail)}'`;

            const headers = {
                Authorization: `Bearer ${apiKey}`,
                'Content-Type': 'application/json'
            };

            try {
                const response = await fetch(url, { headers });
                if (!response.ok) throw new Error('Network response was not ok');

                const data = await response.json();
                // Assuming there is only one user with this email, otherwise you might need additional handling
                const userData = data.records.length > 0 ? data.records[0].fields : null;
                console.log("user data",userData);
                setUserData(userData);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchUser();
    }, [loginEmail]);

    // jsx
    return (
        <>
            <div className="bg-white">
                <div className="relative">
                    {/* Decorative image and overlay */}
                    <div aria-hidden="true" className="absolute inset-0 overflow-hidden" style={{ backgroundColor: '#849E96' }} >
                        {/* <img
                            src={heroImage}
                            alt=""
                            className="h-full w-full object-cover object-center"
                        /> */}
                    </div>

                    <div className="relative mx-auto flex flex-col items-center px-6 py-16 text-center sm:py-12 lg:px-0">
                        <h1 className="text-3xl font-bold tracking-tight text-white lg:text-5xl">Upgrade Your Network, Anonymously</h1>
                        <p className="mt-4 text-xl text-white">
                            Earn <strong>up to 30% or $6,287 avg per referral</strong> for <strong>5 mins</strong> | Up to <strong>{currencyFormat(numberValue * 6287)}/yr</strong> with the <strong>{Math.round(numberValue)} referrals</strong> below.
                        </p>
                        {/* <a
                            href="https://www.getupgraded.ca/partner-with-upgraded"
                            target="_blank"
                            className="mt-8 inline-block rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-gray-900 hover:bg-gray-100"
                        >
                            Refer & Earn Details
                        </a> */}
                        <form className="space-y-6 px-3 -mt-2 pb-4" method="POST" onSubmit={addNewPeople}>
                            <label htmlFor="email" className="sr-only">
                                Linkedin Url
                            </label>
                            <input
                                type="text"
                                name="personLinkedInUrl"
                                id="personLinkedInUrl"
                                className="inline rounded-md rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-600 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 w-full sm:w-80"
                                placeholder="Add a personal linkedin url"
                            />
                            <button
                                type="submit"
                                className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-white bg-black ring-1 ring-inset ring-gray-300 hover:bg-gray-800"
                            >
                                Add
                            </button>
                        </form>
                        <button class="bg-transparent hover:bg-gray-800 text-white font-semibold hover:text-white py-2 px-4 border border-white hover:border-transparent rounded" onClick={handleOpenDataModal}>
                            Import & Invite All - {userData.ReferralParam}
                        </button>
                    </div>
                </div>
            </div>

            <nav className="mx-auto max-w-7xl" aria-label="Progress">
                <ol role="list" className="overflow-hidden rounded-md lg:flex lg:rounded-none">
                    {/* First step: Complete */}
                    <li className="relative overflow-hidden lg:flex-1">
                        <div className="rounded-t-md overflow-hidden border border-gray-200 lg:border-0">
                            <span className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full" aria-hidden="true" />
                            <span className="flex items-start px-6 py-5 text-sm font-medium">
                                <span className="flex-shrink-0">
                                    <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-600">
                                        <span className="text-gray-600">01</span>
                                    </span>
                                </span>
                                <span className="ml-4 mt-0.5 flex min-w-0 flex-col text-sm font-medium text-gray-500">
                                    <span className="text-sm font-medium">Export Network</span>
                                    <span className="text-sm font-medium text-gray-500"><button className="text-blue-500" onClick={handleOpenModal}>How To Guide</button> or <a className="text-blue-500" target="_blank" href="https://www.linkedin.com/mypreferences/d/download-my-data">Export Your Data</a>.</span>
                                </span>
                            </span>

                        </div>
                    </li>

                    {/* Second step: Current */}
                    <li className="relative overflow-hidden lg:flex-1">
                        <div className="overflow-hidden lg:border-0">
                            <span className="absolute left-0 top-0 h-full w-1 bg-transparent lg:bottom-0 lg:top-auto lg:h-1 lg:w-full" aria-hidden="true" />
                            <span className="flex items-start px-6 py-5 text-sm font-medium">
                                <span className="flex-shrink-0">
                                    <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-600">
                                        <span className="text-gray-600">02</span>
                                    </span>
                                </span>
                                <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                                    <span className="text-sm font-medium text-gray-600">Import Network</span>
                                    <span className="text-sm font-medium text-gray-500"><button className="text-blue-500" onClick={handleOpenDataModal}>Upload</button> & we'll enrich to easily reach out.</span>
                                </span>
                            </span>
                        </div>
                    </li>
                    <DataImportModal
                        isOpen={isDataModalOpen}
                        onClose={handleCloseDataModal}
                        user={user}
                        onUploadComplete={handleUploadComplete}
                        copyClipboardNotif={copyClipboardNotif}
                        setCopyClipboardNotif={setCopyClipboardNotif}
                        setNotifications={setNotifications}
                    />


                    {/* Third step: Upcoming */}
                    <li className="relative overflow-hidden lg:flex-1">
                        <div className="rounded-b-md overflow-hidden border border-gray-200 lg:border-0">
                            <span className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full" aria-hidden="true" />
                            <span className="flex items-start px-6 py-5 text-sm font-medium">
                                <span className="flex-shrink-0">
                                    <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-600">
                                        <span className="text-gray-500">03</span>
                                    </span>
                                </span>
                                <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                                    <span className="text-sm font-medium text-gray-500">Refer & Earn <InformationCircleIcon className="h-4 w-4 inline" /></span>
                                    <span className="text-sm font-medium text-gray-500">Message using action buttons below.</span>
                                </span>
                            </span>

                        </div>
                    </li>
                </ol>
            </nav>
            <ReferredModal
                isOpen={isReferredModalOpen}
                onClose={handleCloseReferredModal}
                referralInfo={referralInfo}
                referralCount={referralCount}
            />
            <LinkedInExportModal isOpen={isModalOpen} onClose={handleCloseModal} />
            <ClipboardModal
                isOpen={isClipModalOpen}
                onClose={handleCloseClipModal}
            />
            <div className="bg-white">
                {/* <div className="mx-auto text-center">
                    <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Upgrade Your Network ✌️</h2>
                    <p className="mt-6 text-lg leading-8 text-gray-900">
                        Partner with Upgraded to help your network save $1,000,000's and make +$100,000/yr in the process barely lifting a finger.
                    </p>
                    <p className="text-xl my-2"> <span className="text-black font-bold">Guarantees & Payouts</span><br /> <span className="text-black font-bold">1) Software:</span> $10,000/year found or its free | up to $626.94/yr per user (30%) <br /> <span className="text-black font-bold">2) Managed Service:</span> $57,000/year found or we work for free | up to $1,710/yr per managed service client (15%) </p>
                </div>
                <br />
                {/* <!-- Onboarding Section --> */}
                <div>
                    <div className="px-4 sm:px-6 lg:px-8">
                        {/* Old Header */}
                        {/* <div class="grid grid-cols-2 gap-4">
                            <Select
                                options={people1}
                                className="max-w-[300px]"
                                onChange={handleSelectChange}
                                styles={{
                                    menu: (provided) => ({
                                        ...provided,
                                        marginTop: '-20px', // Adjust the distance here
                                    }),
                                    control: (provided) => ({
                                        ...provided,
                                        marginTop: '10px', // Adjust the margin from top if needed
                                    }),
                                }}
                                formatOptionLabel={person => (
                                    <div className="person-option">
                                        <img src={person.avatar} alt="person-image" style={{ width: '20px', height: '20px', marginTop: '3px', marginRight: '10px', float: "left", borderRadius: "50%" }} />
                                        <span>{person.label}</span>
                                    </div>
                                )}
                            />
                            <h2 className="text-3xl font-bold text-gray-900 sm:text-3xl text-right">
                                Earn up to {currencyFormat(numberValue * 1710)}/yr
                                <br />
                                <span className="text-xl">with the {Math.round(numberValue)} referrals below</span>
                            </h2>
                        </div> */}
                        <br />
                        {/* <div className="container mx-auto p-4">
                            <div className="grid grid-cols-3 gap-4">
                                <div className="col-span-2">
                                    <h1 className="text-base font-semibold leading-6 text-gray-900">Upgrade Your Canadian Network</h1>
                                    <p className="mt-2 text-sm text-gray-700">
                                        1. Export your LinkedIn network: <button className="text-blue-500" onClick={handleOpenModal}>How To Guide</button> or <a className="text-blue-500" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/mypreferences/d/download-my-data">Straight to Export Your Data</a>
                                        <br />
                                        2. Upload your LinkedIn connections and we'll enrich them so you can easily reach out
                                        <br />
                                        3. Invite and earn from referrals by clicking the action buttons below
                                    </p>
                                    <div className="mt-4">
                                        <a href="#referral-email">
                                            <button
                                                type="button"
                                                className="rounded-md bg-green-500 shadow-xl px-3 py-2 text-sm font-semibold text-white hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-700"
                                            >
                                                See example email
                                            </button>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <DataUploader
                                        user={user}
                                        onUploadComplete={handleUploadComplete}
                                        copyClipboardNotif={copyClipboardNotif}
                                        setCopyClipboardNotif={setCopyClipboardNotif}
                                        setNotifications={setNotifications}
                                    />
                                </div>
                                <LinkedInExportModal isOpen={isModalOpen} onClose={handleCloseModal} />
                                <ClipboardModal
                                    isOpen={isClipModalOpen}
                                    onClose={handleCloseClipModal}
                                />
                            </div>
                        </div> */}

                        {/* Table */}
                        <div className="flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr className="h-10">
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-0">
                                                    Name & Website
                                                </th>
                                                <th scope="col" className="px-1 py-3.5 text-left text-sm font-semibold text-gray-900 max-w-[180px]">
                                                    <a href="#" className="group inline-flex">
                                                        Company & Title
                                                        <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                                                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    </a>
                                                </th>
                                                <th scope="col" className="px-1 py-3.5 text-left text-sm font-semibold text-gray-900 max-w-[90px]">
                                                    <a href="#" className="group inline-flex">
                                                        Location
                                                        <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                                                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    </a>
                                                </th>
                                                <th scope="col" className="px-1 py-3.5 text-left text-sm font-semibold text-gray-900 max-w-[100px]">
                                                    Headcount
                                                </th>
                                                <th scope="col" className="px-1 py-3.5 text-left text-sm font-semibold text-gray-900 max-w-[120px]">
                                                    Est $ Earned
                                                </th>
                                                <th scope="col" class="px-1 py-3.5 text-left text-sm font-semibold text-gray-900 flex items-center justify-center">
                                                    <a href="#referral-email">Refer & Earn | Invite All</a>
                                                    <Switch
                                                        checked={enabled}
                                                        onChange={setEnabled}
                                                        className={classNames(
                                                            enabled ? 'bg-green-500' : 'bg-gray-200',
                                                            'relative inline-flex ml-2 h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2',
                                                        )}
                                                    >
                                                        <span className="sr-only">Use setting</span>
                                                        <span
                                                            aria-hidden="true"
                                                            className={classNames(
                                                                enabled ? 'translate-x-5' : 'translate-x-0',
                                                                'mb-2 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                                                            )}
                                                        />
                                                    </Switch>

                                                    <button
                                                        className="ml-6 border rounded-md bg-green-500 px-4 py-2 text-sm font-medium text-white shadow hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                                        onClick={() => {
                                                            handleOpenReferredModal();
                                                            handleReferralProcess();
                                                        }}
                                                    >
                                                        Invite All
                                                    </button>
                                                    {/* {copySuccess && <p className="mt-2 text-green-500">{copySuccess}</p>} */}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {grantLoading ? <tr>
                                                <td colSpan={7} className="text-center">
                                                    <div className="flex justify-center items-center py-4">
                                                        <ClipLoader />
                                                    </div>
                                                </td>
                                            </tr> : people
                                                .filter(person => person.fields.UploadedBy === userEmail || person.fields.UploadedBy === "team@getupgraded.ca")
                                                .sort((a, b) =>
                                                    (!a.fields.Enriched && b.fields.Enriched ? -1 : (a.fields.Enriched && !b.fields.Enriched ? 1 : 0))
                                                )
                                                // .sort((a, b) => {
                                                //     // If both have a website or both don't have a website, sort by Headcount
                                                //     if (a.fields.Headcount !== b.fields.Headcount) {
                                                //         return b.fields.Headcount - a.fields.Headcount;
                                                //     }
                                                //     return 0;
                                                // })
                                                .map((person) => (
                                                    <tr key={person.Id}>
                                                        <td className="py-5 px-5 text-sm max-w-[150px]">
                                                            <div className="flex items-center">
                                                                {person.fields.ProfilePic ? (
                                                                    <img className="h-11 w-11 rounded-full" src={person.fields.ProfilePic} alt={`${person.fields.FirstName} ${person.fields.LastName}`} />
                                                                ) : (
                                                                    <div className="h-10 w-11 rounded-full bg-[#7CA5CF] flex items-center justify-center text-gray-100 font-bold">
                                                                        {getInitials(person.fields.FirstName, person.fields.LastName)}
                                                                    </div>
                                                                )}
                                                                <div className="ml-2">
                                                                    <div className="font-medium text-gray-700 text-wrap max-w-[90px]">
                                                                        {person.fields.FirstName} {person.fields.LastName}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 text-gray-500 text-wrap max-w-[170px] break-words text-xs text-center text-wrap">
                                                                {person.fields.Email}
                                                            </div>
                                                        </td>
                                                        <td className="whitespace-wrap px-1 py-3 text-sm text-gray-500 max-w-[185px] text-wrap">
                                                            <div className="max-w-xs text-wrap text-gray-800">{person.fields.Company}</div>
                                                            <div className="mt-1 max-w-xs text-wrap text-gray-500 text-xs">{person.fields.Position}</div>
                                                            <div className="mt-1 max-w-xs text-wrap text-gray-500 text-xs">{person.fields.Website}</div>
                                                        </td>
                                                        <td className="whitespace-wrap px-1 py-3 text-wrap text-xs text-gray-500 max-w-[150px]">
                                                            <p>{person.fields.City}</p>
                                                        </td>
                                                        <td className="py-4 pl-0 pr-4 text-sm leading-6 max-w-[100px] align-center text-center">
                                                            <div className="text-center justify-end gap-x-2 sm:justify-start text-gray-600">
                                                                {person.fields.Headcount}
                                                            </div>
                                                        </td>
                                                        <td className="whitespace-nowrap px-1 py-3 text-sm font-bold text-gray-800 max-w-[150px]">
                                                            $6,287/yr
                                                        </td>
                                                        <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-sm font-medium sm:pr-0 flex items-center h-24">
                                                            <button
                                                                // onClick={handleOpenClipModal}
                                                                onClick={() => { handleOpenClipModal(); handleCopy(person); }}
                                                                className="text-gray-400 px-2 py-2 mr-1 rounded hover:text-gray-600"
                                                            >
                                                                <ClipboardDocumentListIcon className="h-5 w-5" />
                                                            </button>
                                                            <a href={`https://app.getupgraded.ca/?website=${(person.fields.Website)}`} target="_blank">
                                                                <button
                                                                    type="button"
                                                                    className={`rounded mr-1 px-2 py-1 text-lg font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 ${person.fields.Website ? "bg-green-500 hover:bg-green-600" : "bg-gray-500"}`}
                                                                >
                                                                    View Report
                                                                </button>
                                                            </a>
                                                            <a
                                                                href={person.fields.PersonalLinkedIn || "#"}
                                                                className={person.fields.PersonalLinkedIn ? "text-blue-500 hover:text-blue-700" : "text-gray-500 hover:text-gray-700"}
                                                                target={person.fields.PersonalLinkedIn ? "_blank" : "_self"}
                                                                rel="noopener noreferrer"
                                                            >
                                                                <button
                                                                    type="button"
                                                                    data-twe-ripple-color="light"
                                                                    className={`inline-block rounded px-3 py-2 font-bold leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg flex items-center ${person.fields.PersonalLinkedIn ? "bg-[#0077b5]" : "bg-gray-500"}`}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512" class="h-5 w-5">
                                                                        <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
                                                                    </svg>
                                                                    {/* <span class="ml-2">Send</span> */}
                                                                </button>
                                                            </a>
                                                            {person.fields.Email ? (
                                                                <a
                                                                    href={`mailto:${person.fields.Email}?subject=Discover%20Savings%20with%20Upgraded&body=Hi%20there,%0A%0AI%27d%20like%20to%20share%20a%20way%20to%20find%20money%20you%20didn%27t%20know%20about%20before,%20it%27s%20called%20Upgraded.%20They%20work%20entirely%20on%20a%20contingency%20basis.%20They%27ve%20done%20over%20%249.4m%20in%20grants,%20vendor%20savings%20and%20automations%20at%20an%20~84%%20success%20rate%20and%20your%20report%20shows%20you%20have%20over%20%24105,231/yr%20in%20savings%20or%20%248,769/mo.%20Kindly%20see%20this%20link%20to%20your%20report%20below,%20would%20love%0A%0Ahttps://app.getupgraded.ca/?website=getupgraded.ca`}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <button
                                                                        type="button"
                                                                        className="flex items-center space-x-2 rounded-md bg-green-500 shadow-xl px-3 py-2 text-sm font-semibold text-white hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-700 ml-2"
                                                                    >
                                                                        <EnvelopeIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                                                    </button>
                                                                </a>
                                                            ) : (
                                                                <button
                                                                    type="button"
                                                                    className="flex items-center space-x-2 rounded-md bg-gray-500 shadow-xl px-3 py-2 text-sm font-semibold text-white hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-700 ml-2"
                                                                    disabled
                                                                >
                                                                    <EnvelopeIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                                                </button>
                                                            )}
                                                            {person.fields.MobileNumber ? (
                                                                <a href={`sms:${person.fields.MobileNumber}`}>
                                                                    <button
                                                                        type="button"
                                                                        className="flex items-center space-x-2 rounded-md bg-green-500 shadow-xl px-2 py-1 text-sm font-semibold text-white hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-700 ml-2"
                                                                    >
                                                                        <ChatBubbleOvalLeftIcon className="h-7 w-7 text-white" aria-hidden="true" />
                                                                    </button>
                                                                </a>
                                                            ) : (
                                                                <button
                                                                    type="button"
                                                                    className="flex items-center space-x-2 rounded-md bg-gray-500 shadow-xl px-2 py-1 text-sm font-semibold text-white hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-700 ml-2"
                                                                    disabled
                                                                >
                                                                    <ChatBubbleOvalLeftIcon className="h-7 w-7 text-white" aria-hidden="true" />
                                                                </button>
                                                            )}
                                                            <a href={`tel:${person.fields.MobileNumber}`}>
                                                                <button
                                                                    type="button"
                                                                    className={`items-center mx-2 space-x-2 rounded-md bg- shadow-xl px-2 py-1 text-sm font-semibold text-white  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-700 ml-2 ${person.fields.MobileNumber ? "bg-green-500 hover:bg-green-600" : "bg-gray-500"}`}
                                                                >
                                                                    <PhoneArrowUpRightIcon className="h-7 w-7 text-white" aria-hidden="true" />
                                                                </button>
                                                            </a>
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    enrichConnection(person)
                                                                    handleDelete(person)
                                                                }}
                                                                className={`rounded mr-1 px-2 py-1 text-lg font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 bg-green-500 hover:bg-green-600`}
                                                            >
                                                                Invite
                                                            </button>
                                                            {/* <Switch
                                                                checked={enabled}
                                                                onChange={setEnabled}
                                                                className={classNames(
                                                                    enabled ? 'bg-green-500' : 'bg-gray-200',
                                                                    'relative inline-flex mt-2 ml-2 h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2',
                                                                )}
                                                            >
                                                                <span className="sr-only">Use setting</span>
                                                                <span
                                                                    aria-hidden="true"
                                                                    className={classNames(
                                                                        enabled ? 'translate-x-5' : 'translate-x-0',
                                                                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                                                                    )}
                                                                />
                                                            </Switch> */}
                                                            {person.fields.Enriched ? (
                                                                <CloudArrowUpIcon
                                                                    className="mt-1 h-8 w-8 text-gray-200 hover:text-gray-300 hover:cursor-pointer"
                                                                />
                                                            ) : (
                                                                <CloudArrowUpIcon
                                                                    onClick={() => enrichConnection(person)}
                                                                    className="mt-1 h-8 w-8 text-green-500 hover:text-green-500 hover:cursor-pointer"
                                                                />
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br />
                <br />
                <Notification
                    showNotif={copyClipboardNotif.showNotif}
                    setShowNotif={(val) => setCopyClipboardNotif(val, setCopyClipboardNotif)}
                    contents={copyClipboardNotif.contents}
                />
            </div >

        </>
    );
}

MyNetwork.defaultProps = {
    admin: false,
};