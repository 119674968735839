import { React, Fragment, useState, useEffect, useRef, useContext, Listbox, Label, ListboxButton, ListboxOption, ListboxOptions } from "react";
import { Menu } from "@headlessui/react";
import { Switch } from '@headlessui/react'
import { FunnelIcon, TrashIcon, XMarkIcon, ChevronUpDownIcon, CheckIcon, ChevronDownIcon, EnvelopeIcon, ChatBubbleOvalLeftIcon, PhoneArrowUpRightIcon, LinkIcon, CloudArrowUpIcon } from "@heroicons/react/20/solid";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon, ClipboardDocumentListIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { currencyFormat, classNames } from "./helpers.js";
import Airtable from "airtable";
import Cookies from "js-cookie";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Select from 'react-select'
import DataUploader from "./DataUploader.js";
import LinkedInExportModal from './LinkedInExportModal';
import DataImportModal from './DataImportModal';
import ClipboardModal from './ClipboardModal';
import { setShowNotif, setContents, shortenText } from "./helpers";
import { CopyClipboard } from "flowbite";
import Notification from "./Notification.js";
import { AppContext } from "./AppContext.js";

const people1 = [
    {
        id: 13,
        value: 'Daniel Schloss',
        label: 'Daniel Schloss',
        avatar:
            'https://media.licdn.com/dms/image/D4E03AQEPy6SB_StC4Q/profile-displayphoto-shrink_800_800/0/1674154777990?e=1723075200&v=beta&t=s1W7kWKqZMIwPvutn8moZ_hDEEv-tEKzbxKQ-_8xDsA',
    },
    {
        id: 14,
        value: 'Haris Raheel',
        label: 'Haris Raheel',
        avatar:
            'https://i.imgur.com/AvV2FSX.jpeg',
    },
    {
        id: 15,
        value: 'Alex Feldman',
        label: 'Alex Feldman',
        avatar:
            'https://media.licdn.com/dms/image/D4E03AQGLaxwC2j2UQQ/profile-displayphoto-shrink_800_800/0/1715391185719?e=1723075200&v=beta&t=2wNH5DSV99_xx8KMLgKvTUNVyQODx7n-uCESBPJUyjg',
    },
    {
        id: 2,
        value: 'Shaq Rahman',
        label: 'Shaq Rahman',
        avatar:
            'https://media.licdn.com/dms/image/D5603AQHsdwMw9zuotw/profile-displayphoto-shrink_200_200/0/1682904544984?e=1723680000&v=beta&t=06TiRqY6tB0w4ZOq2AAeB1OUcPzdESEkcftl30tbPTc',
    },
    {
        id: 2,
        value: 'Yu Hei Chan',
        label: 'Yu Hei Chan',
        avatar:
            'https://media.licdn.com/dms/image/D4D03AQFSJktyOvK6ow/profile-displayphoto-shrink_200_200/0/1683179647984?e=1723680000&v=beta&t=ewI__-s4tUMIQMVsPxcHqLKXFjD2WRjAigl99zBmY0g',
    },
    {
        id: 1,
        value: 'Sarah Schumacher',
        label: 'Sarah Schumacher',
        avatar:
            'https://media.licdn.com/dms/image/D5603AQG02L5jUZc7mg/profile-displayphoto-shrink_800_800/0/1689863043738?e=1723075200&v=beta&t=4dzUqxI93pOYbmhjc02KfN7bpomj4EOwRsTqOTuz6JU',
    },
    {
        id: 2,
        value: 'Kerem Tirmandi',
        label: 'Kerem Tirmandi',
        avatar:
            'https://media.licdn.com/dms/image/D5603AQGWcnTn0TvsJg/profile-displayphoto-shrink_400_400/0/1692826073695?e=1723075200&v=beta&t=4NDGLb-bieo6O1Xvyz5INzkpQYuOQz0-RaqVxIhfLvU',
    },
    {
        id: 3,
        value: 'Romain Pecard',
        label: 'Romain Pecard',
        avatar:
            'https://media.licdn.com/dms/image/C5603AQE7GYURJ6YFCA/profile-displayphoto-shrink_800_800/0/1654653657454?e=1723075200&v=beta&t=Ur6-JRG848ctUmT4gEMYrocjiGZDxHfh-wbc-4Yc_5Y',
    },
    {
        id: 4,
        value: 'Michael Williams',
        label: 'Michael Williams',
        avatar:
            'https://media.licdn.com/dms/image/C5603AQGI0z2ea28WiQ/profile-displayphoto-shrink_400_400/0/1516506635589?e=1723075200&v=beta&t=rafO3KSlP8VlnP2k6cMFewhxq1FgTEeY3QEdvELYGCc',
    },
    {
        id: 5,
        value: 'Kyle Williamson-Fox',
        label: 'Kyle Williamson-Fox',
        avatar:
            'https://i.imgur.com/UaXCcqm.jpeg',
    },
    {
        id: 6,
        value: 'Vedran Rasidajic',
        label: 'Vedran Rasidajic',
        avatar:
            'https://media.licdn.com/dms/image/C5603AQH0L-pP0m5O2Q/profile-displayphoto-shrink_800_800/0/1630359744660?e=1723075200&v=beta&t=tOdz9QQBprt8A-IFV5PE80SdhQcK92LxtzRS2j88eHg',
    },
    {
        id: 7,
        value: 'Derek Campbell',
        label: 'Derek Campbell',
        avatar:
            'https://i.imgur.com/9KZxKAc.jpeg',
    },
    {
        id: 8,
        value: 'Shimona Slomowitz',
        label: 'Shimona Slomowitz',
        avatar:
            'https://media.licdn.com/dms/image/C5603AQFT-5EeSk_qOw/profile-displayphoto-shrink_800_800/0/1631632351260?e=1723075200&v=beta&t=pazF0JiQZ4J6t-huDijyNpcZrFacTQZfC3lZrfoJ3Sg',
    },
    {
        id: 9,
        value: 'Colby Richardson',
        label: 'Colby Richardson',
        avatar:
            'https://media.licdn.com/dms/image/D4D03AQH0rhR8iSyfLg/profile-displayphoto-shrink_800_800/0/1683094782091?e=1723075200&v=beta&t=cCzZyodn4QZosHeXYkZ--FUOsZTyqIxwV57I8-Zv93Q',
    },
    {
        id: 10,
        value: 'Houtan Sanandaji',
        label: 'Houtan Sanandaji',
        avatar:
            'https://media.licdn.com/dms/image/D5603AQF7mGytCxjdQQ/profile-displayphoto-shrink_800_800/0/1696808259613?e=1723075200&v=beta&t=nn9JLvM1WJFVPBOqjCUn_Z7K8BMK85Jl6GGwRHs45xg',
    },
    {
        id: 11,
        value: 'Jameson Zimmer',
        label: 'Jameson Zimmer',
        avatar:
            'https://media.licdn.com/dms/image/C5603AQHcYGmOefJ-wg/profile-displayphoto-shrink_800_800/0/1582682922429?e=1723075200&v=beta&t=yFIMFb4l8UOkQ_Gt_x8nntYEmS8y46uVJfLhJcd-eiY',
    },
    {
        id: 12,
        value: 'Matthew Parson',
        label: 'Matthew Parson',
        avatar:
            'https://i.imgur.com/B0d16ts.jpeg',
    }
]

// Daniel Schloss
// Haris Raheel
// Alex Feldman
// Sarah Schumacher - DONE
// Retirees
// Kerem Tirmandi - DONE
// Romain Pecard - DONE
// Michael Williams - DONE
// Kyle Williamson-Fox - DONE
// Vedran Rasidajic
// Derek Campbell
// Shimona Slomowitz
// Colby Richardson
// Houtan Sanandaji
// Jameson Zimmer
// Matthew Parson

// Later
// Ryan Richman
// Shane Richman
// Gord Richman
// Sean Schumacher

function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
        </svg>
    );
}

const apiKey = process.env.REACT_APP_AIR_KEY;
// const baseId = process.env.REACT_APP_AIR_BASEID;
const baseId = "appVulceDQ4SKlQoP"
const clayApiKey = "80bf797298733a979683";
const base = new Airtable({ apiKey }).base(baseId);
const peopleTable = "tbl3evf4TogmErSt6"

export default function GrantWriterNetwork({ }) {
    const [notifications, setNotifications] = useState([]);
    const [copyClipboardNotif, setCopyClipboardNotif] = useState({
        showNotif: false,
        contents: { type: "", heading: "", message: "" },
    });
    const { clerkUser: user } = useContext(AppContext);
    const [selected, setSelected] = useState(people1[3])
    const [people, setPeople] = useState([]);
    const cancelButtonRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [numberValue, setNumberValue] = useState(231);
    const [enabled, setEnabled] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isClipModalOpen, setIsClipModalOpen] = useState(false);
    const [isDataModalOpen, setIsDataModalOpen] = useState(false);
    const [copySuccess, setCopySuccess] = useState('');
    const [newPerson, setnewPerson] = useState('');

    const handleCopy = (person) => {
        console.log(person, "person")

        const textToCopy = `Hi ${person.fields.FirstName}, I'd like to share a way to find money you didn't know about before, it's called Upgraded. They work entirely on a contingency basis. They've done over $9.4m in grants, vendor savings and automations at an ~84% success rate and your report shows you have over $105,231/yr in savings or $8,769/mo. Kindly see this link to your report below, would love https://app.getupgraded.ca/?website=${person.fields.Website}`;
        navigator.clipboard.writeText(textToCopy).then(
            () => {
                setCopySuccess('Copied to clipboard!');
            },
            (err) => {
                setCopySuccess('Failed to copy text.');
            }
        );

        // console.log(setCopyClipboardNotif)
        setShowNotif(true, setCopyClipboardNotif);
        const contents = { type: "success", heading: "Copied to clipboard!", message: "You're armed with the right thing to say, spread the love." }
        console.log(contents)
        setContents(contents, setCopyClipboardNotif);
        // console.log(copyClipboardNotif, "2")
    };

    const handleOpenModal = () => {
        console.log("true")
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenDataModal = () => {
        console.log("true")
        setIsDataModalOpen(true);
    };

    const handleCloseDataModal = () => {
        setIsDataModalOpen(false);
    };

    const handleOpenClipModal = () => {
        console.log("true")
        setIsClipModalOpen(true);
    };

    const handleCloseClipModal = () => {
        setIsClipModalOpen(false);
    };

    const statuses = { Completed: 'text-green-400 bg-green-400/10', Error: 'text-rose-400 bg-rose-400/10' }

    useEffect(() => {
        base("grant_writers_profiles")
            .select({
                maxRecords: 20,
                filterByFormula: `FIND("grant", LOWER({Title}))`  // Adds case-insensitive searching for "grant" in the "Title" field
            })
            .all()
            .then((records) => {
                console.log("records", records)
                setPeople(records);
                setNumberValue(records.length)
            });
    }, []);

    const extractDomainFromEmail = (emailText) => {
        if (!emailText) {
            return "no email";
        }
        const emailRegex = /@([^\s@]+)/;
        const match = emailText.match(emailRegex);
        return match ? match[1] : "no email";
    };

    const handleSelectChange = (selected) => {
        console.log("selected", selected)
        setSelectedOption(selected);
        if (selected) {
            setNumberValue(Math.random() * 300 + 100);
        } else {
            setNumberValue(231); // Default value if no option is selected
        }
    };

    function enrichConnection(person) {
        console.log("enrichConnection", person)
        // alert("You've enriched " + person.fields.FirstName + " " + person.fields.LastName);

        setShowNotif(true, setCopyClipboardNotif);
        const contents = { type: "success", heading: "Started Enrichment!", message: `You've enriched ${person.fields.FirstName + " " + person.fields.LastName}` }
        console.log(contents)
        setContents(contents, setCopyClipboardNotif);

        try {
            fetch(
                `https://mycorsproxy-tuto-35940f676099.herokuapp.com/https://api.clay.com/v3/sources/webhook/pull-in-data-from-a-webhook-7e45f723-9ec9-4222-94e1-d3d4689e5a1b`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${clayApiKey}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    },
                    body: JSON.stringify({
                        PersonalLinkedIn: person.fields.PersonalLinkedIn,
                        Id: person.id,
                        Enriched: "yes"
                    }),
                }
            )
                // next step -> go to useEffect with domainAdded as a dependency
                .then((response) => {
                    console.log("response", response);
                    // alert("It worked! It's just a matter of time, you'll need to refresh the page.");
                    setShowNotif(true, setCopyClipboardNotif);
                    const contents = { type: "success", heading: "Request Received!", message: `It'll take 3 - 5 minutes and you'll be set to see more information for ${person.fields.FirstName + " " + person.fields.LastName}` }
                    console.log(contents)
                    setContents(contents, setCopyClipboardNotif);
                    // setPersonAdded(e.target["personLinkedInUrl"].value);
                });
        } catch (error) {
            console.error("Error in Fetching: ", error);
        }
    }

    function addNewPeople(e) {
        e.preventDefault();

        fetch("https://api.airtable.com/v0/" + baseId + "/" + peopleTable, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${apiKey}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                fields: {
                    PersonalLinkedIn: e.target["personLinkedInUrl"].value,
                    UploadedBy: user.primaryEmailAddress.emailAddress,
                },
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("this is what happened", data);
                // IF ERRORS, ITS BECAUSE WE SET THIS TO THE WRONG VALUE, COULD ADD ENRICH HERE
                setnewPerson(data)
                setShowNotif(true, setCopyClipboardNotif);
                const contents = { type: "success", heading: "Started Enrichment!", message: `You've enriched ${e.target["personLinkedInUrl"].value}, your record will be enriched and added in < 2 mins` }
                console.log(contents)
                setContents(contents, setCopyClipboardNotif);
            })
            .catch((error) => console.error("Error the person was not added to airtable:", error));

        try {
            fetch(
                `https://mycorsproxy-tuto-35940f676099.herokuapp.com/https://api.clay.com/v3/sources/webhook/pull-in-data-from-a-webhook-7e45f723-9ec9-4222-94e1-d3d4689e5a1b`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${clayApiKey}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    },
                    body: JSON.stringify({
                        PersonalLinkedIn: e.target["personLinkedInUrl"].value,
                        Id: newPerson.id
                    }),
                }
            )
                // next step -> go to useEffect with domainAdded as a dependency
                .then((response) => {
                    console.log("response - it worked!", response);
                    // setPersonAdded(e.target["personLinkedInUrl"].value);
                });
        } catch (error) {
            console.error("Error in Fetching: ", error);
        }
    }

    const deals = [
        {
            "name": "AWS",
            "title": "Amazon's cloud services platform",
            "imageUrl": "https://cdn.prod.website-files.com/619c5a34100fe677d0c61e04/61a9a2f67ce25b0154a2cd81_AWS-Logo.png"
        },
        {
            "name": "Notion",
            "title": "Organize teamwork and increase productivity",
            "imageUrl": "https://res.cloudinary.com/secretsaas/image/upload/c_fit,f_auto,h_80,q_auto,w_80/v1677062676/logo/Notion.png"
        },
        {
            "name": "Airtable",
            "title": "Easily store and organize your data",
            "imageUrl": "https://res.cloudinary.com/secretsaas/image/upload/c_fit,f_auto,h_80,q_auto,w_80/v1681200667/logo/Airtable.png"
        },
        {
            "name": "Typeform",
            "title": "Create beautiful forms and surveys that actually get responses",
            "imageUrl": "https://res.cloudinary.com/secretsaas/image/upload/c_fit,f_auto,h_80,q_auto,w_80/v1651502917/logo/Typeform.jpg"
        },
        {
            "name": "Google Cloud",
            "title": "Cloud services by Google",
            "imageUrl": "https://res.cloudinary.com/secretsaas/image/upload/c_fit,f_auto,h_80,q_auto,w_80/v1638533805/logo/Google%20Cloud.png"
        },
        {
            "name": "Segment",
            "title": "Collect, clean, and control customer data",
            "imageUrl": "https://img.logo.dev/segment.com?token=live_6a1a28fd-6420-4492-aeb0-b297461d9de2&size=200&format=jpg&greyscale=false"
        },
        {
            "name": "Stripe",
            "title": "Online payment processing for internet businesses",
            "imageUrl": "https://img.logo.dev/stripe.com?token=live_6a1a28fd-6420-4492-aeb0-b297461d9de2&size=200&format=jpg&greyscale=false"
        },
        {
            "name": "Intercom",
            "title": "Customer messaging platform for sales, marketing, and support",
            "imageUrl": "https://img.logo.dev/intercom.com?token=live_6a1a28fd-6420-4492-aeb0-b297461d9de2&size=200&format=jpg&greyscale=false"
        },
        {
            "name": "HubSpot",
            "title": "Marketing, sales, and service software that helps your business grow",
            "imageUrl": "https://img.logo.dev/hubspot.com?token=live_6a1a28fd-6420-4492-aeb0-b297461d9de2&size=200&format=jpg&greyscale=false"
        },
    ]

    const handleUploadComplete = async (uploadedRecords) => {
        const newRecords = await base('Referrals').select({
            pageSize: uploadedRecords.length,
            sort: [{ field: 'Created', direction: 'desc' }],
        }).firstPage();
        setPeople((prevRecords) => [...newRecords, ...prevRecords]);
    };

    const steps = [
        { id: '01', name: 'Export Network', description: 'Vitae sed mi luctus laoreet.', href: '#', status: 'complete' },
        { id: '02', name: 'Import Network', description: 'Vitae sed mi luctus laoreet.', href: '#', status: 'current' },
        { id: '03', name: 'Refer & Earn', description: 'Vitae sed mi luctus laoreet.', href: '#', status: 'upcoming' },
    ]

    return (
        <>
            <div className="bg-white">
                <div className="relative bg-gray-900">
                    {/* Decorative image and overlay */}
                    <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
                        <img
                            // src="https://images.pexels.com/photos/3182826/pexels-photo-3182826.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                            // src="https://www.wework.com/ideas/wp-content/uploads/sites/4/2022/11/Web_150DPI-20220414-Brand-Platform-Spaces-02-0411-scaled.jpg"
                            src="https://ctfassets.imgix.net/vh7r69kgcki3/7xLLwJUM4mEIbQFxK3wd7f/79093296d49593b9e207471b01c0c92b/Web_150DPI-2023_04_15_ATL_Lifestyle_Staged_Meeting_Room_05434_1.jpg?auto=format%20compress&fit=crop&q=50&w=500px"
                            alt=""
                            className="h-full w-full object-cover object-center"
                        />
                    </div>
                    <div aria-hidden="true" className="absolute inset-0 bg-gray-900 opacity-50" />

                    <div className="relative mx-auto flex max-w-4xl flex-col items-center px-6 py-32 text-center sm:py-24 lg:px-0">
                        <h1 className="text-4xl font-bold tracking-tight text-white lg:text-6xl">Grant Writer Network</h1>
                        <form className="space-y-6 px-3 -mt-2 pb-4" method="POST" onSubmit={addNewPeople}>
                            <label htmlFor="email" className="sr-only">
                                Linkedin Url
                            </label>
                            <input
                                type="text"
                                name="personLinkedInUrl"
                                id="personLinkedInUrl"
                                className="inline rounded-md rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 w-full sm:w-80"
                                placeholder="Enter a grant writer linkedin url"
                            />
                            <button
                                type="submit"
                                className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-white bg-black ring-1 ring-inset ring-gray-300 hover:bg-gray-800"
                            >
                                Add
                            </button>
                        </form>
                    </div>
                </div>
            </div>

            <div className="bg-white">
                <div>
                    <div className="ml-4 px-4 sm:px-6 lg:px-8">
                        <br />
                        {/* Table */}
                        <div className="flow-root">
                            <div className="mx-2 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle">
                                    <table className="min-w-full divide-y divide-white">
                                        <thead>
                                            <tr className="h-10">
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                                    Name & Website
                                                </th>
                                                <th scope="col" className="px-1 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <a href="#" className="group inline-flex">
                                                        Company & Title
                                                        <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                                                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    </a>
                                                </th>
                                                <th scope="col" className="px-1 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <a href="#" className="group inline-flex">
                                                        Location
                                                        <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                                                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    </a>
                                                </th>
                                                <th scope="col" class="px-1 py-3.5 text-left text-sm font-semibold text-gray-900 flex items-center justify-center">
                                                    <a href="#referral-email">Invite One or Invite All</a>
                                                    <Switch
                                                        checked={enabled}
                                                        onChange={setEnabled}
                                                        className={classNames(
                                                            enabled ? 'bg-green-500' : 'bg-gray-200',
                                                            'relative inline-flex ml-2 h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2',
                                                        )}
                                                    >
                                                        <span className="sr-only">Use setting</span>
                                                        <span
                                                            aria-hidden="true"
                                                            className={classNames(
                                                                enabled ? 'translate-x-5' : 'translate-x-0',
                                                                'mb-2 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                                                            )}
                                                        />
                                                    </Switch>
                                                    {/* {copySuccess && <p className="mt-2 text-green-500">{copySuccess}</p>} */}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-white bg-white">
                                            {people
                                                .map((person) => (
                                                    <>
                                                        <tr key={person.Id}>
                                                            <td className="py-5 pl-4 pr-3 text-sm sm:pl-0">
                                                                <div className="flex items-center">
                                                                    <div className={classNames(statuses[person.fields.Status], 'flex-none rounded-full p-1 mr-2')}>
                                                                        <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                                                    </div>
                                                                    <div className="h-11 w-11 flex-shrink-0">
                                                                        <img className="h-11 w-11 rounded-full" src={person.fields.PictureUrlOrig} alt="" />
                                                                    </div>
                                                                    <div className="ml-2">
                                                                        <div className="font-medium text-gray-700 text-wrap max-w-[90px]">
                                                                            {person.fields.Name}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-2 text-gray-500 text-wrap break-words text-xs text-center text-wrap">
                                                                    {person.fields.Email}
                                                                </div>
                                                            </td>
                                                            <td className="whitespace-wrap px-1 py-3 text-sm text-gray-500 text-wrap">
                                                                <div className="max-w-xs text-wrap text-gray-800">{person.fields.CompanyName}</div>
                                                                <div className="mt-1 max-w-xs text-wrap text-gray-500 text-xs">{person.fields.Title}</div>
                                                                <div className="mt-1 max-w-xs text-wrap text-gray-500 text-xs">{person.fields.Website}</div>
                                                            </td>
                                                            <td className="whitespace-wrap px-1 py-3 text-wrap text-xs text-gray-500">
                                                                <p>{person.fields.City}</p>
                                                            </td>
                                                            <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-sm font-medium sm:pr-0 flex items-center h-24">
                                                                <button
                                                                    onClick={() => { handleOpenClipModal(); handleCopy(person); }}
                                                                    className="text-gray-400 px-2 py-2 mr-1 rounded hover:text-gray-600"
                                                                >
                                                                    <ClipboardDocumentListIcon className="h-5 w-5" />
                                                                </button>
                                                                <a href={`https://app.getupgraded.ca/?website=${(person.fields.Website)}`} target="_blank">
                                                                    <button
                                                                        type="button"
                                                                        className={`rounded mr-1 px-2 py-1 text-lg font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 ${person.fields.Website ? "bg-green-500 hover:bg-green-600" : "bg-gray-500"}`}
                                                                    >
                                                                        View Report
                                                                    </button>
                                                                </a>
                                                                <a
                                                                    href={person.fields.PersonalLinkedIn || "#"}
                                                                    className={person.fields.PersonalLinkedIn ? "text-blue-500 hover:text-blue-700" : "text-gray-500 hover:text-gray-700"}
                                                                    target={person.fields.PersonalLinkedIn ? "_blank" : "_self"}
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <button
                                                                        type="button"
                                                                        data-twe-ripple-color="light"
                                                                        className={`inline-block rounded px-3 py-2 font-bold leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg flex items-center ${person.fields.PersonalLinkedIn ? "bg-[#0077b5]" : "bg-gray-500"}`}
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512" className="h-5 w-5">
                                                                            <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
                                                                        </svg>
                                                                    </button>
                                                                </a>
                                                                {person.fields.Email ? (
                                                                    <a
                                                                        href={`mailto:${person.fields.Email}?subject=Discover%20Savings%20with%20Upgraded&body=Hi%20there,%0A%0AI%27d%20like%20to%20share%20a%20way%20to%20find%20money%20you%20didn%27t%20know%20about%20before,%20it%27s%20called%20Upgraded.%20They%20work%20entirely%20on%20a%20contingency%20basis.%20They%27ve%20done%20over%20%249.4m%20in%20grants,%20vendor%20savings%20and%20automations%20at%20an%20~84%%20success%20rate%20and%20your%20report%20shows%20you%20have%20over%20%24105,231/yr%20in%20savings%20or%20%248,769/mo.%20Kindly%20see%20this%20link%20to%20your%20report%20below,%20would%20love%0A%0Ahttps://app.getupgraded.ca/?website=getupgraded.ca`}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        <button
                                                                            type="button"
                                                                            className="flex items-center space-x-2 rounded-md bg-green-500 shadow-xl px-3 py-2 text-sm font-semibold text-white hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-700 ml-2"
                                                                        >
                                                                            <EnvelopeIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                                                        </button>
                                                                    </a>
                                                                ) : (
                                                                    <button
                                                                        type="button"
                                                                        className="flex items-center space-x-2 rounded-md bg-gray-500 shadow-xl px-3 py-2 text-sm font-semibold text-white hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-700 ml-2"
                                                                        disabled
                                                                    >
                                                                        <EnvelopeIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                                                    </button>
                                                                )}
                                                                {person.fields.MobileNumber ? (
                                                                    <a href={`sms:${person.fields.MobileNumber}`}>
                                                                        <button
                                                                            type="button"
                                                                            className="flex items-center space-x-2 rounded-md bg-green-500 shadow-xl px-2 py-1 text-sm font-semibold text-white hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-700 ml-2"
                                                                        >
                                                                            <ChatBubbleOvalLeftIcon className="h-7 w-7 text-white" aria-hidden="true" />
                                                                        </button>
                                                                    </a>
                                                                ) : (
                                                                    <button
                                                                        type="button"
                                                                        className="flex items-center space-x-2 rounded-md bg-gray-500 shadow-xl px-2 py-1 text-sm font-semibold text-white hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-700 ml-2"
                                                                        disabled
                                                                    >
                                                                        <ChatBubbleOvalLeftIcon className="h-7 w-7 text-white" aria-hidden="true" />
                                                                    </button>
                                                                )}
                                                                <a href={`tel:${person.fields.MobileNumber}`}>
                                                                    <button
                                                                        type="button"
                                                                        className={`flex items-center space-x-2 rounded-md bg- shadow-xl px-2 py-1 text-sm font-semibold text-white  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-700 ml-2 ${person.fields.MobileNumber ? "bg-green-500 hover:bg-green-600" : "bg-gray-500"}`}
                                                                    >
                                                                        <PhoneArrowUpRightIcon className="h-7 w-7 text-white" aria-hidden="true" />
                                                                    </button>
                                                                </a>
                                                                <Switch
                                                                    checked={enabled}
                                                                    onChange={setEnabled}
                                                                    className={classNames(
                                                                        enabled ? 'bg-green-500' : 'bg-gray-200',
                                                                        'relative inline-flex mt-2 ml-2 h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2',
                                                                    )}
                                                                >
                                                                    <span className="sr-only">Use setting</span>
                                                                    <span
                                                                        aria-hidden="true"
                                                                        className={classNames(
                                                                            enabled ? 'translate-x-5' : 'translate-x-0',
                                                                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                                                                        )}
                                                                    />
                                                                </Switch>
                                                                {person.fields.Enriched ? (
                                                                    <CloudArrowUpIcon
                                                                        className="mt-1 h-8 w-8 text-gray-200 hover:text-gray-300 hover:cursor-pointer"
                                                                    />
                                                                ) : (
                                                                    <CloudArrowUpIcon
                                                                        onClick={() => enrichConnection(person)}
                                                                        className="mt-1 h-8 w-8 text-green-500 hover:text-green-500 hover:cursor-pointer"
                                                                    />
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="4" className="successful-grants">
                                                                {/* {console.log(person.fields.SuccessfulGrants.split(", "))} */}
                                                                {
                                                                    person.fields.SuccessfulGrants ?
                                                                        person.fields.SuccessfulGrants.replace(/[\[\]]/g, '').trim().split(",\n")
                                                                            .map((grant) => (
                                                                                <span className="items-center rounded-md bg-blue-100 px-2 py-1 my-1 mx-1 text-xs font-medium text-blue-700">
                                                                                    {grant.trim().replace(/^"|"$/g, '')}
                                                                                </span>
                                                                            )) : null
                                                                }
                                                                <span className="items-center rounded-md bg-green-100 px-2 py-1 my-1 mx-1 text-xs font-medium text-green-700">
                                                                    Get Youth Working
                                                                </span>
                                                                <span className="items-center rounded-md bg-blue-100 px-2 py-1 my-1 mx-1 text-xs font-medium text-blue-700">
                                                                    Get B.C working
                                                                </span>
                                                                <span className="items-center rounded-md bg-indigo-100 px-2 py-1 my-1 mx-1 text-xs font-medium text-indigo-700">
                                                                    WorkBC
                                                                </span>
                                                                <span className="items-center rounded-md bg-purple-100 px-2 py-1 my-1 mx-1 text-xs font-medium text-purple-700">
                                                                    Welcoming Newcomers
                                                                </span>
                                                                <span className="items-center rounded-md bg-purple-100 px-2 py-1 my-1 mx-1 text-xs font-medium text-purple-700">
                                                                    Discovering Potential
                                                                </span>
                                                                <span className="items-center rounded-md bg-purple-100 px-2 py-1 my-1 mx-1 text-xs font-medium text-purple-700">
                                                                    Magnet and other Student Work Placement Programs
                                                                </span>
                                                                <span className="items-center rounded-md bg-purple-100 px-2 py-1 my-1 mx-1 text-xs font-medium text-purple-700">
                                                                    Wil Digital
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))
                                            }
                                        </tbody>
                                    </table >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br />
                <br />
                <Notification
                    showNotif={copyClipboardNotif.showNotif}
                    setShowNotif={(val) => setCopyClipboardNotif(val, setCopyClipboardNotif)}
                    contents={copyClipboardNotif.contents}
                />
            </div >

        </>
    );
}

GrantWriterNetwork.defaultProps = {
    admin: false,
};