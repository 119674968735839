// Props are currentStep and totalSteps
const ProgressBar = ({ currentStep, totalSteps }) => {
  const progress = (currentStep / totalSteps) * 100;

  return (
    <span>
      <div class="flex justify-end mb-1">
        <span class="text-lg font-medium text-blue-700 dark:text-white">
          {currentStep} out of {totalSteps}
        </span>
      </div>
      <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
        <div
          class="bg-blue-600 h-2.5 rounded-full transition-[width] duration-[0.3s] ease-[ease-in-out]"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </span>
  );
};

export default ProgressBar;