import {
    commentStyle,
    nextButtonStyle,
    prevButtonStyle,
    questionStyle,
    textAnswerStyle,
    textDivStyle,
  } from "../styles";
  export function BusinessInfo({ formData, handleChangeInput, nextStep, prevStep }) {
    return (
      <>
        <div>
          <h3 class={`${commentStyle}`}>
            {`Perfect, we'll focus on loans for approximately $${formData.fundingAmountRequested} that prioritize your needs.`}
          </h3>
          <h3 class={`${commentStyle}`}>
            {`Now, let's get to know more about your business.`}
          </h3>
          <h1 class={`${questionStyle}`}>Where do you work?</h1>
  
          <div class={`${textDivStyle} flex justify-center items-center space-x-2`}>
            <label className="font-bold w-32">Business Name</label>
            <input
              type="text"
              name="Operating Business Name"
              placeholder="Organization Name"
              value={formData["Operating Business Name"]}
              onChange={(event) => handleChangeInput(event)}
              class={`${textAnswerStyle}`}
            />
          </div>
          <div class={`${textDivStyle} flex justify-center items-center space-x-2`}>
            <label className="font-bold w-32">Legal Business Name</label>
            <input
              type="text"
              name="Legal Business Name"
              placeholder="Legal Business Name"
              value={formData["Legal Business Name"]}
              onChange={(event) => handleChangeInput(event)}
              class={`${textAnswerStyle}`}
            />
          </div>
          <div class={`${textDivStyle} flex justify-center items-center space-x-2`}>
            <label className="font-bold w-32">Website</label>
            <input
              type="text"
              name="Company Website"
              placeholder="Company Website"
              value={formData["Company Website"]}
              onChange={(event) => handleChangeInput(event)}
              class={`${textAnswerStyle}`}
            />
          </div>
          <div class={`${textDivStyle} flex justify-center items-center space-x-2`}>
            <label className="font-bold w-32">Job Title</label>
            <input
              type="text"
              name="Job Title"
              placeholder="Job Title"
              value={formData["Job Title"]}
              onChange={(event) => handleChangeInput(event)}
              class={`${textAnswerStyle}`}
            />
          </div>
          <button type="button" class={`${prevButtonStyle}`} onClick={prevStep}>
            Prev
          </button>
          <button
            type="button"
            class={`${nextButtonStyle}`}
            onClick={nextStep}
            disabled={
              !formData['Legal Business Name'] ||
              !formData['Job Title'] ||
              !formData['Company Website']
            }
          >
            Next
          </button>
        </div>
      </>
    );
  }
  