import React, { useState, useEffect } from "react";
import Notification from "./Notification";
import { Tooltip } from "@material-tailwind/react";
import { setShowNotif, setContents, extractDomain } from "./helpers";
import { useSignUp, useSignIn } from "@clerk/clerk-react";
import { useNavigate, Link } from "react-router-dom";
import { InfinitySpin } from "react-loader-spinner";
import { StarIcon } from '@heroicons/react/20/solid'
import { Carousel } from 'flowbite';
// import { OAuthStrategy } from "@clerk/types";
// test

function SignInPage() {
	const navigate = useNavigate();

	const [userEmail, setUserEmail] = useState("");
	const [userPassword, setUserPassword] = useState("");
	const { isLoaded: isSignInLoaded, signIn, setActive } = useSignIn();
	const [showSignInNotification, setShowSignInNotification] = useState({
		showNotif: false,
		contents: { type: "", heading: "", message: "" },
	});
	const [loadingRedirect, setLoadingRedirect] = useState(false);

	const handleSignIn = async (event) => {
		event.preventDefault();

		if (!isSignInLoaded) return;

		try {
			const result = await signIn.create({
				identifier: userEmail,
				password: userPassword,
			});

			if (result.status === "complete") {
				const companyDomain = extractDomain(userEmail);
				setLoadingRedirect(true);
				await setActive({ session: result.createdSessionId });
				setLoadingRedirect(false);

				navigate(`/?website=${companyDomain}`);
			} else {
				console.log(result.status);
				let contents = { type: "error", heading: "Error Signing In", message: "Incorrect Email or Password" };
				setContents(contents, setShowSignInNotification);
				setShowNotif(true, setShowSignInNotification);
			}
		} catch (error) {
			setLoadingRedirect(false);
			console.error(error);
			let contents = { type: "error", heading: "Error Signing In", message: error?.errors[0]?.message };
			setContents(contents, setShowSignInNotification);
			setShowNotif(true, setShowSignInNotification);
		}
	};

	const signInWith = (strategy) => {
		if (!isSignInLoaded) return;

		signIn.authenticateWithRedirect({
			strategy,
			redirectUrl: '/sso-callback',
			redirectUrlComplete: '/check_oauth_google',
		});
  };

  useEffect(() => {
	const carouselElement = document.getElementById('carousel-testimonials');
	const items = [
				{
					position: 0,
					el: document.getElementById('carousel-indicator-0'),
				},
				{
					position: 1,
					el: document.getElementById('carousel-indicator-1'),
				},
				{
					position: 2,
					el: document.getElementById('carousel-indicator-2'),
				},
			];
		
	const options = {
		defaultPosition: 0,
		interval: 5000,

		indicators: {
			activeClasses: 'bg-gray-900 z-20',
			inactiveClasses:
				'bg-gray-900 z-10',
			items: [
				{
					position: 0,
					el: document.getElementById('carousel-indicator-0'),
				},
				{
					position: 1,
					el: document.getElementById('carousel-indicator-1'),
				},
				{
					position: 2,
					el: document.getElementById('carousel-indicator-2'),
				},
			],
		},

		onNext: () => {
		},
		onPrev: () => {
		},
		onChange: () => {
		},
	};

	const instanceOptions = {
		id: 'carousel-testimonials',
		override: true
	};

	const carousel = new Carousel(carouselElement, items, options, instanceOptions);
	carousel.cycle();
		}, []);

const testimonials = [
	{
		stars: 5,
		text: "“Upgraded found us $64,000/yr in savings on our first payroll cycle - we had no revenue and only 2 employees. Magic”",
		author: "Matt Parson",
		title: "Founder, Benji",
		image: "https://assets-global.website-files.com/655000b5d6f9d81d7029fd25/65f0a34f691a72257fea9cfb_matt-parson-portrait-p-500.jpeg",
	},
	{
		stars: 5,
		text: "“We hired the best CPA in Vancouver to look for credits. Upgraded still blew them out of the water.”",
		author: "Hannah Simmons",
		title: "Founder, ERA Brazil",
		image: "https://assets-global.website-files.com/634f60226f66af42a384d5b7/6369789c70e85f8693c7c324_hannah-simmons-profile.jpeg",
	},
	{
		stars: 5,
		text: "“We raised $100M from the best VCs. Upgraded still found us credits nobody had ever mentioned.”",
		author: "David McMurchy",
		title: "Sales Director, i-Sight",
		image: "https://assets-global.website-files.com/655000b5d6f9d81d7029fd25/65f1325f2d57b8c9b95895ee_david-headshot-1.jpeg",
	},
	// Add more as needed
	];

	return (
		<>
			<div className="flex min-h-full flex-1 h-screen overflow-hidden">
				<div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
					<div className="mx-auto w-full max-w-sm lg:w-96">
						<div>
							<img
								className="h-10 w-auto"
								src="https://i.imgur.com/PbiW6TE.png"
								alt="Upgraded Logo"
							/>
							<h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign In For Funding</h2>
							<p className="mt-2 text-sm leading-6 text-gray-500">
								Not a member?{"  "}
								<a href="/sign-up" className="font-semibold text-indigo-600 hover:text-indigo-500">
									Sign Up Instead
								</a>
							</p>
						</div>

						<div className="mt-10">
							<div>
								<div className="mt-10">
									<div className="grid grid-cols-1 gap-4">
										{/* <Tooltip content="Coming Soon" placement="top"> */}
										<button 
											className="flex w-full items-center justify-center gap-3 rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  focus-visible:ring-transparent"
											onClick={() => {
												signInWith("oauth_google")
											}}
										>
											<svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
												<path
													d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
													fill="#EA4335"
												/>
												<path
													d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
													fill="#4285F4"
												/>
												<path
													d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
													fill="#FBBC05"
												/>
												<path
													d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
													fill="#34A853"
												/>
											</svg>
											<span className="text-sm font-semibold leading-6">Connect with Google</span>
										</button>
										{/* </Tooltip> */}
									</div>
								</div>
								<div className="relative mt-5 mb-4">
									<div className="absolute inset-0 flex items-center" aria-hidden="true">
										<div className="w-full border-t border-gray-200" />
									</div>
									<div className="relative flex justify-center text-sm font-medium leading-6">
										<span className="bg-white px-3 text-gray-500">OR</span>
									</div>
								</div>
								<form onSubmit={handleSignIn} className="space-y-6">
									<div>
										<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
											Work email address
										</label>
										<div className="mt-2">
											<input
												id="email"
												name="email"
												type="email"
												autoComplete="email"
												value={userEmail}
												onChange={(e) => setUserEmail(e.target.value)}
												required
												className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>

									<div>
										<label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
											Password
										</label>
										<div className="mt-2">
											<input
												id="password"
												name="password"
												type="password"
												required
												autoComplete="current-password"
												value={userPassword}
												onChange={(e) => setUserPassword(e.target.value)}
												className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>

									<div>
										{isSignInLoaded && !loadingRedirect ? (
											<button
												disabled={!isSignInLoaded || loadingRedirect}
												type="submit"
												className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
											>
												Sign In
											</button>
										) : (
											<div className="flex text-center items-center justify-center mr-6">
												<InfinitySpin visible={true} width="100" color="black" ariaLabel="infinity-spin-loading" />
											</div>
										)}
									</div>
									<div className="">
                    {/* <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">
                        Remember me
                      </label>
                    </div> */}

                    <div className="text-sm leading-6">
											<Link to="/forgot-password" className="font-semibold text-indigo-600 hover:text-indigo-500">
												Forgot password?
											</Link>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<div className="relative hidden w-0 flex-1 lg:block my-auto py-auto bg-gray-900 h-screen overflow-hidden">
					<section className="px-6 py-24 sm:py-32 lg:px-8 bg-gray-900">
						<figure className="mx-auto max-w-2xl">
							<div id="carousel-testimonials" className="relative w-full bg-gray-900 mb-8 z-50" data-carousel="slide">
								<div className="relative h-56 bg-gray-900 overflow-hidden rounded-lg sm:h-64 xl:h-80 2xl:h-96">
									{testimonials.map((testimonial, i) => (
										<div key={i} className="hidden bg-gray-900 duration-700 ease-in-out" id={`carousel-indicator-${i}`}>
											<p className="sr-only">{testimonial.stars} out of 5 stars</p>
											<div className="flex gap-x-1 pt-6 pl-6 text-indigo-600">
												{Array.from({ length: testimonial.stars }).map((_, starIndex) => (
													<StarIcon key={starIndex} className="h-5 w-5 flex-none" aria-hidden="true" />
												))}
											</div>
											<blockquote className="mt-10 text-8xl pl-6 leading-8 tracking-tight text-white sm:text-4xl sm:leading-9">
												<p>{testimonial.text}</p>
											</blockquote>
											<figcaption className="mt-10 flex pl-6 items-center gap-x-6">
												<img
													className="h-12 w-12 rounded-full bg-gray-900"
													src={testimonial.image}
													alt={testimonial.author}
												/>
												<div className="text-sm leading-6">
													<div className="font-semibold pl-1 text-white">{testimonial.author}</div>
													<div className="mt-0.5 pl-1 text-gray-200">{testimonial.title}</div>
												</div>
											</figcaption>
										</div>
									))}
								</div>
							</div>
							<div className="flex justify-center pr-3 mt-8 space-x-4 sm:space-x-6 flex-wrap"> 
								<div className="flex flex-col items-center p-4 sm:p-6 rounded-lg shadow-md max-w-sm mx-auto"> {/* Added max-w-sm and mx-auto */}
									<div className="text-2xl sm:text-4xl font-bold text-white">$100k+</div> 
									<div className="mt-2 text-gray-200 text-center text-sm sm:text-base">Saved on Average</div> 
								</div>
								<div className="flex flex-col items-center p-4 sm:p-6 rounded-lg shadow-md max-w-sm mx-auto"> {/* Added max-w-sm and mx-auto */}
									<div className="text-2xl sm:text-4xl font-bold text-white">3600+</div> 
									<div className="mt-2 text-gray-200 text-center text-sm sm:text-base">Grants Available</div>
								</div>
								<div className="flex flex-col items-center p-4 sm:p-6 rounded-lg shadow-md max-w-sm mx-auto"> {/* Added max-w-sm and mx-auto */}
									<div className="text-2xl sm:text-4xl font-bold text-white"> $3B+</div> 
									<div className="mt-2 text-gray-200 text-center text-sm sm:text-base">Funding Available</div> 
								</div>
							</div>
						</figure>
					</section>
				</div>
			</div>

			<Notification
				showNotif={showSignInNotification.showNotif}
				setShowNotif={(val) => setShowNotif(val, setShowSignInNotification)}
				contents={showSignInNotification.contents}
			/>
		</>
	);
}

export default SignInPage;
