import React, { useEffect, useState } from 'react';
import { getQueryVariable } from './helpers';
import axios from "axios";

export default function InterestedPage() {
  const [status, setStatus] = useState('loading');

  const startZap = async () => {
    try {
      const response = await axios.post(`https://murmuring-plateau-21252-b7994639367f.herokuapp.com/api/partner-interested?partnerEmail=${getQueryVariable('partnerEmail')}&partnerName=${getQueryVariable('partnerName')}&client=${getQueryVariable('client')}&clientEmail=${getQueryVariable('clientEmail')}&type=${getQueryVariable('type')}`);
      console.log(response.data);
      setStatus('success');
    } catch (err) {
      console.log('error:')
      setStatus(err.message);
    }
  }

  useEffect(() => {
    startZap();
  }, [])

  return (
    <body>
    <div class="flex items-center justify-center h-screen">
      {status === 'loading' 
      ? (
        <svg xmlns="http://www.w3.org/2000/svg" class="animate-spin h-8 w-8 text-gray-500" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C6.373 0 0 6.373 0 14h4z"></path>
        </svg>
      )
      : status === 'success'
      ? (
        <div>
          <div class="flex flex-col items-center space-y-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="text-green-600 w-28 h-28" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <h1 class="text-4xl font-bold">Thank You !</h1>
            <p>Thank you for your interest! We look forward to this collaboration 😀</p>
            <a
              className="inline-flex items-center px-4 py-2 text-white bg-indigo-600 border border-indigo-600 rounded rounded-full hover:bg-indigo-700 focus:outline-none focus:ring"
              href='/sign-up'
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 mr-2" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
              </svg>
              <span class="text-sm font-medium">
                Home
              </span>
            </a>
          </div>
        </div>
      )
      : (
        <div>
          <div class="flex flex-col items-center space-y-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
            <h1 class="text-4xl font-bold">Something went wrong</h1>
            <p>{status}. Please try again later.</p>
            <a
              className="inline-flex items-center px-4 py-2 text-white bg-indigo-600 border border-indigo-600 rounded rounded-full hover:bg-indigo-700 focus:outline-none focus:ring"
              href='/sign-up'
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 mr-2" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
              </svg>
              <span class="text-sm font-medium">
                Home
              </span>
            </a>
          </div>
        </div>
      )
      }
      
    </div>
  </body>
  )
}