import { questionStyle } from "../styles";

export function SuccessSubmit() {
  return (
    <>
      <div>
        <h1 class={`${questionStyle}`}>{`Your Application Has Been Submitted!`}</h1>
      </div>
    </>
  );
}
