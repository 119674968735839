import Airtable from 'airtable';
const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;

// Initialize Airtable with API key
Airtable.configure({
    apiKey: apiKey
});

// Initialize and export the base
export const base = new Airtable({ apiKey }).base(baseId); 