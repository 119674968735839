
import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CurrencyDollarIcon } from "@heroicons/react/20/solid";
import { currencyFormat } from "./helpers";

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;


export default function TechRequest({
  open,
  setOpen,
  upgrade,
  amount,
  company,
  setCompany,
  userEmail,
}) {
  const cancelButtonRef = useRef(null);
  const dialogHeading = `Are you interested in our assistance to help you unlock this upgrade worth ${amount}?`;
  const dialogBody1 = `Tip: You will be able to see the company offering this tech discount after you press "Yes".`;
  const dialogBody2 = `The proper procedure is to allow our company to handle the tech savings application and negotiation. Attempting to independently find and apply discounts online without our involvement and credit undermines our agreement.`;
  const dialogBody3 = `By pressing "Yes", you agree that in the circumstance where you breach our contract, you will be billed for any successful discount applications related to this upgrade that you make independently`;

  const handleSubmit = () => {
    const companyId = company?.id;
    if (companyId) {
      const curCompanyTech = company?.fields?.RequestedTech || "";

      const updatedCompanyTech = curCompanyTech.length > 0 ? `${curCompanyTech},${upgrade?.fields?.Name}` : upgrade?.fields?.Name;

      fetch("https://api.airtable.com/v0/" + baseId + "/" + companiesTableId + "/" + companyId, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          fields: {
            RequestedTech: updatedCompanyTech,
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("success", data);
          setCompany((prevState) => ({
            ...prevState,
            fields: {
              ...prevState.fields,
              RequestedTech: updatedCompanyTech,
            },
          }));

        })
        .catch((error) => console.error("Error:", error));

      const request = `${userEmail} just revealed a Tech Saving for ${company?.fields?.Name}!`
      const website = `Technology Upgrade: ${currencyFormat(upgrade?.fields?.AnnualMargin)}/yr ${upgrade?.fields?.Name}`

      fetch(`https://hooks.zapier.com/hooks/catch/13965335/${process.env.REACT_APP_ZAPIER_SAVINGS}/`, {
        method: "POST",
        body: JSON.stringify({ request, website }),
      });

      // Log to Sheets
      fetch('https://hooks.zapier.com/hooks/catch/13965335/2ota6y3/', {
        method: "POST",
        body: JSON.stringify({
          CompanyName: company?.fields?.Name,
          ClientEmail: userEmail,
          RequesterEmail: userEmail,
          PartnerEmail: '',
          UpgradeName: upgrade?.fields?.Name,
          Amount: currencyFormat(upgrade?.fields?.AnnualMargin),
          ClientName: '',
          Date: new Date().toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }),
          UpgradeType: "Tech (only revealed, not redeemed)",
          Deadline: "",
          Password: "",
        })
      })

      // AirtablePost to All Grants & Savings Table
      // fetch(
      //   `https://api.airtable.com/v0/${baseId}/ALLGRANTS&SAVINGS(Automated-AccountsReceivable)`,
      //   {
      //     method: "POST",
      //     headers: {
      //       Authorization: `Bearer ${apiKey}`,
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({
      //       fields: {
      //         CompanyName: company?.fields?.Name,
      //         ClientEmail: email,
      //         RequesterEmail: userEmail,
      //         PartnerEmail: "",
      //         UpgradeName: upgrade?.fields?.Name,
      //         Amount: currencyFormat(upgrade?.fields?.AnnualMargin),
      //         ClientName: fullName,
      //         Date: new Date().toLocaleDateString("en-US", {
      //           year: "numeric",
      //           month: "2-digit",
      //           day: "2-digit",
      //         }),
      //         InvoiceReminderDate: new Date(
      //           Date.now() + 11 * 24 * 60 * 60 * 1000
      //         ).toLocaleDateString("en-US", {
      //           year: "numeric",
      //           month: "2-digit",
      //           day: "2-digit",
      //         }),
      //         UpgradeType: `Tech (${msg})`,
      //         Deadline: "",
      //         Password: password,
      //         MonthlySpending: monthlySpending,
      //         Status: status,
      //         Link: !builtfirst ? upgrade?.fields?.href : "",
      //         AdditionalInfo:
      //           showAdditional && !builtfirst
      //             ? `${showAdditional}: ${additionalInfo}`
      //             : "n/a",
      //         HumanInstructions: !builtfirst
      //           ? "https://docs.google.com/document/d/1PsxDaAGUH47zf0UtKwajp-OL7gqObllDo-Fpn4jb7S8/edit?pli=1#heading=h.fd89fbawk5ii"
      //           : "",
      //       },
      //     }),
      //   }
      // )
      //   .then((response) => response.json())
      //   .then((data) => console.log("Record added:", data))
      //   .catch((error) => console.error("Error:", error));
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                    <CurrencyDollarIcon className="h-6 w-6 fill-blue-400" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      {dialogHeading}
                    </Dialog.Title>
                    <div className="mt-6">
                      <p className="text-sm text-gray-800">{dialogBody1}<br /><br /><b>Please note: </b>{dialogBody2}<br /><br />{dialogBody3}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-700 sm:col-start-2"
                    onClick={() => {
                      handleSubmit();
                      setOpen(false);
                    }}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}