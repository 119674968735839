import React, { useState, useEffect } from "react";
import { setShowNotif, setContents, checkEmail, checkPhoneNumber, scrollToSection, extractDomain, checkValidPassword } from "./helpers";
import Notification from "./Notification";
import Cookies from "js-cookie";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@material-tailwind/react";
// import { extract } from "query-string/base";

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const usersTable = process.env.REACT_APP_AIR_USERS;

function EmailModal({
  setSignedUp,
  setUserEmail,
  setUserDomain,
  getQueryVariable,
  getEmail,
  setGetEmail,
  countDown,
  validEmail,
  setValidEmail,
  loading,
  secret_1,
  emailCloseable,
  setEmailCloseable,
}) {
  const [seconds, setSeconds] = useState(0);
  const [validPhone, setValidPhone] = useState("empty");
  const [validPassword, setValidPassword] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [phoneMessage, setPhoneMessage] = useState("");

  const admin = Cookies.get("domain") === "all" && getQueryVariable(process.env.REACT_APP_QUERY_SECRET_1) === "true";
  const adminPassword = process.env.REACT_APP_ADMIN_PASSCODE;

  // Email Subscribe Notif
  const [emailModalNotif, setEmailModalNotif] = useState({ showNotif: false, contents: { type: "", heading: "", message: "" } });

  useEffect(() => {
    const signedUp = Cookies.get("signed_up");
    if (loading || signedUp) return;

    const interval = setInterval(() => {
      if (seconds < countDown) {
        setSeconds((seconds) => seconds + 1);
      } else {
        if (!secret_1) {
          setGetEmail(true);
        }
        clearInterval(interval);
      }
    }, 1000);

    // Cleanup function
    return () => clearInterval(interval);
  }, [seconds, countDown, setGetEmail, loading]);

  // this useeffect is purely for debugging
  // useEffect(() => {
  //   if (validEmail === "correct") {
  //     console.log("YEAHH good email");
  //     setGetEmail(false); -----------------> moved to handlesubmit function
  //   } else {
  //     console.log("bad email");
  //   }
  // }, [validEmail]);

  function handleSubmit(event) {
    event.preventDefault();

    console.log("submitted!");

    // defining values in the form
    const data = event.target;
    const email = data["email"].value;
    const phone = data["phone"].value;
    let password = '';
    let validPass = false;

    if (showPasswordField) {
      password = data["password"].value;

      // checking if password is correct
      validPass = password === adminPassword;
    } else {
      validPass = true;
    }
    
    // check if email is correct
    setValidEmail(checkEmail(email) ? "correct" : "incorrect");

    if (checkEmail(email) && validPass) {
      setGetEmail(false);

      setShowNotif(true, setEmailModalNotif);
      const content = {
        type: "success",
        heading: `Thanks for signing up ${email}!`,
        message: "You now have exclusive access to select Grants",
      };
      setContents(content, setEmailModalNotif);

      // set cookies
      Cookies.set("signed_up", true);
      setSignedUp(Cookies.get("signed_up"));
      Cookies.set("email", email);
      setUserEmail(Cookies.get("email"));
      if (extractDomain(email) === process.env.REACT_APP_COOL_EMAIL) {
        Cookies.set("domain", "all");
        setUserDomain(Cookies.get("domain"));
      } else {
        var request = "A lead just signed up for savings!" + phone;
        var website = email;
        fetch(`https://hooks.zapier.com/hooks/catch/13965335/${process.env.REACT_APP_ZAPIER_SAVINGS}/`, {
          method: "POST",
          body: JSON.stringify({ request, website }),
        });

        fetch("https://api.airtable.com/v0/" + baseId + "/" + usersTable, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fields: {
              Email: email,
              Phone: validPhone === "true" ? phone : "",
            },
          }),
        })
          .then((response) => response.json())
          .then((data) => console.log(data))
          .catch((error) => console.error("Error:", error));
        Cookies.set("domain", getQueryVariable("website") ? getQueryVariable("website") : "");
        setUserDomain(Cookies.get("domain"));
      }

      // scroll to section
      console.log('go now');
      scrollToSection("#upgrades");
      setShowPasswordField(false);
    }

  }

  function handleEmailChange(event) {
    const email = event.target.value;

    setShowPasswordField(extractDomain(email) === process.env.REACT_APP_COOL_EMAIL);

    setValidEmail(checkEmail(email) ? "correct not submitted" : "incorrect");
  }

  function handlePhoneChange(event) {
    const phInput = event.target.value;
    const phOutput = checkPhoneNumber(phInput);
    if (phInput === "") {
      setValidPhone("empty");
      setPhoneMessage("");
    } else if (phOutput.result == "Failed") {
      setValidPhone("false");
      setPhoneMessage(phOutput.message);
    } else {
      setValidPhone("true");
    }
  }

  function handlePasswordChange(event) {
    const password = event.target.value;

    setValidPassword(checkValidPassword(password));
  }

  return (
    <>
      <div>
        {getEmail ? (
          <>
            <div className="pt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-xl my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div class="bg-grey-lighter min-h-screen flex flex-col">
                  <div class="container max-w-md mx-auto flex-1 flex flex-col items-center justify-center px-2">
                    {(getQueryVariable(process.env.REACT_APP_QUERY_SECRET_1) === "true" || emailCloseable === true) && <div className="float-right hidden pr-2 pt-4 sm:block">
                      <button
                        className="text-black absolute right-1 top-19 mt-1 -mr-1"
                        onClick={() => {
                          setGetEmail(false);
                          setEmailCloseable(false);
                          setShowPasswordField(false);
                        }}
                      >
                        <span className="h-6 w-3 text-2xl">
                          ×
                        </span>
                      </button>
                    </div>}
                    <div class="bg-white px-6 py-8 rounded shadow-md text-black w-[280px] sm:w-[480px]">
                      <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <img
                          className="mx-auto h-12 w-auto"
                          src="https://assets.website-files.com/634f60226f66af42a384d5b7/6356df5890197b8e776cffc2_upgraded-logos-p-500.jpg"
                          alt="Upgraded"
                        />
                        <h2 className="mt-4 mb-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 justify-center">
                          Subscribe to Earnings
                        </h2>
                        <h5 className="text-center">
                          <p className="mb-2">We hate popups too.</p>
                          <p className="mb-2 font-bold text-indigo-700">Sign in to receive your savings report</p>
                          <p className="mb-2">Never miss your grant deadlines</p>
                        </h5>
                      </div>
                      <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
                        <form className="space-y-6" method="POST" onSubmit={handleSubmit}>
                          <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                              Email address<span className="text-red-700">*</span>
                            </label>
                            <div className="mt-2">
                              <input
                                onChange={handleEmailChange}
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                placeholder="name@company.com"
                                className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-${
                                  validEmail === "incorrect" ? "red" : "indigo"
                                }-600 sm:text-sm sm:leading-6`}
                              />
                              <p className="text-red-800 ml-1 mb-2 text-sm">
                                {validEmail === "incorrect" ? "Invalid email, please try again" : ""}
                              </p>
                            </div>
                          </div>

                          {showPasswordField && (
                            <div>
                              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                Enter Password<span className="text-red-700">*</span>
                                {validPassword === true ? (
                                  <CheckCircleIcon
                                    className="inline h-5 w-5 mx-2 text-indigo-500 group-hover:text-indigo-900"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <Tooltip content="Valid passwords need at least 3 characters." placement="top" className="z-50 inline">
                                    <XCircleIcon
                                      className="z-50 inline h-5 w-5 mx-2 text-red-500 group-hover:text-indigo-900"
                                      aria-hidden="true"
                                    />
                                  </Tooltip>
                                )}
                              </label>

                              <div className="mt-2">
                                <input
                                  id="password"
                                  name="password"
                                  type="password"
                                  required
                                  autoComplete="off"
                                  onChange={handlePasswordChange}
                                  className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-${
                                    validPassword === false ? "red" : "indigo"
                                  }-600 sm:text-sm sm:leading-6`}
                                />
                              </div>
                            </div>
                          )}

                          <div>
                            <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                              Phone Number (optional)
                              {validPhone === "true" ? (
                                <CheckCircleIcon
                                  className="inline h-5 w-5 mx-2 text-indigo-500 group-hover:text-indigo-900"
                                  aria-hidden="true"
                                />
                              ) : (
                                ""
                              )}
                            </label>
                            <div className="mt-2">
                              <input
                                id="phone"
                                name="phone"
                                type="tel"
                                onChange={handlePhoneChange}
                                autoComplete="phone"
                                placeholder="289-349-3249"
                                className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-${
                                  validPhone === "false" ? "red" : "indigo"
                                }-600 sm:text-sm sm:leading-6`}
                              />
                              {validPhone === "false" ? <p className="text-red-800 ml-1 mb-2 text-sm">{phoneMessage}</p> : ""}
                            </div>
                          </div>

                          <div>
                            <button
                              type="submit"
                              className="flex w-full justify-center rounded-md bg-gray-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                            >
                              Start Earning
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>

                    {/* <div class="text-grey-dark mt-6">
                        Already have an account? 
                        <a class="no-underline border-b border-blue text-blue" href="../login/">
                          Log in
                        </a>.
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="backdrop-blur opacity-30 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </div>

      <Notification
        showNotif={emailModalNotif.showNotif}
        setShowNotif={(val) => setShowNotif(val, setEmailModalNotif)}
        contents={emailModalNotif.contents}
      />
    </>
  );
}


EmailModal.defaultProps = {
  emailCloseable : false,
}

export default EmailModal;
