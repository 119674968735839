import {
    nextButtonStyle,
    prevButtonStyle,
    questionStyle,
    textAnswerStyle,
    textDivStyle,
  } from "../styles";
  
  export function PersonalInfo({ formData, handleChangeInput, nextStep, prevStep }) {
    return (
      <>
        <div>
          <h1 class={`${questionStyle}`}>
            Let's start with your personal details
          </h1>
          <div class={`${textDivStyle} flex justify-center items-center space-x-2`}>
            <label className="font-bold w-32">First Name</label>
            <input
              type="text"
              name="First Name"
              placeholder="First Name"
              value={formData["First Name"]}
              onChange={(event) => handleChangeInput(event)}
              class={`${textAnswerStyle}`}
            />
          </div>
          <div class={`${textDivStyle} flex justify-center items-center space-x-2`}>
            <label className="font-bold w-32">Last Name</label>
            <input
              type="text"
              name="Last Name"
              placeholder="Last Name"
              value={formData["Last Name"]}
              onChange={(event) => handleChangeInput(event)}
              class={`${textAnswerStyle}`}
            />
          </div>
          <div class={`${textDivStyle} flex justify-center items-center space-x-2`}>
            <label className="font-bold w-32">Email</label>
            <input
              type="text"
              name="Email"
              placeholder="Email"
              value={formData["Email"]}
              onChange={(event) => handleChangeInput(event)}
              class={`${textAnswerStyle}`}
            />
          </div>
          <div class={`${textDivStyle} flex justify-center items-center space-x-2`}>
            <label className="font-bold w-32">Phone Number</label>
            <input
              type="text"
              name="Phone Number"
              placeholder="Phone Number"
              value={formData["Phone Number"]}
              onChange={(event) => handleChangeInput(event)}
              class={`${textAnswerStyle}`}
            />
          </div>
          <button type="button" class={`${prevButtonStyle}`} onClick={prevStep}>
            Prev
          </button>
          <button
            type="button"
            class={`${nextButtonStyle}`}
            onClick={nextStep}
            disabled={
              !formData["First Name"] ||
              !formData["Last Name"] ||
              !formData["Email"] ||
              !formData["Phone Number"]
            }
          >
            Next
          </button>
        </div>
      </>
    );
  }
  