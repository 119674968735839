import React, { Fragment, useState } from "react";
import Airtable from "airtable";
import { filterByDeadline, compareDates, currencyFormat, classNames, wait, getRandomInteger, getWebURL } from "./helpers";
import { monthMap } from "./helperData";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon, XMarkIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
import { Menu } from "@headlessui/react";
import SubscribedPeopleModal from "./SubscribedPeopleModal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const base = new Airtable({ apiKey }).base(baseId);
const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;

// TODOS:
// Unsubscribe URL --> Links to page in app which will change subscirbed? field in airtable to "false"
// Add settings menu (gear icon, side-panel) --> allows users to manually unsub or choose email preferences (tech discounts only, automations, etc)

function NotificationButtons({ find_grants, findDiscounts }) {
  // This state is used to control when the "Are you sure you want to trigger these requests" modal is open.
  const [openConfirmation, setOpenConfirmation] = useState(false);

  // This state keeps track of whether the subscribed modal is visible or not.
  const [showSubscribedList, setShowSubscribedList] = useState(false);

  // This state keeps track of which trigger is going to be run.
  // typeOfFries : string ----- Could be one of the following: "grants" || "tech savings" || "automations"
  const [typeOfFries, setTypeOfFries] = useState("");

  // This state keeps track of whether Grant Notifications are currently being sent out or not
  // If so, it expresses this to the user so they don't click the grant trigger again.
  const [grantsSending, setGrantsSending] = useState(false);

  const [companiesCount, setCompaniesCount] = useState(0);

  // fetch stuff from airtable
  const fetchResponsibilities = async (formula) => {
    const data = await base("responsibilities")
      .select({
        filterByFormula: `OR(${formula})`,
      })
      .all();

    return data;
  };

  // https://hooks.zapier.com/hooks/catch/16093847/31afemu/ --> Automation Matching
  // https://hooks.zapier.com/hooks/catch/16093847/39i8cqf/ --> Automation Notifications
  // https://hooks.zapier.com/hooks/catch/16093847/31ay9l0/ --> Grant Notifications
  // https://hooks.zapier.com/hooks/catch/16093819/39661re/ --> Tech Savings Notifications

  // find all the people on the team and store the information in an array of objects:
  // automationPeople = [{}, {}, {}]
  // each person has the following fields in their object
  //   - name: Will Richman

  //   - url: https://www.linkedin.com/in/willrichman/

  //   - title: Head of Client Savings

  //   - salary: 100000

  //   - pic: https://s3.amazonaws.com/media.mixrank.com/profilepic/ef110784d23238dea98e53ce1d7a7bff

  //   - respAutomationMap:
  //       - An array of objects that map automation upgrades to each responsibility
  //       - in each object, these fields are found
  //           - responsibility
  //           - respHours
  //           - automations
  //           - amount, which is the amount calculated using the formula: hours * salary * 0.8 / 40;

  //  ----------DEPRECATED FIELDS WHICH CAN JUST GET PUT INTO METADATA--------------------------

  //  - upgradeAmount, all of the dollar amount savings combined for that person

  //  - upgradeList, list containing all of the unique automations available for that person

  //  - upgradeQuantity, quantity of unique automations that person has in total
  async function find_automations(company) {
    try {
      let automationPeople = [];
      let data = await base("people")
        .select({
          maxRecords: 50,
          view: "Grid view",
          filterByFormula: `REGEX_MATCH({Domain}, '^${company.fields.Domain}$')`,
        })
        .all();
      data = data.filter((person) => person?.fields?.Domain == company?.fields?.Domain);

      for (const [i, person] of data.entries()) {
        automationPeople.push({
          name: person?.fields?.Name,
          url: person?.fields?.Email,
          title: person?.fields?.Title,
          salary: person?.fields?.Salary,
          pic: person?.fields?.PictureUrlOrig ? person?.fields?.PictureUrlOrig : company.fields.LogoUrl,
        });

        const valuesToSearch = JSON.parse(person.fields.Responsibilities).map((option) => option.value);
        let escapedValues = valuesToSearch.map((value) => value.replace(/'/g, "\\'"));
        let formula = escapedValues.map((value) => `{Name} = '${value}'`).join(", ");

        const resp = await fetchResponsibilities(formula);
        let upgradeAmount = 0;
        let upgradeList = [];
        let respAutomationMap = [];
        let upgradeQuantity = 0;

        // code was breaking before because i didn't consider undefined automations
        resp.map((responsibility) => {
          const automationMatch = responsibility?.fields?.AutomationUpgrades ? JSON.parse(responsibility?.fields?.AutomationUpgrades) : [];
          const filteredAutomations = automationMatch.filter((upgrade) => upgrade.verified).map((upgrade) => upgrade.automation);
          respAutomationMap.push({
            responsibility: responsibility?.fields?.Name,
            respHours: JSON.parse(person?.fields?.Hours)[i],
            automations: filteredAutomations,
            amount: (JSON.parse(person?.fields?.Hours)[i] * person?.fields?.Salary * 0.8) / 40,
          });
          filteredAutomations.map((match) => {
            if (!upgradeList.includes(match)) {
              upgradeList.push(match);
              upgradeQuantity += 1;
            }
          });
          upgradeAmount += (JSON.parse(person.fields.Hours)[i] * person.fields.Salary * 0.8) / 40;
        });

        respAutomationMap = respAutomationMap.sort((a, b) => b.automations.length - a.automations.length);
        respAutomationMap = respAutomationMap.filter((r) => r.automations.length !== 0);
        console.log("passed here");

        const search = automationPeople.find((p) => p.name === person.fields.Name);
        if (search) {
          search.upgradeAmount = upgradeAmount;
          search.upgradeList = upgradeList;
          search.upgradeQuantity = upgradeQuantity;
          search.respAutomationMap = respAutomationMap;
        }
      }

      // sort the people from most upgradequantity to least
      // exclude people without upgrades
      automationPeople = automationPeople.sort((a, b) => b.upgradeQuantity - a.upgradeQuantity);
      automationPeople = automationPeople.filter((p) => p.upgradeQuantity !== 0);
      console.log("AUTOMATION PEOPLE", automationPeople);

      return automationPeople;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  const sendNotifications = () => {
    if (typeOfFries === "") return;
    else if (typeOfFries === "grants") sendGrantNotifications();
    else if (typeOfFries === "tech savings") sendTechNotifications();
    else if (typeOfFries === "automations") sendAutomationNotifications();
    handleCancelClick();
  };

  // Function for when you click either the Grant trigger or the Tech Savings trigger.
  const handleTriggerClick = (trigger) => {
    setTypeOfFries(trigger);
    setOpenConfirmation(true);
  };

  // Function for when you click cancel on the trigger modal.
  const handleCancelClick = () => {
    setTypeOfFries("");
    setOpenConfirmation(false);
  };

  const sendAutomationNotifications = async () => {
    console.log("Sending out Automation Notifications");
    // TODO:
    // add flexibility to add automation code in this component, allowing for the different modal.

    const companyRecords = await base("companies").select({ view: "Grid view" }).all();

    // Setting subscribed companies
    let subscribedCompanies = companyRecords.filter((record) => record?.fields["subscribed?"] === "true");

    const today = new Date(); // Date of today

    // The time (in ms) of the date one week ahead from now.
    const oneWeekInMS = 7 * 24 * 60 * 60 * 1000;

    for (const subscribedCompany of subscribedCompanies) {
      console.log(`-----------GENERATING AUTOMATION EMAIL FOR ${subscribedCompany?.fields?.Name}-----------`);
      // Deadline check
      if (JSON.parse(subscribedCompany?.fields?.Activity)?.LatestActivity?.toLowerCase() !== "n/a") {
        // --> If we've never contacted them before, then proceed normally.

        // If it hasn't been a week since we last created a draft, then skip this company.
        if (today.getTime() - new Date(JSON.parse(subscribedCompany?.fields?.Activity)?.LatestActivity).getTime() < oneWeekInMS) {
          console.log(
            `Skipping ${subscribedCompany?.fields?.Name} because we've contacted them less than a week ago. To override this check, change the LastActivity field in the Activity object found in the Companies Table in Airtable.`
          );
          console.log(
            "Days since last contact",
            Math.floor((today.getTime() - new Date(JSON.parse(subscribedCompany?.fields?.Activity)?.LatestActivity).getTime()) / 86400000)
          );
          continue;
        }
      }

      const lastContact =
        JSON.parse(subscribedCompany?.fields?.Activity)?.LatestActivity?.toLowerCase() !== "n/a"
          ? Math.floor((today.getTime() - new Date(JSON.parse(subscribedCompany?.fields?.Activity)?.LatestActivity).getTime()) / 86400000)
          : "n/a";

      const automationPeople = await find_automations(subscribedCompany);

      if (automationPeople.length === 0) {
        console.log(`UNABLE TO WRITE EMAIL FOR ${subscribedCompany?.fields?.Name} because no automations were found`);
      }

      let respArray = [];
      let responsibility = "";
      let respHours = 0;
      let amount = 0;
      let list = [];
      const firstName = subscribedCompany?.fields?.People1Name?.split(" ")
        ? subscribedCompany?.fields?.People1Name?.split(" ")[0]
        : "firstName";
      const lastName = subscribedCompany?.fields?.People1Name?.split(" ")
        ? subscribedCompany?.fields?.People1Name?.split(" ")[1]
        : "lastName";
      let automationReceiver = "";
      let email = "";
      let noMetaEmail = "";
      let excludePeople = [];
      let personMap = {};

      // when choosing which person to write the draft email about, we will look at the activity object in airtable
      // "Automations": { "excludePeople": [], "personMap": { "url": [excludeResp], "url": [excludeResp] } }

      for (const automationPerson of automationPeople) {
        let activity = JSON.parse(subscribedCompany?.fields?.Activity)["Automations"];

        console.log("yes sir", activity);
        if (Array.isArray(activity)) {
          activity = {
            excludePeople: [],
            personMap: {},
          };
        }

        excludePeople = activity.excludePeople;
        personMap = activity.personMap;

        if (!(automationPerson.url in personMap)) {
          respArray = automationPerson.respAutomationMap;

          responsibility = respArray[0].responsibility;
          respHours = respArray[0].respHours;
          amount = respArray[0].amount;
          list = JSON.stringify(respArray[0].automations);
          automationReceiver = automationPerson.name;

          email = `Hey ${firstName},\n\nBased on your colleague ${automationPerson.name}'s title, ${automationPerson.title
            }, we've found we can automate their responsibility: "${responsibility}" with an upgrade that you might find interesting.\n\nWe've used this upgrade to save folks ~${respHours} hours a week it would result in a ${currencyFormat(
              amount
            )} productivity improvement at a market salary of ${currencyFormat(
              automationPerson.salary
            )}.\n\nOpen to a chat?\n\nBest,\nWill\n\nMeta:\nperson name: ${automationPerson.name}\nestimated title: ${automationPerson.title
            }\nsalary: ${automationPerson.salary
            }\nresponsibility: ${responsibility}\nhours: ${respHours}\neSavings: ${amount}\nautomation list: ${list}`;

          noMetaEmail = `Hey ${firstName},\n\nBased on your colleague ${automationPerson.name}'s title, ${automationPerson.title
            }, we've found we can automate their responsibility: "${responsibility}" with an upgrade that you might find interesting.\n\nWe've used this upgrade to save folks ~${respHours} hours a week it would result in a ${currencyFormat(
              amount
            )} productivity improvement at a market salary of ${currencyFormat(automationPerson.salary)}.\n\nOpen to a chat?\n\nBest,\nWill`;

          excludePeople.push(automationPerson.url);
          personMap[automationPerson.url] = [responsibility];

          break;
        } else if (!excludePeople.includes(automationPerson.url)) {
          if (excludePeople.length + 1 === automationPeople.length) {
            excludePeople = [automationPerson.url];
          } else {
            excludePeople.push(automationPerson.url);
          }

          // right now on automationPerson
          // trying to cycle through the responsbilities so that we get a responsibility that we haven't done yet

          respArray = automationPerson.respAutomationMap;
          let excludeResp = personMap[automationPerson.url];

          for (let i = 0; i < respArray.length; i++) {
            if (!excludeResp.includes(respArray[i].responsibility)) {
              responsibility = respArray[i].responsibility;
              respHours = respArray[i].respHours;
              amount = respArray[i].amount;
              list = JSON.stringify(respArray[i].automations);
              automationReceiver = automationPerson.name;

              email = `Hey ${firstName},\n\nBased on your colleague ${automationPerson.name}'s title, ${automationPerson.title
                }, we've found we can automate their responsibility: "${responsibility}" with an upgrade that you might find interesting.\n\nWe've used this upgrade to save folks ~${respHours} hours a week it would result in a ${currencyFormat(
                  amount
                )} productivity improvement at a market salary of ${currencyFormat(
                  automationPerson.salary
                )}.\n\nOpen to a chat?\n\nBest,\nWill\n\nMeta:\nperson name: ${automationPerson.name}\nestimated title: ${automationPerson.title
                }\nsalary: ${automationPerson.salary
                }\nresponsibility: ${responsibility}\nhours: ${respHours}\neSavings: ${amount}\nautomation list: ${list}`;

              noMetaEmail = `Hey ${firstName},\n\nBased on your colleague ${automationPerson.name}'s title, ${automationPerson.title
                }, we've found we can automate their responsibility: "${responsibility}" with an upgrade that you might find interesting.\n\nWe've used this upgrade to save folks ~${respHours} hours a week it would result in a ${currencyFormat(
                  amount
                )} productivity improvement at a market salary of ${currencyFormat(
                  automationPerson.salary
                )}.\n\nOpen to a chat?\n\nBest,\nWill`;

              excludeResp.push(responsibility);
              excludePeople.push(automationPerson.url);
              personMap[automationPerson.url] = excludeResp;

              break;
            }
          }

          if (responsibility) {
            break;
          } else {
            continue;
          }
        } else {
          continue;
        }
      }

      // what gets executed if email is generated successfully -- automation request
      if (responsibility) {
        console.log(`Email for ${subscribedCompany?.fields?.Name}\n\n${email}`);

        fetch(`https://hooks.zapier.com/hooks/catch/16093847/39i8cqf`, {
          method: "POST",
          body: JSON.stringify({
            company: subscribedCompany?.fields?.Name,
            firstName: firstName,
            lastName: lastName,
            email: email,
            noMetaEmail: noMetaEmail,
            requestEmail: subscribedCompany?.fields?.RequestEmail || "company@company.com",
            lastContact: lastContact !== "n/a" ? `${lastContact} days ago` : "n/a",
            responsibility: responsibility,
            list: list,
            automationReceiver: automationReceiver,
          }),
        })
          .then((response) => console.log("Zapier Request Sent", response))
          .catch((error) => console.error(error));

        fetch("https://api.airtable.com/v0/" + baseId + "/" + companiesTableId + "/" + subscribedCompany?.id, {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fields: {
              Activity: JSON.stringify({
                ...JSON.parse(subscribedCompany?.fields?.Activity),
                LatestActivity: today.toDateString(),
                Automations: {
                  excludePeople,
                  personMap,
                },
              }),
            },
          }),
        })
          .then((response) => console.log("Activity Status for Grants updated in airtable", response))
          .catch((error) => console.error(error));
      } else {
        console.log(`AUTOMATION UPGRADES MAY BE DEPLETED`);
      }
    }
  };

  // This function sends out Tech Notifications
  const sendTechNotifications = () => {
    base("companies")
      .select({ view: "Grid view" })
      .all()
      .then((companyRecords) => {
        base("discounts")
          .select({ view: "Grid view" })
          .all()
          .then((discountRecords) => {
            const today = new Date();
            const oneWeekInMS = 7 * 24 * 60 * 60 * 1000;

            let subscribedCompanies = companyRecords.filter((record) => record?.fields["subscribed?"] === "true");

            for (const subscribedCompany of subscribedCompanies) {
              if (subscribedCompany?.fields?.Technologies === "") {
                console.log(`Skipping ${subscribedCompany?.fields?.Name} because its tech stack is empty`);
                continue;
              } // Skipping the company if it does not have adequate technology stack

              // Last Activity Check --- Making sure that it has been at least a week since we last contacted the client
              if (JSON.parse(subscribedCompany?.fields?.Activity)?.LatestActivity?.toLowerCase() !== "n/a") {
                // --> If we've never contacted them before, then proceed normally.

                // If it hasn't been a week since we last created a draft, then skip this company.
                if (today.getTime() - new Date(JSON.parse(subscribedCompany?.fields?.Activity)?.LatestActivity).getTime() < oneWeekInMS) {
                  console.log(
                    `Skipping ${subscribedCompany?.fields?.Name} because it hasn't been one week yet since we last contacted them. Modify the LatestActivity field in the Activity object to override this check.`
                  );
                  console.log(
                    "Days since last contact",
                    Math.floor(
                      (today.getTime() - new Date(JSON.parse(subscribedCompany?.fields?.Activity)?.LatestActivity).getTime()) / 86400000
                    )
                  );
                  continue;
                }
              }

              // Picking the top most (i.e., largest savings)
              let companyDiscounts = findDiscounts(subscribedCompany?.fields?.Technologies, discountRecords, false);

              // If no discounts were matched with the company, then we skip the company.
              if (companyDiscounts.length === 0) continue;

              // Filtering out discounts for the same technology:
              let temp = [];
              for (let i = 0; i < companyDiscounts.length; i++) {
                // If a tech discount of a certain technology already exists in temp array, then we skip this discount.
                // (This is in order to not have multiple discounts of the same type. Eg., no two hubspot discounts, only one.)
                if (temp.filter((a) => a?.fields?.AssociatedTechnology === companyDiscounts[i]?.fields?.AssociatedTechnology).length !== 0)
                  continue;

                temp.push(companyDiscounts[i]);
              }

              // Removing any tech discounts that we already sent out to them
              companyDiscounts = temp.filter(
                (discount) =>
                  !JSON.parse(subscribedCompany?.fields?.Activity)
                  ["Tech Discounts"]?.map((recordDateObj) => Object.keys(recordDateObj)[0])
                    ?.includes(discount?.id)
              );

              let companyDiscount = companyDiscounts[0];

              if (companyDiscount === undefined) continue; // skipping the company if we can't find any tech discounts for them

              console.log("-----------------------------------------------");
              console.log("Fetching for ", subscribedCompany?.fields?.Name);
              console.log(
                subscribedCompany?.fields?.Name,
                `, ${companyDiscount?.fields?.Name} : ${companyDiscount?.fields?.AnnualMargin} from ${companyDiscount?.fields?.Source}`
              );
              console.log(
                "all comp discounts",
                companyDiscounts.map(
                  (discount) => `${discount?.fields?.Name} worth ${discount?.fields?.AnnualMargin} from ${discount?.fields?.Source}`
                )
              );

              // CREATING FIELDS FOR ZAPIER REQUEST

              // Basic Info
              const firstName = subscribedCompany?.fields?.People1Name?.split(" ")[0];
              const lastName = subscribedCompany?.fields?.People1Name?.split(" ")[1];

              if (!firstName && !lastName) continue; // If there is no name associated with the company, then we skip the company.

              // The deadline of the hubspot task --- set to 1 week from now
              const taskDeadline = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7).toDateString();

              // The date of the last activity with this client -- when we last contacted them
              const LastActivity = JSON.parse(subscribedCompany?.fields?.Activity).LatestActivity;

              // The worth of the tech discount.
              const discountAmount = companyDiscount?.fields?.AnnualMargin;

              // Making the zapier request -- tech notification request
              fetch(`https://hooks.zapier.com/hooks/catch/13965335/39661re/`, {
                method: "POST",
                body: JSON.stringify({
                  company: subscribedCompany?.fields?.Name,
                  firstName: firstName,
                  lastName: lastName,
                  discount: companyDiscount?.fields?.Name,
                  source: companyDiscount?.fields?.Source,
                  amount: currencyFormat(discountAmount),
                  taskDeadline: taskDeadline,
                  email: subscribedCompany?.fields?.RequestEmail || "prayaag@getupgraded.ca",
                  LastActivity: LastActivity,
                  Domain: subscribedCompany?.fields?.Domain,
                  Industry: subscribedCompany?.fields?.Industry,
                  Address: subscribedCompany?.fields?.Address,
                  Headcount: subscribedCompany?.fields?.Headcount,
                  Employees: subscribedCompany?.fields?.Employees,
                  Technologies: subscribedCompany?.fields?.Technologies,
                  Description: subscribedCompany?.fields?.Description,
                  Founded: subscribedCompany?.fields?.Founded,
                  LinkedInUrl: subscribedCompany?.fields?.LinkedInUrl,
                  CompanyType: subscribedCompany?.fields?.CompanyType,
                }),
              })
                .then((response) => console.log("Zapier Request Sent", response))
                .catch((error) => console.error(error));

              // Updating the "Activity" field in Airtable. This adds today's date as the date when the email was sent out.
              fetch("https://api.airtable.com/v0/" + baseId + "/" + companiesTableId + "/" + subscribedCompany?.id, {
                method: "PATCH",
                headers: {
                  Authorization: `Bearer ${apiKey}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  fields: {
                    Activity: JSON.stringify({
                      ...JSON.parse(subscribedCompany?.fields?.Activity),
                      LatestActivity: today.toDateString(),
                      ["Tech Discounts"]: [
                        ...JSON.parse(subscribedCompany?.fields?.Activity)["Tech Discounts"],
                        {
                          [companyDiscount.id]: {
                            Date: today.toDateString(),
                            Text: `${companyDiscount?.fields?.Name} worth ${currencyFormat(discountAmount)} from ${companyDiscount?.fields?.Source
                              }`,
                          },
                        },
                      ],
                    }),
                  },
                }),
              })
                .then((response) => console.log("Activity Status for Grants updated in airtable", response))
                .catch((error) => console.error(error));
            }
          });
      });
  };

  // This function sends out Grant Notifications
  const sendGrantNotifications = () => {
    setCompaniesCount(0);
    setGrantsSending(false);

    base("companies")
      .select({ view: "Grid view" })
      .all()
      .then(async (companyRecords) => {
        // Setting subscribed companies
        let subscribedCompanies = companyRecords.filter((record) => {
          if (
            !record.id ||
            !record?.fields?.Name ||
            !record?.fields?.Activity ||
            !record?.fields?.RequestEmail ||
            !record?.fields["subscribed?"]
          )
            return false;
          else if (record?.fields["subscribed?"] === "true") return true;
          else return false;
        });
        console.log(`There are ${subscribedCompanies.length} subscribed companies`);

        let allCompanyGrants = {};
        const today = new Date(); // Date of today

        // The time (in ms) of the date three weeks ahead from now.
        const oneWeekInMS = 7 * 24 * 60 * 60 * 1000;

        let counter = 0;
        for (const subscribedCompany of subscribedCompanies) {
          if (counter == 20) break;

          // Deadline check
          if (JSON.parse(subscribedCompany?.fields?.Activity)?.LatestActivity?.toLowerCase() !== "n/a") {
            // --> If we've never contacted them before, then proceed normally.
            // If it hasn't been a week since we last created a draft, then skip this company.
            if (today.getTime() - new Date(JSON.parse(subscribedCompany?.fields?.Activity)?.LatestActivity).getTime() < oneWeekInMS) {
              console.log(
                `Skipping ${subscribedCompany?.fields?.Name} because we've contacted them less than a week ago. To override this check, change the LastActivity field in the Activity object found in the Companies Table in Airtable.`
              );
              console.log(
                "Days since last contact",
                Math.floor(
                  (today.getTime() - new Date(JSON.parse(subscribedCompany?.fields?.Activity)?.LatestActivity).getTime()) / 86400000
                )
              );
              continue;
            }
          }

          console.log("Fetching for ", subscribedCompany?.fields?.Name);

          let singleCompanyGrants = find_grants(
            subscribedCompany.fields.Country,
            subscribedCompany.fields.ProvinceOrState,
            subscribedCompany.fields.Address,
            subscribedCompany.fields.Founded,
            subscribedCompany.fields.Industry,
            subscribedCompany.fields.Employees,
            subscribedCompany.fields["Profitable?"]
          )
            .filter((grant) => filterByDeadline(grant?.fields?.Deadline, 90))
            .filter((grant) => grant?.fields?.AnnualMargin && grant.fields.AnnualMargin != 0)
            .filter(
              (grant) =>
                !JSON.parse(subscribedCompany?.fields?.Activity)
                  ?.Grants?.map((recordDateObj) => Object.keys(recordDateObj)[0])
                  ?.includes(grant.recordId)
            )
            .sort((a, b) => compareDates(a?.fields?.Deadline, b?.fields?.Deadline));

          // Removing all grants that have been manually excluded (exclude grants column in companies table)
          if (subscribedCompany?.fields?.ExcludeGrants) {
            singleCompanyGrants = singleCompanyGrants.filter(
              (grant) =>
                subscribedCompany?.fields?.ExcludeGrants.toLowerCase().split(",").includes(grant?.fields?.Name?.toLowerCase()) === false
            );
          }

          if (singleCompanyGrants.length === 0) {
            continue;
          }

          // This is the grant that the client is notified of.
          const singleCompanyGrant = singleCompanyGrants[0];

          // Basic Info
          const firstName = subscribedCompany?.fields?.People1Name?.split(" ")[0];
          const lastName = subscribedCompany?.fields?.People1Name?.split(" ")[1];

          if (!firstName && !lastName) continue; // If there is no name associated with the company, then we skip the company.

          // This is month of the earliest deadline of the grants. Used for things like "... deadline coming up as soon as this September"
          const startingMonth = parseInt(singleCompanyGrants[0]?.fields?.Deadline.split("-")[1]);

          // Used for "... with a deadline coming up in roughly 2 months" ---> this is the where the "2" is calculated
          const numMonthsTillDeadline = startingMonth - new Date().getMonth();

          // Summation of the dollars that each grant is worth
          const grantAmount = singleCompanyGrant?.fields?.AnnualMargin;

          // This gives the date for one week from now. This is the deadline of the task in HubSpot. Adjust as required.
          const taskDeadline = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7).toDateString();

          // Retrieving when we last contacted the client (could be a date, could be "n/a")
          const LastActivity = JSON.parse(subscribedCompany?.fields?.Activity).LatestActivity;

          // The message regarding when the deadline is coming up.
          const deadline_message =
            numMonthsTillDeadline === 0
              ? "deadline coming up roughly this month"
              : numMonthsTillDeadline === 1
                ? "deadline coming up roughly next month"
                : `deadline coming up in ~${numMonthsTillDeadline} months`;

          // Updating the "Activity" field in Airtable. This adds today's date as the date when the email was sent out.
          fetch("https://api.airtable.com/v0/" + baseId + "/" + companiesTableId + "/" + subscribedCompany?.id, {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${apiKey}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              fields: {
                Activity: JSON.stringify({
                  ...JSON.parse(subscribedCompany?.fields?.Activity),
                  LatestActivity: today.toDateString(),
                  Grants: [
                    ...JSON.parse(subscribedCompany?.fields?.Activity)?.Grants,
                    {
                      [singleCompanyGrant.recordId]: {
                        Date: today.toDateString(),
                        Text: `${singleCompanyGrant?.fields?.Name} worth ${currencyFormat(singleCompanyGrant?.fields?.AnnualMargin)}`,
                      },
                    },
                  ],
                }),
              },
            }),
          })
            .then((response) => console.log("Activity Status for Grants updated in airtable", response))
            .catch((error) => console.error(error));

          // grant notifications
          fetch(`https://hooks.zapier.com/hooks/catch/13965335/31ay9l0/`, {
            method: "POST",
            body: JSON.stringify({
              Employees: subscribedCompany?.fields?.Employees,
              Industry: subscribedCompany?.fields?.Industry,
              Address: subscribedCompany?.fields?.Address,
              company: subscribedCompany?.fields?.Name,
              grant: singleCompanyGrant?.fields?.Name,
              grantDeadline: singleCompanyGrant?.fields?.Deadline,
              amount: currencyFormat(grantAmount),
              deadline_message: deadline_message,
              taskDeadline: taskDeadline,
              email: subscribedCompany?.fields?.RequestEmail || "prayaag@getupgraded.ca",
              LastActivity: LastActivity,
              numMonthsTillDeadline: numMonthsTillDeadline,
              month: monthMap[startingMonth],
              allGrants: singleCompanyGrants.map((grant) => grant?.fields?.Name).join(", "),
              firstName: firstName,
              lastName: lastName,
              Domain: subscribedCompany?.fields?.Domain,
              Headcount: subscribedCompany?.fields?.Headcount,
              Technologies: subscribedCompany?.fields?.Technologies,
              Description: subscribedCompany?.fields?.Description,
              Founded: subscribedCompany?.fields?.Founded,
              LinkedInUrl: subscribedCompany?.fields?.LinkedInUrl,
              CompanyType: subscribedCompany?.fields?.CompanyType,
            }),
          })
            .then((response) => console.log("Zapier Request Sent", response))
            .catch((error) => console.error(error));

          allCompanyGrants[subscribedCompany?.fields?.Name] = singleCompanyGrants;
          console.log(`For ${subscribedCompany?.fields?.Name}, we found the following Grants: `, singleCompanyGrants);

          setGrantsSending(true);
          await wait(getRandomInteger(15, 25));
          setGrantsSending(false);
          setCompaniesCount((prev) => prev + 1);
          counter++;
        }

        console.log("Object containing all subscribed companies and their corresponding matched grants", allCompanyGrants);
        // This object looks like this: {'Upgraded' : [grantobject1, grantobject2, ...], 'Okos' : [grantobject1, grantobject2, ...]}
      });
  };

  return (
    <div>
      <div className="inline-flex max-w-7xl px-4">
        <div className="mt-1 mr-4 ml-auto">
          <Menu as="div" className="absolute mr-1">
            <Menu.Button className="mr-3 text-gray-700 hover:text-gray-400">
              <span className="sr-only">Open settings</span>
              <Cog6ToothIcon aria-hidden="true" className="w-8 h-auto px-1 mr-1 hover:text-gray-400" />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="relative right-0 z-50 mt-0.5 w-sm origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <Menu.Item>
                  {({ active }) => {
                    return (
                      <button
                        className={"px-2 text-sm text-black w-full h-full " + (active === true ? "bg-gray-100" : "")}
                        onClick={() => setShowSubscribedList(true)}
                      >
                        <p className={"block px-3 py-1 text-sm leading-6 text-gray-900 w-full " + (active === true ? "bg-gray-50" : "")}>
                          Subscribed Companies List
                        </p>
                      </button>
                    );
                  }}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => {
                    return (
                      <button
                        className={"px-2 text-sm text-black w-full h-full " + (active === true ? "bg-gray-100" : "")}
                        onClick={() => console.log("hello")}
                      >
                        <p className={"block px-3 py-1 text-sm leading-6 text-gray-900 w-full " + (active === true ? "bg-gray-50" : "")}>
                          Master Drafts
                        </p>
                      </button>
                    );
                  }}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>

        <div className="flex flex-col">
          {grantsSending ? (
            <div className="mt-1 ml-5 rounded-s-xl rounded-e-xl bg-gray-600 px-4 py-2 text-sm font-semibold text-white shadow-md">
              Grant Notifs Sending... ({companiesCount} / 20)
            </div>
          ) : (
            <button
              onClick={() => handleTriggerClick("grants")}
              className="mt-1 ml-5 rounded-s-xl rounded-e-xl bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-md hover:bg-red-300 disabled:bg-gray-400"
            >
              Trigger Grant Notifications!
            </button>
          )}
          <button
            onClick={() => handleTriggerClick("tech savings")}
            className="mt-1 ml-5 rounded-s-xl rounded-e-xl bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-md hover:bg-red-300 disabled:bg-gray-400"
          >
            Trigger Tech Savings Notifications!
          </button>
          <button
            onClick={() => handleTriggerClick("automations")}
            className="mt-1 ml-5 rounded-s-xl rounded-e-xl bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-md hover:bg-red-300 disabled:bg-gray-400"
          >
            Trigger Automations Savings Notifications!
          </button>
        </div>

        {/* ////////////////////////////////////////////////////// */}
        {/*     SCRIPT TO PULL ADDITIONAL COMPANIES FROM AIRTABLE  */}
        {/* ////////////////////////////////////////////////////// */}
        {/* <button
          onClick={() => {
            const tableName = "Apollo 500";
            base(tableName)
              .select({ view: "Grid view" })
              .all()
              .then(async (data) => {
                console.log(tableName, data);

                for (let i = 201; i < 300; i++) {
                  const domain = getWebURL(data[i].fields.Website);
                  const peopleName = data[i].fields["First Name"] + data[i].fields["Last Name"];
                  const email = data[i].fields.Email;

                  console.log(domain, email);
                  if (domain === false || !email) continue;

                  window.open(`/?website=${domain}&unblur=true`);

                  await wait(2);

                  // let id = "";
                  // base(tableName)
                  // .select({ view: "Grid view" })
                  // .all()
                  // .then((data) => {
                  //   id = data.filter(record => record.fields.Domain === domain)[0].id;
                  // });

                  //   fetch("https://api.airtable.com/v0/" + baseId + "/" + companiesTableId + "/" + id, {
                  //     method: "PATCH",
                  //     headers: {
                  //       Authorization: `Bearer ${apiKey}`,
                  //       "Content-Type": "application/json",
                  //     },
                  //     body: JSON.stringify({
                  //       fields: {
                  //         Email: email,
                  //         People1Name: peopleName,
                  //       },
                  //     }),
                  //   })
                  //     .then((response) => console.log(response))
                  //     .catch((error) => console.error(error));
                }
              });
          }}
          className="inline-block mt-1 ml-5 flex rounded-s-xl rounded-e-xl bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-md hover:bg-red-300 disabled:bg-gray-400"
        >
          <ExclamationTriangleIcon aria-hidden="true" className="w-4 mt-1 mx-1 h-auto" /> Create Reports
        </button> */}
        {/* ///////////////////////////////////////////////////////*/}
        {/*     SCRIPT TO PULL ADDITIONAL COMPANIES FROM AIRTABLE  */}
        {/* ////////////////////////////////////////////////////// */}

      </div>

      {/* Trigger Modal */}
      {openConfirmation && typeOfFries !== "" && (
        <Transition.Root show={openConfirmation} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpenConfirmation}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    {/* X Button */}
                    {/* <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => setOpenConfirmation(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div> */}

                    {/* Modal Body */}
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Trigger Check
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">{`Are you sure you want to run this Trigger?`}</p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      {/* Run button */}
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                        onClick={() => {
                          sendNotifications();
                        }}
                      >
                        Run
                      </button>

                      {/* Cancel Button */}
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => handleCancelClick()}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}

      {/* Subscribed Companies Modal */}
      {showSubscribedList && <SubscribedPeopleModal open={showSubscribedList} setOpen={setShowSubscribedList} />}
    </div>
  );
}

export default NotificationButtons;
