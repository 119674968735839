import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import { currencyFormat, setContents, setShowNotif, checkEmail } from "./helpers";
import Notification from "./Notification.js";

export default function SendReferralEmailDialog({ open, userEmail, setOpen, upgrade, company, upgradeType, setLoading, showReferralNotification, setShowReferralNotification, enabled }) {

	const [clientEmail, setClientEmail] = useState(company?.fields?.RequestEmail || "");
	const cancelButtonRef = useRef(null);

	const noReferralEmailsHeading = `We couldn't find a partner for this ${upgradeType}`;
	const referralEmailsHeading = `Introduce You to Our Partners`;
	const noReferralEmailsText = `We don't have a partner ready to take on this ${upgradeType} yet. Would you like us to send you an email when we do?`;
	const referralEmailsText = `We have a partner ready to take on this ${upgradeType} for you! Should we send an introductory email expressing your company's interest?`;
	const referralPartnerAvailable = Boolean(upgrade?.fields?.referralEmails);

	const dialogHeading = referralPartnerAvailable ? referralEmailsHeading : noReferralEmailsHeading;
	const dialogBody = referralPartnerAvailable ? referralEmailsText : noReferralEmailsText;

	const sendReferralEmails = async (upgrade, companyRecord) => {
		// const webhook = `https://hooks.zapier.com/hooks/catch/16093819/${process.env.REACT_APP_ZAPIER_REFERRAL}/`;
		// const anonWebhook = `https://hooks.zapier.com/hooks/catch/16093819/${process.env.REACT_APP_ZAPIER_REFERRAL_EMAILS}/`;

		// Official Partners
		// https://hooks.zapier.com/hooks/catch/16093819/3x1y7lx/

		// Prospect Partners
		// https://hooks.zapier.com/hooks/catch/13965335/3pcqi9a/

		const anonWebhook = `https://hooks.zapier.com/hooks/catch/13965335/3pcqi9a/`;
		const webhook = `https://hooks.zapier.com/hooks/catch/16093819/3x1y7lx/`;

		const emails = upgrade?.fields?.referralEmails?.split(",")?.map((item) => item?.trim());
		const anonReferralEmails = upgrade?.fields?.anonReferralEmails?.split(",")?.map((item) => item?.trim());

		if (!Boolean(emails)) {
			setContents(
				{
					type: "error",
					heading: "Invalid Emails",
					message: "Tried to send introduction to invalid email address(es). Please try again later.",
				},
				setShowReferralNotification
			);
			setShowNotif(true, setShowReferralNotification);
			return;
		}

		// Send Referral Emails to Partners (signed partners)
		if (enabled) {
			try {
				for (const email of emails) {
					const fetchResponse = await fetch(webhook, {
						method: "POST",
						body: JSON.stringify({
							clientCompanyName: companyRecord.fields.Name,
							clientPerson: companyRecord?.fields?.People1Name || `The team`,
							clientWebsite: companyRecord?.fields?.Domain,
							clientLinkedIn: companyRecord?.fields?.LinkedInUrl,
							clientCompanySize: companyRecord?.fields?.Employees,
							clientAddress: companyRecord?.fields?.Address,
							clientCountry: companyRecord?.fields?.Country,
							clientFounded: companyRecord?.fields?.Founded,
							clientDescription: companyRecord?.fields?.Description,
							clientIndustries: companyRecord?.fields?.Industry,
							clientEstRevenue: companyRecord?.fields?.AnnualRevenue,
							clientTechnologies: companyRecord?.fields?.Technologies,

							clientTraffic: companyRecord?.fields?.AnnualTraffic,
							clientConversionRate: companyRecord?.fields?.clientConversionRate,
							clientConversionRate: companyRecord?.fields?.ListSize,

							clientEmail: clientEmail,
							partnerEmail: email,
							partnerEmails: emails,
							anonPartnerEmails: anonReferralEmails,

							upgradeName: upgrade?.fields?.Name,
							amount: currencyFormat(upgrade?.fields?.AnnualMargin || upgrade?.fields?.Amount),
							upgradeType: upgradeType,

						}),
					});

					if (!fetchResponse.ok) throw new Error("Error : Bad Request");

					const fetchData = await fetchResponse.json();
					console.log(fetchData);
				}

				const request = `${userEmail} just requested for a Top Upgrade Saving for ${company?.fields?.Name}!`
				const website = `Top Upgrade Upgrade: ${currencyFormat(upgrade?.fields?.AnnualMargin)}/yr ${upgrade?.fields?.Name}`

				fetch(`https://hooks.zapier.com/hooks/catch/13965335/${process.env.REACT_APP_ZAPIER_SAVINGS}/`, {
					method: "POST",
					body: JSON.stringify({ request, website }),
				});

				fetch('https://hooks.zapier.com/hooks/catch/16093847/3vv9abn/', {
					method: "POST",
					body: JSON.stringify({
						CompanyName: company?.fields?.Name,
						ClientEmail: userEmail,
						PartnerEmail: emails.join(','),
						UpgradeName: upgrade?.fields?.Name,
						Amount: currencyFormat(upgrade?.fields?.AnnualMargin),
						ClientName: '',
						Date: new Date().toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }),
						UpgradeType: "Top Upgrade",
					})
				})


				setContents(
					{
						type: "success",
						heading: "Sent an Email to our Partners. Check your Inbox!",
						message: `Email(s) sent to ${emails.join(", ")} from Upgraded. Check your inbox.`,
					},
					setShowReferralNotification
				);
				setShowNotif(true, setShowReferralNotification);
				console.log("here");
			} catch (e) {
				console.log("Error in sending Referral Zap: ");
				console.dir(e);
				setContents({ type: "error", heading: "Email(s) not sent", message: JSON.stringify(e) }, setShowReferralNotification);
				setShowNotif(true, setShowReferralNotification);
			}
		}

		if (enabled) {
			// Send Referral Emails to Anon Partners (potential partners)
			try {
				for (const email of anonReferralEmails) {
					const fetchResponse = await fetch(anonWebhook, {
						method: "POST",
						body: JSON.stringify({
							clientCompanyName: companyRecord.fields.Name,
							clientPerson: companyRecord?.fields?.People1Name || `The team`,
							clientWebsite: companyRecord?.fields?.Domain,
							clientLinkedIn: companyRecord?.fields?.LinkedInUrl,
							clientCompanySize: companyRecord?.fields?.Employees,
							clientAddress: companyRecord?.fields?.Address,
							clientCountry: companyRecord?.fields?.Country,
							clientFounded: companyRecord?.fields?.Founded,
							clientDescription: companyRecord?.fields?.Description,
							clientIndustries: companyRecord?.fields?.Industry,
							clientEstRevenue: companyRecord?.fields?.AnnualRevenue,
							clientTechnologies: companyRecord?.fields?.Technologies,

							clientTraffic: companyRecord?.fields?.AnnualTraffic,
							clientConversionRate: companyRecord?.fields?.clientConversionRate,
							clientListSize: companyRecord?.fields?.ListSize,

							clientEmail: clientEmail,
							partnerEmail: email,
							partnerEmails: emails,
							anonPartnerEmails: anonReferralEmails,

							upgradeName: upgrade?.fields?.Name,
							amount: currencyFormat(upgrade?.fields?.AnnualMargin || upgrade?.fields?.Amount),
							upgradeType: upgradeType,

						}),
					});

					if (!fetchResponse.ok) throw new Error("Error : Bad Request");

					const fetchData = await fetchResponse.json();
					console.log(fetchData);
				}

			} catch (e) {
				console.log("Error in sending Referral Zap: ");
				console.dir(e);
				setContents({ type: "error", heading: "Email(s) not sent", message: JSON.stringify(e) }, setShowReferralNotification);
				setShowNotif(true, setShowReferralNotification);
			}
		}



	};

	const handleSubmit = async () => {
		if (!checkEmail(clientEmail) && clientEmail !== "") {
			setContents(
				{ type: "error", heading: "Invalid Email", message: "Please enter a valid email or leave the input empty." },
				setShowReferralNotification
			);
			setShowNotif(true, setShowReferralNotification);
			return;
		}

		if (referralPartnerAvailable === false) {
			console.log("We will remind you when our partners are ready for this grant!");
		} else {
			setLoading(true);
			await sendReferralEmails(upgrade, company);
			setLoading(false);
		}
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-40" initialFocus={cancelButtonRef} onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-40 w-screen overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
								<div>
									<div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
										<EnvelopeIcon className="h-6 w-6 fill-blue-400" aria-hidden="true" />
									</div>
									<div className="mt-3 text-center sm:mt-5">
										<Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
											{dialogHeading}
										</Dialog.Title>
										<div className="mt-2">
											<p className="text-sm text-gray-500">{dialogBody}</p>
										</div>
										{referralPartnerAvailable && (
											<div>
												<br />
												<div className="mt-2">
													<p className="text-sm text-gray-500">
														If you'd like, we'll include you in the introduction email to keep us all on the same page.
													</p>
													<div className="mt-3">
														<p className="text-xs text-left text-gray-500">Enter your email or leave blank</p>
														<input
															type="text"
															className="p-2 text-sm rounded-lg flex max-w-lg w-full"
															value={clientEmail}
															onChange={(e) => {
																e.preventDefault();
																setClientEmail(e.target.value);
															}}
														/>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
									<button
										type="button"
										className="inline-flex w-full justify-center rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-700 sm:col-start-2"
										onClick={async () => {
											await handleSubmit();
											setOpen(false);
										}}
									>
										{referralPartnerAvailable ? "Proceed" : "Remind Me"}
									</button>
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
										onClick={() => setOpen(false)}
										ref={cancelButtonRef}
									>
										Cancel
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
