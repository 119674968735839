import React, { useEffect, useState } from 'react';
import { useSignIn, useUser, useClerk, SignedIn } from '@clerk/clerk-react';
import { extractDomain } from './helpers';
import { useNavigate } from 'react-router-dom';
import useBase from "./useBase";
const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const usersTableId = process.env.REACT_APP_AIR_USERS;

function CheckOauthGoogle() {
  const { isSignedIn, isLoaded, user } = useUser();
  const navigate = useNavigate();
  const clerk = useClerk();
  const [users, setUsers] = useState();
  const [hasChecked, setHasChecked] = useState(false);
  const { loading, error: usersError } = useBase("users", setUsers);

  useEffect(() => {
    if (loading || !isSignedIn || !isLoaded || hasChecked || !users) return;
    const checkAndAddUser = async () => {
      const userEmail = user.primaryEmailAddress.emailAddress;
      const userDomain = userEmail.split('@')[1].toLowerCase();

      const invalidDomains = [
        "gmail.com",
        "outlook.com",
        "outlook.live.com",
        "hotmail.com",
        "yahoo.com",
        "yahoo.co.ca",
        "yahoo.ca",
        "myyahoo.com",
      ];

      if (invalidDomains.includes(userDomain)) {
        alert('Please use your corporate email address.');
        const companyDomain = extractDomain(userEmail);

        // log email even if it is gmail account
        const usersWithSameEmail = users.filter((user) => user?.fields?.Email === userEmail);
        if (usersWithSameEmail.length === 0) {
          try {
            await fetch("https://api.airtable.com/v0/" + baseId + "/" + usersTableId, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${apiKey}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                fields: {
                  Email: userEmail,
                  Domain: companyDomain,
                },
              }),
            });
          } catch {}
        }
        await user.delete();
        // await clerk.signOut();
        navigate('/');
        return;
      }

      const companyDomain = extractDomain(userEmail);
      const usersWithSameEmail = users.filter((user) => user?.fields?.Email === userEmail);
      if (usersWithSameEmail.length !== 0) {
        navigate(`/?website=${companyDomain}`);
        return;
      }
      try {
        await fetch("https://api.airtable.com/v0/" + baseId + "/" + usersTableId, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fields: {
              Email: userEmail,
              Domain: companyDomain,
            },
          }),
        });
      } catch {}

			navigate(`/?website=${companyDomain}`);

    };

    (async () => {
      await checkAndAddUser();
    })();
    setHasChecked(true);
  }, [isLoaded, loading, isSignedIn,]);

  return <div>Loading...</div>;
}

export default CheckOauthGoogle;
