import React, { useState } from 'react';

const ReferredModal = ({ isOpen, onClose, referralInfo, referralCount }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded shadow-lg w-11/12 md:w-2/3 lg:w-1/2">
        <h2 className="text-2xl font-bold mb-4">Referral Status</h2>
        <h3 className="text-xl font-bold mb-4">Status - In Progress</h3>
        <h4 className="text-xl font-bold mb-4">{referralCount}/{referralInfo.referrable} Connections Referred</h4>
        <p className='mt-10'>Prior to button click, you've referred {referralInfo.referred} connections</p>
        <p className='mt-10'>{referralInfo.referrable} referrable connections are being referred, please wait for the process to finish</p>
        <p className='mt-10'>{referralInfo.unreferrable} connections aren't enriched with a company domain and a work email yet, so they cannot be referred</p>
        <div className="mt-4 flex justify-end">
          <button
            onClick={onClose}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReferredModal;