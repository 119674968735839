export const incorporationLocationAnswers = ["Canada", "United States", "Other"];
export const fundingUseCaseAnswers = [
  "Improve Cash Flow",
  "Business Expansion",
  "Payroll",
  "Inventory",
  "Start a Business",
  "Purchase a Business",
  "Buy Out a Partner",
  "Equipment Leasing",
  "Equipment Purchase",
  "Real Estate (Property Finance)",
  "Leasehold Improvement",
  "Finance Accouts Receivable",
  "Debt Finance",
  "Other",
];
export const loanImportanceFactorsAnswers = [
  "Low Interest Rate",
  "Fast Funding",
  "Amount of Loan",
  "Easy Process",
  "Type of Security Needed",
];

export const businessJourneyStageAnswers = [
  "Just Getting Started",
  "Looking For Funding ASAP",
  "Looking to See What My Options Are",
  "Talked to a Few Lenders but Haven't Seen What I Want Yet",
  "Other",
];

export const timeInBusinessAnswers = [
  "Less Than 6 Months",
  "6 Months to 1 Year",
  "1 to 3 Years",
  "More Than 3 Years",
];

export const lastYearAproxRevenueAnswers = [
  "$0 (Pre-Revenue)",
  "$1 to $10,000",
  "$10,000 to $100,000",
  "$100,000 to $250,000",
  "$250,000 to $500,000",
  "$500,000 to $1,000,000",
  "$1,000,000 to $2,000,000",
  "$2,000,000 to $5,000,000",
  "$5,000,000+",
];

export const lastYearAproxProfitAnswers = [
  "Not Profitable Last Year",
  "Broke Even",
  "Less Than $10,000",
  "$10,000 to $100,000",
  "$100,000 to $250,000",
  "$250,000 to $500,000",
  "$500,000 to $1,000,000",
  "$1,000,000 to $2,000,000",
  "$2,000,000+",
];

export const businessRepresentationAnswers = [
  "Small & Medium Business (Established Businesses who are Local Retail Stores, Restaurants, Plumbers, Consultants)",
  "Software, Innovation or Online Retailer (Digital-Focused Businesses Leveraging Tech and Online Marketplaces)",
  "Starting a new Business (Brand New & Early-Phase Business)",
];

export const revenueModelAnswers = [
  "Business-to-Business",
  "Business-to-Consumer / Direct-to-Consumer",
  "Subscription",
  "E-Commerce",
  "Other",
];

export const yearOverYearGrowthAnswers = [
  "Less Than 0%",
  "0% - 5%",
  "5% - 10%",
  "10% - 20%",
  "20% - 30%",
  "30% - 50%",
  "Above 50%",
];

export const cashRunwayAnswers = [
  "1 to 6 Months",
  "6 to 12 Months",
  "12 to 18 Months",
  "More Than 18 Months",
  "Profitable (Not Applicable)",
];

export const averageMonthlyRevenueLastThreeMonthsAnswers = [
  "$0 (Pre-Revenue)",
  "Less Than $1,000",
  "$1,000 to $8,000",
  "$8,000 to $15,000",
  "$15,000 to $25,000",
  "$25,000 to $50,000",
  "$50,000 to $100,000",
  "$100,000 to $250,000",
  "$250,000+",
];

export const currentReceivablesBalanceAnswers = [
  "I Don't Have Any Accounts Receivable Balance",
  "Less Than $50,000",
  "$50,000 to $100,000",
  "$100,000 to $250,000",
  "$250,000 to $1,000,000",
  "$1,000,000 to $2,000,000",
  "$2,000,000+",
];

export const fixedAssetsValueAnswers = [
  "I Don't Have Any Assets.",
  "Less Than $50,000",
  "$50,000 to $125,000",
  "$125,000 to $250,000",
  "$250,000 to $500,000",
  "$500,000 to $1,000,000",
  "$1,000,000 to $5,000,000",
  "$5,000,000+",
];

export const expectedGrantsAnswers = [
  "I Don't Have SR&ED or Other Grant Claims.",
  "Less Than $50,000",
  "$50,000 to $100,000",
  "$100,000 to $1,000,000",
  "$1,000,000 to $2,000,000",
  "$2,000,000+",
];
