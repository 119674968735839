import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { shortenText, setShowNotif, setContents } from "./helpers";

export default function AddSampleAnswerModal({ open, setOpen, question, _setQuestion, setShowSubmitNotification }) {
	const apiKey = process.env.REACT_APP_AIR_KEY;
	const baseId = process.env.REACT_APP_AIR_BASEID;
	const questionsTableId = process.env.REACT_APP_AIR_QUESTIONS;

	const cancelButtonRef = useRef(null);

	const updateSampleAnswer = async (question, newSampleAnswer) => {
		if (question.id) {
			try {
				const response = await fetch("https://api.airtable.com/v0/" + baseId + "/" + questionsTableId + "/" + question.id, {
					method: "PATCH",
					headers: {
						Authorization: `Bearer ${apiKey}`,
						"Content-Type": "application/json",
					},

					body: JSON.stringify({
						fields: {
							SampleAnswer: newSampleAnswer,
						},
					}),
				});

				let contents = {
					type: "success",
					heading: "Sample Answer Updated",
					message: `Sample answer for Q: '${shortenText(question.fields.QuestionText, 50)}' changed to ${newSampleAnswer}`,
				};

				setShowNotif(true, setShowSubmitNotification);
				setContents(contents, setShowSubmitNotification);
			} catch (error) {
				console.error("Error in Adding Sample Answer", error);

                let contents = {
					type: "error",
					heading: "Unable to change sample answer. Please try again later.",
					message: JSON.stringify(error),
				};

				setShowNotif(true, setShowSubmitNotification);
				setContents(contents, setShowSubmitNotification);

			}
		}

		let newQuestion = question;
		newQuestion.fields.SampleAnswer = newSampleAnswer;

		_setQuestion(newQuestion);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		const newSampleAnswer = event.target["sampleAnswerInput"].value;
		await updateSampleAnswer(question, newSampleAnswer);
		setOpen(false);
	};

	return (
		<>
			<Transition.Root show={open} as={Fragment}>
				<Dialog as="div" className="relative z-40" initialFocus={cancelButtonRef} onClose={setOpen}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
									<form onSubmit={handleSubmit}>
										<div className="sm:flex sm:items-start">
											<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
												<Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
													Add Sample Answer for Question: "{shortenText(question.fields.QuestionText, 100)}"
												</Dialog.Title>
												<div className="mt-2">
													<p className="text-sm text-gray-500">
														Add or edit the sample answer for this question below. Click 'Submit' to save changes and 'Cancel' to ignore
														changes.
													</p>
													<div className="mt-2">
														<textarea
															id="sampleAnswerInput"
															defaultValue={question?.fields?.SampleAnswer}
															className="rounded-lg max-w-screen w-full resize-y"
															rows="3"
														/>
													</div>
												</div>
											</div>
										</div>

										<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
											<button
												type="submit"
												className="inline-flex w-full justify-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 sm:ml-3 sm:w-auto"
											>
												Submit
											</button>
											<button
												type="button"
												className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
												onClick={() => setOpen(false)}
												ref={cancelButtonRef}
											>
												Cancel
											</button>
										</div>
									</form>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
}
