import { useEffect } from 'react';

export default function AutomationSuccess() {
  useEffect(() => {
    const pendingAutomation = localStorage.getItem('pendingAutomation');
    if (pendingAutomation) {
      // Send message to opener window
      if (window.opener) {
        window.opener.postMessage({
          type: 'PAYMENT_SUCCESS',
          data: JSON.parse(pendingAutomation)
        }, '*');
        
        // Clean up
        localStorage.removeItem('pendingAutomation');
        
        // Close this window after a short delay
        setTimeout(() => {
          window.close();
        }, 1000);
      } else {
        // If opener is not available, redirect back
        window.location.href = '/';
      }
    }
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-2xl font-bold text-gray-900">Payment Successful!</h1>
        <p className="mt-2 text-gray-600">Processing your order...</p>
      </div>
    </div>
  );
} 