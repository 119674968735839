import React from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";

function EditGrantFeatureCard({ grant, feature, updateGrantFeature, defaultText}) {
	return (
		<div className="my-3">
			<div className="text-sm">
				<strong>{feature}</strong> : &nbsp; <br />
			</div>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					let newValue = e.target[feature + grant?.id].value;

					updateGrantFeature(feature, grant?.recordId, newValue);
				}}
			>
				<div className="sm:grid sm:grid-flow-row-dense sm:grid-row-2 sm:gap-3">
					<div className="sm:row-start-1">
						<textarea
							rows="6"
							cols="50"
							defaultValue={defaultText}
							type="text"
							id={feature + grant?.id}
							className="p-1 rounded-xl border-2 border-gray-700 text-black"
						/>
					</div>

					<div className="inline-flex sm:row-start-2">
						{/* Clear (X) Button beside input tag */}
						<button
							className="mt-auto hover:bg-gray-100"
							onClick={(e) => {
								e.preventDefault();
								document.getElementById(feature + grant?.id).value = "";
							}}
						>
							<XMarkIcon className="h-6 w-auto m-auto" />
						</button>
						<button
							type="submit"
							className="mx-2 p-2 max-w-md w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
						>
							Update Grant {feature}
						</button>
					</div>
				</div>
			</form>
		</div>
	);
}

export default EditGrantFeatureCard;
