import { useEffect, useState } from "react";
import {
    commentStyle,
    nextButtonStyle,
    prevButtonStyle,
    questionStyle,
    selecliStyle,
    selectDiv1Style,
    selectDiv2Style,
    selectLabelStyle,
    selectUlStyle,
  } from "../styles";
  
  export function CheckboxSteps({
    formData,
    handleChangeInput,
    nextStep,
    prevStep,
    checkHowManySelected,
    data,
    question,
    answers,
    onlySelectOne = false,
  }) {
    const [names, setNames] = useState([]);
    useEffect(() => {
      setNames(Object.keys(formData[data]));
      console.log('names', names);
      console.log('data', data);
    }, [data])
    return (
      <>
        <div>
          {data === "Loan Importance Factors" ? (
            <h3 class={`${commentStyle}`}>
              {`Sounds like a plan! Tell us about how you would like things to go to help us tailor your experience`}
            </h3>
          ) : data === "Last Year Aprox Revenue" ? (
            <h3
              class={`${commentStyle}`}
            >{`With specific numbers we can screen you more accurately`}</h3>
          ) : data === "Revenue Model" ? (
            <h3
              class={`${commentStyle}`}
            >{`Many of our lenders have recently given loans to businesses exactly like yours, so let's find out more!`}</h3>
          ) : data === "Current Receivables Balance" ? (
            <h3 class={`${commentStyle}`}>
              {`Perfect! There are many different ways to secure a loan. Let's see what's available at ${formData.operatingBusinessName} for possible collateral.`}
            </h3>
          ) : (
            <h3 class="my-10"></h3>
          )}
          <h1 class={`${questionStyle}`}>{question}</h1>
          {onlySelectOne === false ? (
            <h3 class="mb-5 text-md font-semibold text-gray-900 dark:text-white flex justify-center">
              Choose as many as you like
            </h3>
          ) : (
            ""
          )}
          <ul class={`${selectUlStyle}`}>
            {names.map((varName, index) => (
              <li class={`${selecliStyle}`}>
                <input
                  type="checkbox"
                  name={data}
                  id={varName}
                  class="hidden peer"
                  checked={formData[data][varName]}
                  onClick={(event) => {
                    handleChangeInput(event);
                  }}
                />
                <label for={varName} class={`${selectLabelStyle}`}>
                  <div class={`${selectDiv1Style}`}>
                    <div class={`${selectDiv2Style}`}>{answers[index]}</div>
                  </div>
                </label>
              </li>
            ))}
          </ul>
          <button type="button" class={`${prevButtonStyle}`} onClick={prevStep}>
            Prev
          </button>
          <button
            type="button"
            class={`${nextButtonStyle}`}
            onClick={nextStep}
            disabled={
              onlySelectOne
                ? checkHowManySelected(data) !== 1
                : checkHowManySelected(data) === 0
            }
          >
            Next
          </button>
        </div>
      </>
    );
  }
  