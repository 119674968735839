import { useState } from "react";
import { Accordion, AccordionBody } from "@material-tailwind/react";
import { FolderOpenIcon, FolderIcon } from "@heroicons/react/20/solid";
import { removeQueryParameters } from "./helpers";

const GrantQuestionsCard = ({ company, grant, grantQuestions, submitAnswer, searchValue }) => {
	const [openAnswers, setOpenAnswers] = useState(true);

	const getNumberOfUnansweredQuestions = (questions) => {
		return questions.filter((q) => !q.answer).length;
	};

	const getNumberOfAnsweredQuestions = (questions) => {
		return questions.filter((q) => Boolean(q.answer)).length;
	};

	return (
		<div key={grant.id}>
			<div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
				<div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3"></div>
				<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
					<div>
						<button className="inline-flex rounded-md hover:bg-gray-200" type="button" onClick={() => setOpenAnswers((prev) => !prev)}>
							<div className="flex justify-center text-center items-center mt-1 mx-3">
								{openAnswers ? (
									<FolderOpenIcon className="h-4 w-4 fill-blue-200" aria-hidden />
								) : (
									<FolderIcon className="h-4 w-4 fill-gray-600" aria-hidden />
								)}
							</div>
							<div>
								<h2 className="text-base text-left font-semibold leading-7 text-gray-900">{grant.fields.Name}</h2>
								<ul>
									<li className="">
										<p className="text-left text-sm leading-7">{getNumberOfUnansweredQuestions(grantQuestions)} Unanswered Question(s)</p>
									</li>
									<li>
										<p className="text-left text-sm leading-7">{getNumberOfAnsweredQuestions(grantQuestions)} Answered Question(s)</p>
									</li>
								</ul>
							</div>
						</button>
					</div>

					<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
						<Accordion open={openAnswers} className="col-span-full">
							<AccordionBody className="">
								<div>
									{grantQuestions.length === 0 ? (
										searchValue === "" ? (
											<div>No Questions for this grant. 🫡</div>
										) : (
											<div>No Results Found</div>
										)
									) : (
										grantQuestions.map((question) => {
											return (
												<div key={question.id} className="mt-4">
													<label htmlFor={`${grant.id},${question.id}`} className="block text-sm font-medium leading-6 text-gray-900">
														{question.fields.QuestionText}
													</label>
													<div className="overflow-visible grid grid-cols-8 mt-1 inline-flex max-w-screen w-full">
														<input
															type="text"
															name={`${grant.id},${question.id}`}
															id={`${grant.id},${question.id}`}
															defaultValue={question.answer?.fields?.AnswerText}
															className="block w-full col-span-7 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
														/>
														<div className="flex text-left overflow-visible max-w-lg w-full">
															<button
																onClick={async () => {
																	const newAnswerText = document.getElementById(`${grant.id},${question.id}`).value;
																	await submitAnswer(company.id, question.fields.QuestionID, `${grant?.fields?.Name}-${removeQueryParameters(grant?.fields?.GrantUrl)}`, question.answer, newAnswerText);
																}}
																type="button"
																className="overflow-visible max-w-lg w-full rounded bg-white mx-4 px-1.5 py-1 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
															>
																{question.answer ? "Resubmit" : "Submit"}
															</button>
														</div>
													</div>
												</div>
											);
										})
									)}
								</div>
							</AccordionBody>
						</Accordion>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GrantQuestionsCard;
