import { React, useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { getQueryVariable } from './helpers.js';
import Airtable from 'airtable';
import 'react-loading-skeleton/dist/skeleton.css';
import { AppContext } from './AppContext.js';
import { useUser } from '@clerk/clerk-react';

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? 'rotate-180' : ''
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

export default function Perks(
  {
    // admin,
    // user,
    // company
  }
) {
  const cancelButtonRef = useRef(null);
  const { clerkUser, setClerkUser, company, setCompany, setGrantLoading } =
    useContext(AppContext);
  const { isSignedIn, isLoaded, user } = useUser();
  const apiKey = process.env.REACT_APP_AIR_KEY;
  const baseId = process.env.REACT_APP_AIR_BASEID;
  const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;
  const base = new Airtable({ apiKey }).base(baseId);
  const [jwtKey, setJwtKey] = useState('');
  const [dealPath, setDealPath] = useState(
    `https://getupgraded.builtfirst.com`
  );
  const [domain, setDomain] = useState('');

  useEffect(() => {
    if (!isLoaded) return;

    if (!company) {
      base('companies')
        .select({
          view: 'Grid view',
          filterByFormula: `REGEX_MATCH({Domain}, '^${getQueryVariable(
            'website'
          )}$')`,
        })
        .all()
        .then((records) => {
          const curCompany = records[0];
          setCompany(curCompany);
        });
      return;
    } else if (!clerkUser) {
      setClerkUser(user);
      return;
    }

    if (!jwtKey) {
      const payload = {
        user_email: clerkUser.primaryEmailAddress.emailAddress,
        user_first_name: clerkUser.fullName?.split(' ')[0] || 'Jamie',
        user_last_name: clerkUser.fullName?.split(' ')[1] || 'Cooks',
        user_external_id: clerkUser.id,
        company_external_id: company.id,
        company_name: company.fields.Name,
        company_website: 'https://' + company.fields.Domain,
      };

      const fetchJwt = async () => {
        console.log('getting jwt');
        try {
          const response = await axios.post(
            'https://murmuring-plateau-21252-b7994639367f.herokuapp.com/get-jwt',
            payload
          );
          setJwtKey(response.data.token);
          console.log('JWT: ', response.data.token);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
        setGrantLoading(false);
      };

      fetchJwt();
    } else {
        setDealPath(
          getQueryVariable('dealPath')
            ? `${getQueryVariable('dealPath') + `?cid=2281&jwt=${jwtKey}`}`
            : `https://getupgraded.builtfirst.com?cid=2281&jwt=${jwtKey}`
        );
        const type = getQueryVariable('type');
        if (type === '1') {
          setDomain('https://getupgraded.builtfirst.com/');
        } else if (type === '2') {
          setDomain('https://deals.getupgraded.ca/');
        }
    }
    
  }, [clerkUser, company, jwtKey]);

  useEffect(() => {
    return () => {
      const url = new URL(window.location);
      url.searchParams.delete('dealPath');
      url.searchParams.delete('type');
      window.history.replaceState({}, '', url);
    };
  }, []);

  return (
    <>
      <div className="bg-white py-14 py-12 lg:px-8">
        <div className="mx-auto text-center">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Check out our Perks
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Upgraded partners with leading brands to help you save time and
            money as you launch and grow your business.
          </p>
          <iframe
            src={domain + dealPath}
            height="2000px"
            width="100%"
            title="Builtfirst Offers iFrame"
          ></iframe>
        </div>
      </div>
    </>
  );
}

Perks.defaultProps = {
  admin: false,
};
