import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import {
  SignedIn,
  SignedOut,
  AuthenticateWithRedirectCallback,
  useClerk,
} from '@clerk/clerk-react';

import App from './App.js';
import CompaniesPage from './CompaniesPage.jsx';
import Leaderboard from './LeaderboardPage.jsx';
import ProspectLeaderboard from './ProspectLeaderboardPage.jsx';
import SignUpPage from './SignUpPage.jsx';
import SignInPage from './SignInPage.jsx';
import NewAppPage from './NewAppPage.jsx';
import FeedBackPage from './FeedbackPage.jsx';
import AllGrants from './AllGrants.jsx';
import Layout from './Layout.js';
import UnsubPage from './UnsubPage.jsx';
import InterestedPage from './InterestedPage.jsx';
import GrantAnswersPage from './GrantAnswersPage.jsx';
import CompanyInfoPage from './CompanyInfoPage.jsx';
import NotFoundPage from './NotFoundPage.jsx';
import Reroute from './Reroute.jsx';
import ForgotPasswordPage from './ForgotPasswordPage.jsx';
import CheckOAuthGoogle from './CheckOAuthGoogle.jsx';
import { AppProvider } from './AppContext.js';
import MyNetwork from './MyNetwork.js';
import GrantWriterNetwork from './GrantWriterNetwork.js';
import Perks from './Perks.js';
import Settings from './Settings.js';
import PlansPage from './PlansPage.jsx';
import FinancingRequests from './FinancingRequests.jsx';
import AutomationRequests from './AutomationRequests.jsx';
import AutomationSuccess from './AutomationSuccess.jsx';

function SSOCallback() {
  const { handleRedirectCallback } = useClerk();

  const navigate = useNavigate();

  useEffect(() => {
    const processCallback = async () => {
      try {
        await handleRedirectCallback({
          redirectUrl: '/check_oauth_google',
        });
      } catch (error) {
        console.error('Error handling SSO callback:', error);
        navigate('/check_oauth_google'); // Redirect to an error page or handle the error appropriately
      }
    };

    processCallback();
  }, []);

  return <div>sso loading...</div>;
}

const Router = () => {
  useEffect(() => {
    //"fetch everything from the router and pass as props to child components"//
  }, []);

  return (
    <BrowserRouter>
      <SignedOut>
        <Routes>
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route path="/sign-in" element={<SignInPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/unsubscribe" element={<UnsubPage />} />
          <Route path="/api/partner-interested" element={<InterestedPage />} />
          <Route path="/sso-callback" element={<SSOCallback />} />
          <Route path="/*" element={<Reroute />} />
        </Routes>
      </SignedOut>
      <SignedIn>
        <AppProvider>
          <Routes>
            <Route path="/report-pending" element={<FeedBackPage />} />
            <Route path="/company-info/*" element={<CompanyInfoPage />} />
            <Route path="/unsubscribe" element={<UnsubPage />} />
            <Route
              path="/api/partner-interested"
              element={<InterestedPage />}
            />
            <Route path="/grants-form/*" element={<GrantAnswersPage />} />
            <Route path="/sign-in" element={<Reroute />} />
            <Route path="/sign-up" element={<Reroute />} />
            <Route path="/forgot-password" element={<Reroute />} />
            <Route path="/check_oauth_google" element={<CheckOAuthGoogle />} />

            <Route path="/" element={<Layout />}>
              <Route path="/edit-grant-questions" element={<NewAppPage />} />
              <Route path="/automation-success" element={<AutomationSuccess />} />
              <Route path="?" element={<App />} />
              <Route path="all-grants" element={<AllGrants />} />
              <Route path="companies" element={<CompaniesPage />} />
              <Route path="leaderboard" element={<Leaderboard />} />
              <Route path="prospect-leaderboard" element={<ProspectLeaderboard />} />
              <Route path="my-network" element={<MyNetwork />} />
              <Route path="successful-grant-writers" element={<GrantWriterNetwork />} />
              <Route path="perks" element={<Perks />} />
              <Route path="pricing" element={<PlansPage />} />
              <Route path="/financing-requests" element={<FinancingRequests />} />
              <Route path="/automation-requests" element={<AutomationRequests />} />
              <Route path="/settings" element={<Settings />} />
            </Route>

            <Route path="/*" element={<NotFoundPage />} />
          </Routes>
        </AppProvider>
      </SignedIn>
    </BrowserRouter>
  );
};

export default Router;
