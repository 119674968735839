import React from "react";
import ReactDOM from "react-dom/client";
import Router from "./Router";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { sendToVercelAnalytics } from "./vitals";
import { ClerkProvider } from "@clerk/clerk-react";

const clerkPublishableKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

ReactDOM.createRoot(document.getElementById("root")).render(
	// <React.StrictMode>
		<ClerkProvider publishableKey={clerkPublishableKey} appearance={{ elements: { footerAction: "hidden", profileSection__danger : "hidden" } }}>
			<Router />
		</ClerkProvider>
	// </React.StrictMode>
);

reportWebVitals(sendToVercelAnalytics);
