import { ArrowUpCircleIcon } from "@heroicons/react/20/solid";

const automationPartners = [
	{
		name: 'Jayesh',
		email: 'j@delightfulautomations.com',
	},
	{
		name: 'Robert',
		email: 'robert@opzer.co',
	},
	{
		name: 'Kalani',
		email: 'kalani@kihbba.com',
	},
	{
		name: 'Andres',
		email: 'andres@connex.digital',
	},
	{
		name: 'Nathan',
		email: 'nathan@flowdigitalinc.com',
	},
	{
			name: 'Vandit Bhatt',
			email: 'vandit.bhatt@exelatech.com',
	},
	{
			name: 'Gaurav Jamdar',
			email: 'gaurav.jamdar@exelatech.com',
	},
	{
			name: 'Jaymin Chhaya',
			email: 'jaymin.chhaya@exelatech.com',
	},
	{
			name: 'Sandeep Sapru',
			email: 'sandeep.sapru@exelatech.com',
	},
	{
			name: 'Daniel Kattan',
			email: 'daniel@workninjas.com',
	},
	{
			name: 'Dobbin Buck',
			email: 'dobbin@getuwired.com',
	},
	{
			name: 'Morten Laursen',
			email: 'mol@automize.dk',
	},
	{
			name: 'John Fohrman',
			email: 'john.fohrman@metagrate.com',
	},
	{
			name: 'Stephen Link',
			email: 'stephen.link@exelatech.com',
	},
	{
			name: 'Dawn Santana',
			email: 'dawn.santana@exelatech.com',
	},
	{
			name: 'Sagar Pund',
			email: 'sagar.pund@exelaonline.com',
	},
	{
			name: 'Shaun Rao',
			email: 'shaun.rao@exelatech.com',
	},
	{
			name: 'Edward Stranks',
			email: 'edward@themoderategenius.com',
	},
	{
			name: 'Cherine Morris',
			email: 'cherine.morris@exelatech.com',
	},
	{
			name: 'Edward Lindlbauer',
			email: 'edward.lindlbauer@exelaonline.com',
	},
	{
			name: 'Vinod Mahajan',
			email: 'vinod@vkaps.com',
	},
	{
			name: 'Dwayne Lucas',
			email: 'dwayne@flow.digital',
	},
	{
			name: 'Ken Bechard',
			email: 'ken.bechard@exelatech.com',
	},
	{
			name: 'Ivy Garcia',
			email: 'ivy.garcia@exelaonline.com',
	},
	{
			name: 'Venkat Sankar',
			email: 'venkat.sankar@exelatech.com',
	},
	{
			name: 'Jill Smith',
			email: 'jill.smith@exelatech.com',
	},
	{
			name: 'Alejandro Calderon',
			email: 'alejandro@workninjas.com',
	},
	{
			name: 'Connor Gustafson',
			email: 'connor@nocodeconsulting.com',
	},
	{
			name: 'Juan Roman',
			email: 'juanmiguel@tugerente.com',
	},
	{
			name: 'Troy Tessalone',
			email: 'troy@automationace.com',
	},
	{
			name: 'Todd Harper',
			email: 'todd@weddingtechbff.com',
	},
	{
			name: 'Kris Zielinski',
			email: 'kris@seamless-automation.eu',
	},
	{
			name: 'Nicolas Martinez',
			email: 'nicolas@tugerente.com',
	},
	{
			name: 'Joan Sattler',
			email: 'joan.sattler@exelaonline.com',
	},
	{
			name: 'Michael Lloyd',
			email: 'michael.lloyd@exelatech.com',
	},
	{
			name: 'Ivan Gomez',
			email: 'ivan.gomez@exelatech.com',
	},
	{
			name: 'Gabi Hennen',
			email: 'gabi.hennen@merchology.com',
	},
	{
			name: 'Geoff Gitelson',
			email: 'geoff.gitelson@exelatech.com',
	},
	{
			name: 'Jim Schwebach',
			email: 'jim.schwebach@exelatech.com',
	},
	{
			name: 'Austin Kueffner',
			email: 'austin@outliant.com',
	},
	{
			name: 'Erwan Lent',
			email: 'erwan@outliant.com',
	},
	{
			name: 'Syed Rizvi',
			email: 'syed@flow.digital',
	},
	{
			name: 'Akshay Gore',
			email: 'akshay.gore@exelaonline.com',
	},
	{
			name: 'Marcus Jones',
			email: 'marcus@outliant.com',
	},
	{
			name: 'Samuel Hootini',
			email: 'samuel@propellur.com',
	},
	{
			name: 'Jason Steward',
			email: 'jason.steward@exelaonline.com',
	},
	{
			name: 'Toni Dugal',
			email: 'toni.dugal@exelatech.com',
	},
	{
			name: 'Bhargav Thakkar',
			email: 'bhargav.thakkar@exelatech.com',
	},
	{
			name: 'Joseph Marotte',
			email: 'joseph@galadrim.fr',
	},
	{
			name: 'Samir Inamdar',
			email: 'samir.inamdar@exelatech.com',
	},
	{
			name: 'Ofir Cohen',
			email: 'ocohenn@shmerling.co.il',
	},
	{
			name: 'Dale Harn',
			email: 'dale.harn@exelatech.com',
	},
	{
			name: 'Andrew Ward',
			email: 'andrew.ward@merchology.com',
	},
	{
			name: 'Mohamed Swellam',
			email: 'mswellam@geekyair.com',
	},
	{
			name: 'Amey Nalawade',
			email: 'amey.nalawade@exelatech.com',
	},
	{
			name: 'Craig Barrett',
			email: 'craig.barrett@net3marketing.com',
	},
	{
			name: 'Srinivasan Ramaswamy',
			email: 'srinivasan.ramaswamy@exelatech.com',
	},
	{
			name: 'Janne Penttilae',
			email: 'janne@pilvi.com',
	},
	{
			name: 'Ulf Gohde',
			email: 'ulf.gohde@exelatech.com',
	},
	{
			name: 'Reagan Patrick',
			email: 'reagan@flow.digital',
	},
	{
			name: 'Mahesh Shah',
			email: 'mahesh.shah@exelatech.com',
	},
	{
			name: 'Tanveer Molakalmuru',
			email: 'tanveer.molakalmuru@exelatech.com',
	},
	{
			name: 'Melissa Allen',
			email: 'mallen@getuwired.com',
	},
	{
			name: 'Aaron Leblanc',
			email: 'aaron@hypelocal.com',
	},
	{
			name: 'Glenn King',
			email: 'glenn.king@exelatech.com',
	},
];

const additionalInfoMap = {
	'aws': 'Account ID',
	'amazon web services': 'Account ID',
	'google': 'Billing ID',
}

// const automationPartners = [
// 	{
// 		name: 'Ray',
// 		email: 'raymond@getupgraded.ca',
// 	},
// 	{
// 		name: 'Will',
// 		email: 'will@getupgraded.ca',
// 	},
// 	{
// 		name: 'Daniel',
// 		email: 'daniel@getupgraded.ca',
// 	},
// 	{
// 		name: 'Haris',
// 		email: 'haris@getupgraded.ca',
// 	},
// ]

// const financingPartners = [
// 	{
// 		name: 'Ray',
// 		email: 'raymond@getupgraded.ca',
// 	},
// ]

const financingPartners = [
	{
		name: 'Ray',
		email: 'raymond@getupgraded.ca',
	},
	{
		name: 'Daniel',
		email: 'daniel@getupgraded.ca',
	},
	{
		name: 'Haris',
		email: 'haris@getupgraded.ca',
	},
	{
		name: '',
		email: 'ppark@merchantgrowth.com'
	},
	{
		name: '',
		email: 'grace@gofloat.io'
	},
	{
		name: '',
		email: 'jason@8fig.co'
	},
	{
		name: '',
		email: 'tysonb@timiacapital.com'
	},
	{
		name: '',
		email: 'mmagnacca@corl.io'
	},
	{
		name: '',
		email: 'josh@flowcap.com'
	},
	{
		name: '',
		email: 'nmacintosh@venbridge.com'
	},
	{
		name: '',
		email: 'rnaor@liquidcapitalcorp.com'
	},
	{
		name: '',
		email: 'jhoward@headwaycap.com'
	},

	{
		name: '',
		email: 'mitch@helloquickly.com'
	},
	{
		name: '',
		email: 'ryan.oconnor@wayflyer.com'
	},
	{
		name: '',
		email: 'renato@pipe.com'
	},
	{
		name: '',
		email: 'cyrus@amplefinance.com'
	},
	{
		name: '',
		email: 'jmok@brex.com'
	},
	{
		name: 'Pat',
		email: 'piaconi@liquidcapitalcorp.com'
	},
	{
		name: 'Tegan',
		email: 'tpeabody@ondeck.com'
	},
	{
		name: 'Sarena',
		email: 'sally@fundthrough.com'
	},
	{
		name: 'Francisco',
		email: 'fcancino@fundthrough.com'
	},
	{
		name: 'Billy',
		email: 'billy@brex.com'
	},
	{
		name: 'Josh',
		email: 'josh@pipe.com'
	},
	{
		name: 'Kelly',
		email: 'kpischzan@fundthrough.com'
	},
	{
		name: 'Stryker',
		email: 'amott@brex.com'
	},
	{
		name: 'Jesse',
		email: 'jesse.cyrulnik@bluevine.com'
	},
	{
		name: 'Tiffanni',
		email: 'tiffanni.hernandez@greenboxcapital.com'
	},
	{
		name: 'Marc',
		email: 'marc.brichs@wayflyer.com'
	},
	{
		name: 'Samuel',
		email: 'sharris@brex.com'
	},
	{
		name: 'Ron',
		email: 'redinger@liquidcapitalcorp.com'
	},
	{
		name: 'Sam',
		email: 'slastovich@brex.com'
	},
	{
		name: 'Conor',
		email: 'conor.osullivan@wayflyer.com'
	},
	{
		name: 'Scott',
		email: 'skaufman@liquidcapitalcorp.com'
	},
	{
		name: 'Pasquale',
		email: 'pasquale@pipe.com'
	},
	{
		name: 'Louise',
		email: 'louise.holmes@wayflyer.com'
	},
	{
		name: 'Gaby',
		email: 'glevey@brex.com'
	},
	{
		name: 'Emma',
		email: 'emma.bradfieldskelly@wayflyer.com'
	},
	{
		name: 'Reese',
		email: 'reese@pipe.com'
	},
	{
		name: 'Dennis',
		email: 'dennis@pipe.com'
	},
	{
		name: 'Sean',
		email: 'smcwhorter@ondeck.com'
	},
	{
		name: 'Alex',
		email: 'alex.stewart@wayflyer.com'
	},
	{
		name: 'Tom',
		email: 'tom.quilligan@wayflyer.com'
	},
	{
		name: 'Sayf',
		email: 'schowdhury@brex.com'
	},
	{
		name: 'Nir',
		email: 'nir.klar@bluevine.com'
	},
	{
		name: 'Evan',
		email: 'evan.brown@bluevine.com'
	},
	{
		name: 'Jordan',
		email: 'jbulkoski@brex.com'
	},
	{
		name: 'Harry',
		email: 'harry@pipe.com'
	},
	{
		name: 'Miguel',
		email: 'miguel@capchase.com'
	},
	{
		name: 'Aidan',
		email: 'aidan.corbett@wayflyer.com'
	},
	{
		name: 'Dillon',
		email: 'dkraus@ondeck.com'
	},
	{
		name: 'Nadiv',
		email: 'nadiv.rahman@bluevine.com'
	},
	{
		name: 'Alison',
		email: 'asilver@brex.com'
	},
	{
		name: 'Amrit',
		email: 'amrit@pipe.com'
	},
	{
		name: 'Steven',
		email: 'suster@fundthrough.com'
	},
	{
		name: 'Pedro',
		email: 'pedro.franceschi@brex.com'
	},
	{
		name: 'Corey',
		email: 'corey@pipe.com'
	},
	{
		name: 'Bryan',
		email: 'bwatson@venbridge.com'
	},
	{
		name: 'Elizabeth',
		email: 'elizabeth.edinger@liquidcapitalcorp.com'
	},
	{
		name: 'Tom',
		email: 'thomas.flynn@bluevine.com'
	},
	{
		name: 'Ashish',
		email: 'auchil@brex.com'
	},
	{
		name: 'Deepak',
		email: 'dramachandran@fundthrough.com'
	},
	{
		name: 'Brittany',
		email: 'bsmith@ondeck.com'
	},
	{
		name: 'Eyal',
		email: 'eyal@bluevine.com'
	},
	{
		name: 'Rachel',
		email: 'rmcdowell@ondeck.com'
	},
	{
		name: 'Mike',
		email: 'mdrska@brex.com'
	},
	{
		name: 'Andy',
		email: 'andy.shirtcliff@amplafinance.com'
	},
	{
		name: 'Ethan',
		email: 'ecrowell@brex.com'
	},
	{
		name: 'Ty',
		email: 'christopher.mclaren@capchase.com'
	},
	{
		name: 'Henrique',
		email: 'hdubugras@brex.com'
	},
	{
		name: 'Joel',
		email: 'jminnich@brex.com'
	},
	{
		name: 'Ian',
		email: 'ian.garrett@capchase.com'
	},
	{
		name: 'Porchia',
		email: 'pjenkins@brex.com'
	},
	{
		name: 'Ferran',
		email: 'ferran@capchase.com'
	},
	{
		name: 'Thiago',
		email: 'tbocayuva@brex.com'
	},
	{
		name: 'Joe',
		email: 'jfinello@ondeck.com'
	},
	{
		name: 'Andrew',
		email: 'agerig@ondeck.com'
	},
	{
		name: 'Jose',
		email: 'jose.noriega@greenboxcapital.com'
	},
	{
		name: 'Anshul',
		email: 'ashah@brex.com'
	},
	{
		name: 'Adam',
		email: 'abooth@ondeck.com'
	},
	{
		name: 'Kyla',
		email: 'kyla@brex.com'
	},
	{
		name: 'Angelina',
		email: 'amele@brex.com'
	},
	{
		name: 'Przemek',
		email: 'przemek@capchase.com'
	},
	{
		name: 'Greg',
		email: 'gmeyer@brex.com'
	},
	{
		name: 'Alexander',
		email: 'abetancur@ondeck.com'
	},
	{
		name: 'Blake',
		email: 'beddington@ondeck.com'
	},
	{
		name: 'Adam',
		email: 'adam@capchase.com'
	},
	{
		name: 'Jimmy',
		email: 'jimmy@brex.com'
	},
	{
		name: 'Dylan',
		email: 'dylan.ferro@bluevine.com'
	},
	{
		name: 'Nicholas',
		email: 'nhernandez@ondeck.com'
	},
	{
		name: 'Cedric',
		email: 'cedric@floatlending.com'
	},
	{
		name: 'Jay',
		email: 'jay.patterson@wayflyer.com'
	},
	{
		name: 'Nicholas',
		email: 'nicholas.bond@bluevine.com'
	},
	{
		name: 'Jose',
		email: 'jmohr@ondeck.com'
	},
	{
		name: 'Pauric',
		email: 'pauric.mcgorry@wayflyer.com'
	},
	{
		name: 'Edward',
		email: 'echan@fundthrough.com'
	},
	{
		name: 'Stanley',
		email: 'ssantana@ondeck.com'
	},
	{
		name: 'Maria',
		email: 'maria@capchase.com'
	},
	{
		name: 'Sean',
		email: 'sean.hanvey@wayflyer.com'
	},
	{
		name: 'Lena',
		email: 'lena.karutis@bluevine.com'
	},
	{
		name: 'Jessica',
		email: 'jessica@brex.com'
	},
	{
		name: 'Conor',
		email: 'conor.mccabe@wayflyer.com'
	},
	{
		name: 'Nick',
		email: 'nwang@brex.com'
	},
	{
		name: 'Jannis',
		email: 'jannis@gofloat.io'
	},
	{
		name: 'Punit',
		email: 'punit@pipe.com'
	},
	{
		name: 'Steve',
		email: 'scruz@ondeck.com'
	},
	{
		name: 'Alon',
		email: 'alupo@brex.com'
	},
	{
		name: 'Nikolas',
		email: 'ngalounis@brex.com'
	},
	{
		name: 'Sam',
		email: 'sweil@brex.com'
	},
	{
		name: 'Austin',
		email: 'austin@brex.com'
	},
	{
		name: 'Kelsey',
		email: 'kbartee@brex.com'
	}
	
]

const statuses = {
	"Guaranteed": "text-green-600 bg-green-50 ring-green-600/20",
	"Overdue": "text-red-600 bg-red-50 ring-red-600/10",
	"Accounting": "text-red-800 bg-red-100 ring-red-600/10",
	"Finance": "text-blue-800 bg-blue-100 ring-blue-600/10",
	"Sales": "text-yellow-800 bg-yellow-100 ring-yellow-600/10",
	"Product": "text-green-800 bg-green-100 ring-green-600/10",
	"Engineering": "text-blue-800 bg-blue-100 ring-blue-600/10",
	"Marketing": "text-indigo-800 bg-indigo-100 ring-indigo-600/10",
	"Technology": "text-indigo-800 bg-indigo-100 ring-indigo-600/10",
	"HR": "text-purple-800 bg-purple-100 ring-purple-600/10",
	"CS": "text-purple-800 bg-purple-100 ring-purple-600/10",
	"Operations": "text-orange-800 bg-orange-100 ring-pink-600/10",
	"Customer Success": "text-orange-800 bg-orange-100 ring-pink-600/10",
	"Training": "text-white bg-emerald-500 ring-emerald-400/10",
	"Hiring": "text-orange-800 bg-orange-100 ring-pink-600/10",
	"Market Expansion": "text-green-800 bg-green-100 ring-green-600/10",
	"R&D": "text-yellow-800 bg-yellow-100 ring-yellow-600/10",
	"Tax Credits": "text-yellow-800 bg-yellow-100 ring-yellow-600/10",
	"Grant": "text-white bg-gray-500 ring-yellow-600/10",
	"Other": "text-white bg-gray-500 ring-yellow-600/10",
	"Incubators & Accelerators": "text-red-800 bg-red-100 ring-red-600/10",
	"Interest-Free Loan": "text-orange-800 bg-orange-100 ring-pink-600/10",
	"Loan": "text-orange-800 bg-orange-100 ring-pink-600/10",
	"Social Impact": "text-indigo-800 bg-indigo-100 ring-indigo-600/10",
	"Niche": "text-fuchsia-800 bg-fuchsia-100 ring-fuchsia-600/10",
	"Closed" : ""
};

const days = [
	{
		date: "Recommended",
		dateTime: "2023-03-22",
		transactions: [
			{
				id: 1,
				invoiceNumber: "00012",
				href: "https://buy.stripe.com/aEU03K0pcb4U2vC9AB",
				amount: "~$" + Math.floor(Math.random() * (10000 - 1000) + 1000) + "/yr",
				tax: "Automate Accounts Receivable",
				status: "Accounting",
				client: "Upgrade #00012",
				description: "",
				category: "Finance",
				probability: "23.7% success",
				ttv: "15 days to results",
				icon: ArrowUpCircleIcon,
			},
			{
				id: 2,
				invoiceNumber: "00011",
				href: "https://buy.stripe.com/aEU03K0pcb4U2vC9AB",
				amount: "~$" + Math.floor(Math.random() * (10000 - 1000) + 1000) + "/yr",
				tax: "Automate Accounts Payable",
				status: "Finance",
				client: "Upgrade # 00" + Math.floor(Math.random() * (1000 - 100) + 100),
				description: "",
				category: "Finance",
				probability: "34.5% success",
				ttv: "20 days to results",
				icon: ArrowUpCircleIcon,
			},
			{
				id: 3,
				invoiceNumber: "00009",
				href: "https://buy.stripe.com/aEU03K0pcb4U2vC9AB",
				amount: "~$" + Math.floor(Math.random() * (10000 - 1000) + 1000) + "/yr",
				tax: "Improve Product Analytics",
				status: "Product",
				client: "Upgrade # 00" + Math.floor(Math.random() * (1000 - 100) + 100),
				description: "",
				category: "Finance",
				probability: "78.4% success",
				ttv: "7 days to results",
				icon: ArrowUpCircleIcon,
			},
			{
				id: 3,
				invoiceNumber: "00009",
				href: "https://buy.stripe.com/aEU03K0pcb4U2vC9AB",
				amount: "~$" + Math.floor(Math.random() * (10000 - 1000) + 1000) + "/yr",
				tax: "Improve",
				status: "Technology",
				client: "Upgrade # 00" + Math.floor(Math.random() * (1000 - 100) + 100),
				description: "",
				category: "Finance",
				probability: "78.4% success",
				ttv: "7 days to results",
				icon: ArrowUpCircleIcon,
			},
			{
				id: 3,
				invoiceNumber: "00009",
				href: "https://buy.stripe.com/aEU03K0pcb4U2vC9AB",
				amount: "~$" + Math.floor(Math.random() * (10000 - 1000) + 1000) + "/yr",
				tax: "Improve",
				status: "CS",
				client: "Upgrade # 00" + Math.floor(Math.random() * (1000 - 100) + 100),
				description: "",
				category: "Finance",
				probability: "78.4% success",
				ttv: "7 days to results",
				icon: ArrowUpCircleIcon,
			},
			{
				id: 3,
				invoiceNumber: "00009",
				href: "https://buy.stripe.com/aEU03K0pcb4U2vC9AB",
				amount: "~$" + Math.floor(Math.random() * (10000 - 1000) + 1000) + "/yr",
				tax: "Improve",
				status: "Marketing",
				client: "Upgrade # 00" + Math.floor(Math.random() * (1000 - 100) + 100),
				description: "",
				category: "Finance",
				probability: "78.4% success",
				ttv: "7 days to results",
				icon: ArrowUpCircleIcon,
			},
			{
				id: 3,
				invoiceNumber: "00009",
				href: "https://buy.stripe.com/aEU03K0pcb4U2vC9AB",
				amount: "~$" + Math.floor(Math.random() * (10000 - 1000) + 1000) + "/yr",
				tax: "Improve",
				status: "Engineering",
				client: "Upgrade # 00" + Math.floor(Math.random() * (1000 - 100) + 100),
				description: "",
				category: "Finance",
				probability: "78.4% success",
				ttv: "7 days to results",
				icon: ArrowUpCircleIcon,
			},
		],
	},
	{
		date: "Available",
		dateTime: "2023-03-21",
		transactions: [
			{
				id: 4,
				invoiceNumber: "00010",
				href: "https://buy.stripe.com/aEU03K0pcb4U2vC9AB",
				amount: "~$" + Math.floor(Math.random() * (10000 - 1000) + 1000) + "/yr",
				tax: "Improved Close Rates",
				status: "Sales",
				client: "Upgrade # 00" + Math.floor(Math.random() * (1000 - 100) + 100),
				description: "",
				category: "Finance",
				probability: "85.6% success",
				ttv: "13 days to results",
				icon: ArrowUpCircleIcon,
			},
		],
	},
];

const clients = [
	{
		id: 1,
		name: "Reticle Advertising",
		imageUrl: "https://tailwindui.com/img/logos/48x48/tuple.svg",
		lastInvoice: {
			date: "December 13, 2022",
			dateTime: "2022-12-13",
			amount: "$2,000.00",
			status: "Paid",
		},
	},
	{
		id: 2,
		name: "SavvyTax",
		imageUrl: "https://tailwindui.com/img/logos/48x48/savvycal.svg",
		lastInvoice: {
			date: "January 22, 2023",
			dateTime: "2023-01-22",
			amount: "$14,000.00",
			status: "Paid",
		},
	},
	{
		id: 4,
		name: "Reform SEO",
		imageUrl: "https://tailwindui.com/img/logos/48x48/reform.svg",
		lastInvoice: {
			date: "January 23, 2023",
			dateTime: "2023-01-23",
			amount: "$7,600.00",
			status: "Paid",
		},
	},
	{
		id: 5,
		name: "Reform SEO",
		imageUrl: "https://tailwindui.com/img/logos/48x48/reform.svg",
		lastInvoice: {
			date: "January 23, 2023",
			dateTime: "2023-01-23",
			amount: "$7,600.00",
			status: "Paid",
		},
	},
	{
		id: 6,
		name: "Reform SEO",
		imageUrl: "https://tailwindui.com/img/logos/48x48/reform.svg",
		lastInvoice: {
			date: "January 23, 2023",
			dateTime: "2023-01-23",
			amount: "$7,600.00",
			status: "Paid",
		},
	},
];

const team = [
	{
		name: "Tom Cook",
		email: "tom.cook@example.com",
		href: "#",
		imageUrl:
			"https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
	},
	{
		name: "Whitney Francis",
		email: "whitney.francis@example.com",
		href: "#",
		imageUrl:
			"https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
	},
	{
		name: "Leonard Krasner",
		email: "leonard.krasner@example.com",
		href: "#",
		imageUrl:
			"https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
	},
	{
		name: "Floyd Miles",
		email: "floyd.miles@example.com",
		href: "#",
		imageUrl:
			"https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
	},
	{
		name: "Emily Selman",
		email: "emily.selman@example.com",
		href: "#",
		imageUrl:
			"https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
	},
];

const countryOptions = [
	{ value: "CA", label: "Canada" },
	{ value: "US", label: "United States" },
	{ value: "GB", label: "United Kingdom" },
	{ value: "AU", label: "Australia" },
	{ value: "DE", label: "Germany" },
	{ value: "FR", label: "France" },
	{ value: "IT", label: "Italy" },
	{ value: "JP", label: "Japan" },
	{ value: "CN", label: "China" },
	{ value: "IN", label: "India" },
	{ value: "BR", label: "Brazil" },
	{ value: "MX", label: "Mexico" },
	{ value: "RU", label: "Russia" },
	{ value: "ES", label: "Spain" },
	{ value: "KR", label: "South Korea" },
	{ value: "SA", label: "Saudi Arabia" },
	{ value: "TR", label: "Turkey" },
	{ value: "ID", label: "Indonesia" },
	{ value: "NL", label: "Netherlands" },
	{ value: "SE", label: "Sweden" },
	{ value: "PL", label: "Poland" },
	{ value: "AR", label: "Argentina" },
	{ value: "ZA", label: "South Africa" },
	{ value: "EG", label: "Egypt" },
	{ value: "IR", label: "Iran" },
	{ value: "PK", label: "Pakistan" },
	{ value: "NG", label: "Nigeria" },
	{ value: "CO", label: "Colombia" },
	{ value: "KE", label: "Kenya" },
	{ value: "VE", label: "Venezuela" },
	{ value: "IL", label: "Israel" },
	{ value: "UY", label: "Uruguay" },
	{ value: "DK", label: "Denmark" },
];

const industryOptions = [
	{ value: "Agriculture", label: "Agriculture" },
	{ value: "Farming", label: "Farming" },
	{ value: "Crop Cultivation", label: "Crop Cultivation" },
	{ value: "Animal Husbandry", label: "Animal Husbandry" },
	{ value: "Aquaculture", label: "Aquaculture" },
	{ value: "Horticulture", label: "Horticulture" },
	{ value: "Agroforestry", label: "Agroforestry" },
	{ value: "Dairy", label: "Dairy" },
	{ value: "Poultry", label: "Poultry" },
	{ value: "Organic Farming", label: "Organic Farming" },
	{ value: "Hydroponics", label: "Hydroponics" },
	{ value: "Viticulture", label: "Viticulture" },
	{ value: "Environmental Services", label: "Environmental Services" },
	{ value: "Technology", label: "Technology" },
	{ value: "Software Development", label: "Software Development" },
	{ value: "Hardware Manufacturing", label: "Hardware Manufacturing" },
	{ value: "IT Services", label: "IT Services" },
	{ value: "E-commerce", label: "E-commerce" },
	{ value: "Information Services", label: "Information Services" },
	{ value: "Telecommunications", label: "Telecommunications" },
	{ value: "Data Analytics", label: "Data Analytics" },
	{ value: "Cybersecurity", label: "Cybersecurity" },
	{ value: "Artificial Intelligence", label: "Artificial Intelligence" },
	{ value: "Virtual Reality", label: "Virtual Reality" },
	{ value: "Internet", label: "Internet" },
	{ value: "IT", label: "IT" },
	{ value: "Biotechnology", label: "Biotechnology" },
	{ value: "Computer Software", label: "Computer Software" },
	{ value: "Information Technology", label: "Information Technology" },
	{ value: "Computer and Network Security", label: "Computer and Network Security" },
	{ value: "IT Services and IT Consulting", label: "IT Services and IT Consulting" },
	{ value: "Medical", label: "Medical" },
	{ value: "Pharmaceutical", label: "Pharmaceutical" },
	{ value: "Healthcare", label: "Healthcare" },
	{ value: "Hospitals/Clinics", label: "Hospitals/Clinics" },
	{ value: "Pharmaceutical Manufacturing", label: "Pharmaceutical Manufacturing" },
	{ value: "Biotechnology Research", label: "Biotechnology Research" },
	{ value: "Health IT Services", label: "Health IT Services" },
	{ value: "Medical Devices", label: "Medical Devices" },
	{ value: "Nursing Homes", label: "Nursing Homes" },
	{ value: "Dental Services", label: "Dental Services" },
	{ value: "Home Healthcare", label: "Home Healthcare" },
	{ value: "Physical Therapy", label: "Physical Therapy" },
	{ value: "Veterinary Services", label: "Veterinary Services" },
	{ value: "Construction", label: "Construction" },
	{ value: "Manufacturing", label: "Manufacturing" },
	{ value: "Automotive", label: "Automotive" },
	{ value: "Aerospace and Defense", label: "Aerospace and Defense" },
	{ value: "Chemicals", label: "Chemicals" },
	{ value: "Construction Materials", label: "Construction Materials" },
	{ value: "Consumer Goods", label: "Consumer Goods" },
	{ value: "Food Production", label: "Food Production" },
	{ value: "Electronics", label: "Electronics" },
	{ value: "Machinery", label: "Machinery" },
	{ value: "Textiles", label: "Textiles" },
	{ value: "Pharmaceuticals", label: "Pharmaceuticals" },
	{ value: "Aerospace", label: "Aerospace" },
	{ value: "Defense", label: "Defense" },
	{ value: "Energy", label: "Energy" },
	{ value: "Mining", label: "Mining" },
	{ value: "Oil and Gas", label: "Oil and Gas" },
	{ value: "Renewables", label: "Renewables" },
	{ value: "Electricity Generation", label: "Electricity Generation" },
	{ value: "Coal Mining", label: "Coal Mining" },
	{ value: "Nuclear", label: "Nuclear" },
	{ value: "Hydropower", label: "Hydropower" },
	{ value: "Wind Power", label: "Wind Power" },
	{ value: "Mining", label: "Mining" },
	{ value: "Solar Power", label: "Solar Power" },
	{ value: "Energy Storage", label: "Energy Storage" },
	{ value: "Energy Distribution", label: "Energy Distribution" },
	{ value: "Finance", label: "Finance" },
	{ value: "Banking", label: "Banking" },
	{ value: "Insurance", label: "Insurance" },
	{ value: "Financial Services", label: "Financial Services" },
	{ value: "Asset Management", label: "Asset Management" },
	{ value: "Investment Banking", label: "Investment Banking" },
	{ value: "Credit Services", label: "Credit Services" },
	{ value: "Fintech", label: "Fintech" },
	{ value: "Real Estate Investment", label: "Real Estate Investment" },
	{ value: "Venture Capital", label: "Venture Capital" },
	{ value: "Private Equity", label: "Private Equity" },
	{ value: "Education", label: "Education" },
	{ value: "Primary/Secondary Education", label: "Primary/Secondary Education" },
	{ value: "Higher Education", label: "Higher Education" },
	{ value: "Vocational Education", label: "Vocational Education" },
	{ value: "E-learning", label: "E-learning" },
	{ value: "Tutoring", label: "Tutoring" },
	{ value: "Test Preparation", label: "Test Preparation" },
	{ value: "Educational Software", label: "Educational Software" },
	{ value: "Special Education", label: "Special Education" },
	{ value: "Career Counseling", label: "Career Counseling" },
	{ value: "Language Learning", label: "Language Learning" },
	{ value: "Retail", label: "Retail" },
	{ value: "E-commerce", label: "E-commerce" },
	{ value: "Supermarkets", label: "Supermarkets" },
	{ value: "Department Stores", label: "Department Stores" },
	{ value: "Specialty Stores", label: "Specialty Stores" },
	{ value: "Convenience Stores", label: "Convenience Stores" },
	{ value: "Discount Stores", label: "Discount Stores" },
	{ value: "Drug Stores", label: "Drug Stores" },
	{ value: "Duty-free Stores", label: "Duty-free Stores" },
	{ value: "Hypermarkets", label: "Hypermarkets" },
	{ value: "Online Retail", label: "Online Retail" },
	{ value: "Boutiques", label: "Boutiques" },
	{ value: "Travel", label: "Travel" },
	{ value: "Airlines", label: "Airlines" },
	{ value: "Hotels", label: "Hotels" },
	{ value: "Travel Agencies", label: "Travel Agencies" },
	{ value: "Tour Operators", label: "Tour Operators" },
	{ value: "Cruise Lines", label: "Cruise Lines" },
	{ value: "Car Rental", label: "Car Rental" },
	{ value: "Vacation Rentals", label: "Vacation Rentals" },
	{ value: "Online Travel Platforms", label: "Online Travel Platforms" },
	{ value: "Luxury Travel", label: "Luxury Travel" },
	{ value: "Adventure Travel", label: "Adventure Travel" },
	{ value: "Ecotourism", label: "Ecotourism" },
	{ value: "Automotive", label: "Automotive" },
	{ value: "Vehicle Manufacturing", label: "Vehicle Manufacturing" },
	{ value: "Auto Parts Manufacturing", label: "Auto Parts Manufacturing" },
	{ value: "Car Dealerships", label: "Car Dealerships" },
	{ value: "Auto Repair Services", label: "Auto Repair Services" },
	{ value: "Auto Design and Engineering", label: "Auto Design and Engineering" },
	{ value: "Electric Vehicles", label: "Electric Vehicles" },
	{ value: "Autonomous Vehicles", label: "Autonomous Vehicles" },
	{ value: "Motorcycles", label: "Motorcycles" },
	{ value: "Trucks", label: "Trucks" },
	{ value: "Buses", label: "Buses" },
	{ value: "Recreational Vehicles", label: "Recreational Vehicles" },
	{ value: "Motor Vehicle Manufacturing", label: "Motor Vehicle Manufacturing" },
	{ value: "Broadcast Media Production and Distribution", label: "Broadcast Media Production and Distribution" },
	{ value: "Business Intelligence", label: "Business Intelligence" },
];

const countryCodes = {
	CA: "canada",
	US: "united states",
	GB: "united kingdom",
	AU: "australia",
	DE: "germany",
	FR: "france",
	IT: "italy",
	JP: "japan",
	CN: "china",
	IN: "india",
	BR: "brazil",
	MX: "mexico",
	RU: "russia",
	ES: "spain",
	KR: "south korea",
	SA: "saudi arabia",
	TR: "turkey",
	ID: "indonesia",
	NL: "netherlands",
	SE: "sweden",
	PL: "poland",
	AR: "argentina",
	ZA: "south africa",
	EG: "egypt",
	IR: "iran",
	PK: "pakistan",
	NG: "nigeria",
	CO: "colombia",
	KE: "kenya",
	VE: "venezuela",
	IL: "israel",
	DK: "denmark",
	UY: "uruguay",
};

const provinces = [
	"alberta",
	"manitoba",
	"saskatchewan",
	"newfoundland and labrador",
	"prince edward island",
	"british columbia",
	"ontario",
	"northwest territories",
	"yukon",
	"nunavut",
	"quebec",
	"new brunswick",
	"nova scotia",
];

const regionCodes = {
	// Canadian Provinces
	CA: {
		AB: "alberta",
		BC: "british columbia",
		MB: "manitoba",
		NB: "new brunswick",
		NL: "newfoundland and labrador",
		NS: "nova scotia",
		ON: "ontario",
		PE: "prince edward island",
		QC: "quebec",
		SK: "saskatchewan",
		NT: "northwest territories",
		NU: "nunavut",
		YT: "yukon",
	},

	// US States
	US: {
		AL: "alabama",
		AK: "alaska",
		AZ: "arizona",
		AR: "arkansas",
		CA: "california",
		CO: "colorado",
		CT: "connecticut",
		DE: "delaware",
		FL: "florida",
		GA: "georgia",
		HI: "hawaii",
		ID: "idaho",
		IL: "illinois",
		IN: "indiana",
		IA: "iowa",
		KS: "kansas",
		KY: "kentucky",
		LA: "louisiana",
		ME: "maine",
		MD: "maryland",
		MA: "massachusetts",
		MI: "michigan",
		MN: "minnesota",
		MS: "mississippi",
		MO: "missouri",
		MT: "montana",
		NE: "nebraska",
		NV: "nevada",
		NH: "new hampshire",
		NJ: "new jersey",
		NM: "new mexico",
		NY: "new york",
		NC: "north carolina",
		ND: "north dakota",
		OH: "ohio",
		OK: "oklahoma",
		OR: "oregon",
		PA: "pennsylvania",
		RI: "rhode island",
		SC: "south carolina",
		SD: "south dakota",
		TN: "tennessee",
		TX: "texas",
		UT: "utah",
		VT: "vermont",
		VA: "virginia",
		WA: "washington",
		WV: "west virginia",
		WI: "wisconsin",
		WY: "wyoming",
		DC: "district of columbia",
		PR: "puerto rico",
		GU: "guam",
		VI: "virgin islands",
		MP: "northern mariana islands",
		AS: "american samoa",
	},
};

var discounts = {
	Microsoft: "120000",
	mParticle: "105000",
	AWS: "100000",
	Amazon: "100000",
	Heroku: "60000",
	Google: "100000",
	Segment: "50000",
	MainStreet: "50000",
	Canva: "50000",
	Snap: "50000",
	MobileAction: "25000",
	Wilmerhale: "25000",
	JUICE: "19800",
	Sendbird: "16000",
	Lodestone: "15000",
	Hubspot: "14820",
	Intercom: "14795",
	Cloudflare: "14000",
	CloudApp: "10800",
	Pave: "10000",
	Freshworks: "10000",
	Lively: "10000",
	Studio: "Nine	10000",
	Drift: "10000",
	Slack: "9000",
	Slite: "9000",
	Cashfree: "8000",
	Horizon: "7500",
	Notion: "7200",
	Render: "7200",
	HubSpot: "7000",
	Drata: "7000",
	Snap: "7000",
	KgBase: "6560",
	Visible: "5250",
	"Kalendar AI": "5000",
	SendGrid: "5000",
	Humaans: "5000",
	Doppler: "5000",
	Rippling: "5000",
	Freshworks: "5000",
	NVIDIA: "5000",
	Carta: "5000",
	Chargebee: "5000",
	Unbounce: "5000",
	Fomo: "5000",
	Dialpad: "5000",
	Cuota: "5000",
	Caylent: "4000",
	Zoom: "4000",
	Slack: "4000",
	Vidyard: "3800",
	Outplay: "3600",
	MongoDB: "3600",
	Gem: "3600",
	Coliving: "3600",
	"KPI Sense": "3600",
	AdEspresso: "3594",
	Close: "3588",
	MobileAction: "3588",
	Harvestr: "3000",
	Taskade: "3000",
	Coda: "3000",
	Retool: "3000",
	GraphCMS: "2875",
	Zendesk: "2808",
	FullStory: "2750",
	Twilio: "2500",
	FullStory: "2500",
	Karma: "2400",
	MongoDB: "2184",
	Salesforce: "2150",
	Deel: "2000",
	Remote: "2000",
	PartnerHero: "2000",
	"1Password": "2000",
	Adjust: "2000",
	Pipedrive: "2000",
	Kyndoo: "2000",
	Xendoo: "2000",
	"Trust.co": "2000",
	Motion: "2000",
	Fluxguard: "1776",
	DocSend: "1600",
	Salesforce: "1500",
	Legalpad: "1500",
	Dover: "1500",
	Capbase: "1500",
	Deel: "1500",
	Donut: "1500",
	Instabug: "1500",
	Nightfall: "1500",
	Stripe: "1450",
	Retool: "1200",
	Yodel: "1200",
	Product: "1200",
	GoGrow: "1200",
	KPI: "Sense	1050",
	Airtable: "1000",
	Brex: "1000",
	Notion: "1000",
	Zoom: "1000",
	Cohere: "1000",
	Bubble: "1000",
	Pilot: "1000",
	Miro: "1000",
	Hootsuite: "1000",
	Algolia: "1000",
	Notion: "1000",
	Stripe: "1000",
	Auth0: "1000",
	Justworks: "1000",
	"Lighter Capital": "1000",
	"Global Shares": "1000",
	Icon: "1000",
	Shoobx: "1000",
	Zoho: "1000",
	Lumen5: "1000",
	Growbots: "1000",
	Lattice: "1000",
	Embroker: "1000",
	Swagup: "1000",
	ThinkNimble: "1000",
	Secureframe: "1000",
	"Boast AI": "1000",
	"Wisdom Labs": "1000",
	bolster: "1000",
	Bambee: "1000",
	clara: "1000",
	Square: "1000",
	Brex: "1000",
	sugarcrm: "1000",
	Salesforce: "1000",
	ClearCo: "1000",
	Contra: "1000",
	CircleCI: "1000",
	Avocademy: "1000",
	Datapeople: "1000",
	Flexspace: "1000",
	"Better Uptime": "897",
	Laika: "835",
	SingleSprout: "800",
	Pulley: "750",
	"Carbon Ads": "750",
	Codi: "719",
	userpilot: "700",
	Pipedrive: "699",
	Crunchfirm: "699",
	Capchase: "699",
	iubenda: "600",
	Stripe: "600",
	Mathworks: "580",
	Balance: "560",
	Serviceform: "548",
	Impact: "540",
	Pipedrive: "540",
	Weavy: "500",
	MailerSend: "500",
	"Viral Loops": "500",
	Instabug: "500",
	Front: "500",
	OneSignal: "500",
	Promoted: "500",
	Ramp: "500",
	Zoho: "500",
	Anana: "500",
	Formstack: "500",
	Cobalt: "500",
	"First Republic Bank": "500",
	Rippling: "500",
	TaxTaker: "	500",
	Ramp: "500",
	Vanta: "480",
	Biteable: "475",
	Slab: "450",
	"TABS Score: Diligence-as-a-Service": "449",
	CometChat: "447",
	TriNet: "411",
	Fomo: "398",
	Pipefy: "372",
	BrowserStack: "360",
	Graphite: "350",
	DrawSQL: "350",
	Quickbooks: "300",
	"Product Hunt Jobs": "298",
	Clerky: "295",
	Aircall: "270",
	Rolebot: "264",
	Airtable: "250",
	Bench: "245",
	Foundersuite: "208",
	Divvy: "207",
	airfocus: "200",
	Twist: "200",
	Gem: "200",
	Segment: "200",
	Mixmax: "176",
	Quickbooks: "168",
	"Copper CRM": "168",
	"The Information": "150",
	Sentry: "150",
	Gusto: "150",
	Zoom: "150",
	apollo: "150",
	Pilot: "150",
	Grasshopper: "150",
	Gusto: "150",
	Baremetrics: "125",
	Typeform: "120",
	"Process Street": "108",
	"Stripe Atlas": "100",
	Trends: "100",
	Bigin: "100",
	Todoist: "60",
	Calm: "60",
	Airalo: "30",
	Finimize: "25",
	Canva: "23",
	Hippo: "10",
	Appsflyer: "10",
	Plaid: "10",
	OpenPhone: "10",
	"Sticker Mule": "10",
	Rover: "0",
	Shortcut: "0",
	Paychex: "0",
	Vouch: "0",
};

const industries = {
	Agriculture: [
		"agriculture",
		"farming",
		"crop cultivation",
		"animal husbandry",
		"aquaculture",
		"horticulture",
		"agroforestry",
		"dairy",
		"poultry",
		"organic farming",
		"hydroponics",
		"viticulture",
		"environmental services",
	],
	Technology: [
		"technology",
		"software development",
		"hardware manufacturing",
		"it services",
		"e-commerce",
		"information services",
		"telecommunications",
		"data analytics",
		"cybersecurity",
		"artificial intelligence",
		"virtual reality",
		"internet",
		"it",
		"biotechnology",
		"computer software",
		"internet",
		"information technology",
		"computer and network security",
		"it services and it consulting",
		"biotechnology research",
	],
	Healthcare: [
		"medical",
		"pharmaceutical",
		"healthcare",
		"hospitals/clinics",
		"pharmaceutical manufacturing",
		"biotechnology",
		"health it services",
		"medical devices",
		"nursing homes",
		"dental services",
		"home healthcare",
		"physical therapy",
		"veterinary services",
		"biotechnology",
	],
	Manufacturing: [
		"construction",
		"manufacturing",
		"automotive",
		"aerospace and defense",
		"chemicals",
		"construction materials",
		"consumer goods",
		"food production",
		"electronics",
		"machinery",
		"textiles",
		"pharmaceuticals",
		"aerospace",
		"defense",
	],
	Energy: [
		"energy",
		"mining",
		"oil and gas",
		"renewables",
		"electricity generation",
		"coal mining",
		"nuclear",
		"hydropower",
		"wind power",
		"mining",
		"solar power",
		"energy storage",
		"energy distribution",
	],
	Finance: [
		"finance",
		"banking",
		"insurance",
		"financial services",
		"asset management",
		"investment banking",
		"credit services",
		"fintech",
		"real estate investment",
		"venture capital",
		"private equity",
	],
	Education: [
		"education",
		"primary/secondary education",
		"higher education",
		"vocational education",
		"e-learning",
		"tutoring",
		"test preparation",
		"educational software",
		"special education",
		"career counseling",
		"language learning",
	],
	Retail: [
		"retail",
		"e-commerce",
		"supermarkets",
		"department stores",
		"specialty stores",
		"convenience stores",
		"discount stores",
		"drug stores",
		"duty-free stores",
		"hypermarkets",
		"online retail",
		"boutiques",
	],
	Travel: [
		"travel",
		"airlines",
		"hotels",
		"travel agencies",
		"tour operators",
		"cruise lines",
		"car rental",
		"vacation rentals",
		"online travel platforms",
		"luxury travel",
		"adventure travel",
		"ecotourism",
	],
	Automotive: [
		"automotive",
		"vehicle manufacturing",
		"auto parts manufacturing",
		"car dealerships",
		"auto repair services",
		"auto design and engineering",
		"electric vehicles",
		"autonomous vehicles",
		"motorcycles",
		"trucks",
		"buses",
		"recreational vehicles",
		"motor vehicle manufacturing",
	],
	Entertainment: ["broadcast media production and distribution"],
	"Corporate Services": ["business intelligence"],
};

const monthMap = {
	1: "January",
	2: "February",
	3: "March",
	4: "April",
	5: "May",
	6: "June",
	7: "July",
	8: "August",
	9: "September",
	10: "October",
	11: "November",
	12: "December",
};

const extensions = [
	"ca",
	"com",
	"net",
	"org",
	"xyz",
	"biz",
	"info",
	"edu",
	"one",
	"sale",
	"store",
	"event",
	"blog",
	"co",
	"io",
	"health",
	"ai",
	"technology",
	"dev",
	"me",
	"auto",
	"gov",
	"dev",
	"live",
	"agency",
	"app",
	"tech",
	"eco",
	"ag",
	"dance",
	"inc",
	"guru",
	"art",
	"network",
	"tech",
	"media",
	"africa"
];

const statsTwo = [
	{
		name: "Tech Savings",
		stat: "$238,000",
		previousStat: "70,946",
		change: "12%",
		changeType: "increase",
	},
	{
		name: "Grants",
		stat: "$2,756,834",
		previousStat: "56.14%",
		change: "2.02%",
		changeType: "increase",
	},
	{
		name: "Automations & Outsourcing",
		stat: "$829,039",
		previousStat: "28.62%",
		change: "4.05%",
		changeType: "decrease",
	},
	{
		name: "Financing",
		stat: "$1,828,000",
		previousStat: "28.62%",
		change: "4.05%",
		changeType: "decrease",
	},
];

export {
	automationPartners,
	additionalInfoMap,
	financingPartners,
	statuses,
	clients,
	days,
	team,
	countryCodes,
	countryOptions,
	industries,
	industryOptions,
	discounts,
	regionCodes,
	monthMap,
	extensions,
	statsTwo,
	provinces,
};
