import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import Airtable from "airtable";
import { XMarkIcon, MinusCircleIcon, PencilIcon, PlusIcon } from "@heroicons/react/20/solid";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@material-tailwind/react";
import RemoveQuestionDialog from "./RemoveQuestionDialog";
import { hasSampleAnswer, isNumbered, numberingCompare, setContents, setShowNotif, removeQueryParameters } from "./helpers";
import Notification from "./Notification";
import AddSampleAnswerModal from "./AddSampleAnswerModal";
import { RotatingLines } from "react-loader-spinner";

const GrantQuestionsForm = ({ admin, setAdmin }) => {
	const apiKey = process.env.REACT_APP_AIR_KEY;
	const baseId = process.env.REACT_APP_AIR_BASEID;
	const questionsTableId = process.env.REACT_APP_AIR_QUESTIONS;
	const base = new Airtable({ apiKey }).base(baseId);

	const [selectedOption, setSelectedOption] = useState(null);
	const [grants, setGrants] = useState([]);
	const [questions, setQuestions] = useState([]);
	const [newQuestions, setNewQuestions] = useState([]);
	const [openRemoveQuestionsModal, setOpenRemoveQuestionsModal] = useState(false);
	const [openAddSampleAnswerModal, setOpenAddSampleAnswerModal] = useState(false);

	// The question to add a sample answer to
	const [questionToAddSample, setQuestionToAddSample] = useState(null);

	// The currently selected question to delete permanently
	const [questionToDelete, setQuestionToDelete] = useState({});

	// The currently selected question to remove from the current grant
	const [questionToRemove, setQuestionToRemove] = useState({});

	// State For Notification when Submit is clicked
	const [showSubmitQuestionsNotif, setShowSubmitQuestionsNotif] = useState({
		showNotif: false,
		contents: { type: "", heading: "", message: "" },
	});

	// State For Notification when Question is Deleted
	const [showDeleteNotif, setShowDeleteNotif] = useState({
		showNotif: false,
		contents: { type: "", heading: "", message: "" },
	});

	// State For Notification when Question is Removed
	const [showRemoveNotif, setShowRemoveNotif] = useState({
		showNotif: false,
		contents: { type: "", heading: "", message: "" },
	});

	// State For Notification when Sample Answer is updated or added
	const [showSubmitNotification, setShowSubmitNotification] = useState({
		showNotif: false,
		contents: { type: "", heading: "", message: "" },
	});

	// Getting Grants from Airtable

	useEffect(() => {
		base("deduped_grants")
			.select({
				view: "Grid view",
			})
			.all()
			.then((records) => {
				let formatForSelect = records.map((record) => ({ value: `${record?.fields?.Name}-${removeQueryParameters(record?.fields?.GrantUrl)}`, label: record.fields.Name }));
				setGrants(formatForSelect);
			})
			.catch((err) => {
				console.error("Error occurred in fetching grants: ", err);
			});
	}, []);

	// Getting Questions from Airtable
	useEffect(() => {
		fetchQuestions().then(() => {});
	}, []);

	// This function shows the the user a notification based on the responses from post and patch requests.
	const setSubmitQuestionNotificationFields = (data) => {
		let contents = { type: "", heading: "", message: "" };
		let dataArray = [...data];
		if (dataArray.some((data) => !data?.id) === false) {
			contents.type = "success";
			contents.heading = `Questions Submitted`;
			contents.message = `${newQuestions.length} question(s) edited or newly created`;
		} else {
			contents.type = "error";
			contents.heading = "Failed to Submit";
			contents.message = JSON.stringify(data);
		}

		setContents(contents, setShowSubmitQuestionsNotif);
		setShowNotif(true, setShowSubmitQuestionsNotif);
	};

	// This function shows the user a notification based on the responses from a Delete request.
	const setDeleteQuestionNotificationFields = (data, questionText) => {
		let contents = { type: "", heading: "", message: "" };

		if (data?.deleted && data.deleted === true) {
			contents.type = "success";
			contents.heading = `Question Deleted`;
			contents.message = `'${questionText}' deleted`;
		} else {
			contents.type = "error";
			contents.heading = "Failed to Delete";
			contents.message = JSON.stringify(data);
		}

		setContents(contents, setShowDeleteNotif);
		setShowNotif(true, setShowDeleteNotif);
	};

	const setRemoveQuestionNotificationFields = (data, questionText) => {
		let contents = { type: "", heading: "", message: "" };

		if (data?.id) {
			contents.type = "success";
			contents.heading = `Question Removed`;
			contents.message = `'${questionText}' removed`;
		} else {
			contents.type = "error";
			contents.heading = "Failed to Remove";
			contents.message = JSON.stringify(data);
		}

		setContents(contents, setShowRemoveNotif);
		setShowNotif(true, setShowRemoveNotif);
	};

	// This function copies the question text of question to the user's clipboard.
	// If question.fields.QuestionText is undefined or null, then the function returns out.
	const copyQuestionTextToClipboard = async (question) => {
		if (!question?.fields?.QuestionText && typeof question?.fields?.QuestionText != "string") return;
		try {
			await navigator.clipboard.writeText(question.fields.QuestionText);
		} catch (err) {
			console.log("Error in Copying Question Text:", err);
		}
	};

	// This function gives the questions that we display for the current grant.
	// Typical Usage: getQuestionsToDisplay(questions, selectedOption.value), where questions & selectedOption are states.
	const getQuestionsToDisplay = (questionsArray, grantIdentifier) => {
		return questionsArray
			.filter((question) => (question?.fields?.grantIdentifier ? question.fields.grantIdentifier.split(",").includes(grantIdentifier) : false))
			.sort((a, b) => {
				if (a.fields.Link && b.fields.Link) return 0;
				else if (a.fields.Link) return -1;
				else if (b.fields.Link) return 1;

				if (a?.fields?.QuestionText === "" && b?.fields?.QuestionText === "") return 0;
				else if (a?.fields?.QuestionText === "") return 1;
				else if (b?.fields.QuestionText === "") return -1;

				if (isNumbered(a?.fields?.QuestionText) && isNumbered(b?.fields?.QuestionText))
					return numberingCompare(a?.fields.QuestionText, b?.fields?.QuestionText);
				else if (isNumbered(a?.fields?.QuestionText)) return 1;
				else if (isNumbered(b?.fields?.QuestionText)) return -1;

				return 0;
			});
	};

	// This function gives the options of which questions to pick from given that the user has selected a grant.
	// Usage: getQuestionOptions(questions, selectedOption.value);
	const getQuestionOptions = (questionsArray, grantIdentifier) => {
		let displayArray = getQuestionsToDisplay(questionsArray, grantIdentifier).map((q) => q.fields.QuestionText);
		return questions.filter((q) => displayArray.includes(q.fields.QuestionText) === false);
	};

	// This function fetches questions from Airtable
	const fetchQuestions = async () => {
		base("questions")
			.select({
				view: "Grid view",
			})
			.all()
			.then((records) => {
				setQuestions(records);
				console.log("questions re-fetched");
			})
			.catch((err) => {
				console.error("Error occurred in fetching questions: ", err);
			});
	};

	// Making a POST request for the given question.
	// (Adding the given question to the Airtable Questions table)
	// Used for submitting new questions.
	const postQuestion = (question) => {
		return new Promise((resolve, reject) => {
			console.log("post", question.fields.QuestionText);
			fetch("https://api.airtable.com/v0/" + baseId + "/" + questionsTableId, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${apiKey}`,
					"Content-Type": "application/json",
				},

				body: JSON.stringify({
					fields: question.fields,
				}),
			})
				.then((response) => response.json())
				.then((data) => {
					console.log("Post Confirmation: ", data);
					resolve(data);
				})
				.catch((error) => {
					console.error("Error:", error);
					reject(error);
				});
		});
	};

	// Making a PATCH request for the given question.
	// (Editing the given question in the Airtable Questions table)
	// Used for submitting newly edited questions.
	const patchQuestion = (question) => {
		console.log("api url: ", "https://api.airtable.com/v0/" + baseId + "/" + questionsTableId + "/" + question.id)
		return new Promise((resolve, reject) => {
			fetch("https://api.airtable.com/v0/" + baseId + "/" + questionsTableId + "/" + question.id, {
				method: "PATCH",
				headers: {
					Authorization: `Bearer ${apiKey}`,
					"Content-Type": "application/json",
				},

				body: JSON.stringify({
					fields: {
						grantIdentifier: question.fields.grantIdentifier,
						QuestionText: question.fields.QuestionText,
					},
				}),
			})
				.then((response) => response.json())
				.then((data) => {
					console.log("Patch Confirmation: ", data);
					resolve(data);
				})
				.catch((error) => {
					console.error("Error:", error);
					reject(error);
				});
		});
	};

	const updateQuestions = async () => {
		return Promise.all(
			newQuestions.map(async (question) => {
				if (!question?.id) {
					// If the question is newly added
					console.log('new question');
					return postQuestion(question);
				} else {
					// If the question is old (but they changed the text)
					console.log('old question');
					return patchQuestion(question);
				}
			})
		);
	};

	const handleQuestionChange = async (question, event) => {
		let newQuestion = question;
		newQuestion.fields.QuestionText = event.label;

		let existingQuestion = questions.find((question) => question.fields.QuestionText === newQuestion.fields.QuestionText);

		if (existingQuestion != undefined) {
			newQuestion["id"] = existingQuestion.id;
			newQuestion.fields.grantIdentifier = existingQuestion.fields.grantIdentifier;

			if (!newQuestion.fields["grantIdentifier"]) {
				newQuestion.fields["grantIdentifier"] = selectedOption.value;
			} else if (newQuestion.fields.grantIdentifier.split(",").includes(selectedOption.value) === false) {
				newQuestion.fields.grantIdentifier = [...newQuestion.fields.grantIdentifier.split(","), selectedOption.value].join(",");
			}
		}

		setNewQuestions((prev) => [...prev.filter((q) => q.fields.QuestionID !== newQuestion.fields.QuestionID), newQuestion]);
		setQuestions((prev) => [...prev.filter((q) => q.fields.QuestionID !== newQuestion.fields.QuestionID), newQuestion]);
	};

	const handleAddQuestion = () => {
		if (!selectedOption) return;

		let newQuestion = {
			fields: {
				QuestionID: `Q${Date.now()}`,
				QuestionText: "",
				grantIdentifier: selectedOption.value,
			},
		};

		setNewQuestions((prev) => [...prev, newQuestion]);
		setQuestions((prev) => [...prev, newQuestion]);
	};

	const handleSubmit = async () => {
		if (newQuestions.length === 0) return;

		console.log("Submitting");
		updateQuestions()
			.then((data) => {
				setSubmitQuestionNotificationFields(data);
				return fetchQuestions();
			})
			.then(() => setNewQuestions([]))
			.catch((e) => console.error("Error in Submitting : ", e));
	};

	const handleDeleteQuestion = (question) => {
		if (!question?.id) {
			setNewQuestions((prev) =>
				prev.filter((q) =>
					q?.fields?.QuestionID === question?.fields?.QuestionID && q?.fields?.QuestionText === question?.fields?.QuestionText
						? false
						: true
				)
			);

			setQuestions((prev) =>
				prev.filter((q) =>
					q?.fields?.QuestionID === question?.fields?.QuestionID && q?.fields?.QuestionText === question?.fields?.QuestionText
						? false
						: true
				)
			);
		} else {
			setNewQuestions((prev) => prev.filter((q) => q.fields.QuestionID != question.fields.QuestionID));
			setQuestions((prev) => prev.filter((q) => q.fields.QuestionID != question.fields.QuestionID));

			const questionText = question?.fields?.QuestionText;

			fetch("https://api.airtable.com/v0/" + baseId + "/" + questionsTableId + "/" + question.id, {
				method: "DELETE",
				headers: {
					Authorization: `Bearer ${apiKey}`,
					"Content-Type": "application/json",
				},
			})
				.then((response) => response.json())
				.then((confirmation) => {
					console.log("Record Deleted Confirmation", confirmation);
					setDeleteQuestionNotificationFields(confirmation, questionText);
				})
				.catch((e) => console.log("Error in Deleting Question: ", e));
		}
	};

	const removeGrantFromQuestion = (question, grantIdentifier) => {
		if (!question?.fields?.grantIdentifier || question.fields.grantIdentifier.split(",").includes(grantIdentifier) === false) return;

		if (!question?.id) {
			handleDeleteQuestion(question);
			return;
		}

		let newQuestion = question;
		newQuestion.fields.grantIdentifier = newQuestion.fields.grantIdentifier.split(",")
			.filter((recordId) => recordId != grantIdentifier)
			.join(",");

		const questionText = newQuestion?.fields?.QuestionText;
		patchQuestion(newQuestion).then((data) => {
			console.log("data", data);
			setRemoveQuestionNotificationFields(data, questionText);
		});

		setNewQuestions((prev) => [...prev.filter((q) => q.fields.QuestionID != question.fields.QuestionID), newQuestion]);
		setQuestions((prev) => [...prev.filter((q) => q.fields.QuestionID != question.fields.QuestionID), newQuestion]);
	};

	const handleAddSampleAnswer = (question) => {
		if (!selectedOption || !question || !question?.fields?.grantIdentifier) return;

		setQuestionToAddSample(question);
		setOpenAddSampleAnswerModal((prev) => !prev);
	};

	return !admin ? (
		<div className="justify-center items-center text-center w-full">
			<h1>Log In to see Grant Questions Form</h1>
		</div>
	) : (
		<>
			<div className="items-center justify-center space-y-4 col-span-8">
				Grant
				<div className="w-4/12">
					{grants.length === 0 ? (
						<div className="flex items-center justify-center">
							<RotatingLines color="grey" height="40" width="40" />
						</div>
					) : (
						<Select options={grants} value={selectedOption} onChange={(selected) => setSelectedOption(selected)} />
					)}
				</div>
				<h2 className="text-base font-semibold leading-7 text-gray-900">Grant Questions</h2>
				<form className="space-y-4 w-1/1">
					{selectedOption && questions ? (
						getQuestionsToDisplay(questions, selectedOption.value).map((question, index) => (
							<div key={index}>
								<div className="inline-flex max-w-screen w-full max-h-screen h-full">
									<Tooltip className="z-50" placement="left" content="Copy Question Text to Clipboard">
										<button type="button" onClick={() => copyQuestionTextToClipboard(question)} className="rounded-md mx-1">
											<ClipboardDocumentIcon className="w-6 h-6 text-gray-300 hover:text-gray-600" aria-hidden />
										</button>
									</Tooltip>

									<CreatableSelect
										className="max-w-screen w-full max-h-screen h-full"
										value={{ value: question.id, label: question.fields.QuestionText }}
										defaultValue={{ value: question.id, label: question.fields.QuestionText }}
										onChange={(e) => handleQuestionChange(question, e)}
										options={getQuestionOptions(questions, selectedOption.value).map((record) => ({
											value: record.id,
											label: record.fields.QuestionText,
										}))}
										placeholder="Select or create questions..."
									/>

									{question?.id && (
										<Tooltip placement="left" content="Remove this question from this grant?">
											<button
												className="rounded-md"
												type="button"
												onClick={() => {
													setQuestionToDelete({});
													setQuestionToRemove(question);
													setOpenRemoveQuestionsModal((prev) => !prev);
												}}
											>
												<MinusCircleIcon
													className="h-8 w-8 fill-gray-700 rounded-xl hover:fill-yellow-500 hover:bg-yellow-100"
													aria-hidden
												/>
											</button>
										</Tooltip>
									)}
									<Tooltip placement="right" content="Permanently delete this question?">
										<button
											className="rounded-md"
											type="button"
											onClick={() => {
												if (!question?.id) {
													handleDeleteQuestion(question);
												} else {
													setQuestionToDelete(question);
													setQuestionToRemove({});
													setOpenRemoveQuestionsModal((prev) => !prev);
												}
											}}
										>
											<XMarkIcon className="h-8 w-8 fill-gray-700 rounded-xl hover:fill-red-400 hover:bg-red-100" aria-hidden />
										</button>
									</Tooltip>
								</div>
								<div className="inline-flex max-w-screen w-full ml-16">
									
									<button onClick = {() => handleAddSampleAnswer(question)} type = "button" className="inline-flex mt-1 rounded bg-indigo-10 px-2 py-1 text-xs font-semibold text-gray-300 shadow-sm hover:text-gray-600 hover:bg-gray-50">
										{hasSampleAnswer(question) ? <PencilIcon className="h-4 w-auto mt-1" /> : <PlusIcon className="h-4 w-auto mt-1" />}
										<div className="rounded bg-indigo-10 px-2 py-1 text-xs font-semibold text-gray-300 shadow-sm hover:text-gray-600 hover:bg-gray-50">
											{hasSampleAnswer(question) ? "Edit Sample Answer" : "Add Sample Answer"}
										</div>
									</button>
								</div>
							</div>
						))
					) : (
						<></>
					)}

					<div className="">
						{selectedOption && (
							<button
								type="button"
								onClick={handleAddQuestion}
								className="rounded-md bg-white px-20 py-2.5 mr-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
							>
								+
							</button>
						)}

						{selectedOption && (
							<button
								type="button"
								onClick={async () => await handleSubmit()}
								className="rounded-md bg-indigo-600 ml-2 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
								Submit
							</button>
						)}
					</div>
				</form>
				{openRemoveQuestionsModal && (
					<RemoveQuestionDialog
						open={openRemoveQuestionsModal}
						setOpen={setOpenRemoveQuestionsModal}
						handleDeleteQuestion={handleDeleteQuestion}
						handleRemoveQuestion={(q) => removeGrantFromQuestion(q, selectedOption.value)}
						DeleteOrRemove={Object.keys(questionToDelete).length === 0 ? "Remove" : "Delete"}
						question={Object.keys(questionToDelete).length === 0 ? questionToRemove : questionToDelete}
						setQuestion={Object.keys(questionToDelete).length === 0 ? setQuestionToRemove : setQuestionToDelete}
					/>
				)}
				{openAddSampleAnswerModal && (
					<AddSampleAnswerModal
						open={openAddSampleAnswerModal}
						setOpen={setOpenAddSampleAnswerModal}
						question={questionToAddSample}
						_setQuestion={(question) =>
							setQuestions((questions) => [...questions.filter((q) => q.fields.QuestionID !== question.fields.QuestionID), question])
						}
						setShowSubmitNotification={setShowSubmitNotification}
					/>
				)}
			</div>
			{/* Notification For submitting questions */}
			<Notification
				showNotif={showSubmitQuestionsNotif.showNotif}
				setShowNotif={(val) => setShowNotif(val, setShowSubmitQuestionsNotif)}
				contents={showSubmitQuestionsNotif.contents}
			/>
			{/* Notfiication for Deleting Questions */}
			<Notification
				showNotif={showDeleteNotif.showNotif}
				setShowNotif={(val) => setShowNotif(val, setShowDeleteNotif)}
				contents={showDeleteNotif.contents}
			/>
			{/* Notification for Removing Questions */}
			<Notification
				showNotif={showRemoveNotif.showNotif}
				setShowNotif={(val) => setShowNotif(val, setShowRemoveNotif)}
				contents={showRemoveNotif.contents}
			/>

			{/* Notification for Submitting Sample Answers */}
			<Notification
				showNotif={showSubmitNotification.showNotif}
				setShowNotif={(val) => setShowNotif(val, setShowSubmitNotification)}
				contents={showSubmitNotification.contents}
			/>
		</>
	);
};

export default GrantQuestionsForm;
