import React, { useState, useEffect } from 'react';

export default function AutomationControls() {
  const [isRunning, setIsRunning] = useState(false);
  const [selectedGrant, setSelectedGrant] = useState('');
  const [availableGrants, setAvailableGrants] = useState([]);
  const [automationProgress, setAutomationProgress] = useState({
    companiesProcessed: 0,
    automationId: null,
    hasMoreCompanies: true,
    currentBatch: 0,
    totalCompaniesFound: 0,
    processedThisRun: 0,
    skippedCompanies: 0
  });
  const [status, setStatus] = useState({
    lastRun: null,
    nextRun: null
  });
  const [isLoading, setIsLoading] = useState(false);

  // Fetch available grants on component mount
  useEffect(() => {
    fetchAvailableGrants();
  }, []);

  // Fetch progress regularly when automation is running
  useEffect(() => {
    let progressInterval;
    if (isRunning) {
      // Fetch immediately when starting
      fetchProgress();
      // Then set up interval
      progressInterval = setInterval(fetchProgress, 2000); // Reduced to 2 seconds
    }
    return () => {
      if (progressInterval) {
        clearInterval(progressInterval);
      }
    };
  }, [isRunning]);

  const fetchAvailableGrants = async () => {
    try {
      const response = await fetch('/api/grants');
      const grants = await response.json();
      setAvailableGrants(grants);
    } catch (error) {
      console.error('Error fetching grants:', error);
    }
  };

  const startAutomation = async () => {
    if (isLoading || !selectedGrant) return;
    
    setIsLoading(true);
    try {
      console.log('Starting automation with grant:', selectedGrant);
      
      const response = await fetch(`/api/automation/start`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ selectedGrant }),
      });
      
      console.log('Response status:', response.status);
      
      const data = await response.json();
      console.log('Start automation response:', data);
      
      if (data.success) {
        setIsRunning(true);
        setAutomationProgress(prev => ({
          ...prev,
          automationId: data.automationId,
          currentBatch: 0,
          totalCompaniesFound: data.totalCompaniesFound || 0,
          processedThisRun: 0,
          skippedCompanies: 0
        }));
        
        fetchProgress();
      } else {
        console.error('Failed to start automation:', data.message);
        alert(`Failed to start automation: ${data.message}`);
      }
    } catch (error) {
      console.error('Error starting automation:', error);
      alert(`Error starting automation: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const stopAutomation = async () => {
    try {
      const response = await fetch(`/api/automation/stop`, {
        method: 'POST',
      });
      const data = await response.json();
      if (data.success) {
        setIsRunning(false);
        handleAutomationComplete();
      }
    } catch (error) {
      console.error('Error stopping automation:', error);
    }
  };

  const fetchProgress = async () => {
    try {
      console.log('Fetching progress...'); // Debug log
      const response = await fetch(`/api/automation/progress`);
      const data = await response.json();
      console.log('Progress data:', data); // Debug log
      
      setIsRunning(data.isRunning);
      setAutomationProgress({
        companiesProcessed: data.companiesProcessed || 0,
        automationId: data.currentAutomationId,
        hasMoreCompanies: data.hasMoreCompanies,
        currentBatch: data.currentBatch || 0,
        totalCompaniesFound: data.totalCompaniesFound || 0,
        processedThisRun: data.processedThisRun || 0,
        skippedCompanies: data.skippedCompanies || 0
      });
      setStatus({
        lastRun: data.lastRun,
        nextRun: data.nextRun
      });

      // If no more companies and not running, reset state
      if (!data.hasMoreCompanies && !data.isRunning) {
        handleAutomationComplete();
      }
    } catch (error) {
      console.error('Error fetching progress:', error);
      setIsRunning(false); // Reset running state on error
    }
  };

  const handleAutomationComplete = () => {
    setIsRunning(false);
    setAutomationProgress({
      companiesProcessed: 0,
      automationId: null,
      hasMoreCompanies: true,
      currentBatch: 0,
      totalCompaniesFound: 0,
      processedThisRun: 0,
      skippedCompanies: 0
    });
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow">
      <div className="flex items-center justify-between mb-4">
        <div>
          <h3 className="text-lg font-medium">Grant Automation Controls</h3>
          <p className="text-sm text-gray-500">
            {isRunning ? 'Automation is running' : 'Automation is stopped'}
          </p>
          <div className="mt-4 space-y-2">
            {automationProgress.totalCompaniesFound > 0 && (
              <p className="text-sm text-gray-600">
                Total companies to process: {automationProgress.totalCompaniesFound}
              </p>
            )}
            {automationProgress.currentBatch > 0 && (
              <p className="text-sm text-gray-600">
                Current batch: {automationProgress.currentBatch}
              </p>
            )}
            <p className="text-sm text-gray-600">
              Successfully processed: {automationProgress.companiesProcessed}
            </p>
            {automationProgress.skippedCompanies > 0 && (
              <p className="text-sm text-gray-600">
                Skipped companies: {automationProgress.skippedCompanies}
              </p>
            )}
            {status.lastRun && (
              <p className="text-xs text-gray-400">
                Last run: {new Date(status.lastRun).toLocaleString()}
              </p>
            )}
            {status.nextRun && (
              <p className="text-xs text-gray-400">
                Next run: {new Date(status.nextRun).toLocaleString()}
              </p>
            )}
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <select
            className="w-[450px] px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
            value={selectedGrant}
            onChange={(e) => setSelectedGrant(e.target.value)}
            disabled={isRunning}
          >
            <option value="">Select a grant (optional)</option>
            {availableGrants.map((grant) => (
              <option key={grant.value} value={grant.value}>
                {grant.label}
              </option>
            ))}
          </select>

          <button
            className={`px-4 py-2 rounded-md text-white font-medium ${
                isLoading ? 'bg-gray-400' :
                isRunning ? 'bg-red-600 hover:bg-red-700' : 'bg-green-600 hover:bg-green-700'
            }`}
            onClick={isRunning ? stopAutomation : startAutomation}
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : 
             isRunning ? 'Stop Automation' : 'Start Automation'}
          </button>
        </div>
      </div>
    </div>
  );
} 