import { Fragment, useState, useEffect, useRef } from "react";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { UsersIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon, PencilIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import { classNames, shortenText, checkEmail } from "./helpers.js";
import Airtable from "airtable";

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const base = new Airtable({ apiKey }).base(baseId);
const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;

export default function SubscribedPeopleModal({ open, setOpen }) {
  /* 
  
    1. iterate over companies to create the modal
    2. create a newlyChangedCompanies state that starts out empty.
    3. on checkbox click:
      - change the companies state to reflect the new subcribed value
      - if the company is in newlyChangedCompanies, then modify its subscribed value
      - if the company isn't in newlyChangedComapnies, then add it with the required changes.
    4. do the same whenever emails are changed. (when the circle click is pressed)
    5. in handleSave, only iterate over newlyChangedCompanies
  
  */

  const [query, setQuery] = useState("");
  const [newlyChangedComapnies, setNewlyChangedComapnies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [editCompanyFields, setEditCompanyFields] = useState({});
  const [editPeople1Name, setEditPeople1Name] = useState({});

  const emailInputRef = useRef(null);
  const peopleNameInputRef = useRef(null);

  useEffect(() => console.log("new changes added ", newlyChangedComapnies), [newlyChangedComapnies]);

  useEffect(() => {
    base("companies")
      .select({ view: "Grid view" })
      .all()
      .then((data) => {
        // removing all the companies that are don't have valid fields.
        // empty airtable ids, empty names, empty subscribed fields, etc.
        let companyRecords = data.filter((company) => {
          if (!company?.id || !company?.fields?.Name || !company?.fields?.RequestEmail) return false;
          if (!company?.fields["subscribed?"]) return false;

          return true;
        });

        setCompanies(companyRecords);

        let editCompanyRecords = {};

        for (const company of companyRecords) {
          editCompanyRecords = { ...editCompanyRecords, [company?.fields?.Name]: false };
        }

        setEditCompanyFields(editCompanyRecords);
        setEditPeople1Name(editCompanyRecords);
      });
  }, []);

  const handleSave = () => {
    for (const company of newlyChangedComapnies) {
      console.log(company?.fields?.Name);

      fetch("https://api.airtable.com/v0/" + baseId + "/" + companiesTableId + "/" + company.id, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fields: {
            "subscribed?": company.fields["subscribed?"],
            RequestEmail: company.fields.RequestEmail,
            People1Name: company.fields.People1Name,
          },
        }),
      })
        .then((response) => response.json())
        .then((confirmation) => {
          console.log(`Fields Updated for ${company?.fields?.Name} `, confirmation);
        })
        .catch((e) => console.error("Error", e));
    }

  };

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery("")} appear>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto mt-5 max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox onChange={(company) => {}}>
                {({ activeOption }) => (
                  <>
                    <div className="relative">
                      <MagnifyingGlassIcon
                        className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <Combobox.Input
                        className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                        placeholder="Search..."
                        onChange={(event) => setQuery(event.target.value)}
                      />
                    </div>

                    {(query === "" ||
                      (query !== "" &&
                        companies.filter((company) => company?.fields?.Name?.trim().toLowerCase().includes(query.toLowerCase())).length >
                          0)) && (
                      <Combobox.Options as="div" static hold className="flex divide-x divide-gray-100">
                        <div
                          className={classNames(
                            "max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4",
                            activeOption && "sm:h-96"
                          )}
                        >
                          <h2 className="mb-4 mt-2 text-xs font-semibold text-gray-500">
                            {query === "" ? "Subscribed Companies" : "All Companies"}
                          </h2>
                          <div className="-mx-2 text-sm text-gray-700">
                            {(query === ""
                              ? companies?.filter((company) => company?.fields["subscribed?"] === "true")
                              : companies?.filter((company) => company?.fields?.Name?.trim().toLowerCase().includes(query))
                            ).map((company) => (
                              <Combobox.Option
                                as="div"
                                key={company.id}
                                value={company}
                                className={({ active }) =>
                                  classNames(
                                    "flex cursor-default select-none items-center rounded-md p-2",
                                    active && "bg-gray-100 text-gray-900"
                                  )
                                }
                              >
                                {({ active }) => (
                                  <>
                                    <img src={company?.fields?.LogoUrl} alt="" className="h-6 w-6 flex-none rounded-full" />
                                    <span className="ml-3 flex-auto truncate">{company?.fields?.Name}</span>
                                    <input
                                      className="mx-3 rounded-md"
                                      id={`${company?.fields?.Name}-checkbox`}
                                      name={`${company?.fields?.Name}-checkbox`}
                                      type="checkbox"
                                      checked={
                                        company?.fields["subscribed?"] === "true"
                                          ? true
                                          : company?.fields["subscribed?"] === "false"
                                          ? false
                                          : undefined
                                      }
                                      // defaultChecked={company?.fields["subscribed?"] === "true"}
                                      onChange={(e) => {
                                        let value = e?.target?.checked;

                                        setNewlyChangedComapnies((prev) => {
                                          let newCompany = company;
                                          newCompany.fields["subscribed?"] = value ? "true" : "false";
                                          return [...prev?.filter((a) => a?.fields?.Name !== company?.fields?.Name), newCompany];
                                        });

                                        setCompanies((prev) => {
                                          let newCompany = company;
                                          newCompany.fields["subscribed?"] = value ? "true" : "false";
                                          return [...prev?.filter((a) => a?.fields?.Name != company?.fields?.Name), newCompany];
                                        });

                                        // if (value === true) {
                                        //   setSubscribedCompanies((prev) => {
                                        //     return [...prev?.filter((a) => a?.fields?.Name !== company?.fields?.Name), company];
                                        //   });
                                        //   setUnsubbedCompanies((prev) => {
                                        //     return prev?.filter((a) => a?.fields?.Name !== company?.fields?.Name);
                                        //   });
                                        // } else if (value === false) {
                                        //   setSubscribedCompanies((prev) => {
                                        //     return prev?.filter((a) => a?.fields?.Name !== company?.fields?.Name);
                                        //   });
                                        //   setUnsubbedCompanies((prev) => {
                                        //     return [...prev?.filter((a) => a?.fields?.Name !== company?.fields?.Name), company];
                                        //   });
                                        // }
                                        // setCompanies((prev) => {
                                        //   let newCompany = {
                                        //     ...company,
                                        //     fields: { ...company?.fields, "subscribed?": value ? "true" : "false" },
                                        //   };
                                        //   return [...prev?.filter((a) => a?.fields?.Name !== company?.fields?.Name), newCompany];
                                        // });
                                      }}
                                    />
                                    {/* {active && <ChevronRightIcon className="ml-3 h-5 w-5 flex-none text-gray-400" aria-hidden="true" />} */}
                                  </>
                                )}
                              </Combobox.Option>
                            ))}
                          </div>
                        </div>

                        {activeOption && (
                          <div className="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                            <div className="flex-none p-6 text-center">
                              <h2 className="py-2 font-semibold text-sm text-gray-900">{activeOption?.fields?.Name}'s Person of Contact</h2>
                              <img src={activeOption?.fields?.People1PictureUrl} alt="" className="mx-auto h-16 w-16 rounded-full" />
                              <span className="inline-flex ml-2">
                                {editPeople1Name[activeOption?.fields?.Name] === true ? (
                                  <input
                                    id={`${activeOption?.fields?.Name}-people1Name`}
                                    ref={peopleNameInputRef}
                                    className="rounded-md max-h-sm max-w-xs w-sm h-sm py-1 mt-1 mb-1 text-sm"
                                    defaultValue={activeOption?.fields?.People1Name}
                                  />
                                ) : (
                                  <h2 className="mt-3 font-semibold text-gray-900">{activeOption?.fields?.People1Name}</h2>
                                )}
                                <button
                                  className="ml-2 mt-3"
                                  onClick={() => {
                                    if (editPeople1Name[activeOption?.fields?.Name] === true) {
                                      const newName = document.getElementById(`${activeOption?.fields?.Name}-people1Name`).value;

                                      if (newName === "") {
                                        console.log("bad People1Name");
                                        peopleNameInputRef.current.focus();
                                        return;
                                      }

                                      // If the input is the same as the current, don't add the company to the newlyChangedCompanies array
                                      // ---> Reduces the number of unnecessary API calls 
                                      if (activeOption?.fields?.People1Name !== newName) {
                                        activeOption.fields.People1Name = newName;
                                        setNewlyChangedComapnies((prev) => {
                                          return [...prev.filter((a) => a?.fields?.Name !== activeOption?.fields?.Name), activeOption];
                                        });
                                      }
                                    }

                                    setEditPeople1Name((prev) => ({
                                      ...prev,
                                      [activeOption?.fields?.Name]: !prev[activeOption?.fields?.Name],
                                    }));
                                  }}
                                >
                                  {editPeople1Name[activeOption?.fields?.Name] === false ? (
                                    <PencilIcon className="w-4 h-auto hover:text-indigo-400 z-50 hover:cursor-pointer" aria-hidden={true} />
                                  ) : (
                                    <CheckCircleIcon
                                      className="w-4 h-auto hover:text-indigo-400 z-50 hover:cursor-pointer"
                                      aria-hidden={true}
                                    />
                                  )}
                                </button>
                              </span>
                              <p className="text-sm leading-6 text-gray-500">{activeOption?.fields?.People1Title}</p>
                            </div>
                            <div className="flex flex-auto flex-col justify-between p-6">
                              <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                                {/* <dt className="col-end-1 font-semibold text-gray-900">URL</dt> */}
                                {/* <dd className="truncate">
                                  <a href={activeOption?.fields?.} className="text-indigo-600 underline">
                                    {activeOption.url}
                                  </a>
                                </dd> */}
                                <dt className="col-end-1 font-semibold text-gray-900">Email</dt>
                                <dd className="truncate">
                                  {editCompanyFields[activeOption?.fields?.Name] === false && activeOption ? (
                                    <a href={`mailto:${activeOption?.fields?.RequestEmail}`} className="text-indigo-600 underline">
                                      {shortenText(activeOption?.fields?.RequestEmail, 20)}
                                    </a>
                                  ) : (
                                    <input
                                      id={`${activeOption.fields.Name}-reqEmail`}
                                      ref={emailInputRef}
                                      className="rounded-md max-w-sm w-sm text-sm"
                                      defaultValue={activeOption?.fields?.RequestEmail}
                                    />
                                  )}
                                  <span className="inline-flex ml-2">
                                    <button
                                      onClick={(e) => {
                                        if (editCompanyFields[activeOption?.fields?.Name] === true) {
                                          const newEmail = document.getElementById(`${activeOption?.fields?.Name}-reqEmail`).value;

                                          if (!checkEmail(newEmail)) {
                                            console.log("bad email");
                                            emailInputRef.current.focus();
                                            return;
                                          }

                                          activeOption.fields.RequestEmail = newEmail;
                                          setNewlyChangedComapnies((prev) => {
                                            return [...prev?.filter((a) => a?.fields?.Name != activeOption?.fields?.Name), activeOption];
                                          });
                                        }

                                        setEditCompanyFields((prev) => ({
                                          ...prev,
                                          [activeOption?.fields?.Name]: !prev[activeOption?.fields?.Name],
                                        }));
                                      }}
                                    >
                                      {editCompanyFields[activeOption?.fields?.Name] === false ? (
                                        <PencilIcon
                                          className="w-4 h-auto hover:text-indigo-400 z-50 hover:cursor-pointer"
                                          aria-hidden={true}
                                        />
                                      ) : (
                                        <CheckCircleIcon
                                          className="w-4 h-auto hover:text-indigo-400 z-50 hover:cursor-pointer"
                                          aria-hidden={true}
                                        />
                                      )}
                                    </button>
                                  </span>
                                </dd>
                                <dt className="col-end-1 font-semibold text-gray-900">Last Contacted</dt>
                                <dd className="truncate">
                                  <p className="text=black text-sm">
                                    {JSON.parse(activeOption?.fields?.Activity ? activeOption.fields.Activity : "{}")?.LatestActivity}
                                  </p>
                                </dd>
                              </dl>
                              {/* {<button
                                type="button"
                                className="mt-6 w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                Make Changes
                              </button>} */}
                            </div>
                          </div>
                        )}
                      </Combobox.Options>
                    )}

                    {query !== "" &&
                      companies?.filter((company) => company?.fields?.Name?.trim().toLowerCase().includes(query)).length === 0 && (
                        <div className="px-6 py-14 text-center text-sm sm:px-14">
                          <UsersIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                          <p className="mt-4 font-semibold text-gray-900">No companies found</p>
                          <p className="mt-2 text-gray-500">We couldn’t find anything with that term. Please try again.</p>
                        </div>
                      )}
                  </>
                )}
              </Combobox>
              <div className="px-2 py-3">
                <button
                  onClick={handleSave}
                  className="px-2 py-1 flex mx-auto justify-center items-center bg-indigo-600 shadow-sm text-white text-sm font-semibold rounded-s-md rounded-e-md hover:bg-indigo-500"
                >
                  Save Changes
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
