import React from 'react';

const ErrorList = ({ errors }) => {
  return (
    <div className="bg-red-500 text-white p-4 my-10">
      <ul className="list-disc list-inside">
        {errors.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </div>
  );
};

export default ErrorList;