import { MagnifyingGlassIcon, SparklesIcon } from "@heroicons/react/24/outline";
import { useState, Fragment, useEffect } from "react";
import { Listbox, Dialog, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { CheckIcon } from "@heroicons/react/20/solid";
import Airtable from "airtable";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { automationCards, getAutomationCards } from "./automationCards";
import { ShieldCheckIcon } from "@heroicons/react/24/outline";
import { useUser } from "@clerk/clerk-react";
import { ShareIcon } from "@heroicons/react/24/outline";

const base = new Airtable({
  apiKey: process.env.REACT_APP_AIR_KEY,
}).base(process.env.REACT_APP_AIR_BASEID);

// const STRIPE_DEPOSIT_LINK = 'https://buy.stripe.com/6oEdUAfk68WM6LS7sP';
const STRIPE_DEPOSIT_LINK = "https://buy.stripe.com/7sI03K3Bo1ukgms4gG";

const createSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/(^-|-$)/g, "");
};

const AutomationDirectory = ({ company }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedCard, setSelectedCard] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showRequirementsForm, setShowRequirementsForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allCards, setAllCards] = useState(automationCards);
  const { isLoaded, isSignedIn, user } = useUser();
  const [urlParams] = useState(new URLSearchParams(window.location.search));
  const [selectedStage, setSelectedStage] = useState("all");
  const [heartedCards, setHeartedCards] = useState(() => {
    const saved = localStorage.getItem("heartedCards");
    return saved ? JSON.parse(saved) : [];
  });

  // Add categories based on your automations
  const categories = [
    { id: "all", name: "All Categories" },
    { id: "sales_marketing", name: "Sales & Marketing" },
    { id: "onboarding", name: "Onboarding" },
    { id: "customer_success", name: "Customer Success" },
    { id: "project_management", name: "Project Management" },
    { id: "hr_recruiting", name: "HR & Recruiting" },
    { id: "finance_accounting", name: "Finance & Accounting" },
  ];

  const popularAutomations = [
    "Automatically process and categorize incoming emails",
    "Generate meeting summaries and action items using AI",
    "Automate data entry from documents and forms",
    "Schedule social media posts across platforms",
  ];

  // Add this useEffect to load the combined cards
  useEffect(() => {
    const loadCards = async () => {
      const combinedCards = await getAutomationCards();
      setAllCards(combinedCards);
    };
    loadCards();
  }, [selectedStage]);

  // Add this useEffect to handle initial load
  useEffect(() => {
    const automationSlug = urlParams.get("automation");
    if (automationSlug) {
      const matchingCard = allCards.find(
        (card) => createSlug(card.title) === automationSlug
      );
      if (matchingCard) {
        setSelectedCard(matchingCard);
        setIsModalOpen(true);
      }
    }
  }, []);

  const HeartButton = ({ card, isHearted, onHeart }) => {
    return (
      <button
        onClick={(e) => {
          e.stopPropagation();
          onHeart(card);
        }}
        className={`absolute top-2 right-2 p-2 rounded-full ${
          isHearted
            ? "text-red-500 bg-red-50"
            : "text-gray-400 bg-white hover:bg-gray-50"
        }`}
      >
        <svg
          className="w-5 h-5"
          fill={isHearted ? "currentColor" : "none"}
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
          />
        </svg>
      </button>
    );
  };

  // const steps = [
  //   {
  //     id: "Step 1",
  //     name: "Automation details + buy now",
  //     href: "#",
  //     status: "complete",
  //   },
  //   { id: "Step 2", name: "Requirements form", href: "#", status: "current" },
  //   {
  //     id: "Step 3",
  //     name: "Automation delivered",
  //     href: "#",
  //     status: "upcoming",
  //   },
  // ];

  const steps = [
    {
      id: "01",
      name: "Automation Details",
      description: "Review benefits",
      href: "#",
      status: "complete",
    },
    {
      id: "02",
      name: "Buy Now & Requirements",
      description: "Input info & buy",
      href: "#",
      status: "current",
    },
    {
      id: "03",
      name: "Receive & Watch it Run",
      description: "Watch it run",
      href: "#",
      status: "upcoming",
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const stages = [
    { id: 'all', name: 'All Stages' },
    { id: 'ready', name: 'Ready to Use' },
    { id: 'coming_soon', name: 'Coming Soon' },
    { id: 'suggested', name: 'Suggested' }
  ];

  // Add this console log to debug our cards
  useEffect(() => {
    console.log('All cards:', allCards.map(card => ({
      title: card.title,
      stage: card.stage
    })));
  }, [allCards]);

  const filteredAutomations = allCards
    .filter((card) => {
      // First check if card matches the selected stage
      if (selectedStage === 'all') {
        return true; // Show all cards when 'all' is selected
      }

      // Make sure both values are lowercase for consistent comparison
      const cardStage = (card.stage || '').toLowerCase();
      const selected = selectedStage.toLowerCase();
      
      return cardStage === selected;
    })
    .filter((card) => {
      const matchesSearch =
        card.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        card.tools.some((tool) =>
          tool.toLowerCase().includes(searchQuery.toLowerCase())
        );

      if (selectedCategory === "all") return matchesSearch;

      // Category matching logic
      const categoryMatches = {
        sales_marketing: ["Buffer", "LinkedIn", "CRM", "Gmail", "Calendar", "DocuSign"],
        onboarding: ["Gmail", "Zoom", "Forms", "CRM"],
        customer_success: ["Zendesk", "Intercom", "CRM"],
        project_management: ["Asana", "Jira", "Slack", "Calendar"],
        hr_recruiting: ["LinkedIn", "Workday", "Greenhouse"],
        finance_accounting: ["QuickBooks", "Stripe", "Xero"],
      };

      return (
        matchesSearch &&
        card.tools.some((tool) =>
          categoryMatches[selectedCategory]?.includes(tool)
        )
      );
    })
    .sort((a, b) => {
      // Sort by usedBy count within same stage
      const aUsers = parseInt(a.usedBy?.replace(/[^0-9.]/g, '')) || 0;
      const bUsers = parseInt(b.usedBy?.replace(/[^0-9.]/g, '')) || 0;
      return bUsers - aUsers;
    });

  const handleCardClick = async (e, card) => {
    // Keep existing CreatableSelect check
    if (
      e.target.closest(".react-select__control") ||
      e.target.closest(".react-select__menu")
    ) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    // Update URL with card slug
    const slug = createSlug(card.title);
    const newUrl = new URL(window.location);
    newUrl.searchParams.set("automation", slug);
    window.history.pushState({}, "", newUrl);

    // Get email from Clerk user
    const userEmail = user?.primaryEmailAddress?.emailAddress;

    if (!isLoaded || !isSignedIn || !userEmail) {
      console.error("User not loaded or not signed in");
      return;
    }

    try {
      // Create record in Airtable
      await base("automationRequests").create([
        {
          fields: {
            RequesterEmail: userEmail,
            PartnerEmail: card.partnerEmail || "spark@merchantgrowth.com",
            AutomationDescription: card.title,
            Status: "Interested",
            AutomationCategory: card.tools.join(", "),
            Savings: card.benefits ? card.benefits[0] : "",
            Budget: card.pricing?.setup || 5000,
            ClientName: company?.fields?.Name || "",
            Date: new Date().toISOString().split("T")[0],
            Type: "Automation",
            LoomVideoLink: card.promptUrl || "",
            TechnologiesUsed: card.tools.join(", "),
            Requirements: JSON.stringify(card.requirements || {}),
          },
        },
      ]);

      console.log("Created automation request record");
    } catch (error) {
      console.error("Error creating automation request:", error);
    }

    // Continue with existing modal logic
    setSelectedCard(card);
    setIsModalOpen(true);
  };

  const handleBuyNow = (card) => {
    // Generate a unique payment ID
    const paymentId = `payment_${Date.now()}_${Math.random()
      .toString(36)
      .substr(2, 9)}`;

    // Store the payment info
    localStorage.setItem(
      "pendingAutomation",
      JSON.stringify({
        id: paymentId,
        title: card.title,
        website: new URLSearchParams(window.location.search).get("website"),
        company: new URLSearchParams(window.location.search).get("company"),
        timestamp: Date.now(),
      })
    );

    // Open Stripe payment in new tab with success_url
    window.open(
      `${STRIPE_DEPOSIT_LINK}?client_reference_id=${paymentId}&success_url=${window.location.origin}/automation-success`,
      "_blank"
    );
  };

  // Add this useEffect to poll for payment completion
  useEffect(() => {
    const handlePaymentMessage = (event) => {
      if (event.data.type === "PAYMENT_SUCCESS") {
        const card = automationCards.find(
          (c) => c.title === event.data.data.title
        );
        if (card) {
          setSelectedCard(card);
          setShowRequirementsForm(true);
        }
      }
    };

    window.addEventListener("message", handlePaymentMessage);
    return () => window.removeEventListener("message", handlePaymentMessage);
  }, []);

  // Keep the existing polling mechanism as a fallback
  useEffect(() => {
    const checkPaymentStatus = async () => {
      const pendingAutomation = localStorage.getItem("pendingAutomation");
      if (!pendingAutomation) return;

      const data = JSON.parse(pendingAutomation);
      const timestamp = data.timestamp;

      // If it's been more than 30 minutes, clear the pending automation
      if (Date.now() - timestamp > 30 * 60 * 1000) {
        localStorage.removeItem("pendingAutomation");
        return;
      }

      try {
        // Replace this URL with your API endpoint that checks Stripe payment status
        const response = await fetch("/api/check-payment-status");
        const { isComplete } = await response.json();

        if (isComplete) {
          const card = automationCards.find((c) => c.title === data.title);
          if (card) {
            setSelectedCard(card);
            setShowRequirementsForm(true);
          }
          localStorage.removeItem("pendingAutomation");
        }
      } catch (error) {
        console.error("Error checking payment status:", error);
      }
    };

    const pollInterval = setInterval(checkPaymentStatus, 5000);
    return () => clearInterval(pollInterval);
  }, []);

  // Updated form submit handler (removing the duplicate)
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Existing validation code
      const errors = {};
      selectedCard.requirements?.fromClient?.forEach((req) => {
        if (!formData[req.name]?.trim()) {
          errors[req.name] = "This field is required";
        }
      });

      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        setIsSubmitting(false);
        return;
      }

      // 1. Generate automation scripts
      const automationData = {
        type: selectedCard.title,
        requirements: formData,
        json: selectedCard.json,
        tools: selectedCard.tools,
        company: new URLSearchParams(window.location.search).get("company"),
        website: new URLSearchParams(window.location.search).get("website"),
      };

      // Generate and deploy Make.com workflow
      const makeResponse = await fetch("/api/create-make-workflow", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(automationData),
      });
      const makeData = await makeResponse.json();

      // Generate and deploy n8n workflow
      const n8nResponse = await fetch("/api/create-n8n-workflow", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(automationData),
      });
      const n8nData = await n8nResponse.json();

      // Create record in Airtable with workflow URLs
      await base("automationRequests").create([
        {
          fields: {
            CompanyName: automationData.company,
            Website: automationData.website,
            AutomationDescription: selectedCard.title,
            Requirements: JSON.stringify(formData),
            Status: "Active",
            Date: new Date().toISOString(),
            MakeWorkflowUrl: makeData.workflowUrl,
            N8nWorkflowUrl: n8nData.workflowUrl,
            MakeWorkflowId: makeData.workflowId,
            N8nWorkflowId: n8nData.workflowId,
          },
        },
      ]);

      // Show success notification with workflow URLs
      const notification = document.createElement("div");
      notification.className =
        "fixed top-4 right-4 bg-green-50 border border-green-200 text-green-800 rounded-lg p-4 z-50 animate-fade-in-down";
      notification.innerHTML = `
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-sm font-medium">Automation is now running!</p>
            <div class="mt-2 text-xs">
              <a href="${makeData.workflowUrl}" target="_blank" class="text-blue-600 hover:underline">View Make.com workflow</a>
              <span class="mx-2">|</span>
              <a href="${n8nData.workflowUrl}" target="_blank" class="text-blue-600 hover:underline">View n8n workflow</a>
            </div>
          </div>
        </div>
      `;

      document.body.appendChild(notification);
      setTimeout(() => notification.remove(), 5000);

      // Close the form
      setShowRequirementsForm(false);
    } catch (error) {
      console.error("Form submission error:", error);

      // Show error notification
      const notification = document.createElement("div");
      notification.className =
        "fixed top-4 right-4 bg-red-50 border border-red-200 text-red-800 rounded-lg p-4 z-50 animate-fade-in-down";
      notification.innerHTML = `
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-sm font-medium">Error creating automation. Please try again.</p>
            <p class="text-xs mt-1">${error.message}</p>
          </div>
        </div>
      `;

      document.body.appendChild(notification);
      setTimeout(() => notification.remove(), 5000);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleModalClose = () => {
    const newUrl = new URL(window.location);
    newUrl.searchParams.delete("automation");
    window.history.pushState({}, "", newUrl);
    setIsModalOpen(false);
  };

  const getShareableLink = (card) => {
    const url = new URL(window.location);
    url.searchParams.set("automation", createSlug(card.title));
    return url.toString();
  };

  const handleHeartCard = async (card) => {
    const isHearted = heartedCards.includes(card.title);
    let newHeartedCards;

    if (isHearted) {
      newHeartedCards = heartedCards.filter((title) => title !== card.title);
    } else {
      newHeartedCards = [...heartedCards, card.title];
    }

    setHeartedCards(newHeartedCards);
    localStorage.setItem("heartedCards", JSON.stringify(newHeartedCards));

    // Update hearts count in Airtable
    try {
      await base("automationsClickMinded").update([
        {
          id: card.id,
          fields: {
            Hearts: isHearted ? card.hearts - 1 : card.hearts + 1,
          },
        },
      ]);
    } catch (error) {
      console.error("Error updating hearts:", error);
    }
  };

  // Update the stage selection buttons to force a refresh
  const handleStageChange = (newStage) => {
    setSelectedStage(newStage);
    // Optionally force a refresh of the cards
    setAllCards([]); // Clear cards temporarily
    const loadCards = async () => {
      const combinedCards = await getAutomationCards();
      setAllCards(combinedCards);
    };
    loadCards();
  };

  // Add stage selector before the grid
  return (
    <div className="px-6 py-8">
      <h2 className="text-3xl font-bold text-gray-900 mb-6">
        What would you like to automate?
        <span className="ml-2 text-sm font-semibold text-gray-400">
          ({filteredAutomations.length} automations. You're on your way to a
          fully automated, ai-powered company.)
        </span>
      </h2>

      <div className="relative mb-4">
        <div className="p-[5px] rounded-lg bg-gradient-to-r from-yellow-300 via-orange-500 to-purple-600">
          <div className="flex gap-2">
            <div className="w-48 border border-gray-700 rounded-lg">
              <Listbox value={selectedCategory} onChange={setSelectedCategory}>
                <div className="relative">
                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-6 pl-4 pr-10 text-left text-xl font-medium text-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500 border border-gray-200 hover:border-gray-300 transition-colors">
                    <span className="block truncate">
                      {
                        categories.find((cat) => cat.id === selectedCategory)
                          ?.name
                      }
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Listbox.Options className="absolute z-10 mt-1 max-h-58 w-80 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                    {categories.map((category) => (
                      <Listbox.Option
                        key={category.id}
                        value={category.id}
                        className={({ active, selected }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active
                              ? "bg-orange-100 text-orange-900"
                              : "text-gray-900"
                          }`
                        }
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {category.name}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-orange-600">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </div>
              </Listbox>
            </div>

            <div className="relative flex-1">
              <SparklesIcon className="absolute left-3 top-1/2 -translate-y-1/2 h-7 w-7 text-gray-400 z-10" />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full py-[25px] pl-12 pr-4 text-xl font-medium text-gray-900 bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
                placeholder="Search automations or describe what you'd like to automate..."
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex space-x-4 mb-4">
        {stages.map((stage) => (
          <button
            key={stage.id}
            onClick={() => handleStageChange(stage.id)}
            className={`px-4 py-2 rounded-lg ${
              selectedStage === stage.id
                ? "bg-orange-100 text-orange-700"
                : "bg-gray-100 text-gray-700 hover:bg-gray-200"
            }`}
          >
            {stage.name}
          </button>
        ))}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {filteredAutomations.map((card) => (
          <div key={card.title} className="relative h-full">
            <div
              onClick={(e) => handleCardClick(e, card)}
              className={`flex flex-col p-6 border-2 rounded-lg h-full ${
                card.promptUrl
                  ? "border-purple-200 hover:border-purple-500"
                  : "border-gray-200 hover:border-orange-500"
              } hover:shadow-lg transition-all duration-200 cursor-pointer`}
            >
              <div className="flex gap-2 mb-4">
                {card.icons.map((icon, i) => (
                  <img key={i} src={icon} alt="" className="h-6 w-6" />
                ))}
                {card.type && (
                  <span className="text-xs text-purple-600 font-medium px-2 py-1 bg-purple-50 rounded-full">
                    {card.type}
                  </span>
                )}
              </div>

              <div className="space-y-4">
                <div>
                  <h3 className="text-sm text-gray-900">
                    {card.title.split(":").map((part, i) =>
                      i === 0 ? (
                        <span key={i} className="font-bold">
                          {part}
                        </span>
                      ) : (
                        <span key={i} className="font-normal">
                          : {part}
                        </span>
                      )
                    )}
                  </h3>
                  {/* <p className="text-sm text-gray-500 mt-1">
                    {card.tools.join(", ")}
                  </p> */}
                </div>
              </div>
            </div>
            {card.hearts}
            <HeartButton
              card={card}
              isHearted={heartedCards.includes(card.title)}
              onHeart={handleHeartCard}
            />
            {card.status !== "ready" && (
              <div className="mt-10">
                <span className="absolute bottom-2 right-2 px-2 py-1 text-xs font-medium rounded-full bg-gray-100 text-gray-600">
                  {card.stage === "coming_soon" ? "Coming Soon" : 
                   card.stage === "ready" ? "Ready to Use" : 
                   card.stage === "suggested" ? "Suggested" : 
                   "Ready to Use"}
                </span>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Modal for detailed information */}
      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleModalClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                  <div className="absolute right-0 top-0 pr-4 pt-4 flex gap-2">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          getShareableLink(selectedCard)
                        );
                        // Optionally show a toast notification
                      }}
                    >
                      <span className="sr-only">Share</span>
                      <ShareIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      onClick={handleModalClose}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  {selectedCard && (
                    <div className="flex flex-col">
                      <div className="mt-3 w-full">
                        <Dialog.Title
                          as="div"
                          className="space-y-2 text-center"
                        >
                          {selectedCard.title.split(":").map((part, i) =>
                            i === 0 ? (
                              <h3
                                key={i}
                                className="text-4xl font-bold tracking-tight bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 bg-clip-text text-transparent"
                              >
                                {part}
                              </h3>
                            ) : (
                              <p key={i} className="text-xl text-gray-500 mt-2">
                                {part}
                              </p>
                            )
                          )}
                        </Dialog.Title>
                        <Dialog.Title
                          as="h3"
                          className="mt-5 text-xl font-semibold leading-6 text-gray-900"
                        >
                          Examples:
                        </Dialog.Title>
                        {/* Display the image from Airtable if available */}
                        {selectedCard.imageUrl ? (
                          <div className="mt-4">
                            <img
                              src={selectedCard.imageUrl}
                              alt={selectedCard.title}
                              className="w-full h-auto object-contain rounded-lg"
                            />
                          </div>
                        ) : (
                          selectedCard.exampleOutputs && (
                            <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                              {selectedCard.exampleOutputs.map((output, i) => (
                                <div key={i} className="relative">
                                  <img
                                    src={output.imageUrl}
                                    alt={output.description}
                                    className="w-full h-80 object-cover rounded-lg"
                                  />
                                </div>
                              ))}
                            </div>
                          )
                        )}
                        {/* <Dialog.Title
                          as="h3"
                          className="mt-2 mb-2 text-xl font-semibold leading-6 text-gray-900"
                        >
                          How it works:
                        </Dialog.Title> */}
                        <nav aria-label="Progress">
                          <ol
                            role="list"
                            className="mt-4 space-y-4 md:flex md:space-x-8 md:space-y-0"
                          >
                            {steps.map((step) => (
                              <li key={step.name} className="md:flex-1">
                                {step.status === "complete" ? (
                                  <a
                                    href={step.href}
                                    className="group flex flex-col border-l-4 border-gray-600 pl-4 hover:border-gray-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-2"
                                  >
                                    <span className="text-sm font-medium text-gray-600 group-hover:text-gray-800">
                                      {step.id}
                                    </span>
                                    <span className="text-sm font-medium">
                                      {step.name}
                                    </span>
                                  </a>
                                ) : step.status === "current" ? (
                                  <a
                                    href={step.href}
                                    aria-current="step"
                                    className="flex flex-col border-l-4 border-gray-200 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-2"
                                  >
                                    <span className="text-sm font-medium text-gray-600">
                                      {step.id}
                                    </span>
                                    <span className="text-sm font-medium">
                                      {step.name}
                                    </span>
                                  </a>
                                ) : (
                                  <a
                                    href={step.href}
                                    className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-2"
                                  >
                                    <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                                      {step.id}
                                    </span>
                                    <span className="text-sm font-medium">
                                      {step.name}
                                    </span>
                                  </a>
                                )}
                              </li>
                            ))}
                          </ol>
                        </nav>

                        {/* <div className="lg:border-b lg:border-t lg:border-gray-200">
                          <nav
                            aria-label="Progress"
                            className="mx-auto max-w-7xl"
                          >
                            <ol
                              role="list"
                              className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
                            >
                              {steps.map((step, stepIdx) => (
                                <li
                                  key={step.id}
                                  className="relative overflow-hidden lg:flex-1"
                                >
                                  <div
                                    className={classNames(
                                      stepIdx === 0
                                        ? "rounded-t-md border-b-0"
                                        : "",
                                      stepIdx === steps.length - 1
                                        ? "rounded-b-md border-t-0"
                                        : "",
                                      "overflow-hidden border border-gray-200 lg:border-0"
                                    )}
                                  >
                                    {step.status === "complete" ? (
                                      <a href={step.href} className="group">
                                        <span
                                          aria-hidden="true"
                                          className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                                        />
                                        <span
                                          className={classNames(
                                            stepIdx !== 0 ? "lg:pl-9" : "",
                                            "flex items-start px-6 py-5 text-sm font-medium"
                                          )}
                                        >
                                          <span className="shrink-0">
                                            <span className="flex size-10 items-center justify-center rounded-full bg-gray-600">
                                              <CheckIcon
                                                aria-hidden="true"
                                                className="size-6 text-white"
                                              />
                                            </span>
                                          </span>
                                          <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                                            <span className="text-sm font-medium">
                                              {step.name}
                                            </span>
                                            <span className="text-sm font-medium text-gray-500">
                                              {step.description}
                                            </span>
                                          </span>
                                        </span>
                                      </a>
                                    ) : step.status === "current" ? (
                                      <a href={step.href} aria-current="step">
                                        <span
                                          aria-hidden="true"
                                          className="absolute left-0 top-0 h-full w-1 bg-gray-600 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                                        />
                                        <span
                                          className={classNames(
                                            stepIdx !== 0 ? "lg:pl-9" : "",
                                            "flex items-start px-6 py-5 text-sm font-medium"
                                          )}
                                        >
                                          <span className="shrink-0">
                                            <span className="flex size-10 items-center justify-center rounded-full border-2 border-gray-600">
                                              <span className="text-gray-600">
                                                {step.id}
                                              </span>
                                            </span>
                                          </span>
                                          <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                                            <span className="text-sm font-medium">
                                              {step.name}
                                            </span>
                                            <span className="text-sm font-medium text-gray-500">
                                              {step.description}
                                            </span>
                                          </span>
                                        </span>
                                      </a>
                                    ) : (
                                      <a href={step.href} className="group">
                                        <span
                                          aria-hidden="true"
                                          className="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                                        />
                                        <span
                                          className={classNames(
                                            stepIdx !== 0 ? "lg:pl-9" : "",
                                            "flex items-start px-6 py-5 text-sm font-medium"
                                          )}
                                        >
                                          <span className="shrink-0">
                                            <span className="flex size-10 items-center justify-center rounded-full border-2 border-gray-300">
                                              <span className="text-gray-500">
                                                {step.id}
                                              </span>
                                            </span>
                                          </span>
                                          <span className="ml-4 mt-0.5 flex min-w-0 flex-col">
                                            <span className="text-sm font-medium">
                                              {step.name}
                                            </span>
                                            <span className="text-sm font-medium text-gray-500">
                                              {step.description}
                                            </span>
                                          </span>
                                        </span>
                                      </a>
                                    )}

                                    {stepIdx !== 0 ? (
                                      <>
                                        <div
                                          aria-hidden="true"
                                          className="absolute inset-0 left-0 top-0 hidden w-3 lg:block"
                                        >
                                          <svg
                                            fill="none"
                                            viewBox="0 0 12 82"
                                            preserveAspectRatio="none"
                                            className="size-full text-gray-300"
                                          >
                                            <path
                                              d="M0.5 0V31L10.5 41L0.5 51V82"
                                              stroke="currentcolor"
                                              vectorEffect="non-scaling-stroke"
                                            />
                                          </svg>
                                        </div>
                                      </>
                                    ) : null}
                                  </div>
                                </li>
                              ))}
                            </ol>
                          </nav>
                        </div> */}

                        {/* Benefits and Included Sections Side by Side */}
                        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
                          {/* Benefits Section */}
                          {selectedCard.benefits && (
                            <div>
                              <h4 className="text-sm font-semibold text-gray-900 mb-2">
                                Benefits:
                              </h4>
                              <ul className="text-sm text-gray-600 space-y-1">
                                {selectedCard.benefits.map((benefit, i) => (
                                  <li key={i} className="flex items-start">
                                    <svg
                                      className="h-4 w-4 text-green-500"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                      />
                                    </svg>
                                    <span>{benefit}</span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}

                          {/* What's Included Section */}
                          {selectedCard.included && (
                            <div>
                              <h4 className="text-sm font-semibold text-gray-900 mb-2">
                                Included:
                              </h4>
                              <ul className="text-sm text-gray-600 space-y-1">
                                {selectedCard.included.map((item, i) => (
                                  <li key={i} className="flex items-start">
                                    <svg
                                      className="h-4 w-4 text-green-500"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                      />
                                    </svg>
                                    <span>{item}</span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                        {/* Pricing Section */}
                        {selectedCard.pricing && (
                          <div className="mt-4 border-t border-gray-200 pt-4">
                            <div className="flex justify-between items-center mb-2">
                              <div>
                                <p className="text-sm font-semibold text-gray-900">
                                  ${selectedCard.pricing.setup} setup
                                </p>
                                <p className="text-sm text-gray-500">
                                  + ${selectedCard.pricing.monthly}/month
                                </p>
                              </div>
                              {selectedCard.turnaroundDays && (
                                <div className="text-sm text-gray-600">
                                  {selectedCard.turnaroundDays} day setup
                                </div>
                              )}
                            </div>

                            {/* Added Guarantee Section */}
                            <div className="flex items-center justify-between mb-4">
                              <div className="flex items-center gap-2">
                                <ShieldCheckIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span className="text-sm text-gray-500 font-medium">
                                  30-day money back guarantee (or 14 day free
                                  trial)
                                </span>
                              </div>
                              <button
                                onClick={() => setShowRequirementsForm(true)}
                                className="text-sm text-orange-600 hover:text-orange-700"
                              >
                                Requirements Form
                              </button>
                            </div>

                            {selectedCard.stripeLink && (
                              <button
                                onClick={() => handleBuyNow(selectedCard)}
                                className="w-full bg-gradient-to-r from-yellow-400 via-orange-500 to-purple-600 text-white font-semibold py-2 px-4 rounded-md hover:from-yellow-500 hover:via-orange-600 hover:to-purple-700 transition-all duration-200"
                              >
                                Buy Now
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Requirements Form Modal */}
      <Transition.Root show={showRequirementsForm} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={setShowRequirementsForm}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                  <div className="absolute right-0 top-0 pr-4 pt-4">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      onClick={() => setShowRequirementsForm(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-semibold leading-6 text-gray-900 mb-4"
                      >
                        Required Information
                      </Dialog.Title>

                      <form onSubmit={handleFormSubmit} className="space-y-6">
                        {selectedCard?.requirements?.fromClient?.map(
                          (requirement) => (
                            <div key={requirement.name} className="space-y-2">
                              <label
                                htmlFor={requirement.name}
                                className="block text-sm font-medium text-gray-900"
                              >
                                {requirement.name}
                                <span className="text-red-500">*</span>
                              </label>
                              <div className="relative">
                                <textarea
                                  id={requirement.name}
                                  name={requirement.name}
                                  value={formData[requirement.name] || ""}
                                  onChange={(e) =>
                                    setFormData((prev) => ({
                                      ...prev,
                                      [requirement.name]: e.target.value,
                                    }))
                                  }
                                  placeholder={requirement.example}
                                  rows={2}
                                  className={`
                                  block w-full rounded-md border-gray-300 shadow-sm 
                                  focus:border-orange-500 focus:ring-orange-500 
                                  transition-colors placeholder:whitespace-pre-wrap
                                `}
                                  required
                                />
                                {formErrors[requirement.name] && (
                                  <div className="text-red-500 text-xs mt-1">
                                    {formErrors[requirement.name]}
                                  </div>
                                )}
                              </div>
                            </div>
                          )
                        )}

                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                          <button
                            type="submit"
                            className="inline-flex w-full justify-center rounded-md bg-gradient-to-r from-yellow-400 via-orange-500 to-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:from-yellow-500 hover:via-orange-600 hover:to-purple-700 sm:ml-3 sm:w-auto"
                          >
                            Start Automation
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={() => setShowRequirementsForm(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default AutomationDirectory;
