import React, { useState, useEffect } from 'react';
import { checkEmail, checkPhoneNumber, checkValidDomainName } from './helpers';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { UserButton, UserProfile, useUser } from "@clerk/clerk-react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import Cookies from 'js-cookie';

export default function FeedBackPage() {
  const { isSignedIn, isLoaded, user } = useUser();

  const [JITDomain, setJITDomain] = useState('');
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
		if (isLoaded === false || isSignedIn === false) return;

    setUserEmail(user.primaryEmailAddress.emailAddress);
  }, [user, isLoaded, isSignedIn])

  // setting up the defaultvalues for the inputs of the form
  useEffect(() => {
    if (Cookies.get("JITDomain")) {
      setJITDomain(Cookies.get("JITDomain"));
    }
  }, [])

  // sending the manual form for review
  useEffect(() => {
    if (userEmail && JITDomain) {
      const request = `User landed in Feedback Page for JIT\nLinkedIn enrichment failed. User is told to correct the default values\nCompany: ${JITDomain}\nUser: ${userEmail}\nLink for us to fix manually: https://airtable.com/shr6TlpPvxchW3JB7`
      const website = `Send to ${userEmail} when the report is done`
      fetch(`https://hooks.zapier.com/hooks/catch/13965335/${process.env.REACT_APP_ZAPIER_SAVINGS}/`, {
        method: "POST",
        body: JSON.stringify({ request, website }),
      })
    }
  }, [userEmail, JITDomain])


  return (
    <>
      <div className="mx-auto px-5 grid min-h-full grid-cols-1 grid-rows-[0,auto,1fr] bg-white lg:grid-cols-[max(50%,36rem),1fr]">
        <header className="mx-auto w-full max-w-7xl px-6 pt-6 sm:pt-10 lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:px-8">
          <a href="/">
            <span className="sr-only">Your Company</span>
            <img
              className="h-10 w-auto"
              src="https://assets.website-files.com/634f60226f66af42a384d5b7/647819351547cbfe06a601b8_upgraded-full-word-logo-no-background.png"
              alt="Your Company"
            />
          </a>
        </header>
        
        <main className="mx-auto w-full max-w-7xl px-6 py-24 sm:py-32 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:pl-32">

        <div className="">
          <button onClick={() => window.history.go(-1)} className="text-sm font-semibold leading-7 text-indigo-600">
            <span aria-hidden="true">&larr;</span> Back to previous page
          </button>
        </div>
        
        <div className="max-w-lg">
            <h1 className="mt-4 max-w-lg text-5xl font-bold tracking-tight text-gray-900">Thank you for waiting!</h1>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Your report is ready, but our system couldn't enrich everything.
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Some temporary default values are used to get your report up and running.
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Please review your company info using the side panel and correct anything that's off. Thank you!
            </p>
            <div className='mt-6'>
              <a 
                href={`https://app.getupgraded.ca/?website=${JITDomain}`} 
                className=" text-blue-500 hover:text-blue-700"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ArrowTopRightOnSquareIcon className="mb-2 h-6 w-6 inline"/> Savings report for {JITDomain}
              </a>
            </div>
            
          </div>
        
        </main>
        <div className="ml-20 hidden lg:relative lg:col-start-2 lg:row-start-1 lg:row-end-4 lg:block">
          <img
            alt='annual traffic'
            className='mt-20 shadow-xl'
            src="https://i.gifer.com/TBms.gif"
          />
        </div>
      </div>
    </>
  )
}