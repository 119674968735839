import { useEffect, useState } from 'react';
import Airtable from 'airtable';

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const base = new Airtable({ apiKey: apiKey }).base(baseId);

// tableName -- What table do you want to fetch from
// callbackFn --- what do you want to with the fetched data?
//      Note : if you don't want to do anything special with the fetched data, you can leave it empty and just use the data property.
function useBase(tableName, callbackFn = null, grants = []) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let ignore = false;
    setLoading(true);

    (() => {
      if (ignore) {
        console.log('ignoring');
        return;
      }

      if (grants.length === 0) {
        base(tableName)
          .select({ view: 'Grid view' })
          .all()
          .then((data) => {
            if (callbackFn) callbackFn(data);
            setData(data);
          })
          .catch((e) => {
            setError(e);
            console.error(`Error occurred fetching from ${tableName}.`, e);
          })
          .finally(() => setLoading(false));
      }
    })();

    return () => {
      ignore = true;
    };
  }, [tableName, callbackFn]);

  return { loading, data, error };
}

export default useBase;
