import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate } from "react-router-dom";

export default function LinearDeterminate({ loading, JITWorking, unknownDomain }) {
  const [progress, setProgress] = useState(0);
  const [display, setDisplay] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    let hideTimer;

    if (loading && JITWorking) {
      timer = setInterval(() => {
        setProgress((oldProgress) => {
          let diff = unknownDomain ? Math.random() * 1 : Math.random() * 5;
          return Math.min(oldProgress + diff, 100);
        });
      }, 200); // Reduced interval for faster progression
    } else {
      setProgress(100);
      hideTimer = setTimeout(() => {
        setDisplay(false);
      }, 1000);
    }

    return () => {
      clearInterval(timer);
      clearTimeout(hideTimer);
    };
  }, [loading, JITWorking, unknownDomain, navigate]);

  return (
    <Box sx={{ width: '100%', zIndex: 100, position: "relative" }}>
      {display && <LinearProgress variant="determinate" value={progress} sx={{ height: '4px' }} />}
    </Box>
  );
}
