import {
    nextButtonStyle,
    prevButtonStyle,
    questionStyle,
    textDivStyle,
    textareaStyle,
  } from "../styles";
  
  export function DescribeExistingLender({
    formData,
    handleChangeInput,
    handleSubmitFormData,
    prevStep,
  }) {
    return (
      <>
        <div>
          <label for="Lender Relationships" class={`${questionStyle}`}>
            {`Existing Lender Relationships: List and describe any existing lender relationships you have or loans applied for in the last year`}
          </label>
          <div class={`${textDivStyle}`}>
            <textarea
              name="Lender Relationships"
              rows="4"
              class={`${textareaStyle}`}
              placeholder=""
              value={formData["Lender Relationships"]}
              onChange={(event) => handleChangeInput(event)}
            ></textarea>
          </div>
          <button type="button" class={`${prevButtonStyle}`} onClick={prevStep}>
            Prev
          </button>
          <button
            type="button"
            class={`${nextButtonStyle}`}
            onClick={handleSubmitFormData}
            disabled={!formData["Lender Relationships"]}
          >
            Submit
          </button>
        </div>
      </>
    );
  }
  