import React, { useState } from 'react';
import DataUploader from "./DataUploader.js";

const DataImportModal = ({ isOpen, onClose, user, copyClipboardNotif, setCopyClipboardNotif, setNotifications, handleUploadComplete }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded shadow-lg w-11/12 md:w-2/3 lg:w-1/2">
                <h2 className="text-2xl font-bold mb-4">Import Your LinkedIn Connections</h2>
                <DataUploader
                    user={user}
                    onUploadComplete={handleUploadComplete}
                    copyClipboardNotif={copyClipboardNotif}
                    setCopyClipboardNotif={setCopyClipboardNotif}
                    setNotifications={setNotifications}
                />
                <div className="mt-4 flex justify-end">
                    <button
                        onClick={onClose}
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DataImportModal;
