import React, { Fragment, useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { countryOptions, countryCodes, regionCodes } from "./helperData";
import { setShowNotif, setContents, capitalizeWords, checkEmail, highlightErrorOnElement, getQueryVariable } from "./helpers";
import IndustriesMappingModal from "./IndustriesMappingModal";
import { useUser } from "@clerk/clerk-react";
import useBase from "./useBase";
import NotificationButtons from "./NotificationButtons";

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;
const usersTableId = process.env.REACT_APP_AIR_USERS;

export default function SidePanel({
	restricted,
	// signedUp,
	// userEmail,
	// setSignedUp,
	// setUserEmail,
	// setUserDomain,
	// setGetEmail,
	admin,
	flaggedGrants,
	setFlaggedGrants,
	secret_1,
	eligibleGrants,
	setEligibleGrants,
	open,
	setOpen,
	setFormSubmitted,
	setCompanyFormNotif,
	company,
	setCompany,
	industries,
	setIndustries,
	industryRecords,
	setIndustryRecords,
	// setEmailCloseable,
	find_grants,
	findDiscounts
}) {
	const { user, isLoaded: isUserLoaded } = useUser();
	const { loading: usersLoading, data: users, error: usersError } = useBase("users");

	const handleDeleteUser = async () => {
		if (!isUserLoaded) return;

		try {
			const possibleUserRecords = users.filter(
				(userRecord) =>
					userRecord.fields.Email === user.primaryEmailAddress.emailAddress && userRecord.fields.Domain === getQueryVariable("website")
			);

			if (possibleUserRecords.length === 0) {
				throw new Error("No possible user");
			} else if (possibleUserRecords.length > 1) {
				console.error("More than one possible user");
			}

			const userRecord = possibleUserRecords[0];
			const userRecordId = userRecord.id;

			console.log(userRecord, userRecordId);

			const response = await fetch("https://api.airtable.com/v0/" + baseId + "/" + usersTableId + "/" + userRecordId, {
				method: "DELETE",
				headers: {
					Authorization: `Bearer ${apiKey}`,
					"Content-Type": "application/json",
				},
			});

			const confirmation = await response.json();
			console.log(confirmation);

			await user.delete();

		} catch (error) {
			console.error("Error in Deleting User Account: ", error);
			console.error("Backends out of sync.");
		}
	};

	// getNewIndustrySelect() : String --> Array
	// Returns an array consisting of new options for the Industry Select in the side panel (based on the company's updated information)
	const getNewIndustrySelectOptions = () => {
		// This array will be populated with all of the 'parent' industries based on the subindustries of the company.
		// For example, if the company's industries are 'energy storage, it services', then after the nested for-loop below,
		// companyParentIndustries === ["Energy", "Technology"].

		// (Similarly, if the company's industry list is "biotechnology", companyParentIndustries will become ["Healthcare", "Technology"]
		// since biotechnology is under both Healthcare and Technology.
		let companyParentIndustries = [];

		// Double nested for loop to populate companyParentIndustries.
		if (company?.fields?.Industry) {
			company.fields.Industry.toLowerCase()
				.split(",")
				.map((item) => item.trim())
				.forEach((companyIndustry) => {
					Object.keys(industries).forEach((parentIndustry) => {
						if (industries[parentIndustry].includes(companyIndustry.toLowerCase())) {
							companyParentIndustries.push(parentIndustry);
						}
					});
				});
		}

		// Declaring the new options for the industries select (filtering out anything that is in companyParentIndustries), then
		// mapping each industry to an object of the form { value : str, label : str }.
		let newIndustryOptions = Object.keys(industries)
			.filter((industry) => companyParentIndustries.map((item) => item.toLowerCase()).includes(industry.toLowerCase()) === false)
			.map((industry) => ({
				value: capitalizeWords(industry),
				label: capitalizeWords(industry),
			}));

		return newIndustryOptions;
	};

	const requestEmailRef = useRef(null);
	const ccEmailRef = useRef(null);
	const bccEmailRef = useRef(null);

	// Getting Province Options
	let provinceOptions = [];
	if (company?.fields?.Country) {
		company.fields.Country.split(",").forEach(
			(countryCode) =>
			(provinceOptions = [
				...provinceOptions,
				...(Object.keys(regionCodes).includes(countryCode)
					? Object.keys(regionCodes[countryCode]).map((provinceCode) => ({
						value: provinceCode,
						label: capitalizeWords(regionCodes[countryCode][provinceCode]),
					}))
					: []),
			])
		);
	}

	const sendingFrequencyOptions = [
		{ label: "Never", value: "-1" },
		{ label: "Monthly", value: "28" },
		{ label: "Bi-Weekly", value: "14" },
		{ label: "Weekly", value: "7" },
		{ label: "Twice a Week", value: "4" },
		{ label: "Every Three Days", value: "3" },
		{ label: "Three Times a Week", value: "2" },
		{ label: "Daily", value: "1" },
	];
	const [openIndustriesModal, setOpenIndustriesModal] = useState(false);
	const [industryOptions, setIndustryOptions] = useState([]);

	// setting industry options for the react select
	useEffect(() => {
		if (!industries || !company) return;
		let newIndustries = getNewIndustrySelectOptions();

		setIndustryOptions(newIndustries);
	}, [industries, company]);

	function handleSubmit(event) {
		event.preventDefault();
		console.log(event.target[0].value);
		console.log("event", event);
		console.log("event target", event.target);

		setShowNotif(true, setCompanyFormNotif);
		setContents(
			{ type: "success", heading: "Form Submitted", message: "Thank you for updating your information. Please refresh the page." },
			setCompanyFormNotif
		);

		// Company Id
		console.log("company id", company.id);
		var companyId = company.id;

		// Company Name
		console.log("company name", event.target["companyName"].value);
		var companyName = event.target["companyName"].value;

		// Employees
		console.log("employees", event.target["employees"].value);
		var employees = event.target["employees"].value;

		// Location
		console.log("location", event.target["location"].value);
		var location = event.target["location"].value;

		// Country
		const countryString = event.target["country"].value;
		const countriesString = Array.from(event.target["country"])
			.map((country) => country.value)
			.join(",");
		console.log("event country:", countryString);
		console.log("event countries:", countriesString);

		var country = countryString ? countryString : countriesString;
		console.log("final country", country);

		// Industry
		const industryString = event.target["industry"].value;
		const industriesString = Array.from(event.target["industry"])
			.map((industry) => industry.value)
			.join(",");

		var industry = industryString ? industryString : industriesString;

		console.log("industry", industry);

		// Eligible Grants

		if (secret_1) {
			// const eligibleGrant = event.target["eligibleGrants"].value
			// console.log('DEBUG 1', eligibleGrant);
			const inputGrants = Array.from(event.target["eligibleGrants"]).map((grant) => JSON.parse(grant.value));
			// console.log('DEBUG 2', eligibleGrants);
			// console.log("event grant:", eligibleGrant);
			// console.log("event grants:", eligibleGrants);
			// console.log('event target grants', Array.from(event.target["eligibleGrants"])[1]);

			console.log("input grants", inputGrants);
			console.log("not updated state", eligibleGrants);

			eligibleGrants.forEach((grant) => {
				console.log("current grant", grant.fields.Name);
				console.log("total", inputGrants);
				if (!inputGrants?.map((grant) => grant.fields.Name)?.includes(grant.fields.Name)) {
					// excludeGrants.push(grant.fields.Name)
					setFlaggedGrants((prevArray) => [...prevArray, grant]);
					console.log("now flagged", grant.fields.Name);
				}
			});

			flaggedGrants.forEach((grant) => {
				if (inputGrants.map((grant) => grant.fields.Name).includes(grant.fields.Name)) {
					setFlaggedGrants(flaggedGrants.filter((cur) => cur.fields.Name !== grant.fields.Name));
				}
			});

			// console.log("WHAT ARE THE EXCLUDED", flaggedGrants);

			setEligibleGrants(inputGrants);
		}

		// Founded
		var founded = event.target["founded"].value;
		console.log("founded", event.target["founded"].value);

		// LinkedInUrl
		var linkedInUrl = event.target["linkedInUrl"].value;
		console.log("linkedInUrl", event.target["linkedInUrl"].value);

		// Annual Revenue
		var annualRevenue = event.target["annualRevenue"].value.replace(",", "");
		console.log("annualRevenue", annualRevenue);

		// Conversion Rate
		var conversionRate = event.target["conversionRate"].value;
		console.log("conversionRate", event.target["conversionRate"].value);

		// Avg Deal Size
		var avgDealSize = event.target["avgDealSize"].value.replace(",", "");
		console.log("avgDealSize", event.target["avgDealSize"].value);

		// Debt
		var debt = event.target["Debt"].value.replace(",", "");
		console.log("Debt", event.target["Debt"].value);

		// Annual Traffic
		var annualTraffic = event.target["AnnualTraffic"].value.replace(",", "");
		console.log("AnnualTraffic", event.target["AnnualTraffic"].value);

		// Operational Costs
		var operationalCosts = event.target["OperationalCosts"].value.replace(",", "");
		console.log("OperationalCosts", event.target["OperationalCosts"].value);

		// List Size
		var listSize = event.target["ListSize"].value.replace(",", "");
		console.log("ListSize", event.target["ListSize"].value);

		// Likely Customers
		console.log("likelyCustomers", event.target["likelyCustomers"].value);
		var likelyCustomers = event.target["likelyCustomers"].value;

		// Description
		console.log("description", event.target["description"].value);
		var description = event.target["description"].value;

		// LongDescription
		console.log("longDescription", event.target["longDescription"].value);
		var longDescription = event.target["longDescription"].value;

		// Technologies
		const technologyString = event.target["technology"].value;
		const technologiesString = Array.from(event.target["technology"])
			.map((technology) => technology.value.trim())
			.join(", ");
		console.log("event technology:", technologyString);
		console.log("event technologies:", technologiesString);

		var technology = technologyString ? technologyString : technologiesString;

		console.log("technology", technology);

		var profitable = event.target["profitable-checkbox"].checked === true ? "Yes" : "No";
		console.log("profitable-checkbox", profitable);

		let provincesOrStates = Array.from(event.target["province"])
			.map((option) => option.value)
			.join(",");

		if (!provincesOrStates) {
			provincesOrStates = event.target["province"].value;
		}
		console.log("province", provincesOrStates);

		let sendingFrequencyNumValue = parseFloat(event.target["sendingFrequencyOption"].value);
		let sendingFrequency = sendingFrequencyNumValue === -1 ? null : sendingFrequencyNumValue;
		let reportingEmailsValue = sendingFrequencyNumValue === -1 ? "false" : "true";
		console.log("sendingFrequency and reportingEmail", sendingFrequency, reportingEmailsValue);

		let requestEmails = event.target["requestEmail"];
		if (requestEmails)
			requestEmails = requestEmails.value
				.split(",")
				.map((item) => item.trim())
				.join(",");

		if (requestEmails && requestEmails.split(",").some((email) => !checkEmail(email))) {
			highlightErrorOnElement(requestEmailRef.current, true, 5);
			console.log("One or more of the Emails are invlaid. Form not Submitted.");
			return;
		}
		console.log("Request Email", requestEmails);

		let person1Name = event.target["People1Name"].value;
		console.log("People1Name", person1Name);

		let ccEmails = event.target["CC"];
		if (ccEmails)
			ccEmails = ccEmails.value
				.split(",")
				.map((item) => item.trim())
				.join(",");

		if (ccEmails && ccEmails.split(",").some((email) => !checkEmail(email))) {
			highlightErrorOnElement(ccEmailRef.current, true, 5);
			console.log("One or more of the emails are invalid. Form not submitted.");
			return;
		}

		console.log("CCEmails", ccEmails);

		let bccEmails = event.target["BCC"];
		if (bccEmails)
			bccEmails = bccEmails.value
				.split(",")
				.map((item) => item.trim())
				.join(",");

		if (bccEmails && bccEmails.split(",").some((email) => !checkEmail(email))) {
			highlightErrorOnElement(bccEmailRef.current, true, 5);
			console.log("One or more of the emails are invalid. Form not submitted.");
			return;
		}

		console.log("bcc emails", bccEmails);

		let fromName = event.target["fromName"];
		if (fromName) fromName = fromName.value;
		console.log("from name", fromName);

		let blurEmailString = company?.fields?.blurMe;
		let blurEmailCheckbox = event.target["blurEmail"];
		if (blurEmailCheckbox) blurEmailString = blurEmailCheckbox.checked === false ? "false" : "true";
		console.log("blurMe", blurEmailString);

		let lastSentValue = company?.fields?.lastSent;
		if (!lastSentValue) {
			lastSentValue = new Date(2024, 0, 1).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" });
		}

		// Debt
		var debt = event.target["Debt"].value.replace(",", "");
		console.log("Debt", event.target["Debt"].value);

		// Annual Traffic
		var annualTraffic = event.target["AnnualTraffic"].value.replace(",", "");
		console.log("AnnualTraffic", event.target["AnnualTraffic"].value);

		// Operational Costs
		var operationalCosts = event.target["OperationalCosts"].value.replace(",", "");
		console.log("OperationalCosts", event.target["OperationalCosts"].value);

		// List Size
		var listSize = event.target["ListSize"].value.replace(",", "");
		console.log("ListSize", event.target["ListSize"].value);

		console.log(longDescription, "longDescription")

		fetch("https://api.airtable.com/v0/" + baseId + "/" + companiesTableId + "/" + companyId, {
			method: "PATCH",
			headers: {
				Authorization: `Bearer ${apiKey}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				fields: {
					Name: companyName,
					Employees: parseFloat(employees),
					Address: location,
					Country: country,
					Industry: industry,
					Founded: parseFloat(founded),
					LinkedInUrl: linkedInUrl,
					AnnualRevenue: parseFloat(annualRevenue),
					ConversionRate: parseFloat(conversionRate),
					AvgDealSize: parseFloat(avgDealSize),
					Debt: parseFloat(debt),
					AnnualTraffic: parseFloat(annualTraffic),
					OperationalCosts: parseFloat(operationalCosts),
					ListSize: parseFloat(listSize),
					LikelyCustomers: likelyCustomers,
					Description: description,
					LongDescription: longDescription,
					Technologies: technology,
					"Profitable?": profitable,
					ExcludeGrants: flaggedGrants?.map((grant) => grant.fields.Name).join(","),
					ProvinceOrState: provincesOrStates,
					sendFrequency: sendingFrequency,
					reportingEmails: reportingEmailsValue,
					RequestEmail: requestEmails,
					People1Name: person1Name,
					ccEmails: ccEmails,
					bccEmails: bccEmails,
					fromName: fromName,
					blurMe: blurEmailString,
					lastSent: lastSentValue,
				},
			}),
		})
			.then((response) => response.json())
			.then((data) => console.log(data))
			.catch((error) => console.error("Error:", error));

		setOpen((prev) => !prev);
		setTimeout(() => setFormSubmitted(true), 1000);

		setCompany((prev) => {
			let newCompany = prev;
			newCompany = {
				...newCompany,
				fields: {
					...newCompany.fields,
					Name: companyName,
					Employees: parseFloat(employees),
					Address: location,
					Country: country,
					Industry: industry,
					Founded: parseFloat(founded),
					LinkedInUrl: linkedInUrl,
					AnnualRevenue: parseFloat(annualRevenue),
					ConversionRate: parseFloat(conversionRate),
					AvgDealSize: parseFloat(avgDealSize),
					Debt: parseFloat(debt),
					AnnualTraffic: parseFloat(annualTraffic),
					OperationalCosts: parseFloat(operationalCosts),
					ListSize: parseFloat(listSize),
					LikelyCustomers: likelyCustomers,
					Description: description,
					LongDescription: longDescription,
					Technologies: technology,
					"Profitable?": profitable,
					ExcludeGrants: flaggedGrants?.map((grant) => grant.fields.Name).join(","),
					ProvinceOrState: provincesOrStates,
					sendFrequency: sendingFrequency,
					reportingEmails: reportingEmailsValue,
					RequestEmail: requestEmails,
					People1Name: person1Name,
					ccEmails: ccEmails,
					bccEmails: bccEmails,
					fromName: fromName,
					blurMe: blurEmailString,
					lastSent: lastSentValue,
				},
			};
			return newCompany;
		});

		setIndustryOptions(getNewIndustrySelectOptions());
	}

	// Add this new function for handling Select changes
	const handleSelectChange = (selectedOptions) => {
		const inputGrants = selectedOptions.map((option) => JSON.parse(option.value));

		eligibleGrants.forEach((grant) => {
			if (!inputGrants.map((grant) => grant.fields.Name).includes(grant.fields.Name)) {
				setFlaggedGrants((prevArray) => [...prevArray, grant]);
			}
		});

		flaggedGrants.forEach((grant) => {
			if (inputGrants.map((grant) => grant.fields.Name).includes(grant.fields.Name)) {
				setFlaggedGrants(flaggedGrants.filter((cur) => cur.fields.Name !== grant.fields.Name));
			}
		});
	};

	// Modify the existing handleChange for regular inputs
	const handleChange = (event) => {
		// Handle regular input changes if needed
		console.log(event.target.name, event.target.value);
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-50" onClose={setOpen}>
				<div className="fixed inset-0" />
				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto w-screen max-w-md">
									{/* Side Panel */}
									<form
										className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
										onSubmit={handleSubmit}
									// disabled={restricted}
									>
										<div className="h-0 flex-1 overflow-y-auto">
											<div className="bg-white-700 px-4 pt-6 pb-0 sm:px-6">
												<div className="flex items-center justify-between">
													<Dialog.Title className="text-base font-semibold leading-6 text-white">New Project</Dialog.Title>
													<div className="mr-10 sm:mr-0 flex h-7 items-center">
														<button
															type="button"
															className="rounded-md bg-white-700 text-white-200 hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-white"
															onClick={() => setOpen(false)}
														>
															<span className="sr-only">Close panel</span>
															<XMarkIcon className="h-6 w-6" aria-hidden="true" />
														</button>
													</div>
												</div>
											</div>
											<div className="flex flex-1 flex-col justify-between">
												<div className="divide-y divide-gray-200 px-4 sm:px-6">
													<div className="space-y-6 pb-5">
														<div>
															<h1 className="font-bold">Welcome, Team {company?.fields?.Name}</h1>
														</div>
														{/* {signedUp ? (
															<>
																<h1 className="font-bold text-lg text-gray-600">Welcome Back {userEmail}</h1>
																<button
																	type="button"
																	className="m-auto inline-flex items-center gap-x-2 rounded-md bg-stone-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-900 mt-2"
																	onClick={() => {
																		setOpen(false);
																		Cookies.remove("signed_up");
																		setSignedUp(Cookies.get("signed_up"));
																		Cookies.remove("email");
																		setUserEmail(Cookies.get("email"));
																		Cookies.remove("domain");
																		setUserDomain("");
																	}}
																>
																	Logout
																</button>
															</>
														) : (
															<button
																type="button"
																className="m-auto inline-flex items-center gap-x-2 rounded-md bg-stone-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-stone-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-900 mt-2"
																onClick={() => {
																	setOpen(false);
																	setGetEmail(true);
																	setEmailCloseable(true);
																}}
															>
																Sign Up for Savings
															</button>
														)} */}
														{restricted ? (
															<div>
																<div className="font-bold">Sorry, you do not have permission to edit this company's information</div>
																<img
																	className="mt-20"
																	src="https://images.unsplash.com/photo-1517849845537-4d257902454a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
																/>
															</div>
														) : (

															<>
																<div className="relative">
																	<label 
																		htmlFor="company-name" 
																		className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
																		>
																		Company Name
																	</label>
																	<div className="mt-1">
																		<input
																			type="text"
																			name="companyName"
																			id="companyName"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																			placeholder="Lindsey Kenney"
																			defaultValue={company && company.fields ? company.fields.Name : "Great Co"}
																		/>
																	</div>
																</div>

																<div className="sm:col-span-3 relative">
																	<label htmlFor="first-name" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
																		Employees
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			id="employees"
																			name="employees"
																			onChange={handleChange}
																			autoComplete="given-name"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																			placeholder="21"
																			defaultValue={company && company.fields ? company.fields.Employees : "Great Co"}
																		/>
																	</div>
																</div>
																{/* <div>
																	<h3 className="text-sm font-medium leading-6 text-gray-900">Team Members</h3>
																	<div className="mt-2">
																		<div className="flex space-x-2">
																			<a key="Hello.com" href="#" className="rounded-full hover:opacity-75">
																				<img
																					className="inline-block h-8 w-8 rounded-full"
																					src={company && company.fields ? company.fields.People1PictureUrl : ""}
																					alt="Hello.com"
																				/>
																			</a>
																			<button
																				type="button"
																				className="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border-2 border-dashed border-gray-200 bg-white text-gray-400 hover:border-gray-300 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
																			>
																				<span className="sr-only">Add team member</span>
																				<PlusIcon className="h-5 w-5" aria-hidden="true" />
																			</button>
																		</div>
																	</div>
																</div> */}

																<div className="sm:col-span-3 relative">
																	<label htmlFor="first-name" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
																		Location
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="location"
																			id="location"
																			autoComplete="given-name"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																			placeholder="Vancouver, Canada"
																			defaultValue={company && company.fields ? company.fields.Address : "Great Co"}
																		/>
																	</div>
																</div>
																<div className="sm:col-span-3 relative">
																	<label htmlFor="first-name" className="absolute -top-1.5 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900 z-10">
																		Country
																	</label>
																	<div className="mt-2">
																		<Select
																			styles={{
																				menu: (provided) => ({
																					...provided,
																					zIndex: 9999, // Ensure the dropdown is above other elements
																				}),
																			}}
																			options={countryOptions}
																			isMulti
																			defaultValue={
																				company?.fields?.Country
																					? company.fields.Country.split(",").map((code) => ({
																						value: code,
																						label: countryCodes[code]
																							?.split(" ")
																							.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
																							.join(" "),
																					}))
																					: null

																				// [{ value: company.fields.Country, label: countryCodes[company.fields.Country]}]
																			}
																			type="text"
																			name="country"
																			id="country"
																			autoComplete="given-name"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																		// placeholder="Canada"
																		/>
																	</div>
																</div>

																<div className="sm:col-span-3 relative">
																	<label htmlFor="first-name" className="absolute -top-1.5 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900 z-10">
																		Province Or State
																	</label>
																	<div className="mt-2">
																		<Select
																			styles={{
																				menu: (provided) => ({
																					...provided,
																					zIndex: 9999, // Ensure the dropdown is above other elements
																				}),
																			}}
																			options={provinceOptions}
																			isMulti
																			defaultValue={
																				company?.fields?.ProvinceOrState
																					? provinceOptions.filter((option) =>
																						company.fields.ProvinceOrState.split(",").includes(option.value)
																					)
																					: null
																			}
																			type="text"
																			name="province"
																			id="province"
																			autoComplete="given-name"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																		/>
																	</div>
																</div>

																<div className="sm:col-span-3 relative">
																	<label htmlFor="first-name" className="absolute -top-1.5 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900 z-10">
																		Industry
																	</label>
																	<div className="mt-2">
																		<Select
																			styles={{
																				menu: (provided) => ({
																					...provided,
																					zIndex: 9999, // Ensure the dropdown is above other elements
																				}),
																			}}
																			options={industryOptions}
																			isMulti
																			defaultValue={
																				company?.fields?.Industry
																					? company.fields.Industry.split(",").map((industry) => ({ value: industry, label: industry }))
																					: null

																				// [{ value: company.fields.Country, label: countryCodes[company.fields.Country]}]
																			}
																			type="text"
																			name="industry"
																			id="industry"
																			autoComplete="given-name"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																		/>
																	</div>
																</div>
																<div className="sm:col-span-3 relative">
																	<label htmlFor="first-name" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
																		Founded
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="founded"
																			id="founded"
																			autoComplete="given-name"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																			placeholder="2014"
																			defaultValue={company && company.fields ? company.fields.Founded : 2012}
																		/>
																	</div>
																</div>
																<div className="sm:col-span-3 relative">
																	<label htmlFor="first-name" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
																		LinkedInUrl
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="linkedInUrl"
																			id="linkedInUrl"
																			autoComplete="given-name"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																			placeholder="enter linkedin url here"
																			defaultValue={company && company.fields ? company.fields.LinkedInUrl : "enter linkedin url here"}
																		/>
																	</div>
																</div>
																<div className="sm:col-span-3 relative">
																	<label htmlFor="annualRevenue" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
																		Annual Revenue
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="annualRevenue"
																			id="annualRevenue"
																			autoComplete="given-name"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																			placeholder="3,294"
																			defaultValue={company?.fields?.AnnualRevenue ? company.fields.AnnualRevenue : ""}
																		/>
																	</div>
																</div>
																																{/* technologies multi select */}
																																<div className="sm:col-span-3 relative">
																	<label htmlFor="technology" className="absolute -top-1.5 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900 z-10">
																		Technologies
																	</label>
																	<div className="mt-2">
																		<CreatableSelect
																			styles={{
																				menu: (provided) => ({
																					...provided,
																					zIndex: 9999, // Ensure the dropdown is above other elements
																				}),
																			}}
																			isMulti
																			defaultValue={
																				company?.fields?.Technologies
																					? company.fields.Technologies.split(",").map((technology) => ({
																						value: technology,
																						label: technology,
																					}))
																					: null

																				// [{ value: company.fields.Country, label: countryCodes[company.fields.Country]}]
																			}
																			type="text"
																			name="technology"
																			id="technology"
																			autoComplete="given-name"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																		/>
																	</div>
																</div>
																<div>
																	<label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="profitable-checkbox">
																		Profitable?
																		<input
																			className="ml-3"
																			id="profitable-checkbox"
																			name="profitable-checkbox"
																			type="checkbox"
																			defaultChecked={company?.fields["Profitable?"] === "Yes" ? true : false}
																		/>
																	</label>
																</div>
																<div className="sm:col-span-3 relative">
																	<label htmlFor="first-name" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
																		Conversion Rate (Visit to Sign Up to Deal)
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="conversionRate"
																			id="conversionRate"
																			autoComplete="given-name"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																			placeholder="2.3%"
																			defaultValue={company?.fields?.ConversionRate ? company.fields.ConversionRate : 2}
																		/>
																	</div>
																</div>
																<div className="sm:col-span-3 relative">
																	<label htmlFor="first-name" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
																		Average Deal Size
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="avgDealSize"
																			id="avgDealSize"
																			onChange={handleChange}
																			autoComplete="given-name"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																			placeholder="$12,000"
																			defaultValue={company?.fields?.AvgDealSize ? company.fields.AvgDealSize : 10000}
																		/>
																	</div>
																</div>

																<div className="sm:col-span-3 relative">
																	<label htmlFor="first-name" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
																		Debt
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="Debt"
																			id="Debt"
																			autoComplete="given-name"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																			placeholder="$12,000"
																			defaultValue={company?.fields?.Debt ? company.fields.Debt : 10000}
																		/>
																	</div>
																</div>

																<div className="sm:col-span-3 relative">
																	<label htmlFor="first-name" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
																		Annual Traffic
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="AnnualTraffic"
																			id="AnnualTraffic"
																			autoComplete="given-name"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																			placeholder="$12,000"
																			defaultValue={company?.fields?.AnnualTraffic ? company.fields.AnnualTraffic : 10000}
																		/>
																	</div>
																</div>

																<div className="sm:col-span-3 relative">
																	<label htmlFor="first-name" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
																		Operational Costs
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="OperationalCosts"
																			id="OperationalCosts"
																			autoComplete="given-name"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																			placeholder="$12,000"
																			defaultValue={company?.fields?.OperationalCosts ? company.fields.OperationalCosts : 10000}
																		/>
																	</div>
																</div>

																<div className="sm:col-span-3 relative">
																	<label htmlFor="first-name" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
																		Email List Size
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="ListSize"
																			id="ListSize"
																			autoComplete="given-name"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																			placeholder="$12,000"
																			defaultValue={company?.fields?.ListSize ? company.fields.ListSize : 10000}
																		/>
																	</div>
																</div>

																<div className="sm:col-span-3 relative">
																	<label htmlFor="first-name" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
																		Likely Customers
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="likelyCustomers"
																			id="likelyCustomers"
																			autoComplete="given-name"
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																			placeholder="CEOs, COOs, Owners, Directors"
																			defaultValue={
																				company?.fields?.LikelyCustomers ? company.fields.LikelyCustomers : "CEOs, COOs, Owners, Directors"
																			}
																		/>
																	</div>
																</div>
																<div className="col-span-full relative">
																	<label htmlFor="about" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
																		Description
																	</label>
																	<div className="mt-2">
																		<textarea
																			id="description"
																			name="description"
																			rows={3}
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																			placeholder="Lindsay Kenney LLP is a full service, Canadian west coast law firm providing legal services to local, regional, national and international clients from our offices in downtown Vancouver and Langley. We are owned and operated locally and have been in business in the Lower Mainland and Fraser Valley for over 40 years. We represent a broad spectrum of business enterprises, institutional clients, insurance companies and individuals. We provide litigation and dispute resolution services and practical, personalized business advice. Client service is a priority at Lindsay Kenney LLP."
																			defaultValue={company?.fields?.Description ? company.fields.Description : "Great Co"}
																		/>
																	</div>
																</div>
																<div className="sm:col-span-full">
																	<label className="block text-sm font-medium leading-6 text-gray-900">Manage Company Contact(s)</label>
																	<p className="block text-xs font-small text-gray-700 mb-2">
																		Enter lists of emails (separated by commas!)
																	</p>
																	<span className="items-center justify-center my-4">
																		<label className="block text-sm font-medium leading-6 text-gray-700">To Email(s):</label>
																		<textarea
																			ref={requestEmailRef}
																			defaultValue={company?.fields?.RequestEmail || ""}
																			id="requestEmail"
																			name="requestEmail"
																			className="block text-sm rounded-md p-3 ml-2 max-w-screen w-full h-100 resize-y"
																		/>
																	</span>
																	<span className="items-center justify-center my-4">
																		<label className="block text-sm font-medium leading-6 text-gray-700 mt-4">
																			Who's CC'd on this email?
																		</label>

																		<textarea
																			ref={ccEmailRef}
																			id="CC"
																			name="CC"
																			defaultValue={company?.fields?.ccEmails || ""}
																			className="block text-sm max-w-screen max-w-screen w-full p-3 rounded-md resize-y h-100 ml-2"
																		/>
																	</span>
																	<span className="inline-flex items-center justify-center grid grid-cols-1 my-4">
																		<label className="block text-sm font-medium leading-6 text-gray-700">Name of First Contact</label>
																		<input
																			id="People1Name"
																			name="People1Name"
																			defaultValue={company?.fields?.People1Name || ""}
																			className="block text-sm max-w-screen w-sm p-3 rounded-md h-8 ml-2"
																		/>
																	</span>
																	{admin && (
																		<>
																			{/* <label className="block text-sm font-medium leading-6 text-gray-900">Admin:</label> */}
																			<span className="items-center justify-center my-4">
																				<label className="block text-sm font-medium leading-6 text-gray-900 mt-3">
																					Who's BCC'd on this email?
																				</label>
																				<textarea
																					ref={bccEmailRef}
																					id="BCC"
																					name="BCC"
																					defaultValue={company?.fields?.bccEmails || ""}
																					className="block text-sm max-w-screen w-full p-3 resize-y rounded-md h-100 ml-2"
																				/>
																			</span>
																			<span className="items-center justify-center my-4">
																				<label className="block text-sm font-medium leading-6 text-gray-900 mt-3">
																					Who's this email from? (Enter a name)
																				</label>
																				<input
																					id="fromName"
																					name="fromName"
																					defaultValue={company?.fields?.fromName || ""}
																					className="block text-xs max-w-screen w-full p-3 rounded-md h-8 ml-2"
																				/>
																			</span>
																			<span className="inline-flex items-center justify-center my-4">
																				<label className="block text-sm font-medium leading-6 text-gray-900">
																					Should this email be blurred?
																				</label>
																				<input
																					type="checkbox"
																					id="blurEmail"
																					name="blurEmail"
																					defaultChecked={company.fields.blurMe === "true"}
																					className="block text-sm ml-3"
																				/>
																			</span>
																		</>
																	)}
																</div>
																<div className="sm:col-span-full">
																	<label className="block text-sm font-medium leading-6 text-gray-900">Sending Frequency</label>
																	<select
																		id="sendingFrequencyOption"
																		defaultValue={
																			!company?.fields?.reportingEmails ||
																				company.fields.reportingEmails === "false" ||
																				!company?.fields?.sendFrequency
																				? "-1"
																				: company.fields.sendFrequency
																		}
																		className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block max-w-sm w-full"
																	>
																		{sendingFrequencyOptions.map((option) => (
																			<option key={option.label} value={option.value}>
																				{option.label}
																			</option>
																		))}
																	</select>
																</div>
																{admin && company?.fields?.Industry && (
																	<div className="text-sm">
																		<button
																			type="button"
																			className="rounded-md bg-stone-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-stone-600"
																			onClick={() => setOpenIndustriesModal(true)}
																		>
																			See Industries Mapping
																		</button>
																	</div>
																)}
																{secret_1 && (
																	<div className="sm:col-span-3">
																		<label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
																			Eligible Grants
																		</label>
																		<div className="mt-2">
																			<Select
																				isMulti
																				onChange={handleSelectChange}
																				options={
																					flaggedGrants
																						? flaggedGrants.map((grant) => ({ value: JSON.stringify(grant), label: grant.fields.Name }))
																						: null
																				}
																				defaultValue={
																					eligibleGrants
																						? eligibleGrants.map((grant) => ({ value: JSON.stringify(grant), label: grant.fields.Name }))
																						: null

																					// [{ value: company.fields.Country, label: countryCodes[company.fields.Country]}]
																				}
																				type="text"
																				name="eligibleGrants"
																				id="eligibleGrants"
																				autoComplete="given-name"
																				className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>
																)}
																{/* Admin Endless Fries Buttons */}
																{/* {console.log("admin", admin)}
																{console.log("secret_1", secret_1)} */}
																{admin && (
																	<div className="flex min-h-full flex-1 flex-col justify-center items-center py-12 sm:px-6 lg:px-8 bg-red-50">
																		<NotificationButtons find_grants={find_grants} findDiscounts={findDiscounts} />
																	</div>
																)}
																{/* Edit Company Info */}
																<div className="col-span-full relative">
																	<label htmlFor="about" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
																		Website Info
																	</label>
																	<div className="mt-2">
																		<textarea
																			id="longDescription"
																			name="longDescription"
																			rows={15}
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
																			placeholder="Lindsay Kenney LLP is a full service, Canadian west coast law firm providing legal services to local, regional, national and international clients from our offices in downtown Vancouver and Langley. We are owned and operated locally and have been in business in the Lower Mainland and Fraser Valley for over 40 years. We represent a broad spectrum of business enterprises, institutional clients, insurance companies and individuals. We provide litigation and dispute resolution services and practical, personalized business advice. Client service is a priority at Lindsay Kenney LLP."
																			defaultValue={company?.fields?.LongDescription ? company.fields.LongDescription : "Great Co"}
																		/>
																	</div>
																</div>


															</>
														)}
													</div>
												</div>
											</div>
										</div>
										<div className="mr-30 flex flex-shrink-0 justify-left px-4 py-4">
											<button
												type="button"
												className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
												onClick={() => setOpen(false)}
											// title={restricted ? "Sorry, you do not have permission" : null}
											>
												Cancel
											</button>
											<button
												type="submit"
												className="ml-4 inline-flex rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
											// disabled={restricted}
											>
												Save
											</button>
										</div>
									</form>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
				{admin && company?.fields?.Industry && (
					<IndustriesMappingModal
						open={openIndustriesModal}
						setOpen={setOpenIndustriesModal}
						company={company}
						industries={industries}
						setIndustries={setIndustries}
						industryRecords={industryRecords}
						setIndustryRecords={setIndustryRecords}
					/>
				)}
			</Dialog>
		</Transition.Root>
	);
}
