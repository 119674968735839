import {
  React,
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { FunnelIcon, TrashIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  BarsArrowDownIcon,
  BarsArrowUpIcon,
} from "@heroicons/react/24/outline";
import { currencyFormat, classNames, extractDomain } from "./helpers.js";
import { Tooltip } from "@material-tailwind/react";
import Airtable from "airtable";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Menu } from "@headlessui/react";
import { send } from "emailjs-com";
import { AppContext } from "./AppContext.js";
import { useUser } from "@clerk/clerk-react";

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;

const base = new Airtable({ apiKey }).base(baseId);

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

const pageSize = 36;

export default function AutomationRequests({}) {
  const [automations, setAutomations] = useState([]);
  const [automationRequests, setAutomationRequests] = useState([]);
  // User Validation
  const { user, isSignedIn, isLoaded: isUserLoaded } = useUser();

  useEffect(() => {
    base("automations")
      .select({})
      .all()
      .then((records) => {
        console.log(records);
        setAutomations(
          records.map((record) => ({
            id: record.id,
            Name: record.fields.Name,
            Votes: record.fields.Votes || 0,
            UserVotes: record.fields.UserVotes || [],
          }))
        );
        // setAutomations(records);
        console.log("yes!");
      });
  }, []);

  const handleUpvote = (automationId) => {
    // Check if user has already voted for this item
    const automation = automations.find((i) => i.id === automationId);
    if (automation?.fields?.UserVotes.includes(user.id)) {
      alert("You've already voted for this item!");
      return;
    }

    // Add vote logic
    base("Votes").create(
      [
        {
          fields: {
            User: [user.id], // Assuming user is logged in
            Automation: [automationId],
          },
        },
      ],
      (err, records) => {
        if (err) {
          console.error(err);
          return;
        }
        // Update the UI after successful vote
        setAutomations(
          automations.map((i) =>
            i.id === automationId
              ? {
                  ...i,
                  votes: i.votes + 1,
                  userVotes: [...i.userVotes, user.id],
                }
              : i
          )
        );
      }
    );
  };

  useEffect(() => {
    base("automationRequests")
      .select({ view: "Grid view" })
      .all()
      .then((data) => {
        setAutomationRequests(data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <div className="bg-white px-6 py-12 sm:py-12 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Automation Requests
          </h2>
          <h3 className="mt-4 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            {" "}
            {currencyFormat(
              automationRequests.reduce((sum, automation) => {
                return sum + (automation.fields.Budget || 0);
              }, 0)
            )}
            /yr
          </h3>

          <p className="mt-6 text-lg leading-8 text-gray-600">
            Recent automation requests we think are a good fit for you
          </p>
          <br />
        </div>
        {/* <h1>Upvotable Board</h1>
        <ul>
          {automations.map((automation) => (
            <li key={automation.id}>
              {automation?.Name} - {automation?.Votes} votes
              <button onClick={() => handleUpvote(automation.id)}>
                Upvote
              </button>
            </li>
          ))}
        </ul> */}

        <div className="mx-auto max-w-7xl text-center">
          <div className="grid grid-cols-3 gap-4">
            {automationRequests.map((automation, index) => {
              const fields = automation?.fields;

              // Keys to be excluded
              const excludedKeys = [
                "firstName",
                "lastName",
                "phoneNumber",
                "legalBusinessName",
                "companyWebsite",
                "email",
                "CompanyName",
                "ClientEmail",
                "PartnerEmail",
              ];

              // Fields to bring to the top
              const topFields = [
                {
                  label: "Automation Description",
                  key: "AutomationDescription",
                },
                {
                  label: "Budget",
                  key: "Budget",
                  format: (value) => `${value}/yr`,
                },
                { label: "Date", key: "Date" },
              ];

              // Filter out excluded keys and remove top fields from the general list
              const filteredEntries = Object.entries(fields).filter(
                ([key]) =>
                  !excludedKeys.includes(key) &&
                  !topFields.some((topField) => topField.key === key)
              );

              return (
                <div
                  key={index}
                  className="bg-white p-4 rounded-lg shadow-md mb-4"
                >
                  {topFields.map(({ label, key, format }) => {
                    const value = fields[key];
                    if (!value) return null;
                    const formattedValue = format ? format(value) : value;
                    return (
                      <div key={key} className="flex flex-col mb-4">
                        <span className="font-medium text-lg">{label}</span>
                        <span className="text-xl text-green-600">
                          {formattedValue}
                        </span>
                      </div>
                    );
                  })}
                  {filteredEntries.map(([key, value]) => (
                    <div key={key} className="flex flex-col mb-2">
                      <span className="font-medium">{key}</span>
                      <span className="text-gray-600">{value}</span>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

AutomationRequests.defaultProps = {
  admin: false,
};
