import React, { useState, useEffect, useRef } from 'react';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SimpleMultiStepForm from './SimpleMultiStepForm';
import FinanceForm from "./FinanceForm";

// function Icon({ id, open }) {
// 	return (
// 		<svg
// 			xmlns="http://www.w3.org/2000/svg"
// 			className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
// 			fill="none"
// 			viewBox="0 0 24 24"
// 			stroke="currentColor"
// 			strokeWidth={2}
// 		>
// 			<path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
// 		</svg>
// 	);
// }

function Financing({ company, isLoading, userEmail }) {
	// let Tally;

	// const tallyEmbedLink = "https://tally.so/embed/3xMLD5?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1";
	// // Old
	// // https://tally.so/embed/mKVJMk?transparentBackground=1&dynamicHeight=1
	// //
	// // New
	// // https://tally.so/embed/3xMLD5?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1

	// const script = document.createElement("script");

	// script.src = "https://tally.so/widgets/embed.js";
	// script.async = true;
	// script.id = "tally-form";
	// script.onload = () => {
	// 	return Tally?.loadEmbeds();
	// };

	// document.body.appendChild(script);

	// <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
	// 	<iframe
	// 		data-tally-src={tallyEmbedLink}
	// 		loading="lazy"
	// 		width="100%"
	// 		height="571"
	// 		title="Upgraded Financing Quote Application"
	// 	></iframe>
	// </div>

	return (
		<>
			{isLoading ? (
				<Skeleton height={600} />
			) : (
				<>
					{/* <div className='bg-white rounded-lg mx-4 p-4'>
						<h1 className='text-2xl text-center'>Financing Application</h1>
						<br />
						<br />
						<br />
						<SimpleMultiStepForm showStepNumber={true} />
					</div> */}

					<div className="bg-white rounded-lg mx-4 p-4">
						<h1 className="text-gray-800 text-2xl text-center font-semibold mb-6">You're Steps Away From Up to $800,000</h1>
						<h1 className="text-gray-900 text-md text-center font-semibold mb-6">Funds in under 7 days • 8 - 24% interest • No hidden fees • No collateral </h1>
						<h1 className="text-gray-900 text-sm text-center font-semibold mb-6">(Asking for more than 40% to 70% annual revenue significantly reduces likelihood of success)</h1>
						<FinanceForm userEmail={userEmail} company={company} />
					</div>
				</>
			)}
		</>
	);
}

export default Financing;
