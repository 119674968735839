import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { InfinitySpin } from "react-loader-spinner";
import { setShowNotif, setContents } from "./helpers";

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const grantTableId = process.env.REACT_APP_AIR_GRANTS;

export default function DeleteGrantDialog({ open, setOpen, grant, setGrants }) {
	const cancelButtonRef = useRef(null);
	const [loadingArchive, setLoadingArchive] = useState(false);
	const [showResultNotification, setShowResultNotification] = useState({
		showNotif: false,
		contents: { type: "", heading: "", message: "" },
	});

	const deleteGrant = (recordId) => {
		fetch("https://api.airtable.com/v0/" + baseId + "/" + grantTableId + "/" + "recordId", {
			method: "DELETE",
			headers: {
				Authorization: `Bearer ${apiKey}`,
				"Content-Type": "application/json",
			},
		})
			.then((response) => response.json())
			.then((confirmation) => {
				console.log("Record Deleted Confirmation", confirmation);

				let contents = { type : "success", heading : "Record Deleted Confirmation", message : JSON.stringify(confirmation)};
				setShowNotif(true, setShowResultNotification);
				setContents(contents, setShowResultNotification);

				setOpen(false);
			})
			.catch((e) => {
				console.log(e);

				let contents = { type : "error", heading : "Error In Deleting Record", message : e.message};
				setShowNotif(true, setShowResultNotification);
				setContents(contents, setShowResultNotification);
			});

		setGrants((prev) => prev.filter((g) => g?.recordId != recordId && g?.id != recordId));
	};

	const archiveGrant = async (recordId) => {
		setLoadingArchive(true);
		fetch("https://api.airtable.com/v0/" + baseId + "/" + grantTableId + "/" + recordId, {
			method: "PATCH",
			headers: {
				Authorization: `Bearer ${apiKey}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				fields: {
					Eligible_Industry: "",
				},
			}),
		})
			.then((response) => response.json())
			.then((confirmation) => {
				console.log("Record Archived Confirmation", confirmation);
				setOpen(false);
			})
			.catch((e) => console.log(e))
			.finally(() => setLoadingArchive(false));

		// console.log('archived grant!');
		setGrants((prev) => prev.filter((g) => g?.recordId != recordId && g?.id != recordId));
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
								<div className="sm:flex sm:items-start">
									<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-200 sm:mx-0 sm:h-10 sm:w-10">
										<ExclamationTriangleIcon className="h-6 w-6 text-black" aria-hidden="true" />
									</div>
									<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
										<Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
											Removing <p className="text-sm">{grant?.fields?.Name}</p>
										</Dialog.Title>
										<div className="mt-2 space-y-2">
											<p className="text-sm text-gray-500">
												<strong>Deleting</strong> is permanent. This action can not be undone.
											</p>
											<p className="text-sm text-gray-500">
												<strong>Archiving</strong> can be undone.
											</p>
										</div>
									</div>
								</div>
								<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
									<button
										type="button"
										className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
										onClick={() => {
											deleteGrant(grant.recordId);
										}}
									>
										Delete
									</button>
									{loadingArchive ? (
										<InfinitySpin visible={true} width="100" color="white" ariaLabel="infinity-spin-loading" />
									) : (
										<button
											type="button"
											className="mt-3 ml-3 inline-flex w-full justify-center rounded-md bg-yellow-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-white hover:bg-yellow-400 sm:mt-0 sm:w-auto"
											onClick={() => {
												archiveGrant(grant.recordId);
											}}
										>
											Archive
										</button>
									)}
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
										onClick={() => setOpen(false)}
										ref={cancelButtonRef}
									>
										Cancel
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
